/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { Fragment } from 'react';
import { MatrixQuestionDefaultProps, QuestionBase } from './QuestionBase';

export interface MatrixRadioQuestionProps extends MatrixQuestionDefaultProps {
  currentAnswered: number[];
}

export function MatrixRadioQuestion(
  { question, currentAnswered, isMobile, addAnswer }: MatrixRadioQuestionProps,
): JSX.Element {
  const handleCheck = (rowIndex: number, choiceIndex: number): void => {
    const newAnswer = [...currentAnswered];
    newAnswer[rowIndex] = choiceIndex;
    addAnswer(newAnswer, question.id);
  };

  if (isMobile) {
    return (
      <QuestionBase question={question}>
        {question.rows.map((row) => (
          <div className='form-light dropdown' key={row.id}>
            <p>{row.value}</p>
            <select
              id={row.id.toString()}
              className='form-control'
              onChange={(e) => handleCheck(row.id, Number(e.target.value))}
              value={
                currentAnswered[row.id] == null
                  ? 'default'
                  : currentAnswered[row.id]
              }
              data-cy={`${question.id}-${row.id}`}
            >
              <option value='default' disabled>
                -- select an option --
              </option>
              {question.choices.map((choice, choiceIndex) => (
                <option value={choiceIndex} key={choiceIndex}>
                  {choice}
                </option>
              ))}
            </select>
          </div>
        ))}
      </QuestionBase>
    );
  }
  return (
    <QuestionBase question={question} matrix>
      <div
        style={{
          gridTemplateColumns: `4fr
            repeat(${question.choices.length}, 1fr)`,
        }}
        className='matrix-grid'
        key={Math.random()}
      >
        <div className='title' />
        { question.choices.map((choice, choiceIndex) => (
          <div className='matrix-cell choice' key={choiceIndex}>
            {choice}
          </div>
        ))}
        {question.rows.map((row) => (
          <Fragment key={row.id}>
            <div className='title'>{row.value}</div>

            {question.choices.map((choice, choiceIndex) => (
              <div key={choiceIndex} className='matrix-cell'>
                <input
                  className='survey-input'
                  type='radio'
                  id={`${question.id}-${row.id}.${choiceIndex}`}
                  name={`${question.id}-${row.id}-radio`}
                  checked={currentAnswered[row.id] === choiceIndex}
                  onChange={() => handleCheck(row.id, choiceIndex)}
                  data-cy={`${question.id}-${row.id}-${choiceIndex}`}
                />
              </div>
            ))}
          </Fragment>
        ))}
      </div>
    </QuestionBase>
  );
}
