/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import twitchLogo from '../../../_assets/twitch-drops-campaign/twitch.png';

export const definition: Definition = {
  title: { type: DefinitionPropertyType.TranslatedString },
  campaigns: { type: DefinitionPropertyType.Array,
    elements: {
      title: { type: DefinitionPropertyType.TranslatedString },
      description: { type: DefinitionPropertyType.TranslatedString },
      link: { type: DefinitionPropertyType.String },
      linkText: { type: DefinitionPropertyType.TranslatedString },
    } },
};

export interface Props {
  title: string,
  campaigns: { title: string, description: string, link: string, linkText: string }[]
}

export default function StreamingCampaignsCard({ title, campaigns }: Props): JSX.Element {
  return (
    <div>
      <div className='subtitle'>
        <h3>{title}</h3>
      </div>
      <div className='streaming-campaigns-card'>
        <div className='streaming-campaigns-card--header'>
          <img
            src={twitchLogo}
            alt={`${title} logo`}
            className='streaming-campaigns-card--header--img'
          />
        </div>
        {campaigns?.map((campaign) => (
          <div className='streaming-campaigns-card--section' key={campaign.title}>
            {campaign.title && <h4>{campaign.title}</h4>}
            {campaign.description && <span>{campaign.description}</span>}
            <a
              href={campaign.link}
              target='_blank'
              rel='noreferrer'
              className='btn btn-outline-light streaming-campaigns-card--button'
            >
              <OpenInNewIcon />
              {campaign.linkText}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
