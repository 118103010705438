/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import logoBhvr from '../../../../_assets/bhvr.svg';
import { RequestError, isRequestError } from '../../../../Error.model';
import { getProviderLogo } from '../../../../account/providers/ProviderLogo';
import { ProviderLinkButton } from '../../../../account/providers/provider/button/ProviderLinkButton';
import { ProviderUnlinkButton } from '../../../../account/providers/provider/button/ProviderUnlinkButton';
import { useAuth } from '../../../../authentication/AuthenticationContext';
import { buildAuthUrl } from '../../../../utils/Providers';
import { useSnackbar } from '../../../../snackbar/SnackbarContext';
import { RedeemingGameProvider,
  getLinkingProviderFromRedeemingProvider } from '../../../../account/providers/ProviderEnum';
import {
  StreamingCampaignActivationStep,
  useStreamingCampaign,
} from '../../../../contexts/StreamingCampaignContext';
import {
  ActivationStepComplete,
  StatusMessage,
} from './activation-steps/ActivationStepComplete';
import { ActivationStepContainer } from './activation-steps/ActivationStepContainer';
import { ActivationStepClaim } from './activation-steps/ActivationStepClaim';
import { ActivationStepProvider } from './activation-steps/ActivationStepProvider';
import { get } from '../../../../utils/Request';
import { useConfig } from '../../../../contexts/ConfigContext';

export const definition: Definition = {
  description: {
    type: DefinitionPropertyType.TranslatedString,
  },
};

interface Props {
  description: string;
}

export function Activation({ description }: Props): JSX.Element {
  const { campaign, activationStep, updateActivationStep } = useStreamingCampaign();
  const { t } = useTranslation(['streamingCampaigns', 'translation']);
  const config = useConfig();
  const snackbar = useSnackbar();
  const { user, logout, updateVisibleModal } = useAuth();

  const [error, setError] = useState<RequestError | null>(null);
  const [checkedForProviderError, setCheckedForProviderError] = useState(false);
  const [providerError, setProviderError] = useState<StatusMessage | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<RedeemingGameProvider | null>(
    user?.inventory?.[campaign?.gameId]?.preferredProvider || null,
  );

  const gameName = t(`translation:games.${campaign?.gameId}`);
  const streamingPlatform = campaign?.streamingPlatform;
  const gameId = campaign?.gameId;

  const streamingPlatformAuthUrl = buildAuthUrl(
    config.clientConfig?.providers[campaign?.streamingPlatform]?.authUrl || null,
    config.serverConfig?.providers[campaign?.streamingPlatform] || null,
    streamingPlatform,
  );
  const streamingPlatformAuthMethod = user?.authenticationMethods.find((method) => method.type === streamingPlatform);
  const streamingPlatformIsConnected = !!streamingPlatformAuthMethod;
  const streamingPlatformAccessTokenIsValid = streamingPlatformIsConnected
  && streamingPlatformAuthMethod?.isValidAccessTokens;
  const streamingPlatformIsOnlyAuthMethod = streamingPlatformIsConnected && user?.authenticationMethods.length === 1;

  const isLoggedIn = !!user;

  const relatedLinkProvider = getLinkingProviderFromRedeemingProvider(selectedProvider);
  const validSelectedProvider = selectedProvider && checkedForProviderError && !providerError;

  const gameNotOpenedError: StatusMessage = {
    id: 'game-not-opened',
    statusType: 'error',
    message: t('activation.errors.game-not-opened', { game: gameName,
      provider: t(`translation:${relatedLinkProvider}`) }),
  };

  const signInBhvr = (): void => { updateVisibleModal('loginOptions'); };
  const signUpBhvr = (): void => { updateVisibleModal('registerOptions'); };

  const isGameStatusValid = async (provider: RedeemingGameProvider): Promise<boolean> => {
    try {
      const gameStatusValid = await get<boolean>(
        `${config.clientConfig?.host}/players/me/accounts/${provider}/${gameId}/kraken-ownership`,
      );
      return gameStatusValid;
    } catch (e) {
      if (isRequestError(e)) {
        snackbar.addErrorMessage(e.code);
      }
    }
    return false;
  };

  useEffect(() => {
    if (isLoggedIn) {
      updateActivationStep(StreamingCampaignActivationStep.STREAMING_PLATFORM);
      if (streamingPlatformAccessTokenIsValid) {
        updateActivationStep(StreamingCampaignActivationStep.PROVIDER);
        if (validSelectedProvider) {
          updateActivationStep(StreamingCampaignActivationStep.CLAIM);
        }
      }
    } else {
      updateActivationStep(StreamingCampaignActivationStep.LOGIN);
    }
  }, [isLoggedIn, streamingPlatformAccessTokenIsValid, validSelectedProvider]);

  useEffect(() => {
    if (error) {
      snackbar.addErrorMessage(error.code);
    }
    if (user?.inventory?.[gameId]?.preferredProvider
      && user?.inventory?.[gameId]?.preferredProvider !== selectedProvider) {
      setSelectedProvider(user.inventory[gameId].preferredProvider);
    }
  }, [error, user]);

  useEffect(() => {
    const validateProvider = async (): Promise<void> => {
      if (selectedProvider) {
        const gameStatusValid = await isGameStatusValid(selectedProvider);
        if (!gameStatusValid) {
          setProviderError(gameNotOpenedError);
        }
        setCheckedForProviderError(true);
      } else {
        setProviderError(null);
        setCheckedForProviderError(false);
      }
    };

    validateProvider();
  }, [selectedProvider]);

  return (
    <div className='streaming-campaign-activation'>
      <p className='streaming-campaign-activation--description'>{description}</p>
      <Stepper
        activeStep={activationStep || 0}
        orientation='vertical'
        className='streaming-campaign-activation--stepper'
      >
        <ActivationStepContainer
          title={t('activation.steps.login.title')}
          active={activationStep === StreamingCampaignActivationStep.LOGIN}
          expanded={activationStep >= StreamingCampaignActivationStep.LOGIN}
        >
          {isLoggedIn && (
            <ActivationStepComplete
              imgSrc={logoBhvr}
              imgAlt='bhvr-logo'
              details={user?.email}
              chipLabel={t('translation:account.provider.connected')}
            >
              <button
                type='button'
                onClick={logout}
                className='btn btn-secondary'
                data-cy='bhvr-logout-btn'
              >
                {t('translation:button.logout')}
              </button>
            </ActivationStepComplete>
          )}
          {!isLoggedIn && (
            <>
              <button type='button' onClick={signUpBhvr} className='btn btn-primary-game'>
                {t('translation:auth.login.sign-up-page')}
              </button>
              <button type='button' onClick={signInBhvr} className='btn btn-secondary mx-3' data-cy='bhvr-sign-in-btn'>
                {t('translation:button.sign-in')}
              </button>
            </>
          )}
        </ActivationStepContainer>

        <ActivationStepContainer
          title={streamingPlatformIsConnected
            ? t(
              'activation.steps.streaming-platform.title-connected',
              { provider: t(`translation:${campaign.streamingPlatform}`) },
            )
            : t(
              'activation.steps.streaming-platform.title-not-connected',
              { provider: t(`translation:${campaign.streamingPlatform}`) },
            )}
          active={activationStep === StreamingCampaignActivationStep.STREAMING_PLATFORM}
          expanded={activationStep >= StreamingCampaignActivationStep.STREAMING_PLATFORM}
        >
          {streamingPlatformAccessTokenIsValid && (
            <ActivationStepComplete
              imgSrc={getProviderLogo(streamingPlatform)}
              imgAlt='streaming-platform-logo'
              chipLabel={t('translation:account.provider.connected')}
              statusMessages={streamingPlatformIsOnlyAuthMethod ? [{
                id: 'streaming-platform-auth',
                statusType: 'warning',
                message: t('translation:account.provider.onlyAuthenticationMethodWarning'),
              }] : null}
            >
              <ProviderUnlinkButton
                isAuthMethod={!!streamingPlatformAuthMethod}
                provider={streamingPlatform}
                setError={setError}
                showWarning={false}
                isOnlyAuthMethod={streamingPlatformIsOnlyAuthMethod}
                customLabel={t('translation:button.logout')}
              />
            </ActivationStepComplete>
          )}
          {!streamingPlatformAccessTokenIsValid && (
            <ProviderLinkButton
              provider={streamingPlatform}
              providerAccount={streamingPlatformAuthMethod}
              providerAuthUrl={streamingPlatformAuthUrl}
              setError={setError}
              isAuthProvider
              customLabel={streamingPlatformIsConnected
                ? t('translation:button.sign-in')
                : t('translation:account.my-account.link')}
              className='btn btn-primary-game'
            />
          )}
        </ActivationStepContainer>

        <ActivationStepContainer
          title={t('activation.steps.provider.title', { game: gameName })}
          active={activationStep === StreamingCampaignActivationStep.PROVIDER}
          expanded={activationStep >= StreamingCampaignActivationStep.PROVIDER}
        >
          <ActivationStepProvider
            campaign={campaign}
            selectedProvider={selectedProvider}
            isGameStatusValid={isGameStatusValid}
            setProviderError={setProviderError}
            gameNotOpenedError={gameNotOpenedError}
            setCheckedForProviderError={setCheckedForProviderError}
            providerError={providerError}
            setError={setError}
            setSelectedProvider={setSelectedProvider}
            relatedLinkProvider={relatedLinkProvider}
          />
        </ActivationStepContainer>

        <ActivationStepContainer
          title={t('activation.steps.claim.title')}
          active={activationStep === StreamingCampaignActivationStep.CLAIM}
          description={t('activation.steps.claim.description', { game: gameName })}
          expanded={activationStep === StreamingCampaignActivationStep.CLAIM}
          completed={activationStep === StreamingCampaignActivationStep.CLAIM}
        >
          <ActivationStepClaim gameId={gameId} participatingChannelsLink={campaign.participatingChannelsLink} />
        </ActivationStepContainer>
      </Stepper>
    </div>
  );
}

export default Activation;
