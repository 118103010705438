/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface BreadcrumbPath {
  key: string;
  path: string;
}

interface Props {
  paths: BreadcrumbPath[];
}

export function Breadcrumb(props: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className='custom-breadcrumb'>
      {props.paths.map((value, index) => {
        if (index === 0) {
          return (
            <Link
              className='link-path'
              to={value.path}
              key={index}
            >
              { t(value.key) }
            </Link>
          );
        }

        if (value.path) {
          return (
            <Fragment key={index}>
              <span className='chevron'>&gt;</span>
              <Link
                className='link-path'
                to={value.path}
              >
                { t(value.key) }
              </Link>
            </Fragment>
          );
        }

        return (
          <Fragment key={index}>
            <span className='chevron'>&gt;</span>
            <span className='no-link-path'>
              { t(value.key) }
            </span>
          </Fragment>
        );
      })}
    </div>
  );
}
