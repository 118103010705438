/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultFieldProps, FieldBase, nameToDataCy } from './FieldBase';

export function NicknameInput({ name, containerClassName, ...props }: DefaultFieldProps): JSX.Element {
  const { t } = useTranslation();
  const [field, meta] = useField({ name });
  const [displayNicknameOptions, setDisplayNicknameOptions] = useState<boolean>(false);
  const [removeOptionsSpacing, setRemoveOptionsSpacing] = useState<boolean>(true);
  let focusTimeout: ReturnType<typeof setTimeout>;
  let blurTimeout: ReturnType<typeof setTimeout>;

  const handleFocus = (): void => {
    if (focusTimeout) {
      clearTimeout(focusTimeout);
    }
    setRemoveOptionsSpacing(false);
    focusTimeout = setTimeout(() => {
      setDisplayNicknameOptions(true);
    }, 1);
  };

  const handleBlur = (): void => {
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
    setDisplayNicknameOptions(false);
    blurTimeout = setTimeout(() => {
      setRemoveOptionsSpacing(true);
    }, 200);
  };

  useEffect(() => () => {
    if (focusTimeout) {
      clearTimeout(focusTimeout);
    }
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
  }, []);

  return (
    <>
      <FieldBase name={name} meta={meta} containerClassName={containerClassName}>
        <input
          name={name}
          id={name}
          data-cy={`${nameToDataCy(name)}-input`}
          className={`form-control ${(meta.error && meta.touched) ? 'is-invalid' : ''}`}
          type='text'
          disabled={props.disabled}
          aria-describedby={props['aria-describedby']}
          {...field}
          onFocus={handleFocus}
          onBlur={(e) => {
            field.onBlur(e);
            handleBlur();
          }}
        />
        {props.children}
      </FieldBase>
      <div className={
        `register-nickname
        ${displayNicknameOptions ? 'isDisplay' : ''}
        ${removeOptionsSpacing ? 'd-none' : 'd-block'}
      `
      }
      >
        <span>{t('auth.register.nickname-title')}</span>
        <ul>
          <li>{t('auth.register.nickname-line1')}</li>
          <li>{t('auth.register.nickname-line2')}</li>
          <li>{t('auth.register.nickname-line3')}</li>
        </ul>
      </div>
    </>
  );
}
