/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

/* eslint-disable camelcase */
import { useEffect, useState } from 'react';

/* eslint-disable camelcase */
interface Props {
  onLoad: () => void;
  appId: string;
}

export interface FacebookPermissions {
  data: {
    permission: string;
    status: 'granted' | 'denied';
  }[];
}

interface FacebookInitParams {
  appId: string;
  version: string;
  xfbml?: boolean;
  autoLogAppEvents?: boolean;
}

interface FacebookBasicProfile {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
}

type FacebookApiMethod = 'get' | 'post' | 'delete';

interface FacebookApiPath {
  '/me': FacebookBasicProfile;
  '/me/permissions': FacebookPermissions;
}
type FacebookPath = keyof FacebookApiPath;

interface FacebookLoginOptions {
  scope: string;
  auth_type?: 'rerequest' | 'reauthenticate' | 'reauthorize';
}

export interface FacebookLoginStatus {
  status: 'connected' | 'not_authorized' | 'unknown';
  authResponse?: {
    accessToken: string;
    signedRequest: string;
    userID: string;
  };
}

interface FacebookSdk {
  // https://developers.facebook.com/docs/javascript/reference/FB.init/v6.0
  init(params: FacebookInitParams): void;
  // https://developers.facebook.com/docs/javascript/reference/FB.api/v6.0
  api<T extends FacebookPath>(path: T, callback: (response: FacebookApiPath[T]) => void): void;
  api<T extends FacebookPath>(
    path: T, method: FacebookApiMethod, callback: (response: FacebookApiPath[T]) => void
  ): void;
  api<T extends FacebookPath>(path: T, params: unknown, callback: (response: FacebookApiPath[T]) => void): void;
  api<T extends FacebookPath>(
    path: T, method: FacebookApiMethod, params: unknown, callback: (response: FacebookApiPath[T]) => void
  ): void;
  // https://developers.facebook.com/docs/reference/javascript/FB.login/v6.0
  login(callback: () => void, options: FacebookLoginOptions): void;
  // https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus/v6.0
  getLoginStatus(callback: (status: FacebookLoginStatus) => void, forceReload?: boolean): void;
}

declare global {
  interface Window {
    fbAsyncInit?: () => void;
    FB?: FacebookSdk;
  }
}

export function FacebookSdkLoader(props: Props): JSX.Element {
  const elementId = 'facebook-sdk';
  const version = 'v15.0';
  const [dispatchEvents, setDispatchEvents] = useState<boolean>(false);

  const dispatchLoadEvent = (): void => {
    if (dispatchEvents) {
      props.onLoad();
    }
  };

  const addFacebookInitCallback = (): void => {
    if (!window.fbAsyncInit && !window.FB) {
      /* istanbul ignore next */
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: props.appId,
          autoLogAppEvents: true,
          xfbml: true,
          version,
        });
        window.fbAsyncInit = null;
        dispatchLoadEvent();
      };
    }
  };

  const importFacebookSdk = (): void => {
    if (document.getElementById(elementId)) {
      dispatchLoadEvent();
      return;
    }

    const script = document.createElement('script');
    script.id = elementId;
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    setDispatchEvents(true);

    return () => {
      setDispatchEvents(false);
    };
  }, []);

  useEffect(() => {
    if (dispatchEvents) {
      addFacebookInitCallback();
      importFacebookSdk();
    }
  }, [dispatchEvents]);

  return null;
}
