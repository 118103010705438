/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ChangeEvent } from 'react';
import { MultipleQuestion, QuestionBase, QuestionDefaultProps } from './QuestionBase';

interface CheckboxQuestionProps extends QuestionDefaultProps {
  question: MultipleQuestion;
  currentAnswered: number[];
}

export function CheckboxQuestion({ question, currentAnswered, addAnswer }: CheckboxQuestionProps): JSX.Element {
  const handleChecked = (e: ChangeEvent, i: number): void => {
    let newAnswer = [...currentAnswered];

    if ((e.target as HTMLInputElement).checked) {
      newAnswer.push(i);
    } else {
      newAnswer = newAnswer.filter((ans) => ans !== i);
    }

    addAnswer(newAnswer, question.id);
  };

  return (
    <QuestionBase question={question}>
      {question.choices.map((choice, i) => (
        <div className='choice-container' key={i}>
          <input
            className='survey-input'
            type='checkbox'
            id={`${question.id}-${i}`}
            name={`${question.id}-checkbox`}
            data-cy={`${question.id}-${i}`}
            checked={currentAnswered.includes(i)}
            onChange={(e) => handleChecked(e, i)}
            disabled={currentAnswered.length === question.max && !currentAnswered.includes(i)}
          />
          <label htmlFor={`${question.id}-${i}`}>
            {choice}
          </label>
        </div>
      ))}
    </QuestionBase>
  );
}
