/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { FieldMetaProps } from 'formik';
import React, { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends ComponentPropsWithoutRef<'div'> {
  name: string,
  meta?: FieldMetaProps<unknown>,
  containerClassName?: string,
}

export interface DefaultFieldProps extends ComponentPropsWithoutRef<'input'> {
  name?: string,
  label?: string,
  values?: string,
  setFieldValue?: (field: string, value: string, shouldValidate?: boolean) => void,
  containerClassName?: string,
}

export function nameToDataCy(name: string): string {
  return name.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
}

export function FieldBase({ name = '', meta = null, containerClassName = 'mt-4', ...props }: Props): JSX.Element {
  const { i18n, t } = useTranslation();

  return (
    <div className={`form-group ${containerClassName}`}>
      {i18n.exists(`form.label.${name}`)
        && <label htmlFor={name}>{t(`form.label.${name}`)}</label>}
      {props.children}
      {(meta && meta.error && meta.touched) ? (
        <div
          className='form-error'
          data-cy={`${nameToDataCy(name)}-error-text`}
        >
          {t(`${meta.error}`, { field: t(`form.label.${name}`) })}

        </div>
      ) : null}
    </div>
  );
}
