/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Promotion } from '../../Promotion.model';
import rewardImgPlaceholder from '../../../../_assets/reward-placeholder.png';

interface Props {
  promotion: Promotion,
}

export function PromotionHeader({ promotion }: Props): JSX.Element {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width:576px)');

  const [rewardPictureUrl, setRewardPicture] = useState<string | null>(null);

  useEffect(() => {
    const { combinedRewardPictureUrl, defaultPictureUrl } = promotion.promotionData;

    if (combinedRewardPictureUrl) {
      setRewardPicture(isSmallScreen ? combinedRewardPictureUrl.mobile : combinedRewardPictureUrl.desktop);
    } else {
      setRewardPicture(isSmallScreen ? defaultPictureUrl.mobile : defaultPictureUrl.desktop);
    }
  }, [isSmallScreen, promotion]);

  return (
    <div className='promotion-header'>
      <img
        className='promotion-header-reward-img'
        src={process.env.NODE_ENV === 'development' ? rewardImgPlaceholder : rewardPictureUrl}
        alt={t(`promotions:${promotion.promotionData.title}`)}
      />
    </div>
  );
}
