/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFlowContainer } from '../../modal/modal-flow-container/ModalFlowContainer';
import { ModalFlowContent } from '../../modal/modal-flow-container/ModalFlowContent';
import { SyncInProgressAlert } from './sync-in-progress-alert/SyncInProgressAlert';
import { SyncedAccountsCircle } from './synced-accounts-circle/SyncedAccountsCircle';
import { RedeemingGameProvider } from '../../../account/providers/ProviderEnum';

interface Props {
  close: () => void;
  platformStatuses: {
    id: RedeemingGameProvider;
    synced: boolean;
  }[];
  selectedPlatform: RedeemingGameProvider;
}

export function SynchronizationSuccess({ close, platformStatuses, selectedPlatform }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <ModalFlowContainer title={t('cross-progression.synchronization.title')}>
      <ModalFlowContent name='cross-progression-success'>
        <SyncedAccountsCircle platforms={platformStatuses} selectedPlatform={selectedPlatform} />
        <SyncInProgressAlert />
      </ModalFlowContent>
      <button
        type='button'
        className='btn btn-secondary cross-progression-success--close-btn'
        onClick={close}
        data-cy='cross-progression-success-close-btn'
      >
        {t('button.close')}
      </button>
    </ModalFlowContainer>
  );
}
