/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.scss';
import './i18n';
import { datadogRum } from '@datadog/browser-rum';
import { App } from './App';

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: 'b4c1a6a0-0047-4d85-87b7-4bb1a03b7bb3',
    clientToken: 'pubfc6f73a06ed520a91fcbd8bc3086487f',
    site: 'datadoghq.com',
    service: 'frontend',
    sessionSampleRate: 5,
    sessionReplaySampleRate: 1,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
