/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApprovalRequest, BhvrUser } from '../../authentication/Authentication.model';
import { useAuth } from '../../authentication/AuthenticationContext';
import { useSnackbar } from '../../snackbar/SnackbarContext';
import { get, patch } from '../../utils/Request';
import { EditButton } from '../../_shared/edit-button/EditButton';
import { CheckboxInput } from '../../_shared/form/CheckboxInput';
import { PageLayout } from '../layout/PageLayout';
import { CommunicationPreferencesPromotions } from './CommunicationPreferencesPromotions';
import { getApprovalsRequestsFromForm } from '../../utils/Approvals';
import {
  MKT_CONSENT_LEGAL_CONTENT_IDS,
  MktConsentLegalContentId,
  MktLegalContentModel,
} from '../../marketing-consent/MarketingConsent.model';
import { useConfig } from '../../contexts/ConfigContext';

type Approvals = {
  [key in MktConsentLegalContentId]: boolean
};

type FormValues = Approvals & {
  communicationLang?: string;
};

type Props = {
  noAuth?: boolean,
};

const falseApprovals: Approvals = {
  newsletter: false,
  'personalized-ads-offers': false,
};

export function CommunicationPreferences({ noAuth }: Props): JSX.Element {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<FormValues>>();
  const config = useConfig();
  const snackbar = useSnackbar();
  const auth = useAuth();

  const [mktLegalContents, setMktLegalContents] = useState<MktLegalContentModel[]>([]);
  const [initialValuesFormik, setInitialValuesFormik] = useState<FormValues>({
    communicationLang: '',
    ...falseApprovals,
  });
  const [editEnabled, setEditEnabled] = useState<boolean>(!!noAuth);

  const hasApprovalsChanged = (initial: FormValues, current: FormValues): boolean => {
    const changedFields = Object.keys(current).filter((key) => {
      if (key === 'communicationLang') {
        return false;
      }
      const approvalId = key as MktConsentLegalContentId;
      return initial[approvalId] !== current[approvalId];
    });
    return changedFields.length > 0;
  };

  const handleSubmitForm = async (values: FormValues): Promise<void> => {
    const approvalsChanged = hasApprovalsChanged(initialValuesFormik, values);
    if (approvalsChanged || initialValuesFormik?.communicationLang !== values.communicationLang) {
      try {
        if (initialValuesFormik?.communicationLang !== values.communicationLang) {
          const response = await patch<BhvrUser>(`${config.clientConfig?.host}/players/me`, {
            data: {
              communicationLang: values.communicationLang,
            },
          });
          auth.updateUser(response);
        }

        if (approvalsChanged) {
          const { communicationLang: initialCommLang, ...initialApprovals } = initialValuesFormik;
          const { communicationLang, ...approvals } = values;

          const approvalRequests: ApprovalRequest[] = getApprovalsRequestsFromForm(
            mktLegalContents,
            initialApprovals,
            approvals,
          );

          const approvalsSuccess = await auth.addApprovals(approvalRequests);
          if (!approvalsSuccess) {
            snackbar.addErrorMessage('account.legal-contents.modal.mandatory.failure', undefined, 'translation');
            return;
          }
        }

        snackbar.addSuccessMessage('account.settings.success');
        setEditEnabled(false);
        setInitialValuesFormik(values);
      } catch (e) {
        snackbar.addErrorMessage('UnknownError');
      }
    }
  };

  const toggleCheckAll = (setFieldValue: (field: string, value: unknown) => void, uncheck: boolean): void => {
    for (const legalContent of mktLegalContents) {
      setFieldValue(legalContent.id, !uncheck);
    }
  };

  const toggleEdit = (): void => {
    if (editEnabled) {
      setEditEnabled(false);
      formikRef.current?.resetForm();
    } else {
      setEditEnabled(true);
    }
  };

  const getMktLegalContents = async (): Promise<void> => {
    try {
      const legalContents = (await Promise.all(
        MKT_CONSENT_LEGAL_CONTENT_IDS.map(
          async (legalContentId): Promise<MktLegalContentModel | null> => {
            try {
              return await get<MktLegalContentModel>(
                `${config.clientConfig?.host}/legal-contents/${legalContentId}`,
              );
            } catch {
              return null;
            }
          },
        ),
      )).filter((lc): lc is MktLegalContentModel => !!lc);
      setMktLegalContents(legalContents);
    } catch {
      setMktLegalContents([]);
    }
  };

  const getUserApprovalValue = (legalContent: MktLegalContentModel):
  [approvalId: MktConsentLegalContentId, approved: boolean] => {
    const { id, version } = legalContent;
    try {
      const userApproval = auth.user.approvals[id];
      if (userApproval?.version === version) {
        return [id, userApproval.approved];
      }
      return [id, false];
    } catch {
      return [id, false];
    }
  };

  const getInitialFormValues = async (): Promise<void> => {
    const initUserApprovals = mktLegalContents.map(
      (legalContent) => getUserApprovalValue(legalContent),
    );

    const approvalsObj = initUserApprovals.reduce((acc: Approvals, [approvalId, value]) => {
      acc[approvalId] = value;
      return acc;
    }, falseApprovals);

    setInitialValuesFormik((oldVals) => ({
      ...oldVals,
      ...approvalsObj,
      communicationLang: auth.user.communicationLang,
    }));
  };

  useEffect(() => {
    getInitialFormValues();
  }, [auth.user?.approvals, mktLegalContents]);

  useEffect(() => {
    if (auth.user) {
      getMktLegalContents();
    }
  }, [auth.user?.email]);

  return (
    <PageLayout hideMenu={noAuth}>
      <div className='communication-preferences-container'>
        <div className='account-data-container'>
          <h2>{t('account.settings.communication.title')}</h2>
          <p className='settings-subtitle'>{t('account.settings.communication.description')}</p>
          <p className='settings-subtitle'>{t('account.settings.communication.note')}</p>

          <div className='communication-preferences-promotions-container'>
            <CommunicationPreferencesPromotions />
          </div>

          <div className='form-container'>
            {!noAuth && (
              <div className='form-container-header justify-content-end'>
                <EditButton
                  isActive={editEnabled}
                  onClick={toggleEdit}
                  aria-label='Edit Communication Preferences'
                  data-cy='edit-communication-preferences-button'
                />
              </div>
            )}
            <Formik
              initialValues={initialValuesFormik}
              innerRef={formikRef}
              enableReinitialize
              onSubmit={handleSubmitForm}
            >
              {({ isSubmitting, handleReset, values, setFieldValue }) => (
                <Form className='form-light'>
                  {!noAuth && (
                    <Field name='communicationLang'>
                      {({ field }: FieldProps) => (
                        <div className='form-group'>
                          <label htmlFor='communicationLang'>
                            {t('account.settings.communication.preferredLang')}
                          </label>
                          <select
                            id='communicationLang'
                            data-cy='language-preference-form'
                            className='form-control'
                            disabled={!editEnabled || isSubmitting}
                            {...field}
                          >
                            <option value='en' key='en'>
                              {t('countries.iso-to-name.en')}
                            </option>

                            <option value='fr' key='fr'>
                              {t('countries.iso-to-name.fr')}
                            </option>

                            <option value='ja' key='ja'>
                              {t('countries.iso-to-name.ja')}
                            </option>
                          </select>
                        </div>
                      )}
                    </Field>
                  )}

                  <h4 className='mb-3 mt-4'>{t('account.settings.communication.title')}</h4>

                  {mktLegalContents.map((legalContent) => (
                    <div className='ml-3' key={legalContent.id}>
                      <CheckboxInput
                        name={legalContent.id}
                        title={
                          t(`marketing-consent.approvals.${legalContent.id}`)
                        }
                        disabled={!editEnabled || isSubmitting}
                        containerClassName=''
                      />
                    </div>
                  ))}

                  <span className='communication-preferences--note'>{t('account.settings.communication.opt-out')}</span>

                  {editEnabled && (
                    <>
                      <div className='form-group mt-2 mb-0 d-flex'>
                        <button
                          type='button'
                          className='btn btn-link'
                          onClick={() => toggleCheckAll(setFieldValue, Object.values(values).includes(true))}
                        >
                          {Object.values(values).includes(true) ? 'Uncheck All' : 'Check All'}
                        </button>
                      </div>
                      {!noAuth && (
                        <div className='form-group mt-5 d-flex justify-content-between'>
                          <button
                            type='button'
                            className='btn btn-link'
                            onClick={() => { handleReset(); toggleEdit(); }}
                          >
                            {t('common.cancel')}
                          </button>
                          <button
                            type='submit'
                            data-cy='submit-button'
                            className='btn btn-primary-game'
                            disabled={isSubmitting}
                          >
                            {t('button.save')}
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
