/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { CheckCircle } from '@mui/icons-material';
import { Chip } from '../../../../chip/Chip';
import {
  StatusMessage as StatusMessageComponent,
  StatusType,
} from '../../../../message/status-message/StatusMessage';

export interface StatusMessage {
  id: string,
  statusType: StatusType,
  message: string,
}

interface Props {
  imgSrc: string;
  imgAlt: string;
  details?: string;
  chipLabel: string;
  imgLayoutId?: string;
  statusMessages?: StatusMessage[] | null;
  children: ReactNode;
}
export function ActivationStepComplete({
  imgSrc,
  imgAlt,
  details,
  chipLabel,
  imgLayoutId,
  statusMessages,
  children,
}: Props):JSX.Element {
  return (
    <motion.div className='activation-step-complete'>
      {!!statusMessages?.length && statusMessages.map((message) => (
        <StatusMessageComponent
          key={message.id}
          statusType={message.statusType}
          message={message.message}
          className='activation-step-complete--status'
        />
      ))}
      <div className='activation-step-complete--content' data-cy={`step-complete-${imgAlt}`}>
        <motion.img
          layoutId={imgLayoutId}
          src={imgSrc}
          alt={imgAlt}
          className='activation-step-complete--logo'
        />
        {details && <span className='activation-step-complete--text'>{details}</span> }
        <Chip color='light' label={chipLabel} customIcon={<CheckCircle className='chip--icon chip--icon--success' />} />
      </div>
      <div className='activation-step-complete--button' data-cy={`${imgAlt}-step-complete-btn`}>
        {children}
      </div>
    </motion.div>
  );
}
