/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ReactNode, useEffect } from 'react';
import { ProviderName } from '../../ProviderEnum';
import { RequestError } from '../../../../Error.model';
import { patch, post } from '../../../../utils/Request';
import { AuthenticationMethod, GameAccount } from '../../../../authentication/Authentication.model';
import { useAuth } from '../../../../authentication/AuthenticationContext';
import { removeState } from '../../../../utils/Providers';
import { GameIds } from '../../../my-games/MyGames.model';
import { useConfig } from '../../../../contexts/ConfigContext';

interface Props {
  provider: ProviderName;
  isAuthProvider: boolean;
  providerAccount?: GameAccount | AuthenticationMethod | null;
  errorHandler: (e: RequestError | null) => void;
  children: ReactNode;
  useToken?: boolean;
}

export function ProviderLinkWrapper(
  { provider,
    isAuthProvider,
    providerAccount,
    errorHandler,
    children,
    useToken }: Props,
): JSX.Element {
  const auth = useAuth();
  const config = useConfig();

  const onLinkMessage = async (event: MessageEvent): Promise<void> => {
    if (event.origin !== window.origin || event.data.type !== provider || !event.data.providerData) {
      return;
    }
    errorHandler(null);

    try {
      if (isAuthProvider) {
        const authProviderData = { idToken: event.data.providerData.code };

        if (providerAccount) {
          await patch(`${config.clientConfig?.host}/players/me/providers/${provider}`, { data: authProviderData });
        } else {
          await post(
            `${config.clientConfig?.host}/players/me/link/provider`,
            { data: { provider, parameters: authProviderData } },
          );
        }
      } else {
        // Token used for A/B testing mkt-consent promotions
        const mktToken = sessionStorage.getItem('mktToken');
        const mktABTestingParams = useToken && mktToken ? `?token=${mktToken}&gameId=${GameIds.DBD}` : '';

        const gameProviderData = {
          data: {
            type: provider,
            data: event.data.providerData,
          },
        };

        if (providerAccount) {
          await patch(`${config.clientConfig?.host}/players/me/link`, gameProviderData);
        } else {
          await post(`${config.clientConfig?.host}/players/me/link${mktABTestingParams}`, gameProviderData);
        }
        await patch(`${config.clientConfig?.host}/players/me/accounts/providers/${provider}/games`);
      }
      errorHandler(null);
      auth.getPlayer();
      removeState(provider);
    } catch (e) {
      const err = e as RequestError;
      errorHandler(err);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onLinkMessage);

    return () => {
      window.removeEventListener('message', onLinkMessage);
    };
  }, []);

  return <>{children}</>;
}
