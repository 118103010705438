/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';

import { FacebookLoginStatus, FacebookPermissions, FacebookSdkLoader } from './FacebookSdkLoader';
import { useAuth } from '../../AuthenticationContext';
import { getProviderLogo } from '../../../account/providers/ProviderLogo';
import { useConfig } from '../../../contexts/ConfigContext';

export function FacebookButton(): JSX.Element {
  const requiredScopes = ['public_profile', 'email'];
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const config = useConfig();
  const auth = useAuth();

  const onSdkLoaded = (): void => {
    setIsSdkLoaded(true);
  };

  const checkGrantedPermissions = (permissions: FacebookPermissions): boolean => {
    try {
      if (permissions.data.length < requiredScopes.length) {
        return false;
      }

      for (const scope of requiredScopes) {
        const permission = permissions.data.find((p) => p.permission === scope);
        if (!permission || permission.status !== 'granted') {
          return false;
        }
      }

      return true;
    } catch (e) {
      return false;
    }
  };

  const handleLogin = (res: FacebookLoginStatus): void => {
    if (res.authResponse) {
      window.FB.api('/me', { fields: 'id,name,last_name,first_name,email' }, (profile) => {
        auth.signinWithProvider({
          idToken: res.authResponse.signedRequest,
        }, 'facebook', profile.email);
      });
    }
  };

  const showLoginWindow = (): void => {
    window.FB.login(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      signIn(false);
    }, {
      scope: requiredScopes.join(','),
      auth_type: 'rerequest',
    });
  };

  const signIn = (forceWindow: boolean): void => {
    window.FB.getLoginStatus((res) => {
      // Check connexion status
      if (res.status === 'connected') {
        // Check that the user has granted required scopes
        window.FB.api('/me/permissions', (permissions) => {
          if (checkGrantedPermissions(permissions)) {
            handleLogin(res);
          } else if (forceWindow) {
            showLoginWindow();
          }
        });
      } else if (forceWindow) {
        showLoginWindow();
      }
    }, true);
  };

  const clickSignIn = (): void => {
    if ('ontouchstart' in document.documentElement) {
      showLoginWindow();
    } else {
      signIn(true);
    }
  };

  return (
    <>
      <button
        type='button'
        id='register-facebook'
        data-cy='register-facebook-btn'
        className='btn btn-social btn-facebook'
        title='Facebook'
        disabled={!isSdkLoaded}
        onClick={clickSignIn}
      >
        <img src={getProviderLogo('facebook', 'monochrome')} alt='Facebook Logo' />
      </button>
      <FacebookSdkLoader appId={config.serverConfig?.providers.facebook.appId} onLoad={onSdkLoaded} />
    </>
  );
}
