/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/
import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { request } from '../utils/Request';
import logo from '../_assets/bhvr-footer.png';
import { useConfig } from '../contexts/ConfigContext';

export function SsoAccept(): JSX.Element {
  const { t } = useTranslation(['sso', 'translation']);
  const history = useHistory();
  const location = useLocation();
  const config = useConfig();

  // remove initial "?"
  const uriSearchParam = location.search.substring(1);
  const searchParams = new URLSearchParams(uriSearchParam);
  const clientId = searchParams.get('clientId') ?? searchParams.get('client_id');

  const acceptedSSO = async (): Promise<void> => {
    const response = await request<{ accepted: boolean }>(
      'GET',
      `${config.clientConfig.host}/sso/accept/${clientId}`,
      {},
    );
    if (response.accepted) {
      // Redirect to the backend to continue the oauth flow
      window.location.href = `${config.clientConfig.host}/sso/authorize?${uriSearchParam}`;
    }
  };

  const handleAcceptSSO = async (): Promise<void> => {
    await request(
      'POST',
      `${config.clientConfig.host}/sso/accept/${clientId}?accept=true`,
      {},
    );
    await acceptedSSO();
  };

  const cancelHandler = (): void => {
    window.close();
    if (!window.closed) {
      history.replace('/');
    }
  };

  // TODO: Add BE support to make the fields and client name dynamic
  const clientKey = 'msg-arcs';
  const defaultScopes = ['player', 'player:email'];
  const scopesAsTranslationKeys = defaultScopes.map((scope) => scope.replace(':', '-'));

  const clientName = t(`sso-page.client.${clientKey}.name`);
  const clientTitle = t(`sso-page.client.${clientKey}.title`, { clientName });
  const clientConsent = t(`sso-page.client.${clientKey}.consent`, { clientName });

  return (
    <div className='sso-page'>
      <div className='sso-page--container'>
        <Link to='/' className='bhvr-logo'>
          <img src={logo} width='53' alt={`${t('footer.logo')} Behaviour Interactive`} />
          <div className='bhvr-logo--texts'>
            <span>{t('sso-page.bhvr-account.title1')}</span>
            <span>{t('sso-page.bhvr-account.title2')}</span>
          </div>
        </Link>

        <div className='sso-page--access'>
          <span>{clientTitle}</span>
          <ul>
            {scopesAsTranslationKeys.map((scopeKey) => (
              <li key={scopeKey} className='sso-page--access--field'>
                {t(`sso-page.scopes.${scopeKey}`)}
              </li>
            ))}
          </ul>
          <span className='sso-page--access--consent'>{clientConsent}</span>
        </div>

        {clientId && (
          <Formik
            initialValues={{}}
            enableReinitialize
            onSubmit={handleAcceptSSO}
          >
            <Form className='form-light'>
              <div className='sso-page--form-buttons'>
                <button
                  type='button'
                  onClick={cancelHandler}
                  className='btn btn-secondary sso-page--form-button'
                  data-cy='decline-sso-button'
                >
                  {t('translation:button.decline')}
                </button>
                <button
                  type='submit'
                  data-cy='accept-sso-button'
                  className='btn btn-primary-game sso-page--form-button'
                >
                  {t('translation:button.authorize')}
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </div>
  );
}
