/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { QuestionBase, QuestionDefaultProps, SimpleQuestion } from './QuestionBase';

interface RadioQuestionProps extends QuestionDefaultProps {
  question: SimpleQuestion;
  currentAnswered: number;
}

export function RadioQuestion({ question, currentAnswered, addAnswer }: RadioQuestionProps): JSX.Element {
  return (
    <QuestionBase question={question}>
      {question.choices.map((choice, i) => (
        <div className='choice-container' key={i}>
          <input
            className='survey-input'
            type='radio'
            id={`${question.id}-${i}`}
            name={`${question.id}-radio`}
            data-cy={`${question.id}-${i}`}
            checked={currentAnswered === i}
            onChange={() => addAnswer(i, question.id)}
          />
          <label htmlFor={`${question.id}-${i}`}>
            {choice}
          </label>
        </div>
      ))}
    </QuestionBase>
  );
}
