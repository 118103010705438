/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

export class Popup {
  private static getWidthOrHeight(prop: keyof Pick<Window, 'innerWidth' | 'innerHeight'>): number {
    if (window[prop]) {
      return window[prop];
    }

    if (prop === 'innerHeight') {
      return document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
    }

    return document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
  }

  public static open(url: string, target: string): void {
    const width = this.getWidthOrHeight('innerWidth');

    const popupParams = [
      'location=yes',
      `width=${Popup.getWidth(width)}`,
      `height=${Popup.getHeight(width)}`,
      `top=${Popup.getTop(width)}`,
      `left=${Popup.getLeft(width)}`,
      'scrollbars=no',
      'resizable=no',
      'status=yes',
    ];

    window.open(url, target, popupParams.join(','));
  }

  private static getWidth(width: number, desiredWidth = 520): number {
    const systemZoom = width / window.screen.availWidth;

    const finalWidth = desiredWidth / systemZoom;

    return finalWidth;
  }

  private static getLeft(width: number, desiredWidth = 520): number {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;

    const systemZoom = width / window.screen.availWidth;
    return (width - desiredWidth) / 2 / systemZoom + dualScreenLeft;
  }

  private static getHeight(width: number, desiredHeight = 820): number {
    const systemZoom = width / window.screen.availWidth;

    return desiredHeight / systemZoom;
  }

  private static getTop(width: number, desiredHeight = 820): number {
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const height = this.getWidthOrHeight('innerHeight');

    const systemZoom = width / window.screen.availWidth;
    return (height - desiredHeight) / 2 / systemZoom + dualScreenTop;
  }
}
