/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useState } from 'react';

export interface GameThemeContextModel {
  getGameId: () => string;
  setGameId:(gameId: string) => void;
  gameId: string;
}

const GameThemeContext = createContext<GameThemeContextModel>({
  gameId: null,
  getGameId: null,
  setGameId: null,
});

export const useGameTheme = (): GameThemeContextModel => useContext(GameThemeContext);

const useProvideGameTheme = (): GameThemeContextModel => {
  const [gameId, setCurrentGameId] = useState<string>('');

  const setGameId = (id: string): void => {
    setCurrentGameId(id);
  };

  const getGameId = (): string => (gameId);

  return {
    gameId,
    setGameId,
    getGameId,
  };
};

export function ProvideGameTheme(props: {
  children?: JSX.Element | JSX.Element[];
}): JSX.Element {
  const gameTheme = useProvideGameTheme();

  return (
    <GameThemeContext.Provider value={gameTheme}>
      {props.children}
    </GameThemeContext.Provider>
  );
}
