/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Promotion } from '../../Promotion.model';
import { PromotionStep } from './promotionSteps.model';
import { RedeemingGameProvider, isLinkingGameProvider, isRedeemingGameProvider } from '../../../providers/ProviderEnum';
import { GameProviders } from './gameProviders/GameProviders';
import { GameIds } from '../../../my-games/MyGames.model';
import { ProviderByGame, ProvidersByGame } from './gameProviders/GameProviders.model';
import { Confirmation } from './confirmation/Confirmation';
import { useConfig } from '../../../../contexts/ConfigContext';

interface Props {
  steps: PromotionStep[]
  promotion: Promotion;
  currentStep: number;
  modifyProvider: () => void;
  redeemProvider: RedeemingGameProvider | null;
  setRedeemProvider: (provider: RedeemingGameProvider) =>void;
  setNextStepEnabled: (enabled: boolean) => void;
}

export function PromotionRequirementsFulfillment(
  { steps,
    promotion,
    currentStep,
    modifyProvider,
    redeemProvider,
    setRedeemProvider,
    setNextStepEnabled }: Props,
): JSX.Element {
  const { serverConfig } = useConfig();
  const { t } = useTranslation();

  const [providersByGame, setProvidersByGame] = useState<ProvidersByGame>({});
  const [selectedProviderByGame, setSelectedProviderByGame] = useState<ProviderByGame>({});

  const selectProviders = (providers: ProviderByGame): void => {
    const selectedRedeemProvider = providers[promotion.promotionData.relatedToGameId];
    if (selectedRedeemProvider
      && selectedRedeemProvider !== redeemProvider
      && isRedeemingGameProvider(selectedRedeemProvider)
    ) {
      setRedeemProvider(selectedRedeemProvider);
    }
    setSelectedProviderByGame(providers);
  };

  useEffect(() => {
    const { requiredGameId, relatedToGameId, redeemableOnPlatformIds } = promotion.promotionData;
    const providersByGameTemp = {
      [relatedToGameId]: redeemableOnPlatformIds
        .filter((platformId) => isRedeemingGameProvider(platformId)),
      [requiredGameId]: serverConfig?.gamesConfig
        .find((game) => game.id === requiredGameId)?.providers
        .map((provider) => provider.name) || []
        .filter((provider) => isLinkingGameProvider(provider)),
    };

    // Prefill the dropdown if there is only one provider available
    const defaultProvidersByGame = Object.entries(providersByGameTemp)
      .reduce((acc: ProviderByGame, [gameId, providers]) => {
        if (providers.length === 1) {
          const singleProvider = providers[0];
          acc[gameId as GameIds] = singleProvider;
        }

        return acc;
      }, {});

    setProvidersByGame(providersByGameTemp);
    selectProviders(defaultProvidersByGame);
  }, [promotion.promotionData, serverConfig]);

  const getStepLabel = (step: PromotionStep): string => {
    switch (step) {
      case PromotionStep.Platforms:
        return t('translation:promotion.stepper.providers');
      case PromotionStep.Sync:
        return t('translation:promotion.stepper.sync');
      case PromotionStep.Confirmation:
        return t('translation:promotion.stepper.confirmation');
      default:
        return '';
    }
  };

  const title = currentStep === PromotionStep.Confirmation
    ? t(
      'translation:promotion.claim-reward.direct.description',
      {
        rewardGame: t(`translation:games.${promotion.promotionData.relatedToGameId}`),
        provider: t(`translation:${redeemProvider}`),
      },
    )
    : t('translation:promotion.game-providers.title');

  const showProviders = (currentStep === PromotionStep.Platforms || currentStep === PromotionStep.Sync);
  const showConfirmation = currentStep === PromotionStep.Confirmation && redeemProvider;

  return (
    <>
      <Stepper
        alternativeLabel
        activeStep={currentStep - 1}
        className='promotion-requirements-fulfillment-stepper'
      >
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel>{getStepLabel(step)}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <h4 className='promotion-requirements-fulfillment-title'>{title}</h4>

      {showProviders && (
        <GameProviders
          currentStep={currentStep}
          providersByGame={providersByGame}
          selectedProviderByGame={selectedProviderByGame}
          setSelectedProviderByGame={selectProviders}
          setNextStepEnabled={setNextStepEnabled}
        />
      )}
      {showConfirmation && (
        <Confirmation
          promotion={promotion}
          redeemProvider={redeemProvider}
          modifyProvider={modifyProvider}
        />
      )}
    </>
  );
}
