/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

export const components = {
  PROMOTION: 'promotion',
  CROSS_PROG: 'cross-progression',
  TRAILER: 'trailer',
  BANNER: 'banner',
  FAQ: 'faq',
  PRE_REG: 'pre-registration',
  NEWSLETTER: 'newsletter',
  OWNED_ON: 'owned-on',
  STREAMING_CAMPAIGNS: 'streaming-campaigns',
  HEADER: 'header',
  SECTION: 'section',
  SEPARATOR: 'separator',
  ITEM_LIST: 'item_list',
  STREAMING_CAMPAIGN_REWARD: 'streaming_campaign_reward',
  ACTIVATION: 'activation',
  INSTRUCTIONS: 'instructions',
  CLAIM_FREE_GAME: 'claim-free-game',
  LINK_BUTTON: 'link-button',
  PROVIDER_LINK_CARD: 'provider-link-card',
  TABS: 'tabs',
} as const;

type Values<T> = T[keyof T];
export type ComponentType = Values<typeof components>;

const gamePageComponents = {
  [components.CROSS_PROG]: require('./cross-progression/CrossProgression'),
  [components.PROMOTION]: require('./promotion/Promotion'),
  [components.TRAILER]: require('./trailer/Trailer'),
  [components.FAQ]: require('./faq/Faq'),
  [components.BANNER]: require('./banner/Banner'),
  [components.PRE_REG]: require('./preregistration/PreRegistration'),
  [components.NEWSLETTER]: require('./game-newsletters/GameNewsletters'),
  [components.OWNED_ON]: require('./owned-on/OwnedOn'),
  [components.STREAMING_CAMPAIGNS]: require('./streaming-campaigns-card/StreamingCampaignsCard'),
  [components.CLAIM_FREE_GAME]: require('./claim-free-game/ClaimFreeGame'),
  [components.LINK_BUTTON]: require('./link-button/LinkButton'),
  [components.PROVIDER_LINK_CARD]: require('./provider-link-card/ProviderLinkCard'),
  [components.TABS]: require('./tabs/TabsNav'),
} as const;

const streamingCampaignPageComponents = {
  [components.HEADER]: require('./header/Header'),
  [components.FAQ]: require('./faq/Faq'),
  [components.SECTION]: require('./section/Section'),
  [components.SEPARATOR]: require('./separator/Separator'),
  [components.ITEM_LIST]: require('./item-list/ItemList'),
  [components.ACTIVATION]: require('./streaming-campaign/activation/Activation'),
  [components.INSTRUCTIONS]: require('./instructions/Instructions'),
  [components.STREAMING_CAMPAIGN_REWARD]: require('./streaming-campaign/reward/Reward'),
} as const;

export const allComponents = { ...gamePageComponents, ...streamingCampaignPageComponents };

export { gamePageComponents, streamingCampaignPageComponents };
