/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { Suspense } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { BasePage } from './base-page/BasePage';
import { Loading } from './_shared/loading/Loading';
import { ProvideConfiguration } from './contexts/ConfigContext';
import { ProvideMenu } from './contexts/MenuContext';

export function App(): JSX.Element {
  return (
    <Suspense fallback={<Loading />}>
      <ProvideConfiguration>
        <ProvideMenu>
          <Router>
            <BasePage />
          </Router>
        </ProvideMenu>
      </ProvideConfiguration>
    </Suspense>
  );
}
