/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

/* eslint-disable complexity */
// TODO: reactivate after BA-1600 (when game and auth providers are refactored to be in the same array)
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { HelpOutline } from '@mui/icons-material';
import { useAuth } from '../../../authentication/AuthenticationContext';
import { AuthenticationMethod, GameAccount } from '../../../authentication/Authentication.model';
import { RequestError } from '../../../Error.model';
import { ProviderErrorModal } from './ProviderErrorModal';
import { ProviderName } from '../ProviderEnum';
import { getProviderLogo } from '../ProviderLogo';
import { formatDate } from '../../../utils/Time';
import { ProviderLinkButton } from './button/ProviderLinkButton';
import { ProviderSyncButton, SyncResponse } from './button/ProviderSyncButton';
import { useSnackbar } from '../../../snackbar/SnackbarContext';
import { buildAuthUrl } from '../../../utils/Providers';
import { ProviderUnlinkButton } from './button/ProviderUnlinkButton';
import {
  ProviderErrorModal as ABTestingProviderErrorModal,
} from '../../../marketing-consent/ABTesting/ProviderErrorModal';
import { DecodedToken, isABTestingError } from '../../../marketing-consent/MarketingConsent.model';
import { useConfig } from '../../../contexts/ConfigContext';

interface Props {
  provider: ProviderName;
  showLink?: boolean;
  showUnlink?: boolean;
  showSync?: boolean;
  showFeatures?: boolean;
  decodedToken?: DecodedToken; // For mkt consent ABTesting provider errors
  className?: string;
}

export function ProviderLink({ provider,
  showLink = true,
  showUnlink = true,
  showSync = true,
  showFeatures = true,
  decodedToken,
  className }: Props): JSX.Element {
  const config = useConfig();
  const auth = useAuth();
  const snackbar = useSnackbar();
  const { t, i18n } = useTranslation(['translation', 'error']);

  const [authMethod, setAuthMethod] = useState<AuthenticationMethod>();
  const [authUrl, setAuthUrl] = useState<string | null>(null);
  const [providerAccount, setProviderAccount] = useState<GameAccount>();
  const [features, setFeatures] = useState<string[]>([]);
  const [error, setError] = useState<RequestError | null>(null);
  const [showCustomErrorModal, setShowCustomErrorModal] = useState(false);

  const serverConfig = config.serverConfig.providers[provider];
  const locale = i18n.language;
  const authProviders = ['twitch', 'discord']; // TODO: when BA-1188 is done, get from backend
  const onlyAuthMethod = auth.user.authenticationMethods.length === 1 && !!authMethod;

  const closeCustomErrorModal = (): void => setShowCustomErrorModal(false);

  useEffect(() => {
    if (error) {
      setShowCustomErrorModal(true);
    }
  }, [error]);

  useEffect(() => {
    const providerConfig = config.clientConfig.providers[provider];
    if (providerConfig && serverConfig) {
      setAuthUrl(buildAuthUrl(providerConfig.authUrl, serverConfig, provider));

      const providerFeatures = providerConfig.features ? [...providerConfig.features] : [];
      const hasMerge = config.serverConfig.gamesConfig.some(
        (game) => game.providers.find((x) => x.name === provider)?.isMergeImplemented,
      );
      if (hasMerge) {
        providerFeatures.push('merge');
      }
      setFeatures(providerFeatures);
    }
  }, [provider]);

  useEffect(() => {
    const pa = auth.user.accounts.find((account) => account.type === provider);
    setProviderAccount(pa);
  }, [auth.user.accounts.length]);

  useEffect(() => {
    const am = auth.user.authenticationMethods.find((method) => method.type === provider);
    setAuthMethod(am);
  }, [auth.user.authenticationMethods.length]);

  const onSyncResponse = (response: SyncResponse): void => {
    if (response.type === 'error') {
      snackbar.addErrorMessage(response.code);
    } else {
      snackbar.addSuccessMessage(response.code, { provider: t<string>(`translation:${provider}`) });
    }
  };

  return (
    <>
      {serverConfig && (
        <div className={`provider-card provider-link-card text-center ${className}`}>
          {features.length > 0 && showFeatures && (
            <div
              className='link-features-tooltip-icon'
              data-tooltip-id='provider-link-features-tooltip'
              data-tooltip-provider={provider}
              data-tooltip-provider-features={features}
            >
              <HelpOutline />
            </div>
          )}

          <img src={getProviderLogo(provider)} alt={provider} height='48' />
          <h4 className='mb-3'>{t(`translation:${provider}`)}</h4>
          <div
            className='status-badge mx-auto'
            data-cy={`${provider}-link-status-label`}
            data-tooltip-id={`${provider}-link-status-tooltip`}
          >
            {providerAccount || authMethod
              ? t('translation:account.my-account.linked')
              : t('translation:account.my-account.unlinked')}
            <div className={`status ${providerAccount || authMethod ? 'linked' : 'unlinked'}`} />
          </div>
          {(providerAccount || authMethod) && (
            <ReactTooltip
              id={`${provider}-link-status-tooltip`}
              className='link-status-tooltip'
              place='bottom'
              variant='light'
              opacity='1'
            >
              <p>
                {providerAccount?.userName && (
                  <span className='provider-username'>
                    { providerAccount.userName }
                    <br />
                  </span>
                )}
                <span className='added-date mt-1 mb-3'>
                  {providerAccount
                    && t(
                      'translation:account.provider.linkedOn',
                      { date: formatDate(providerAccount.createdAt, locale) },
                    )}
                  {authMethod
                    && t('translation:account.provider.linkedOn', { date: formatDate(authMethod.createdAt, locale) })}
                </span>
              </p>
            </ReactTooltip>
          )}

          {!providerAccount && !authMethod && showLink && (
            <ProviderLinkButton
              provider={provider}
              providerAuthUrl={authUrl}
              providerAccount={providerAccount}
              setError={(e) => setError(e)}
              className='mx-auto mt-3 mb-0'
              isAuthProvider={authProviders.includes(provider)}
              useToken={!!decodedToken}
            />
          )}

          {(providerAccount || authMethod) && showUnlink && (
            <ProviderUnlinkButton
              provider={provider}
              setError={(e) => setError(e)}
              isAuthMethod={!!authMethod}
              isOnlyAuthMethod={onlyAuthMethod}
              showWarning={onlyAuthMethod}
              className='mt-3'
            />
          )}

          {providerAccount && !authProviders.includes(provider) && showSync && (
            <ProviderSyncButton
              provider={provider}
              providerAccount={providerAccount}
              setError={(e) => setError(e)}
              providerAuthUrl={authUrl}
              isAuthProvider={authProviders.includes(provider)}
              onSyncResponse={onSyncResponse}
              className='mt-1'
            />
          )}

          {/* For mkt consent AB testing only */}
          {!!decodedToken && isABTestingError(error) && (
            <ABTestingProviderErrorModal
              error={error}
              isOpen={showCustomErrorModal}
              decodedToken={decodedToken}
              providerAccount={null}
              close={closeCustomErrorModal}
            />
          )}

          {!decodedToken && (
            <ProviderErrorModal
              error={error}
              close={closeCustomErrorModal}
              isOpen={showCustomErrorModal}
              provider={provider}
            />
          )}
        </div>
      )}
    </>
  );
}
