/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { put } from '../../utils/Request';
import { useSnackbar } from '../../snackbar/SnackbarContext';
import { PasswordInput } from '../../_shared/form/PasswordInput';
import { SubmitButton } from '../../_shared/form/SubmitButton';

import { PageLayout } from '../layout/PageLayout';
import { useAuth } from '../../authentication/AuthenticationContext';
import { useConfig } from '../../contexts/ConfigContext';

interface ChangePasswordValues {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export function ChangePassword(): JSX.Element {
  const config = useConfig();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { user } = useAuth();

  const initialValuesFormik: ChangePasswordValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };
  const settingsPasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required('form.field-required'),
    newPassword: Yup.string()
      .required('form.field-required')
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,33}$/,
        'form.matches-invalid',
      ).matches(
        new RegExp(`^(?!${user.email}$)`, 'i'),
        'form.matches-invalid',
      ),
    confirmNewPassword: Yup.string()
      .required('form.field-required'),
  });

  const handleSubmitForm = (
    values: ChangePasswordValues,
    { setFieldError, setSubmitting, resetForm }: FormikHelpers<ChangePasswordValues>,
  ): void => {
    if (values.newPassword !== values.confirmNewPassword) {
      setFieldError('confirmNewPassword', 'form.password-not-identical');
      setSubmitting(false);
      return;
    }

    put(`${config.clientConfig.host}/players/me/password`, {
      data: {
        newPassword: values.newPassword,
        password: values.oldPassword,
      },
    }).then(() => {
      snackbar.addSuccessMessage('account.settings.success');
      resetForm();
    }).catch((err) => {
      if (err.code === 'InvalidPasswordError') {
        setFieldError('oldPassword', 'form.matches-invalid');
      } else if (err.code === 'PasswordReusedError') {
        setFieldError('newPassword', 'errors.passwordReused');
      } else {
        snackbar.addErrorMessage('UnknownError');
      }
      setSubmitting(false);
    });
  };

  return (
    <PageLayout>
      <div className='change-password-container'>
        <div className='account-data-container'>
          <h2>{t('account.settings.password.title')}</h2>
          <p className='settings-subtitle'>{t('account.settings.password.description')}</p>

          <div className='form-container'>
            <Formik
              initialValues={initialValuesFormik}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={settingsPasswordSchema}
              enableReinitialize
              onSubmit={handleSubmitForm}
            >
              <Form className='form-light'>
                <PasswordInput name='oldPassword' />
                <PasswordInput name='newPassword' />
                <PasswordInput name='confirmNewPassword' />
                <SubmitButton name='save' />
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
