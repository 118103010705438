/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import { Promotion } from '../../../Promotion.model';
import { ClaimedCode } from '../../../ClaimedCode';
import { myI18n } from '../../../../../i18n';

interface Props {
  promotion: Promotion;
  errorCode: string | null;
}

export function PromotionRedeemed({ errorCode, promotion }:Props):JSX.Element {
  const { t } = useTranslation();
  const { promotionData, userData } = promotion;
  const isPromocode = promotionData.rewardDeliveryType === 'PROMOCODE';
  const congratulationsText = isPromocode
    ? t('translation:promotion.claim-reward.promocode.redeem')
    : t('translation:promotion.claim-reward.direct.success-description', {
      rewardGame: t(`games.${promotionData.relatedToGameId}`),
    });

  const showPromocode = isPromocode && userData?.status === 'claimed';

  return (
    <div className='promotion-redeemed'>
      {errorCode
        ? (
          <>
            <SentimentVeryDissatisfiedIcon className='promotion-redeemed-icon' />
            <span className='promotion-redeemed-message' data-cy='claim-promotion-redeemed-error'>
              {myI18n.exists(`error:${errorCode}`)
                ? t(`error:${errorCode}`)
                : t('error:UnknownError')}
            </span>
          </>
        )
        : (
          <>
            <CheckCircle className='promotion-redeemed-icon promotion-redeemed-icon-sucess' />
            <span className='promotion-redeemed-message' data-cy='claim-promotion-redeemed-success'>
              {t('translation:promotion.claim-reward.direct.success-title')}
              <br />
              {congratulationsText}
            </span>
            {showPromocode && <ClaimedCode userData={userData} />}
          </>
        ) }
    </div>
  );
}
