/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

/* eslint-disable camelcase */
import { useEffect } from 'react';

interface Props {
  onLoad: () => void;
}

export function GoogleSdkLoader(props: Props): JSX.Element {
  let dispatchEvents = false;

  const elementId = 'google-sdk';

  const dispatchLoadEvent = (): void => {
    if (dispatchEvents) {
      props.onLoad();
    }
  };

  const loadGoogleApi = (): void => {
    if (!window.google) {
      return null;
    }

    return dispatchLoadEvent();
  };

  const importGoogleSdk = (): boolean => {
    if (document.getElementById(elementId)) {
      return false;
    }

    const script = document.createElement('script');
    script.id = elementId;
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = loadGoogleApi;
    document.body.appendChild(script);
    return true;
  };

  useEffect(() => {
    dispatchEvents = true;

    if (!importGoogleSdk()) {
      loadGoogleApi();
    }

    return () => {
      dispatchEvents = false;
    };
  }, []);

  return null;
}
