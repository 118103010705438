/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

import { EmailInput } from '../../_shared/form/EmailInput';
import { PasswordInput } from '../../_shared/form/PasswordInput';
import { CountryInput } from '../../_shared/form/CountryInput';
import { DateInput } from '../../_shared/form/DateInput';
import { GenderInput } from '../../_shared/form/GenderInput';
import { CheckboxInput } from '../../_shared/form/CheckboxInput';
import { SubmitButton } from '../../_shared/form/SubmitButton';
import {
  ProviderPayload,
  RegisterModalData,
  RegisterProvider,
  RouteParams,
  SignupPayload,
} from '../Authentication.model';
import { NicknameInput } from '../../_shared/form/NicknameInput';
import { useAuth } from '../AuthenticationContext';
import { TranslationLink } from '../../_shared/translation/TranslationLink';
import { MktConsentLegalContentId } from '../../marketing-consent/MarketingConsent.model';
import { useConfig } from '../../contexts/ConfigContext';

interface InitialValuesFormik {
  gender?: string;
  country?: string;
  nickName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  day?: string;
  month?: string;
  year?: string;
  birthDate?: string;
  'newsletter'?: boolean;
  'personalized-ads-offers'?: boolean;
  'privacy-policies'?: boolean;
}

export function Register(): JSX.Element {
  const { t, i18n } = useTranslation();
  const config = useConfig();
  const params = useParams<RouteParams>();
  const auth = useAuth();

  const [signupSchema, setSignupSchema] = useState(null);
  const [provider, setProvider] = useState<RegisterProvider | null>(null);

  const data = auth.visibleModalData as RegisterModalData;

  const [initialValuesFormik] = useState<InitialValuesFormik>({
    gender: '',
    country: '',
    nickName: '',
    email: data?.email || '',
    password: '',
    confirmPassword: '',
    day: '',
    month: '',
    year: '',
    birthDate: null,
    newsletter: false,
    'personalized-ads-offers': false,
    'privacy-policies': false,
  });

  const setYupSchema = (checkPassword = true): void => {
    const fields = {
      country: Yup.string()
        .required('form.field-required'),
      email: Yup.string()
        .email('form.mail-invalid')
        .required('form.field-required'),
      month: Yup.string()
        .required('form.field-required'),
      day: Yup.string()
        .required('form.field-required'),
      year: Yup.string()
        .required('form.field-required'),
      nickName: Yup.string()
        .required('form.field-required')
        .min(2, 'form.field-error-nickname-length')
        .max(32, 'form.field-error-nickname-length')
        .matches(/^[A-Za-z].*$/, 'form.field-error-nickname-letter-start')
        .matches(/^[\w-]+$/, 'form.field-error-nickname-alphanumeric'),
      password: null as Yup.StringSchema,
      confirmPassword: null as Yup.StringSchema,
      newsletter: Yup.boolean(),
      'personalized-ads-offers': Yup.boolean(),
      'privacy-policies': Yup.boolean()
        .oneOf([true]),
    };
    // TODO: Get translations for error messages
    if (checkPassword) {
      fields.password = Yup.string()
        .required('form.field-required')
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,33}$/,
          'form.matches-invalid',
        ).when('email', (email, schema) => {
          if (email) {
            return schema.matches(
              new RegExp(`^(?!${email}$)`, 'i'),
              'form.matches-invalid',
            );
          }
          return schema;
        });
      fields.confirmPassword = Yup.string()
        .required('form.field-required')
        .oneOf([Yup.ref('password'), null], 'form.password-not-identical');
    }

    setSignupSchema(Yup.object().shape(fields));
  };

  const setPayloadProvider = (values: InitialValuesFormik): ProviderPayload => {
    if (provider?.type) {
      return {
        provider: provider.type,
        parameters: {
          idToken: provider.idToken,
        },
      };
    }
    return {
      provider: 'password',
      parameters: {
        email: values.email,
        password: values.password,
      },
    };
  };

  const handleSubmitForm = async (
    values: InitialValuesFormik,
    { setFieldError, setSubmitting }: FormikHelpers<InitialValuesFormik>,
  ): Promise<void> => {
    const date = new Date();
    const dateBirthDate = new Date(+values.year, +values.month - 1, +values.day);
    let birthDate = '';

    if (+values.day > 31 || +values.month > 12
      || +values.year < date.getFullYear() - 100 || +values.year > date.getFullYear()) {
      setFieldError('year', 'form.date-incorrect');
      setSubmitting(false);
      return;
    }

    if (dateBirthDate.getDate() !== +values.day) {
      setFieldError('birthDate', 'form.date-incorrect');
      setSubmitting(false);
      return;
    }

    if (new Date(+values.year + 18, +values.month - 1, +values.day) > new Date()) {
      setFieldError('year', 'errors.under18Years');
      setSubmitting(false);
      return;
    }

    birthDate = `${values.year}-${values.month}-${values.day}`;

    const providerParams = setPayloadProvider(values);
    const approvals: MktConsentLegalContentId[] = [
      ...(values.newsletter ? ['newsletter'] as const : []),
      ...(values['personalized-ads-offers'] ? ['personalized-ads-offers'] as const : []),
    ];

    const payload: SignupPayload = {
      country: values.country,
      birthDate,
      gender: values.gender,
      nickName: values.nickName,
      communicationLang: i18n.language !== 'fr' ? 'en' : 'fr',
      approvals,
      ...providerParams,
    };

    if (config.clientConfig.recaptchaEnabled) {
      const token = await window.grecaptcha.execute(
        config.serverConfig.website.recaptcha.clientId,
        { action: 'login' },
      );
      await auth.register(payload, values.email, values.password, token);
    } else {
      await auth.register(payload, values.email, values.password);
    }

    setSubmitting(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    let providerFromLocation;
    if (data?.email && data?.idToken && data?.type) {
      providerFromLocation = {
        email: data.email,
        idToken: data.idToken,
        type: data.type,
      };
      setProvider(providerFromLocation);
    }

    setYupSchema(!providerFromLocation);
  }, []);

  return (
    <div className='form-light register-form'>
      <div className={params?.gameId ? `full-page-form ${params.gameId}`
        : 'full-page-form'}
      >
        <div className='header-container'>
          <h2 className='header'>{t('button.create-account')}</h2>
        </div>

        <div>
          <Formik
            initialValues={initialValuesFormik}
            validateOnChange={false}
            validateOnBlur
            validationSchema={signupSchema}
            enableReinitialize
            onSubmit={handleSubmitForm}
          >
            {({ setFieldValue, values, isSubmitting }) => (
              <Form className='form-light'>
                <EmailInput disabled={!!provider} />
                {(!provider || !provider.type)
                  && (
                    <>
                      <PasswordInput name='password' />
                      <PasswordInput name='confirmPassword' />
                    </>
                  )}
                <NicknameInput name='nickName' />
                <DateInput name='birthDate' />
                <GenderInput />
                <CountryInput
                  setFieldValue={setFieldValue}
                  values={values.country}
                />
                <CheckboxInput
                  name='newsletter'
                  label={t('marketing-consent.approvals.newsletter')}
                />
                <CheckboxInput
                  name='personalized-ads-offers'
                  containerClassName='register-consent-container'
                  label={t('marketing-consent.approvals.personalized-ads-offers')}
                />
                <p className='register-consent-opt-out'>{t('marketing-consent.opt-out')}</p>

                <p className='register-legal'>{t('auth.register.legal-contents.description')}</p>
                <CheckboxInput
                  required
                  name='privacy-policies'
                  containerClassName='register-consent-container'
                  label={(
                    <Trans
                      i18nKey={t('auth.register.legal-contents.approvals.privacy-policies')}
                      components={{
                        linkTermsOfUse: (
                          <TranslationLink
                            className='font-weight-bold'
                            url={t('auth.register.legal-contents.approvals.terms-of-use.link')}
                          />
                        ),
                        linkPrivacyPolicy: (
                          <TranslationLink
                            className='font-weight-bold'
                            url={t('auth.register.legal-contents.approvals.privacy-policy.link')}
                          />
                        ),
                      }}
                    />
                  )}
                />
                <p className='register-consent-opt-out'>{t('auth.register.legal-contents.withdrawl')}</p>

                <SubmitButton
                  disabled={!values['privacy-policies']}
                  name={isSubmitting ? 'waiting' : 'create-account'}
                  btnClassName={!values['privacy-policies'] ? 'red' : ''}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
