/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';

interface Props {
  title?: string;
  children?: JSX.Element | JSX.Element[];
}

export function ModalFlowContainer({ title, children }: Props): JSX.Element {
  return (
    <div className='modal-flow-container'>
      <div className='title'>{title}</div>
      {children}
    </div>
  );
}
