import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../_shared/modal/Modal';
import { RewardForm } from '../reward-form/RewardForm';
import { PromotionData } from '../../account/promotion/Promotion.model';
import { Description } from './reward-steps/Description';
import { RedeemingGameProvider, getLinkingProviderFromRedeemingProvider } from '../../account/providers/ProviderEnum';
import { ProviderSelection } from './reward-steps/ProviderSelection';
import { ProviderLinkStatus } from './reward-steps/ProviderLinkStatus';
import { MODAL_STEPS } from './RewardModal.model';
import { Success } from './reward-steps/Success';
import { ModalFooter } from '../../_shared/modal/ModalFooter';

interface Props {
  show: boolean;
  promotion: PromotionData;
  closeHandler: (rewardClaimed: boolean) => void;
}

export function RewardModal({ show, promotion, closeHandler: close }: Props): JSX.Element {
  const { t } = useTranslation();
  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const [selectedProvider, setSelectedprovider] = useState<RedeemingGameProvider | null>(null);
  const [providerLinked, setProviderLinked] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [rewardClaimed, setRewardClaimed] = useState(false);

  const { rewards, redeemableOnPlatformIds, relatedToGameId } = promotion;

  const currentStep = MODAL_STEPS[currentStepIdx];

  const closeHandler = (): void => {
    setCurrentStepIdx(0);
    setSelectedprovider(null);
    setProviderLinked(false);
    setRewardClaimed(false);
    close(rewardClaimed);
  };

  const nextStep = (): void => {
    setCurrentStepIdx(currentStepIdx + 1);
  };

  const nextHandler = (): void => {
    if (currentStep === 'description' && redeemableOnPlatformIds.length === 1) {
      const providerLinkStatusIdx = MODAL_STEPS.findIndex((p) => p === 'provider-link-status');
      setSelectedprovider(redeemableOnPlatformIds[0]);
      setCurrentStepIdx(providerLinkStatusIdx);
    } else {
      nextStep();
    }
  };

  const selectedProviderHandler = (provider: RedeemingGameProvider): void => {
    setSelectedprovider(provider);
  };

  const onLinkCallback = (): void => {
    setProviderLinked(true);
    nextStep();
  };

  const nextLabel = currentStep !== 'success' ? t('button.next') : undefined;
  const cancelLabel = currentStep === 'success' ? t('button.close') : t('common.cancel');
  const nextDisabled = (() => {
    if (currentStep === 'provider-selection' && !selectedProvider) {
      return true;
    }
    if (currentStep === 'provider-link-status' && !providerLinked) {
      return true;
    }
    return false;
  })();

  const title = (():string => {
    if (currentStep === 'consent-form') {
      return t('promotion.marketing-consent.form-title');
    }
    return t('promotion.marketing-consent.title');
  })();

  const formSubmitCallback = (rewardIsClaimed: boolean): void => {
    if (rewardIsClaimed) {
      setSuccessMsg(t('translation:promotion.marketing-consent.success-description-reward', {
        rewardGame: t(`games.${relatedToGameId}`),
      }));
    } else {
      setSuccessMsg(t('translation:promotion.marketing-consent.success-description-subscriptions'));
    }
    setRewardClaimed(rewardIsClaimed);
    nextStep();
  };

  return (
    <Modal
      show={show}
      close={closeHandler}
      maxWidth={600}
    >
      <div className='mkt-consent-reward-modal'>
        {currentStep !== 'success' && (
          <h4 className='mkt-consent-reward-modal--title'>
            {title}
          </h4>
        )}

        {currentStep === 'description' && (
          <Description rewards={rewards} />
        )}

        {currentStep === 'provider-selection' && (
          <ProviderSelection
            providers={redeemableOnPlatformIds}
            selectedProvider={selectedProvider}
            selectedProviderHandler={selectedProviderHandler}
          />
        )}

        {selectedProvider && (
          <>
            {currentStep === 'provider-link-status' && (
              <ProviderLinkStatus
                provider={getLinkingProviderFromRedeemingProvider(selectedProvider)}
                onLinkCallback={onLinkCallback}
              />
            )}

            {currentStep === 'consent-form' && (
              <RewardForm
                promotion={promotion}
                provider={selectedProvider}
                cancelHandler={closeHandler}
                cancelLabel={t('common.cancel')}
                submitCallback={formSubmitCallback}
              />
            )}
          </>
        )}

        {currentStep === 'success' && (
          <Success details={successMsg} />
        )}

        {currentStep !== 'consent-form' && (
          <ModalFooter
            name='mkt-consent-reward'
            nextLabel={nextLabel}
            nextHandler={nextHandler}
            nextDisabled={nextDisabled}
            cancelLabel={cancelLabel}
            cancelHandler={closeHandler}
          />
        )}
      </div>
    </Modal>
  );
}
