/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { PagesContent } from '@bhvr/components-manager';
import { GameIds } from '../account/my-games/MyGames.model';
import { LinkingGameProvider, ProviderName } from '../account/providers/ProviderEnum';
import { GameEdition } from '../authentication/Authentication.model';

export interface ProviderConfig {
  clientId?: string;
  appId?: string;
  url?: string;
  redirectUrl?: string;
}

export type ProvidersConfig = {
  [Key in ProviderName]: ProviderConfig;
};

export interface RecaptchaConfig {
  clientId: string;
}

export interface WebsiteConfig {
  recaptcha: RecaptchaConfig;
  url: string;
}

export interface PreRegReferral {
  limit: number;
  chaining: boolean;
  referralUrl: string;
  eventId: string;
}
export interface MarketingConfig {
  iterable?: {
    apiKey: string;
  };
}

export type KillswitchName = 'merge' | 'unmerge';
export interface GameProviderConfig {
  name: LinkingGameProvider;
  packageId?: string;
  killswitch: KillswitchName[];
  isMergeImplemented: boolean;
}
export interface GameConfig {
  id: GameIds;
  providers: GameProviderConfig[];
  displayOrder?: number,
  preRegistration?: {
    id: string;
    referral: PreRegReferral;
  };
  marketingConfig?: {
    iterable?: IterableConfig;
  }
  marketingListId?: string;
  released: boolean;
  new: boolean;
  isTwitchDropsActive: boolean;
  badges: string[];
  editions?: GameEdition[]
  isInPreorderPhase?: boolean
  releaseDate?: string
}

export interface ShareCodeConfig {
  relativeUrlPath: string;
  marketingConfig?: {
    iterable?: IterableConfig;
    id?: string;
  }
  thumbnail: Image
  providers?: string[]
}

export enum LocalStorageKeyName {
  PAGES_CONTENT = 'pagesContent',
}

export enum PageNames {
  GAMEPAGE = 'gamepage',
}
export interface ServerConfig {
  providers: ProvidersConfig;
  website: WebsiteConfig;
  gamesConfig: GameConfig[];
  shareCode: ShareCodeConfig;
  bhvrAccountConfig?: BhvrAccountConfig;
  marketingConsent?: {
    controlGroup?: string;
  }
}

export interface ProviderClientConfig {
  authUrl: string;
  redirectParamName: string;
  redirectParamMethod: 'hash' | 'searchParam';
  features?: string[]; // TODO: get from backend
}

export interface ClientConfig {
  host: string;
  origin: string;
  recaptchaEnabled: boolean;
  providers: {
    [key in ProviderName]: ProviderClientConfig;
  }
}

export interface PagesContentConfig {
  pages: {
    [id: string]: PagesContent
  }
  version?: string | undefined
  expiry?: number
}

export interface ConfigContext {
  clientConfig: ClientConfig | null;
  serverConfig: ServerConfig | null;
  isClientConfigLoad: boolean;
  isServerConfigLoad: boolean;
  pagesContent: PagesContentConfig
  getVersion: () => Promise<string>,
  updateStorageVersion: (versionName: string | null) => void;
  loadPagesContent: (gameId: string) => Promise<PagesContentConfig>;
}

export interface BhvrAccountConfig {
  iterable?: IterableConfig;
}

interface IterableConfig {
  channels?: { providerId: number, name: string }[];
  messageTypes?: { providerId: number, name: string }[];
}

export interface Image {
  src: string
  width?: number
  height?: number
  alt: string
}
export interface LegalContent {
  id: string;
  version: number;
  updatedAt: number;
  isApproved: boolean;
}
