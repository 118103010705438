import React from 'react';
import { RedeemingGameProvider } from '../../account/providers/ProviderEnum';
import { ProviderButton } from '../components/provider-button/ProviderButton';

interface Props {
  providers: RedeemingGameProvider[];
  selectedProvider: RedeemingGameProvider | null;
  selectedProviderHandler: (provider: RedeemingGameProvider) => void;
  display?: 'vertical' | 'horizontal';
  showName?: boolean;
}

export function ProviderListSelector(
  { providers, selectedProvider, selectedProviderHandler, display = 'horizontal', showName = false }: Props,
): JSX.Element {
  return (
    <div className={`provider-list-selector--${display === 'horizontal' ? 'horizontal' : 'vertical'}`}>
      {providers.map((provider) => (
        <ProviderButton
          key={provider}
          cta={() => selectedProviderHandler(provider)}
          provider={provider}
          className='btn-dark'
          showName={showName}
          selected={selectedProvider === provider}
        />
      ))}
    </div>
  );
}
