/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { ReferralStatus, RegistrationStatus } from '../account/Account.model';
import { GameIds } from '../account/my-games/MyGames.model';
import { useAuth } from '../authentication/AuthenticationContext';
import { RequestError } from '../Error.model';
import { post } from '../utils/Request';
import { myI18n } from '../i18n';

import mymLogo from '../_assets/mym-logo-white.webp';
import { useConfig } from '../contexts/ConfigContext';

export function PreregistrationReferral({ gameId, preRegId }: { gameId: GameIds, preRegId: string }): JSX.Element {
  const { referralCode } = useParams<{ referralCode: string }>();
  const { t } = useTranslation();
  const auth = useAuth();
  const history = useHistory();
  const config = useConfig();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewlyReferred, setIsNewlyReferred] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const currentPreReg = auth.user?.preRegistrations?.[gameId]?.[preRegId];
  const isReferred = currentPreReg?.referredBy?.status === ReferralStatus.ACCEPTED;

  const referPlayer = async (): Promise<void> => post(
    `${config.clientConfig?.host}/players/me/games/${gameId}/pre-registrations/${preRegId}/refer`,
    { data: { shareCodeId: referralCode } },
  );

  const acceptReferral = async (): Promise<void> => post(
    `${config.clientConfig?.host}/players/me/games/${gameId}/pre-registrations/${preRegId}/accept-referral`,
  );

  const redirectToSurvey = ():void => history.push(`/account/my-account/games/${gameId}/preregistration/survey`);

  const handleError = (error: RequestError): void => {
    setIsLoading(false);
    if (error.code) {
      setErrorCode(error.code);
    }
  };

  const handleReferral = async (): Promise<void> => {
    try {
      const status = currentPreReg?.status;
      const referredBy = currentPreReg?.referredBy;

      if (!currentPreReg || !status || (status === RegistrationStatus.UNREGISTERED && !referredBy)) {
        await referPlayer();
        await auth.getPlayer();
        redirectToSurvey();
      } else if (referredBy?.status === ReferralStatus.SENT) {
        redirectToSurvey();
      } else if (status === RegistrationStatus.REGISTERED || status === RegistrationStatus.SELECTED_NO_CLAIM) {
        setIsNewlyReferred(true);
        await referPlayer();
        await acceptReferral();
        await auth.getPlayer();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      handleError(e as RequestError);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!currentPreReg || !currentPreReg.status
      || currentPreReg.status === RegistrationStatus.UNREGISTERED
      || currentPreReg.status === RegistrationStatus.REGISTERED
      || currentPreReg.status === RegistrationStatus.SELECTED_NO_CLAIM) {
      handleReferral();
    } else {
      setIsLoading(false);
    }
  }, []);

  const Logo = memo((): JSX.Element | null => {
    switch (gameId) {
      case GameIds.MYM:
        return <img className='logo-img' src={mymLogo} alt='Meet Your Make Logo - White' />;
      default:
        return null;
    }
  });

  return (
    <div className={`survey-page-container ${gameId}`}>
      <div className='survey-flow-container'>
        <div className='logo-container registered'>
          <Logo />
          {isLoading && (
            <div className='loader' data-cy='loader'>
              <CircularProgress />
            </div>
          )}
          {errorCode && (
            <p>
              {myI18n.exists(`error:${errorCode}`)
                ? t(`error:${errorCode}`)
                : t('error:ReferralCodeInvalidOrExpired')}
            </p>
          )}
          {(!isLoading && !errorCode && (currentPreReg?.status === RegistrationStatus.SELECTED
            || currentPreReg?.status === RegistrationStatus.GRANTED))
            && (
              <p>{t('preregistration.referral.alreadyInPlaytest')}</p>
            )}

          {(!isLoading && !errorCode && isReferred && !isNewlyReferred)
            && (
              <>
                <p>{t('error:PlayerAlreadyReferredError')}</p>
              </>
            )}

          {(!isLoading && !errorCode && isReferred && isNewlyReferred)
            && (
              <>
                <CheckCircle className='icon success' />
                <h3 data-cy='registered-title'>
                  {t('preregistration.referral.acceptedTitle')}
                </h3>
                <p>{t('preregistration.referral.acceptedDescription')}</p>
              </>
            )}
          <button
            type='button'
            className='btn btn-primary-game btn-block'
            onClick={() => history.push('/')}
            data-cy='go-my-account'
          >
            {t('preregistration.backToAccount')}
          </button>
        </div>
      </div>
    </div>
  );
}
