import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorOutline } from '@mui/icons-material';
import { Modal } from '../../_shared/modal/Modal';
import { GameAccount } from '../../authentication/Authentication.model';
import { ABTestingError, DecodedToken } from '../MarketingConsent.model';
import { formatDate } from '../../utils/Time';
import { TranslationLink } from '../../_shared/translation/TranslationLink';

interface Props {
  error: ABTestingError | null;
  isOpen: boolean;
  decodedToken: DecodedToken;
  providerAccount: GameAccount | null;
  close: () => void;
}

export function ProviderErrorModal(
  { error, isOpen, decodedToken, providerAccount, close }: Props,
): JSX.Element {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  const { providerName, playerName } = decodedToken;

  const provider = t(`${providerName}`);

  const errorKey = error?.code === 'PlayerPromotionNotEligible' && !!providerAccount
    ? 'PlayerPromotionNotEligible-linked'
    : error?.code;

  const title = errorKey ? t(
    `promotion.marketing-consent.ab-testing.error.${errorKey}.title`,
    { provider, playerName },
  ) : null;
  const details = errorKey ? t(
    `promotion.marketing-consent.ab-testing.error.${errorKey}.details`,
    { provider, playerName, date: providerAccount?.createdAt ? formatDate(providerAccount?.createdAt, locale) : '' },
  ) : null;
  const steps = errorKey ? t(
    `promotion.marketing-consent.ab-testing.error.${errorKey}.steps`,
    { provider, playerName },
  ) : null;

  const playerSupportUrl = t('promotion.marketing-consent.ab-testing.error.playerSupportLink');

  return (
    <>
      <Modal
        show={isOpen}
        close={close}
        textClose={t('translation:button.close')}
        classClose='btn btn-link'
        maxWidth={550}
      >
        <div className='mkt-consent-provider-error-modal'>
          {title && details && steps && (
            <>
              <ErrorOutline className='icon error' />
              <div className='mkt-consent-provider-error-modal--container'>
                <h4>{title}</h4>

                <div className='mkt-consent-provider-error-modal--text'>
                  <Trans
                    i18nKey={details}
                    components={{ b: <b /> }}
                  />
                </div>

                <div className='mkt-consent-provider-error-modal--text'>
                  <span>
                    {t('promotion.marketing-consent.ab-testing.error.general-instruction')}
                  </span>
                </div>

                <div className='mkt-consent-provider-error-modal--text'>
                  <Trans
                    i18nKey={steps}
                    components={{
                      ol: <ol />,
                      li: <li />,
                      b: <b />,
                      playerSupportLink: <TranslationLink url={playerSupportUrl} />,
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
