/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

interface RequestErrorData {
  [key: string]: string;
}

export interface RequestError<T extends object = RequestErrorData> {
  code: string;
  message: string;
  data?: T;
}

export const isRequestError = <T extends object = RequestErrorData>(err: unknown)
: err is RequestError<T> => !!err && typeof err === 'object' && 'code' in err && 'message' in err;
