import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@mui/icons-material';

interface Props {
  details: string
}

export function Success({ details }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className='reward-step-success'>
      <CheckCircle className='reward-step-success--icon' />
      <h4 className='reward-step-success--title'>{t('marketing-consent.subscribed')}</h4>
      <span className='reward-step-success--details'>
        {details}
      </span>
    </div>
  );
}
