/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ReactNode } from 'react';
import { getProviderLogo } from '../../account/providers/ProviderLogo';
import { LinkingGameProvider } from '../../account/providers/ProviderEnum';
import { useAuth } from '../../authentication/AuthenticationContext';
import logoBhvr from '../../_assets/bhvr.svg';

interface Props {
  platform: LinkingGameProvider;
  icon: ReactNode;
  showUsername?: boolean;
}

export function LinkLogos({ platform, icon, showUsername = false }: Props): JSX.Element {
  const auth = useAuth();

  const account = auth.user.accounts.find((acc) => acc.type === platform);

  return (
    <div className='link-behaviour-logos'>
      <div className='account'>
        <img src={getProviderLogo(platform)} alt={platform} />
        {showUsername && <span>{account?.userName}</span>}
      </div>
      {icon}
      <div className='account'>
        <img src={logoBhvr} alt='Behaviour Interactive' />
        {showUsername && <span>{auth.user.nickName}</span>}
      </div>
    </div>
  );
}
