/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';

interface Props extends NavLinkProps {
  isNavLink?: boolean;
  disabled?: boolean;
}

export function LocalizedLink({ isNavLink, ...props }: Props): JSX.Element {
  if (isNavLink) {
    return (
      <NavLink
        className={`${props.className}`}
        to={props.to}
        id={props.id}
        activeClassName='is-active'
        {...props}
      >
        {props.children}
      </NavLink>
    );
  }

  return (
    <Link
      className={`${props.className} ${props.disabled ? 'disabled' : ''}`}
      to={props.to}
      id={props.id}
    >
      {props.children}
    </Link>
  );
}
