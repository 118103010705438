/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useState, useEffect } from 'react';
import { RequestError } from '../Error.model';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { get } from '../utils/Request';
import { GameIds } from '../account/my-games/MyGames.model';
import { Promotion, GamePromotions } from '../account/promotion/Promotion.model';
import { claimPromotion } from '../utils/Promotions';
import { useConfig } from './ConfigContext';

interface PromoContext {
  rewardGameId?: string;
  requiredGameId?: string;
  promotions: Promotion[];
  groupedPromotions: GamePromotions[];
  getPromotions: () => Promise<void>;
  claim: (promoId: string, platform?: string) => Promise<void>;
}

const PromotionContext = createContext<PromoContext>({
  rewardGameId: null,
  requiredGameId: null,
  promotions: [],
  groupedPromotions: [],
  claim: null,
  getPromotions: null,
});

export const usePromotion = (): PromoContext => useContext(PromotionContext);

const useProvidePromotion = (rewardGameId?: GameIds, requiredGameId?: GameIds): PromoContext => {
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [groupedPromotions, setGroupedPromotions] = useState<GamePromotions[]>([]);
  const config = useConfig();
  const snackbar = useSnackbar();

  const groupPromotionsByRequiredGame = (promos: Promotion[]): GamePromotions[] => {
    const requiredGames: GamePromotions[] = [];
    promos.forEach((promo) => {
      if (!promo.promotionData.requiredGameIds || promo.promotionData.requiredGameIds.length === 0) {
        return;
      }
      const currentRequiredGame = promo.promotionData.requiredGameIds[0];
      const requiredGameIndex = requiredGames.findIndex((x) => x.requiredGameId === currentRequiredGame);

      if (requiredGameIndex === -1) {
        requiredGames.push({ requiredGameId: currentRequiredGame, promotions: [promo] });
        return;
      }
      requiredGames[requiredGameIndex].promotions.push(promo);
    });

    return requiredGames;
  };

  async function getPromotions(): Promise<void> {
    if (!rewardGameId && !requiredGameId) {
      throw new Error('no GameId');
    }

    try {
      if (rewardGameId && !requiredGameId) {
        const promos: Promotion[] = await get(
          `${config.clientConfig.host}/players/me/games/${rewardGameId}/promotions`,
        );
        setPromotions(promos);
      }
      if (!rewardGameId && requiredGameId) {
        const promos: Promotion[] = await get(
          `${config.clientConfig.host}/players/me/games/${requiredGameId}/promotions?byRequiredGameId=true`,
        );
        setPromotions(promos);
      }
      if (rewardGameId && requiredGameId) {
        const promos: Promotion[] = await get(
          `${config.clientConfig.host}/players/me/games/${rewardGameId}/promotions`,
        );
        const filteredPromos = groupPromotionsByRequiredGame(promos)
          .find((x) => x.requiredGameId === requiredGameId)?.promotions;
        setPromotions(filteredPromos ?? []);
      }
    } catch (e) {
      const err = e as RequestError;
      snackbar.addErrorMessage(err.code);
    }
  }

  const claim = async (promoId: string, platformName?: string): Promise<void> => {
    try {
      const updatedPromos = await claimPromotion(config.clientConfig.host, promoId, promotions, platformName);
      setPromotions(updatedPromos);
    } catch (e) {
      const err = e as RequestError;
      snackbar.addErrorMessage(err.code || '404');
      throw e;
    }
  };

  useEffect(() => {
    getPromotions();
  }, [rewardGameId, requiredGameId]);

  useEffect(() => {
    setGroupedPromotions(groupPromotionsByRequiredGame(promotions));
  }, [promotions]);

  return {
    rewardGameId,
    requiredGameId,
    promotions,
    groupedPromotions,
    getPromotions,
    claim,
  };
};

export function ProvidePromotion({
  children,
  rewardGameId = null,
  requiredGameId = null,
}: {
  children: JSX.Element | React.ReactNode;
  rewardGameId?: GameIds | null;
  requiredGameId?: GameIds | null;
}): JSX.Element {
  const promotion = useProvidePromotion(rewardGameId, requiredGameId);

  return <PromotionContext.Provider value={promotion}>{children}</PromotionContext.Provider>;
}
