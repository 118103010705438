/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { useEffect } from 'react';
import { useConfig } from '../contexts/ConfigContext';

declare global {
  interface Window {
    grecaptcha: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      execute: (clientId: string, params: any)=>Promise<string>;
      ready: (cb: ()=>void)=>void;
    };
  }
}

interface Props {
  loaded: (isLoaded: boolean) => void;
}

export function ReCaptcha(props: Props): JSX.Element | null {
  const config = useConfig();

  const handleOnLoad = (): void => {
    window?.grecaptcha?.ready(() => {
      props.loaded(true);
    });
  };

  const loadScript = (): void => {
    const element = document.getElementsByTagName('script')[0];
    const fjs = element;
    let js = element;
    if (document.getElementById('recaptcha-sdk')) {
      handleOnLoad();
      return;
    }

    js = document.createElement('script');
    js.id = 'recaptcha-sdk';
    js.src = `https://www.google.com/recaptcha/api.js?render=${config.serverConfig.website.recaptcha.clientId}`;
    js.onload = handleOnLoad;
    if (fjs && fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs);
    } else {
      document.head.appendChild(js);
    }
  };

  useEffect(() => {
    loadScript();
  }, []);

  return null;
}
