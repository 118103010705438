/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RedeemingGameProvider } from '../../account/providers/ProviderEnum';
import { ModalFooter } from '../modal/ModalFooter';
import { ProviderListSelector } from '../provider-list-selector/ProviderListSelector';
import { ModalFlowContainer } from '../modal/modal-flow-container/ModalFlowContainer';
import { ModalFlowContent } from '../modal/modal-flow-container/ModalFlowContent';

interface Props {
  availablePlatforms: RedeemingGameProvider[];
  confirm: (platform: RedeemingGameProvider) => void;
  cancel: () => void;
}

export function PlatformSelection({ availablePlatforms, confirm, cancel }: Props): JSX.Element {
  const { t } = useTranslation();

  const [currentPlatform, setCurrentPlatform] = useState<RedeemingGameProvider | null>(null);

  const selectedProviderHandler = (provider: RedeemingGameProvider): void => {
    setCurrentPlatform(provider);
  };

  const confirmHandler = (): void => {
    if (currentPlatform) {
      confirm(currentPlatform);
    }
  };

  return (
    <>
      <ModalFlowContainer title={t('account.provider.platform-selection.title')}>
        <ModalFlowContent name='platform-selection'>
          <ProviderListSelector
            providers={availablePlatforms}
            selectedProvider={currentPlatform}
            selectedProviderHandler={selectedProviderHandler}
            display='vertical'
            showName
          />
        </ModalFlowContent>
        <ModalFooter
          name='cross-progression'
          nextDisabled={!currentPlatform}
          nextLabel={t('button.continue')}
          cancelLabel={t('common.cancel')}
          nextHandler={confirmHandler}
          cancelHandler={cancel}
        />
      </ModalFlowContainer>
    </>
  );
}
