/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { useFormikContext } from 'formik';
import React, { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldBase } from './FieldBase';

interface Props extends ComponentPropsWithoutRef<'button'> {
  containerClassName?: string,
  btnClassName?: string,
}

export function SubmitButton({ name = 'confirm', containerClassName, btnClassName, ...props }: Props): JSX.Element {
  const { isSubmitting } = useFormikContext();
  const { t } = useTranslation();
  return (
    <FieldBase name={name} containerClassName={containerClassName}>
      <button
        type='submit'
        className={`btn btn-primary-game btn-block btn-with-width m-auto ${btnClassName || ''}`}
        data-cy={`${name}-button`}
        disabled={props.disabled || isSubmitting}
        {...props}
      >
        {t(`button.${name}`)}
      </button>
    </FieldBase>
  );
}
