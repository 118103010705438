/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ReactNode } from 'react';
import { LinkOff } from '@mui/icons-material';
import { Trans, useTranslation } from 'react-i18next';
import { LinkingGameProvider } from '../../account/providers/ProviderEnum';
import { TranslationLink } from '../translation/TranslationLink';
import { ModalFooter } from '../modal/ModalFooter';
import { LinkStep } from './LinkFlow.model';
import { LinkLogos } from './LinkLogos';

interface Props {
  platform: LinkingGameProvider;
  nextStep: (newStep: LinkStep) => void;
  cancel: () => void;
  customDescription?: ReactNode;
}

export function LinkFlowUnlinked({ platform, nextStep, cancel, customDescription = null }: Props): JSX.Element {
  const { t } = useTranslation();

  const platformName = t(`translation:${platform}`);

  return (
    <>
      <div className='link-flow'>
        <p className='link-subtitle'>
          {t('account.provider.link-flow.unlinked-description-1', { platform: platformName })}
        </p>
        <p className='medium-emphasis'>
          {customDescription ?? t('account.provider.link-flow.unlinked-description-2', { platform: platformName })}
        </p>

        <LinkLogos platform={platform} icon={<LinkOff fontSize='large' />} />

        <div className='small-text'>
          <Trans
            i18nKey={t('account.provider.link-flow.disclaimer', { platform: platformName })}
            components={{
              linkPrivacyPolicy: (
                <TranslationLink
                  className='policy-link'
                  url={t('account.legal-contents.approvals.privacy-policy.link')}
                />
              ),
              linkTermsOfUse: (
                <TranslationLink
                  className='policy-link'
                  url={t('account.legal-contents.approvals.terms-of-use.link')}
                />
              ),
            }}
          />
        </div>
      </div>
      <ModalFooter
        name='link-flow'
        nextLabel={t('account.provider.link-flow.next-label.unlinked')}
        cancelLabel={t('common.cancel')}
        nextHandler={() => nextStep('warning')}
        cancelHandler={cancel}
      />
    </>
  );
}
