/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CodePayload, OtpModalData } from '../Authentication.model';
import { useAuth } from '../AuthenticationContext';
import { useConfig } from '../../contexts/ConfigContext';

export function Otp(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const inputEl = useRef(null);
  const config = useConfig();
  const auth = useAuth();

  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const data = auth.visibleModalData as OtpModalData;

  const sendCode = (payload: CodePayload, token: string | null = null):void => {
    if (data?.provider) {
      auth.signinWithProvider(
        { idToken: data.idToken },
        data.provider,
        '',
        payload,
      );
    } else {
      auth.signin(
        {
          email: data?.email,
          password: data?.password,
        },
        token,
        payload,
      );
    }
  };

  const handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault();

    const payload: CodePayload = {
      hash: data?.hash,
      expiryDate: data?.expiryDate,
      code: (event.target as Element).querySelector('input').value,
    };

    if (config.clientConfig.recaptchaEnabled) {
      window.grecaptcha.execute(config.serverConfig.website.recaptcha.clientId, { action: 'sendCode' })
        .then((token) => {
          sendCode(payload, token);
        });
    } else {
      sendCode(payload);
    }
  };

  const resendCode = async (): Promise<void> => {
    if (config.clientConfig.recaptchaEnabled) {
      const token = await window.grecaptcha.execute(
        config.serverConfig.website.recaptcha.clientId,
        { action: 'login' },
      );
      await auth.signin({
        email: data?.email,
        password: data?.password,
      }, token);
    } else {
      await auth.signin({
        email: data?.email,
        password: data?.password,
      });
    }
  };

  useEffect(() => {
    if (data?.isEmailVerified) {
      setIsEmailVerified(data?.isEmailVerified);
    }

    if ((!data?.password || !data?.email)
     && (!data?.provider || !data?.idToken)) {
      history.push('/');
      auth.updateVisibleModal('otp');
    }

    inputEl.current.focus();
  }, []);

  return (
    <div className='form-light'>
      <div className='row text-center'>
        <div className='col-12'>
          <h2>{t('auth.register.sign-up')}</h2>
        </div>
      </div>
      <form name='mfa-form' className='form-light mt-4' onSubmit={handleSubmit}>
        <div className='form-group'>
          <label htmlFor='code'>
            {t(isEmailVerified ? 'auth.otp.text-mfa' : 'auth.register.send-confirmation')}
          </label>
          <input
            ref={inputEl}
            className='form-control mt-3'
            name='code'
            type='text'
            data-cy='validation-code-input'
            required
          />
        </div>
        <div
          onClick={resendCode}
          className='resend-code-btn'
        >
          {t('auth.otp.resend-code')}
        </div>
        <div className='form-group mt-4'>
          <button
            type='submit'
            data-cy='submit-button'
            className='btn btn-primary-game btn-block'
          >
            {t('button.confirm')}
          </button>
        </div>
      </form>
    </div>
  );
}
