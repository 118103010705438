import React from 'react';
import { Edit, Straight } from '@mui/icons-material';
import { set } from '../utils/set';
import { ComponentReference, PageComponent, MoveDirection, PagesContent, Diffs } from '../Config.model';
import { ActionButton } from '../buttons/ActionButton';

export function EditControls({
  pagesContent,
  component,
  componentRef,
  toggleEditModal,
  isSaving,
  onUpdate,
  reverseMode,
  page,
  setDiff,
  diff,
}: {
  pagesContent: PagesContent,
  component: PageComponent,
  componentRef: ComponentReference,
  toggleEditModal: (componentRef: ComponentReference) => void,
  isSaving: boolean,
  onUpdate: (newContent: PagesContent, find?: boolean) => void,
  page: string,
  reverseMode?: boolean,
  setDiff: (diff: Diffs) => void,
  diff: Diffs
}): JSX.Element {
  const moveComponent = (direction: typeof MoveDirection[keyof typeof MoveDirection]): void => {
    const newConfig = { ...pagesContent };

    const order = componentRef.parent?.order ?? pagesContent[page]?.order;
    if (!order) return;

    const currentIndex = order.findIndex((id: string) => id === componentRef.id);

    const save = (nextIndex: number): void => {
      const temp = order[currentIndex];
      order[currentIndex] = order[nextIndex];
      order[nextIndex] = temp;

      let orderDiff;
      if (componentRef.parent) {
        // This is a child component
        const parentIndex = newConfig[page].components.findIndex((c) => c?.id === componentRef.id);
        orderDiff = ['changes', page, 'components', parentIndex, 'order'];
      } else {
        //  This is a root component
        orderDiff = ['changes', page, 'order'];
      }

      setDiff(set(diff, [...orderDiff, currentIndex], order[currentIndex]));
      setDiff(set(diff, [...orderDiff, nextIndex], order[nextIndex]));

      onUpdate(newConfig);
    };

    let nextIndex = -1;
    if (direction === MoveDirection.UP && currentIndex !== 0) {
      nextIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
    } else if (direction === MoveDirection.DOWN && currentIndex !== order.length - 1) {
      nextIndex = currentIndex + 1;
    }

    if (nextIndex === -1) {
      return;
    }
    save(nextIndex);
  };

  return (
    <div className='edit-container'>
      <span className='name-cpn'>{component.name}</span>
      <div className='buttons-container'>
        {!reverseMode && (
          <>
            <ActionButton disabled={isSaving} action={() => moveComponent(MoveDirection.UP)}>
              <Straight color='primary' className='edit-icon' />
              Up
            </ActionButton>
            <ActionButton disabled={isSaving} action={() => moveComponent(MoveDirection.DOWN)}>
              <Straight color='primary' className='edit-icon is-flipped' />
              Down
            </ActionButton>
          </>
        )}
        <ActionButton disabled={isSaving} action={() => toggleEditModal(componentRef)}>
          <Edit color='primary' className='edit-icon' />
          Edit
        </ActionButton>
      </div>
    </div>
  );
}
