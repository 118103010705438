/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';

interface Props { url?: string, className?:string, children?: React.ReactNode }

export function TranslationLink({ url, className, children }: Props): JSX.Element {
  const urlFromText = children.toString();
  return (
    <a
      href={url || urlFromText}
      className={className}
      target='_blank'
      rel='noreferrer'
    >
      {children}
    </a>
  );
}
