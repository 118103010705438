/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../snackbar/SnackbarContext';

interface Props {
  eventId: string;
  generateLink: () => Promise<void>;
}
export function GenerateReferralLinkComponent({ eventId, generateLink }: Props): JSX.Element {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const generate = async (): Promise<void> => {
    try {
      await generateLink();
    } catch {
      snackbar.addErrorMessage('InviteLinkNotGenerated');
    }
  };

  return (
    <div className='referral-container' data-cy='referral-container'>
      <h3>{t('account.my-account.referrals.referTitle')}</h3>
      <div>
        {t(`account.my-account.referrals.${eventId}.generateLinkDescription`)}
      </div>
      <button
        type='button'
        className='btn btn-primary-game generate-btn'
        data-cy='generate-link-btn'
        onClick={generate}
      >
        {t('account.my-account.referrals.getLink')}
      </button>
    </div>
  );
}
