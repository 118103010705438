/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/
import React, { ReactNode } from 'react';
import { UseTranslationResponse, useTranslation } from 'react-i18next';

interface Props {
  namespace: string;
  children: (t: UseTranslationResponse<string, undefined>) => ReactNode;
}

export function WithTranslationNamespace({
  namespace,
  children,
}: Props): JSX.Element {
  const translation = useTranslation(namespace);

  return (
    <>
      {children(translation)}
    </>
  );
}
