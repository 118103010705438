/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Error } from '@mui/icons-material';
import { CrossProgressionCompensationModal } from './CrossProgressionCompensationModal';
import { GameIds } from '../../../../account/my-games/MyGames.model';
import { PlayerCompensations } from '../CrossProgression.model';
import { get } from '../../../../utils/Request';
import { RequestError } from '../../../../Error.model';
import { useConfig } from '../../../../contexts/ConfigContext';

export interface Props {
  gameId: GameIds
}

export default function CrossProgressionCompensation({ gameId }: Props): JSX.Element {
  const { t } = useTranslation();
  const config = useConfig();

  const [showModal, setShowModal] = useState(false);
  const [playerCompensations, setPlayerCompensations] = useState<PlayerCompensations | null>(null);
  const [error, setError] = useState<RequestError | null>(null);

  const getCompensations = async (): Promise<void> => {
    try {
      const data: PlayerCompensations = await get(
        `${config.clientConfig?.host}/players/me/games/${gameId}/cross-progression-compensations`,
      );
      setPlayerCompensations(data);
    } catch (e) {
      const err = e as RequestError;
      if (err.code !== 'NoAccountForThisProviderError') {
        setError(err);
      }
    }
  };

  useEffect(() => {
    getCompensations();
  }, []);

  return (
    <div className='cross-progression-compensation'>
      {(error || playerCompensations?.compensations?.length > 0) && (
        <>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => setShowModal(true)}
            data-cy='view-compensation-modal'
            disabled={!!error}
          >
            {t('cross-progression.compensation.button')}
          </button>
          {error && (
            <div className='compensation--error'>
              <Error className='error' />
              <span>
                {t('cross-progression.compensation.error')}
              </span>
            </div>
          )}
          <CrossProgressionCompensationModal
            playerCompensations={playerCompensations}
            isOpen={showModal}
            close={() => setShowModal(false)}
          />
        </>
      )}
    </div>
  );
}
