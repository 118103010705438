/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useEffect, useState } from 'react';

export interface MenuContextModel {
  toggleMenu: () => void,
  open: boolean
}

const MenuContext = createContext<MenuContextModel>({
  toggleMenu: null,
  open: false,
});

export const useMenu = (): MenuContextModel => useContext(MenuContext);

const useProvideMenu = (): MenuContextModel => {
  const [open, setOpen] = useState<boolean>();

  const toggleMenu = (): void => {
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('open-menu');
    } else {
      document.body.classList.remove('open-menu');
    }
  }, [open]);

  return {
    open,
    toggleMenu,
  };
};

export function ProvideMenu(props: {
  children?: JSX.Element | JSX.Element[];
}): JSX.Element {
  const menu = useProvideMenu();

  return (
    <MenuContext.Provider value={menu}>
      {props.children}
    </MenuContext.Provider>
  );
}
