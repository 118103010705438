/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';

import { Signin } from './signin/Signin';
import { Signup } from './signup/Signup';
import { useGameTheme } from '../contexts/GameThemeContext';

export function Authentication(): JSX.Element {
  const match = useRouteMatch();
  const theme = useGameTheme();

  return (
    <div className='authentication-container'>
      <div className={theme?.gameId
        ? `card-container ${theme.gameId}` : 'card-container'}
      >
        <div className='card-container-flex'>
          <div className='auth-card-container'>
            <Switch>
              <Route path={`${match.path}/signin/:gameId`} exact>
                <Signin />
              </Route>
              <Route path={`${match.path}/signup/:gameId`} exact>
                <Signup />
              </Route>
              <Redirect to='/' />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
