/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ComponentPropsWithoutRef } from 'react';
import { motion } from 'framer-motion';

interface QuestionRequirement {
  questionId: string;
  choice: number;
}

export interface Question {
  id: string;
  title: string;
  subtitle?: string;
  requires?: QuestionRequirement;
}

export interface SimpleQuestion extends Question {
  choices: string[];
}

export interface MultipleQuestion extends SimpleQuestion {
  multiple: true;
  max?: number;
}

export interface MatrixQuestion extends SimpleQuestion {
  rows: {
    id: number;
    value: string | [string, string];
  }[];
}

export interface StringQuestion extends Question {
  textFormat: 'url' | 'text' | 'email'
}

export type Answer = number | number[] | string;

export interface Answers {
  [questionId: string]: Answer;
}

export interface QuestionDefaultProps {
  question: Question;
  currentAnswered: Answer;
  addAnswer: (answer: Answer, questionId: string) => void;
}

export interface MatrixQuestionDefaultProps extends QuestionDefaultProps {
  question: MatrixQuestion;
  isMobile?: boolean;
}

export interface QuestionBaseProps extends ComponentPropsWithoutRef<'div'> {
  question: Question;
  matrix?: boolean;
}

export function QuestionBase({ question, matrix, ...props }: QuestionBaseProps): JSX.Element {
  return (
    <motion.div
      className='question-container'
      transition={{
        type: 'tween',
        duration: 0.3,
      }}
      data-cy='survey-question'
    >
      <div className='question-header'>
        <h4>{question.title}</h4>
        {question.subtitle ? (
          <p>{question.subtitle}</p>
        ) : <br />}
      </div>
      <div className={matrix ? 'matrix-content' : 'question-content'}>
        { props.children }
      </div>
    </motion.div>
  );
}
