/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useState } from 'react';

import { Message, Snackbar, MessageData } from './Snackbar';

export interface SnackbarContext {
  addErrorMessage: (code: string, data?: MessageData, translateFile?: string) => void;
  addSuccessMessage: (code: string, data?: MessageData, translateFile?: string) => void;
  addInfoMessage: (code: string, data?: MessageData, translateFile?: string) => void;
  cleanMessage: () => void;
  message: Message;
}

const snackbarContext = createContext<SnackbarContext>({
  addErrorMessage: null,
  addSuccessMessage: null,
  addInfoMessage: null,
  cleanMessage: null,
  message: null,
});

export const useSnackbar = (): SnackbarContext => useContext(snackbarContext);

const useProvideSnackbar = (): SnackbarContext => {
  const [message, setMessage] = useState<Message>(null);

  const addErrorMessage = (code: string, data: MessageData = {}, translateFile = 'error'):void => {
    const mess: Message = {
      type: 'danger',
      code,
      data,
      translateFile,
    };
    setMessage(mess);
  };

  const addSuccessMessage = (code: string, data: MessageData = {}, translateFile = 'translation'):void => {
    const mess: Message = {
      type: 'success',
      code,
      data,
      translateFile,
    };
    setMessage(mess);
  };

  const addInfoMessage = (code: string, data: MessageData = {}, translateFile = 'translation'):void => {
    const mess: Message = {
      type: 'info',
      code,
      data,
      translateFile,
      unlimittedDuration: true,
    };
    setMessage(mess);
  };

  const cleanMessage = ():void => {
    setMessage(null);
  };

  return {
    addErrorMessage,
    addSuccessMessage,
    addInfoMessage,
    cleanMessage,
    message,
  };
};

interface Props {
  children?: JSX.Element | JSX.Element[];
}

export function ProvideSnackbar(props: Props): JSX.Element {
  const snackbar = useProvideSnackbar();

  return (
    <snackbarContext.Provider value={snackbar}>
      <Snackbar
        message={snackbar.message}
        cleanMessage={snackbar.cleanMessage}
      />
      {props.children}
    </snackbarContext.Provider>
  );
}
