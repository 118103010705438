/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { CheckCircle, Error, InfoOutlined, Warning } from '@mui/icons-material';
import { Trans } from 'react-i18next';

interface Props {
  initialLabel: string;
  labelToDo: string;
  labelDone: string;
  verified: boolean;
  completed: boolean;
  warningOnly?: boolean;
}

export function GameStatusRequirement({
  initialLabel,
  labelToDo,
  labelDone,
  verified,
  completed,
  warningOnly = false,
}: Props): JSX.Element {
  const getLabel = (): string => {
    if (!verified) {
      return initialLabel;
    }
    if (verified && completed) {
      return labelDone;
    }
    return labelToDo;
  };
  return (
    <div className='game-status-requirement' data-cy='game-status-requirement'>
      {!verified && <InfoOutlined className='info' />}
      {verified && completed && <CheckCircle className='success' />}
      {verified && !completed && (warningOnly ? <Warning className='warning' /> : <Error className='error' />)}
      <span data-cy='game-status-requirement-text'>
        <Trans
          i18nKey={getLabel()}
          components={{
            b: <strong />,
          }}
        />
      </span>
    </div>
  );
}
