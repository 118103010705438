/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { QuestionBase, QuestionDefaultProps, StringQuestion } from './QuestionBase';

interface TextQuestionProps extends QuestionDefaultProps {
  question: StringQuestion;
  currentAnswered: string;
}

export function TextQuestion({ question, currentAnswered, addAnswer }: TextQuestionProps): JSX.Element {
  return (
    <QuestionBase question={question}>
      <div className='form-light text-question'>
        <input
          className='form-control'
          type={question.textFormat}
          name={`${question.id}-text`}
          data-cy={question.id}
          value={currentAnswered as string || ''}
          onChange={(e) => addAnswer(e.target.value, question.id)}
        />
      </div>
    </QuestionBase>
  );
}
