/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import { Image } from '../Image';
import { Breadcrumb, BreadcrumbPath } from '../../breadcrumb/Breadcrumb';
import { components } from '../index';
import { GameIds } from '../../../account/my-games/MyGames.model';
import { TMedia, getImageUrl } from '../../../utils/ComponentsManager';

export const possibleChildren = [
  components.OWNED_ON,
];

export const definition: Definition = {
  name: {
    type: DefinitionPropertyType.String,
  },
  background: {
    type: DefinitionPropertyType.Image,
  },
  backgroundFixed: {
    type: DefinitionPropertyType.Boolean,
  },
  logo: {
    type: DefinitionPropertyType.Image,
  },
};

export default function Banner(
  { children, name, background, backgroundFixed = false, logo }:
  { children: React.ReactNode,
    name: string,
    background: TMedia,
    backgroundFixed: boolean,
    logo: TMedia },
):JSX.Element {
  const { gameId } : { gameId: GameIds } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const back = (): void => {
    history.push('/');
  };

  const breadcrumb: BreadcrumbPath[] = [
    {
      key: 'account.game-library.title',
      path: '/account/my-account/games',
    },
    {
      key: name,
      path: '',
    },
  ];

  const BannerClassName = useMemo((): Record<string, string> => {
    const backgroundImage = getImageUrl(background);

    return {
      backgroundImage: `linear-gradient(rgba(42,40,58,.5),rgba(42,40,58,.5)), url('${backgroundImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundAttachment: backgroundFixed ? 'fixed' : 'inherit',
      transition: 'all 0.5s',
    };
  }, [backgroundFixed, background]);

  return (
    <>
      <div className='banner' style={BannerClassName} data-cy={`${gameId}-banner`}>
        <button type='button' className='btn btn-secondary back-btn' onClick={back}>
          <ArrowBack className='icon' />
          {t('translation:button.back')}
        </button>
        <Breadcrumb paths={breadcrumb} />
        <Image
          name={logo ? logo.src : ''}
          alt={`${name} logo`}
          extraClass='logo'
        />
        {children}
      </div>
    </>
  );
}
