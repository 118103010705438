/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Error, Warning, Info } from '@mui/icons-material';

const statusTypes = ['error', 'warning', 'info'] as const;
export type StatusType = typeof statusTypes[number];

interface Props {
  statusType?: StatusType;
  message: string;
  className?: string;
}

export function StatusMessage({ statusType = 'error', message, className }:Props): JSX.Element {
  const statusClass = `status-message--${statusType}`;
  const iconClass = `icon ${statusType}`;

  const getIcon = (): JSX.Element | null => {
    switch (statusType) {
      case 'error':
        return <Error className={iconClass} />;
      case 'warning':
        return <Warning className={iconClass} />;
      case 'info':
        return <Info className={iconClass} />;
      default:
        return null;
    }
  };

  return (
    <div className={`status-message ${statusClass} ${className}`} data-cy='status-message'>
      {getIcon()}
      <span>
        {message}
      </span>
    </div>
  );
}
