/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { getProviderLogo } from '../../../../account/providers/ProviderLogo';
import { Chip } from '../../../chip/Chip';
import { formatDate, getTimeUnitFromDurationInMinutes } from '../../../../utils/Time';
import { Compensation, CompensationStatusType } from '../CrossProgression.model';

interface Props {
  compensation: Compensation;
  currencies: string[];
}

export function CompensationRow({ compensation, currencies }: Props): JSX.Element {
  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const dateFormat: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };
  const locale = i18n.language;
  const isBundle = compensation.dlcs?.length > 0;

  const getAvailableLabel = (availableOn: string): string => {
    const diff = new Date(availableOn).getTime() - Date.now();
    if (diff <= 0) {
      return t('cross-progression.compensation.availability.now');
    }
    const calcul = getTimeUnitFromDurationInMinutes(Math.round(diff / 60000), 0);
    return t('cross-progression.compensation.availability.later', { time: calcul.time, timeUnit: t(calcul.timeUnit) });
  };

  return (
    <>
      <TableRow className='compensation-table--row'>
        <TableCell>
          {isBundle && (
            <IconButton
              className='expand-btn'
              aria-label='expand row'
              size='small'
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          )}
          {isBundle
            ? t('cross-progression.compensation.bundle', { bundleSize: compensation.dlcs.length })
            : compensation.name}
        </TableCell>
        {currencies.map((currency) => (
          <TableCell key={currency} className='currency-cell'>
            {compensation.offers[currency]?.toLocaleString('en-CA') ?? ''}
          </TableCell>
        ))}
        <TableCell>
          <Chip
            label={t(`cross-progression.compensation.status.${compensation.status}`)}
            color={CompensationStatusType[compensation.status]}
          />
        </TableCell>
        <TableCell>
          {compensation.status === 'upcoming' && <Chip label={getAvailableLabel('2024-06-29T09:34:46.180Z')} />}
        </TableCell>
        <TableCell>
          {compensation.platforms?.map((platform) => (
            <img key={platform} src={getProviderLogo(platform)} alt={t(platform)} className='platform-logo' />
          ))}
        </TableCell>
        <TableCell>{formatDate(compensation.syncedOn, locale, dateFormat)}</TableCell>
      </TableRow>
      {isBundle && isExpanded
      && (
        <>
          {compensation.dlcs.map((dlc, index) => (
            <TableRow key={`dlc-${index}`} className='compensation-table--row bundle-item'>
              <TableCell>{dlc.name}</TableCell>
              <TableCell colSpan={currencies.length + 2} />
              <TableCell>
                {dlc.platforms.map((platform) => (
                  <img key={platform} src={getProviderLogo(platform)} alt={t(platform)} className='platform-logo' />
                ))}
              </TableCell>
              <TableCell />
            </TableRow>
          ))}

        </>
      )}
    </>
  );
}
