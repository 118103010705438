/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { PreRegistrations } from '../../../authentication/Authentication.model';
import { ProviderName } from '../../providers/ProviderEnum';
import { getProviderLogo } from '../../providers/ProviderLogo';
import { GameConfig } from '../../../contexts/Config.model';

interface Props {
  gameConfig: GameConfig;
  gameImage: string;
  // TODO: Convert object to Map for easier access
  userPreRegistration: PreRegistrations[keyof PreRegistrations];
  category: 'owned' | 'available' | 'upcomming',
  ownedOnProviders?: ProviderName[];
  isInPreorderPhase?: boolean
}

export function GameCard({
  gameConfig,
  gameImage,
  userPreRegistration,
  category,
  ownedOnProviders = [],
  isInPreorderPhase,
}: Props): JSX.Element {
  const [gameBadges, setGameBadges] = useState<string[]>(gameConfig?.badges || []);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    setGameBadges(gameConfig?.badges || []);
  }, [gameConfig?.badges, userPreRegistration]);

  const getBadges = (): string[] => {
    const newBadgeIndex = gameBadges?.indexOf('new');
    if (gameBadges.length > 1) {
      if (category === 'owned' && newBadgeIndex !== -1) {
        return gameBadges?.filter((badge) => badge === 'new');
      }
      return gameBadges?.filter((badge) => badge !== 'new');
    }
    return gameBadges;
  };

  return (
    <div
      key={gameConfig.id}
      className='game-card'
      data-cy={`games-${gameConfig.id}-button`}
    >
      <Link to={`/account/my-account/games/${gameConfig.id}`}>
        <div className='game-card-img' style={{ backgroundImage: `url('${gameImage}')` }}>
          {getBadges().map((badge, index) => (
            <span
              className={`badge badge--${badge}`}
              key={`badge-${gameConfig.id}-${index}`}
              data-cy={`game-badge-${badge}`}
            >
              {t(`account.my-account.games.${badge}`)}
            </span>
          ))}
          {
            isInPreorderPhase ? (
              <span className='badge badge--pre-order' data-cy={`${gameConfig.id}-pre-order-badge`}>
                {t('account.my-account.games.pre-order')}
              </span>
            ) : <></>
          }
        </div>
        <div className='game-card-title'>
          <div>{t(`games.${gameConfig.id}`)}</div>
          <div className='providers-logo'>
            {ownedOnProviders.map((provider) => (
              <img
                src={getProviderLogo(provider)}
                alt={provider}
                key={`${gameConfig.id}-${provider}-logo`}
              />
            ))}
          </div>
        </div>
      </Link>
      <div className='game-card-overlay'>
        <button
          type='button'
          className='btn btn-secondary btn-block mx-auto mt-3 mb-0'
          data-cy={`${gameConfig.id}-discover-btn`}
          onClick={() => history.push(`/account/my-account/games/${gameConfig.id}`)}
        >
          {t('button.discover-more')}
        </button>
      </div>
    </div>
  );
}
