/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { ProviderName } from '../../../account/providers/ProviderEnum';
import { getProviderLogo } from '../../../account/providers/ProviderLogo';

interface Props {
  providers: ProviderName[],
  selectedProvider: ProviderName | null,
  setSelectedProvider: (provider: ProviderName) =>void;
  linkStatus?: LinkStatus;
  displayLinkStatus?: boolean;
}

export const enum LinkStatus {
  UNSYNCED = 'unsynced',
  LINKED = 'linked',
  UNLINKED = 'unlinked',
}

export function ProviderDropdown(
  { providers,
    selectedProvider,
    setSelectedProvider,
    linkStatus,
    displayLinkStatus }: Props,
): JSX.Element {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const expand = (): void => {
    setIsExpanded(true);
  };

  const close = (): void => {
    setIsExpanded(false);
  };

  const onSelectHandler = (provider: ProviderName): void => {
    if (provider !== selectedProvider) {
      setSelectedProvider(provider);
    }
    close();
  };

  const getLinkLabel = (): string => {
    switch (linkStatus) {
      case LinkStatus.LINKED:
        return t('translation:account.my-account.linked');
      case LinkStatus.UNLINKED:
        return t('translation:account.my-account.unlinked');
      case LinkStatus.UNSYNCED:
        return t('translation:account.my-account.unsynced');
      default:
        return '';
    }
  };

  const selectedClass = !selectedProvider
    ? 'provider-dropdown-placeholder'
    : 'provider-dropdown-selected';

  return (
    <ClickAwayListener onClickAway={() => close()} mouseEvent='onMouseDown'>
      <div
        className='provider-dropdown'
      >
        <button
          type='button'
          className={`provider-dropdown-button ${selectedClass}`}
          onClick={() => (isExpanded ? close() : expand())}
          data-cy='provider-dropdown-btn'
        >
          {selectedProvider ? (
            <>
              <img
                className='provider-dropdown-button-img'
                src={getProviderLogo(selectedProvider)}
                alt={selectedProvider}
              />
              <div className='provider-dropdown-button-label'>
                <span>{ t(`translation:${selectedProvider}`)}</span>
                {
                  displayLinkStatus && (
                    <div className='provider-dropdown-status'>
                      <div className={
                        `provider-dropdown-status-icon provider-dropdown-status-${linkStatus}`
                      }
                      />
                      <span>{getLinkLabel()}</span>
                    </div>
                  )
                }
              </div>
            </>
          ) : <span>{t('translation:dropdown.providers')}</span>}
          {isExpanded
            ? <KeyboardArrowUp className='provider-dropdown-button-icon' />
            : <KeyboardArrowDown className='provider-dropdown-button-icon' /> }
        </button>

        {isExpanded && (
          <div className='provider-dropdown-content'>
            {providers.map((provider) => (
              <button
                key={provider}
                type='button'
                className='provider-dropdown-button'
                onClick={() => onSelectHandler(provider)}
                data-cy={`provider-dropdown-${provider}-btn`}
              >
                <img
                  className='provider-dropdown-button-img'
                  src={getProviderLogo(provider)}
                  alt={provider}
                />
                <span>{t(`translation:${provider}`)}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
