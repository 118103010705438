/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProviderUnlinkConfirmationModal } from '../ProviderUnlinkConfirmationModal';
import { ProviderName } from '../../ProviderEnum';
import { RequestError } from '../../../../Error.model';
import { StatusMessage } from '../../../../_shared/message/status-message/StatusMessage';

interface Props {
  provider: ProviderName
  setError: (error: RequestError | null) => void;
  isAuthMethod: boolean;
  isOnlyAuthMethod: boolean;
  showWarning: boolean;
  customLabel?: string;
  className?: string;
}

export function ProviderUnlinkButton(
  { provider, setError, isAuthMethod, isOnlyAuthMethod, showWarning, customLabel, className }: Props,
): JSX.Element {
  const { t } = useTranslation(['translation', 'error']);

  const [showModalDisconnect, setShowModalDisconnect] = useState(false);
  const openDisconnectModal = (): void => setShowModalDisconnect(true);
  const closeDisconnectModal = (): void => setShowModalDisconnect(false);

  return (
    <>
      <button
        type='button'
        onClick={openDisconnectModal}
        className={`btn btn-secondary btn-block mb-0 ${className}`}
        data-cy={`${provider}-unlink-button`}
        disabled={isOnlyAuthMethod}
      >
        {customLabel || t('translation:account.my-account.unlink')}
      </button>
      {isOnlyAuthMethod && showWarning && (
        <StatusMessage
          statusType='warning'
          message={t('translation:account.provider.onlyAuthenticationMethodWarning')}
          className='warning-msg'
        />
      )}
      <ProviderUnlinkConfirmationModal
        provider={provider}
        close={closeDisconnectModal}
        isOpen={showModalDisconnect}
        setError={setError}
        isAuth={isAuthMethod}
      />
    </>
  );
}
