/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { motion } from 'framer-motion';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ProviderName } from '../../../account/providers/ProviderEnum';
import { getProviderLogo } from '../../../account/providers/ProviderLogo';

interface Props {
  provider: ProviderName;
  cta: () => void;
  className?: string;
  showName?: boolean;
  selected?: boolean;
}

export function ProviderButton({ provider, cta, className, showName = false, selected = false }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <button
      type='button'
      onClick={() => cta()}
      aria-label={provider}
      className={`btn provider-btn ${className} ${selected ? 'provider-btn--selected' : ''}`}
      data-cy={`${provider}-btn`}
      title={t(provider)}
    >
      <motion.img
        layoutId={`${provider}-preferred-provider`}
        src={getProviderLogo(provider)}
        alt={`${provider}-img`}
        className='provider-btn--img'
      />
      {showName
      && (
        <>
          <span>{t(provider)}</span>
          {selected && <Check className='provider-btn--check' />}
        </>
      )}
    </button>
  );
}
