/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowForward } from '@mui/icons-material';
import { ProviderName } from '../../../../providers/ProviderEnum';
import { Promotion } from '../../../Promotion.model';
import { MergedAccount } from '../../../../../authentication/Authentication.model';
import { getProviderLogo } from '../../../../providers/ProviderLogo';
import { useAuth } from '../../../../../authentication/AuthenticationContext';
import rewardImgPlaceholder from '../../../../../_assets/bhvr-footer.png';
import { get } from '../../../../../utils/Request';
import { useConfig } from '../../../../../contexts/ConfigContext';
import { usePromotion } from '../../../../../contexts/PromotionContext';

const CARD_HEIGHT_SMALL = 85;
const CARD_HEIGHT_MEDIUM = 120;
const CARD_ASPECT_RATIO = 1.2;

interface Props {
  promotion: Promotion;
  redeemProvider: ProviderName;
  modifyProvider: () => void;
}

export function Confirmation({ promotion, redeemProvider, modifyProvider }: Props): JSX.Element {
  const auth = useAuth();
  const config = useConfig();
  const { t } = useTranslation();
  const { getPromotions } = usePromotion();

  const [mergedAccounts, setMergedAccounts] = useState<MergedAccount[]>([]);

  useEffect(() => {
    const getMergedAccounts = async (): Promise<void> => {
      try {
        const accounts = await get<MergedAccount[]>(
          `${config.clientConfig?.host}/players/me/mergedAccounts/${promotion.promotionData.relatedToGameId}`,
        );
        const redeemProviderMergedAccount = accounts.find((account) => account.provider === redeemProvider);
        if (redeemProviderMergedAccount.status === 'merged' && accounts.length > 1) {
          setMergedAccounts(
            redeemProviderMergedAccount
              ? [redeemProviderMergedAccount,
                ...accounts.filter((account) => account.provider !== redeemProvider && account.status === 'merged')]
              : [],
          );
        }
      } catch (e) {
        setMergedAccounts([]);
      }
    };

    getMergedAccounts();
    getPromotions();
  }, [redeemProvider]);

  const redeemProviderAccount = auth.user.accounts.find(
    (account) => account.type === redeemProvider,
  );

  const cardHeight = promotion.promotionData.rewards.length > 2
    ? CARD_HEIGHT_SMALL
    : CARD_HEIGHT_MEDIUM;
  const cardWigth = CARD_ASPECT_RATIO * cardHeight;

  return (
    <div className='confirmation-reward'>

      {promotion.promotionData.rewards.length <= 2
      && (
        <>
          <div className='confirmation-reward-card-container'>
            {promotion.promotionData.rewards.map((reward) => (
              <div
                key={reward.displayName}
                className='confirmation-reward-card'
                style={{ width: cardWigth, height: cardHeight }}
              >
                <img
                  alt={t(`promotions:${reward.displayName}`)}
                  src={process.env.NODE_ENV === 'development' ? rewardImgPlaceholder : reward.pictureURL}
                  className='confirmation-reward-card-img'
                  data-cy={`reward-img-${reward.displayName}`}
                />
                <span
                  className='confirmation-reward-card-title'
                  data-cy={`reward-title-${reward.displayName}`}
                >
                  {t(`promotions:${reward.displayName}`)}
                </span>
              </div>
            ))}
          </div>

          <ArrowForward fontSize='large' />
        </>
      )}

      <div className='confirmation-reward-provider'>
        {mergedAccounts.length > 1 ? (
          <>
            <div className='confirmation-reward-provider-merged'>
              {mergedAccounts.map((account) => (
                <img
                  key={account.provider}
                  alt={account.provider}
                  src={getProviderLogo(account.provider)}
                  className='confirmation-reward-provider-logo confirmation-reward-provider-logo-merged'
                />
              ))}
            </div>
            <span className='confirmation-reward-provider-user'>
              {t('translation:account.my-account.mergedAccounts')}
            </span>
          </>
        ) : (
          <>
            <img
              alt={redeemProvider}
              src={getProviderLogo(redeemProvider)}
              className='confirmation-reward-provider-logo'
            />
            <span className='confirmation-reward-provider-name'>
              {t(`translation:${redeemProvider}`)}
            </span>
            <span className='confirmation-reward-provider-user'>
              {redeemProviderAccount?.userName}
            </span>
          </>
        )}

        <button
          type='button'
          className='btn btn-secondary confirmation-reward-provider-button'
          onClick={() => modifyProvider()}
        >
          {t('translation:button.modify')}
        </button>
      </div>

    </div>
  );
}
