/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Error } from '@mui/icons-material';
import { ModalFlowContainer } from '../../modal/modal-flow-container/ModalFlowContainer';
import { ModalFlowContent } from '../../modal/modal-flow-container/ModalFlowContent';

interface Props {
  close: () => void;
}

export function SynchronizationFailed({ close }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <ModalFlowContainer>
      <ModalFlowContent name='cross-progression-success'>
        <div className='synchronization-failed' data-cy='sync-failed'>
          <Error className='icon error' />
          <p>
            {t('cross-progression.failed')}
          </p>
        </div>
      </ModalFlowContent>
      <button
        type='button'
        className='btn btn-secondary cross-progression-success--close-btn'
        onClick={close}
        data-cy='sync-failed-close-btn'
      >
        {t('button.close')}
      </button>
    </ModalFlowContainer>
  );
}
