/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ReactNode } from 'react';

interface Props {
  name: string;
  children?: ReactNode;
}

export function ModalFlowContent({ name, children }: Props): JSX.Element {
  return (
    <div className={`modal-flow-container--content ${name}-flow--content`}>
      {children}
    </div>
  );
}
