/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useMemo } from 'react';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';

export const definition: Definition = {
  color: { type: DefinitionPropertyType.String },
};

interface Props {
  color: string;
}

export function Separator({ color }: Props): JSX.Element {
  const SeparatorStyle = useMemo((): Record<string, string> => {
    if (color) {
      return { backgroundColor: color };
    }
    return {};
  }, [color]);

  return (
    <hr
      className='separator'
      style={SeparatorStyle}
    />
  );
}

export default Separator;
