/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Help } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { TOptions, StringMap } from 'i18next';

interface Props {
  id: string;
  message: string;
  options?: string | TOptions<StringMap>;
}

export function Tooltip({ id, message, options }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <span
        className='info-tooltip'
        data-tooltip-id={`tooltip-${id}`}
      >
        <Help />
        <ReactTooltip id={`tooltip-${id}`} place='right' variant='light'>
          {t(message, options)}
        </ReactTooltip>
      </span>
      <p className='tooltip-mobile'>{t(message, options)}</p>
    </>
  );
}
