/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageLayout } from '../account/layout/PageLayout';

import { GameIds } from '../account/my-games/MyGames.model';
import { ProvidePromotion } from '../contexts/PromotionContext';
import { useAuth } from '../authentication/AuthenticationContext';
import { Activation } from './Activation';
import { ActivationNotConnected } from './ActivationNotConnected';

const excludedGameIds = [GameIds.COFS];

export function ActivationPage(): JSX.Element {
  const history = useHistory();
  const { gameId }: { gameId: GameIds } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    if (excludedGameIds.includes(gameId)) {
      history.push('/');
    }
  }, [gameId]);

  return (
    <>
      {user ? (
        <PageLayout hideMenu disableTransitions>
          <ProvidePromotion requiredGameId={gameId}>
            <Activation requiredGameId={gameId} />
          </ProvidePromotion>
        </PageLayout>
      ) : (
        <PageLayout hideMenu disableTransitions>
          <ActivationNotConnected gameId={gameId} />
        </PageLayout>
      )}
    </>
  );
}
