/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ReactGA from 'react-ga';

import { useSnackbar } from '../../snackbar/SnackbarContext';
import { useAuth } from '../../authentication/AuthenticationContext';
import { Modal } from '../../_shared/modal/Modal';
import { put } from '../../utils/Request';
import { BhvrUser } from '../../authentication/Authentication.model';
import { RequestError } from '../../Error.model';

import { PageLayout } from '../layout/PageLayout';
import { useConfig } from '../../contexts/ConfigContext';

interface MFAModalBody {
  mfa: boolean;
}

interface CodeSendBody {
  code: string,
  hash: string,
  expiryDate: string,
  requiresMfa: boolean,
  isEmailVerified: boolean,
}

export function MultiFactorAuthentication(): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuth();
  const config = useConfig();
  const snackbar = useSnackbar();
  const [showModalCode, setShowModalCode] = useState(false);
  const [hash, setHash] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [initModal, setInitModal] = useState(false);
  const [code, setCode] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const codeSchema = Yup.object().shape({
    code: Yup.string()
      .required('form.field-required'),
  });

  const closeModalCode = (): void => {
    setShowModalCode(false);
    setExpiryDate(null);
    setHash(null);
    setCode(null);
    setErrorCode(null);
  };
  /**
   * first call, you'll receive an error message 'The code is invalid' because you don't have code, a modal is opening.
   * second call, you set the code in the modal's input that you received in your mailbox.
   * Normally your request pass, if not, an error is throwned.
   * @param body
   * @param isCodeSend
   */
  const sendCode = async (body: MFAModalBody | CodeSendBody, isCodeSend = false): Promise<void> => {
    try {
      const user: BhvrUser = await put(`${config.clientConfig?.host}/players/me/mfa`, { data: body });
      if (!isCodeSend) {
        auth.updateUser(user);
        ReactGA.event(
          {
            category: 'User',
            action: user.requiresMfa ? 'settings-mfa-activate' : 'settings-mfa-desactivate',
          },
        );
        closeModalCode();
      }
    } catch (e) {
      const err = e as RequestError;
      if (err.message === 'Code is required') {
        setExpiryDate(err.data.expiryDate);
        setHash(err.data.hash);
        setShowModalCode(true);
      } else {
        snackbar.addErrorMessage(err.code);
      }
    }
  };

  const openModalCode = (): void => {
    const body: MFAModalBody = {
      mfa: !auth.user.requiresMfa,
    };
    sendCode(body, true);
  };

  const handleSubmitCodeForm = async (): Promise<void> => {
    try {
      codeSchema.validate({ code });
      setErrorCode(null);

      const body: CodeSendBody = {
        code,
        hash,
        expiryDate,
        requiresMfa: !auth.user.requiresMfa,
        isEmailVerified: true,
      };

      sendCode(body);
    } catch (e) {
      const err = e as RequestError;
      setErrorCode(err.code);
      setInitModal(true);
    }
  };

  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCode(event.target.value);
  };

  return (
    <PageLayout>
      <div className='mfa-container form-light'>
        <div className='account-data-container'>
          <h2>{t('account.settings.mfa.title')}</h2>
          <p className='settings-subtitle'>{t('account.settings.mfa.description')}</p>
          <div className='form-group'>
            <div className='custom-control custom-switch'>
              <input
                type='checkbox'
                className='custom-control-input'
                id='customSwitches'
                data-cy='mfa-setting-checkbox'
                onChange={openModalCode}
                checked={auth.user.requiresMfa}
              />
              <label className='custom-control-label' htmlFor='customSwitches' data-cy='mfa-status-text'>
                {auth.user.requiresMfa ? t('button.enabled') : t('button.disabled')}
              </label>
            </div>
          </div>

          <Modal
            show={showModalCode}
            confirm={handleSubmitCodeForm}
            init={initModal}
            close={closeModalCode}
            textConfirm={t('button.confirm')}
            textClose={t('common.cancel')}
            maxWidth={550}
          >
            <div className='form-light'>
              <p>{t('auth.otp.text-otp')}</p>
              <div className='form-group'>
                <label htmlFor='code'>{t('form.label.code')}</label>
                <input
                  name='code'
                  id='code'
                  onChange={handleChangeCode}
                  className={`form-control ${(errorCode) ? 'is-invalid' : ''}`}
                  type='text'
                  data-cy='mfa-verification-code-input'
                />
                {errorCode ? (
                  <div className='form-error'>{t(errorCode)}</div>
                ) : null}
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </PageLayout>
  );
}
