/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useConfig } from './ConfigContext';
import { Promotion } from '../account/promotion/Promotion.model';
import { get } from '../utils/Request';
import { useAuth } from '../authentication/AuthenticationContext';
import { claimPromotion } from '../utils/Promotions';
import { GameIds } from '../account/my-games/MyGames.model';

interface Context {
  promotions: Promotion[];
  mandatoryLCApproved: boolean;
  updateMandatoryLCApproved: (value: boolean) => void;
  getPromotions: (token?: string, throwError?: boolean) => Promise<void>,
  claim: ((promoId: string, platform: string) => Promise<void>);
}

const MktConsentPromotionContext = createContext<Context>({
  promotions: [],
  mandatoryLCApproved: false,
  updateMandatoryLCApproved: () => {},
  getPromotions: () => Promise.resolve(),
  claim: () => Promise.resolve(),
});

export const useMktConsentPromotion = (): Context => useContext(MktConsentPromotionContext);

const useProvideMktConsentPromotion = (): Context => {
  const [mandatoryLCApproved, setMandatoryLCApproved] = useState<boolean>(false);
  const [mktConsentPromotions, setMktConsentPromotions] = useState<Promotion[]>([]);
  const auth = useAuth();
  const config = useConfig();

  const getMktConsentPromotions = async (token?: string, throwError?: boolean): Promise<void> => {
    try {
      // Add marketing consent token for A/B testing promotions
      const ABTestingParams = token ? `?token=${token}&gameId=${GameIds.DBD}` : '';
      const promos = await get<Promotion[]>(
        `${config.clientConfig?.host}/players/me/approvals/promotions${ABTestingParams}`,
      );

      setMktConsentPromotions(promos);
    } catch (err) {
      setMktConsentPromotions([]);

      // Manually handle custom error when fetching promotions
      if (throwError) {
        throw err;
      }
    }
  };

  const claim = async (promoId: string, platformName: string): Promise<void> => {
    if (config.clientConfig?.host) {
      const updatedPromos = await claimPromotion(config.clientConfig.host, promoId, mktConsentPromotions, platformName);
      setMktConsentPromotions(updatedPromos);
    }
  };

  const updateMandatoryLCApproved = (value: boolean): void => {
    setMandatoryLCApproved(value);
  };

  useEffect(() => {
    if (auth.user) {
      getMktConsentPromotions();
    } else {
      setMktConsentPromotions([]);
    }
  }, [auth.user?.email]);

  return {
    promotions: mktConsentPromotions,
    mandatoryLCApproved,
    updateMandatoryLCApproved,
    getPromotions: getMktConsentPromotions,
    claim,
  };
};

export function ProvideMktConsentPromotion({
  children,
}: {
  children: JSX.Element | React.ReactNode;
}): JSX.Element {
  const context = useProvideMktConsentPromotion();
  return <MktConsentPromotionContext.Provider value={context}>{children}</MktConsentPromotionContext.Provider>;
}
