import i18next from 'i18next';

export function buildTranslatedListOfStrings(strings: string[]): string {
  const lastIdx = strings.length - 1;
  const lastString = strings[lastIdx];
  const restOfStrings = strings.slice(0, lastIdx);

  const commaSeparatedString = restOfStrings.join(', ');

  const translatedListOfStrings = [
    ...(commaSeparatedString && [commaSeparatedString]),
    lastString,
  ].join(i18next.t('translation:common.and'));

  return translatedListOfStrings;
}
