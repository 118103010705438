/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { HomepageGameCard } from './HomepageGameCard';
import { useConfig } from '../../../contexts/ConfigContext';

export function HomepageGames(): JSX.Element {
  const { t } = useTranslation();
  const config = useConfig();
  const gamesConfigSorted = config.serverConfig.gamesConfig.sort(
    ({ displayOrder: displayOrderA }, { displayOrder: displayOrderB }) => {
      if (displayOrderA == null) return 1;
      if (displayOrderB == null) return -1;
      return displayOrderA - displayOrderB;
    },
  );

  return (
    <div className='homepage-games-container'>
      <div className='homepage-games-content'>
        <h2>{t('home.games.title')}</h2>
        <div className='game-list'>
          {gamesConfigSorted.map((game) => <HomepageGameCard key={game.id} gameId={game.id} />)}
          <div className='homepage-game-card coming-soon'>
            <div className='game-details'>
              <h3>{t('home.games.coming-soon')}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
