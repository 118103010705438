/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePromotion } from '../../../contexts/PromotionContext';
import { PromotionCard } from '../../../account/promotion/PromotionCard';
import { Tooltip } from '../../tooltip/Tooltip';

export function Promotion(): JSX.Element {
  const { t } = useTranslation(['translation']);
  const { rewardGameId: gameId, groupedPromotions } = usePromotion();

  return groupedPromotions.length > 0 ? (
    <section className='promotions-wrap'>
      {groupedPromotions.map((gamePromos) => (
        <div key={`promotion-game-${gamePromos.requiredGameId}`}>
          <div className='subtitle'>
            <h3 className='cross-progression-title'>
              {t(
                'translation:account.my-account.rewards',
                { requiredGame: t(`games.${gamePromos.requiredGameId}`) },
              )}
            </h3>
            <Tooltip
              id={gamePromos.requiredGameId}
              message='tooltip.rewards'
              options={{ requiredGame: t(`games.${gamePromos.requiredGameId}`), rewardGame: t(`games.${gameId}`) }}
            />
          </div>
          {gamePromos.promotions.map((promo) => (
            <PromotionCard
              key={`promotion-item-${promo.promotionData.promotionId}`}
              promotion={promo}
            />
          ))}
        </div>
      ))}
    </section>
  ) : (<></>);
}

export default Promotion;
