/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { StepLabel, StepContent, Step } from '@mui/material';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { TranslationLink } from '../../../../translation/TranslationLink';

interface Props {
  title: string;
  description?: string;
  active: boolean;
  expanded: boolean;
  children?: ReactNode;
  completed?: boolean;
}

export function ActivationStepContainer({
  title,
  description,
  active,
  expanded,
  children,
  completed,
  ...stepProps
}: Props):JSX.Element {
  return (
    <Step
      expanded={expanded}
      completed={completed}
      {...stepProps}
      className={active ? 'activation-step-container--active' : ''}
    >
      <div className='activation-step-container'>
        <div className='activation-step-container--bg' />
        <StepLabel><span className='activation-step-container--title'>{title}</span></StepLabel>
        <StepContent>
          <LayoutGroup>
            <AnimatePresence>
              {description && active && (
                <div className='activation-step-container--description'>
                  <span>
                    <Trans
                      i18nKey={description}
                      components={{
                        linkClaim: <TranslationLink />,
                      }}
                    />
                  </span>
                </div>
              )}
            </AnimatePresence>
          </LayoutGroup>

          <div className='activation-step-container--content'>
            {children}
          </div>
        </StepContent>
      </div>
    </Step>
  );
}
