import { RequestError, isRequestError } from '../Error.model';
import { LinkingGameProvider } from '../account/providers/ProviderEnum';
import { LegalContentModel } from '../legal-contents/LegalContent.model';

const ABTestingErrorCodes = ['GameAccountAlreadyExistsError', 'PlayerPromotionNotEligible'] as const;

type ABTestingErrorCode = typeof ABTestingErrorCodes[number];

export type ABTestingError = Omit<RequestError, 'code'> & { code: ABTestingErrorCode };

export function isABTestingError(error: unknown): error is ABTestingError {
  return isRequestError(error) && ABTestingErrorCodes.includes(error.code as ABTestingErrorCode);
}

export interface DecodedToken {
  playerName: string,
  providerName: LinkingGameProvider,
  rewardId: string,
}

export const MKT_CONSENT_LEGAL_CONTENT_IDS = ['newsletter', 'personalized-ads-offers'] as const;
export type MktConsentLegalContentId = typeof MKT_CONSENT_LEGAL_CONTENT_IDS[number];
export type MktLegalContentModel = Omit<LegalContentModel, 'id'> & { id: MktConsentLegalContentId };
