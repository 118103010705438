/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { memo, useEffect, useState } from 'react';
import { AnimatePresence, LayoutGroup, motion, useAnimation } from 'framer-motion';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../authentication/AuthenticationContext';
import { Questions, Survey } from '../account/surveys/Survey';
import { ReferralStatus, RegistrationStatus } from '../account/Account.model';
import { Answers } from '../account/surveys/QuestionTypes/QuestionBase';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { post } from '../utils/Request';

import { mymAlphaQuestions } from './Questions';
import mymLogo from '../_assets/mym-logo-white.webp';
import { GameIds } from '../account/my-games/MyGames.model';
import { UserAdsTracking } from './Preregistration.model';
import { useConfig } from '../contexts/ConfigContext';

export function PreregistrationSurvey({ gameId, preRegId }: { gameId: GameIds, preRegId: string }): JSX.Element {
  const [status, setStatus] = useState<RegistrationStatus>(RegistrationStatus.UNREGISTERED);
  const [isSurveyCompleted] = useState<boolean>();
  const [hasSurveyStatusError, setHasSurveyStatusError] = useState<boolean>(false);

  const { t } = useTranslation(['translation', 'mym', 'error']);

  const auth = useAuth();
  const config = useConfig();
  const snackbar = useSnackbar();
  const controls = useAnimation();
  const history = useHistory();

  const currentPreReg = auth.user?.preRegistrations?.[gameId]?.[preRegId];
  const isReferred = currentPreReg?.referredBy?.status === ReferralStatus.ACCEPTED;

  const randomizeMatrices = (matrixQuestions: Questions[]): Questions[] => {
    const newQuestions = matrixQuestions.map((question) => {
      if ('rows' in question) {
        const newRows = [...question.rows].sort(() => Math.random() - 0.5);
        return {
          ...question,
          rows: newRows,
        };
      }
      return question;
    });

    return newQuestions;
  };

  const submitSurvey = async (answers: Answers, tosAccepted: boolean, utmParams?: UserAdsTracking): Promise<void> => {
    try {
      await post(`${config.clientConfig?.host}/players/me/games/${gameId}/pre-registrations/${preRegId}/surveys`, {
        data: {
          response: answers,
          isTosAccepted: tosAccepted,
          registeredFromPaidFlow: utmParams || 'bhvraccount',
        },
      });
      auth.getPlayer();
      const preRegistration = auth.user?.preRegistrations?.[gameId]?.[preRegId];
      if (preRegistration?.referredBy?.status === ReferralStatus.SENT) {
        await post(
          `${config.clientConfig?.host}/players/me/games/${gameId}/pre-registrations/${preRegId}/accept-referral`,
        );
        auth.getPlayer();
        setStatus(RegistrationStatus.REGISTERED);
      } else {
        const confirmedStatus = preRegistration?.status || RegistrationStatus.SELECTED_NO_CLAIM;
        setStatus(confirmedStatus);
      }
    } catch (err) {
      snackbar.addErrorMessage('UnknownError');
    }
  };

  const getQuestions = (): Questions[] => {
    switch (gameId) {
      case GameIds.MYM:
        return mymAlphaQuestions as Questions[];
      default:
        return [];
    }
  };

  const handleBack = (): void => {
    history.goBack();
  };

  useEffect(() => {
    controls.start('visible');

    const preRegistrationStatus = auth.user?.preRegistrations?.[gameId]?.[preRegId]?.status;
    if (preRegistrationStatus) {
      setStatus(preRegistrationStatus);
    }
  }, []);

  const Logo = memo((): JSX.Element => {
    switch (gameId) {
      case GameIds.MYM:
        return <img className='logo-img' src={mymLogo} alt='Meet Your Make Logo - White' />;
      default:
        return <></>;
    }
  });

  return (
    <div className={`survey-page-container ${gameId}`}>
      <div className='survey-flow-container'>
        {hasSurveyStatusError ? (
          <>
            <div className='survey-card completed'>
              <div>
                {t('error:UnknownError')}
              </div>
              <button
                type='button'
                onClick={handleBack}
                className='btn btn-primary-game btn-block m-4'
                data-cy='survey-back-btn'
              >
                {t('translation:button.back')}
              </button>
            </div>

          </>
        ) : (
          <LayoutGroup>
            <motion.div
              layout
              className={`logo-container ${status !== RegistrationStatus.UNREGISTERED ? 'registered' : ''}`}
            >
              <motion.div layout='position'>
                <Logo />
                { status !== RegistrationStatus.UNREGISTERED && (
                  <motion.div
                    layout
                    initial={{ opacity: 0, y: 80 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      type: 'tween',
                      ease: 'easeInOut',
                      delay: 0.3,
                    }}
                  >
                    <CheckCircle className='icon success' />
                    {isReferred ? (
                      <>
                        <h3 data-cy='registered-title'>
                          {t('translation:preregistration.referral.acceptedTitle')}
                        </h3>
                        <p>
                          {t('translation:preregistration.referral.acceptedDescription')}
                        </p>
                      </>
                    ) : (
                      <>
                        <h3 data-cy='registered-title'>
                          {t(`${gameId}:preRegistration.${preRegId}.completedTitle`)}
                        </h3>
                        <p>
                          {t(`${gameId}:preRegistration.${preRegId}.completedDescription`)}
                        </p>
                      </>
                    ) }

                    <button
                      type='button'
                      className='btn btn-primary-game btn-block'
                      onClick={() => history.push('/')}
                      data-cy='go-my-account'
                    >
                      Go back to my account
                    </button>
                  </motion.div>
                )}
              </motion.div>
            </motion.div>
            <AnimatePresence>
              { status === RegistrationStatus.UNREGISTERED
               && (
                 <Survey
                   title={t(`${gameId}:preRegistration.${preRegId}.title`)}
                   questions={randomizeMatrices(getQuestions())}
                   submitSurvey={submitSurvey}
                   onError={(hasError) => setHasSurveyStatusError(hasError)}
                   isCompleted={isSurveyCompleted}
                 />
               )}
            </AnimatePresence>
          </LayoutGroup>
        )}

      </div>
    </div>
  );
}
