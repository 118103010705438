/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { Refresh } from '@mui/icons-material';
import { Trans, useTranslation } from 'react-i18next';
import { LinkingGameProvider } from '../../account/providers/ProviderEnum';
import { TranslationLink } from '../translation/TranslationLink';
import { ModalFooter } from '../modal/ModalFooter';
import { LinkStep } from './LinkFlow.model';
import { RequestError } from '../../Error.model';
import { GameAccount } from '../../authentication/Authentication.model';
import { Popup } from '../../utils/Popup';
import { LinkLogos } from './LinkLogos';

interface Props {
  platform: LinkingGameProvider;
  nextStep: (newStep: LinkStep) => void;
  cancel: () => void;
  platformAuthUrl: string;
  userAccount: GameAccount | null;
  error: RequestError | null;
}

export function LinkFlowRefreshConnection(
  { platform, nextStep, cancel, platformAuthUrl, userAccount, error }: Props,
): JSX.Element {
  const { t } = useTranslation();

  const [hasSynced, setHasSynced] = useState(false);

  const platformName = t(`translation:${platform}`);

  const next = (): void => {
    if (platformAuthUrl) {
      Popup.open(platformAuthUrl, `${platform}_auth`);
      setHasSynced(true);
    } else {
      nextStep('failed');
    }
  };

  useEffect(() => {
    if (userAccount && hasSynced) {
      nextStep('success');
    }
  }, [userAccount]);

  useEffect(() => {
    if (error) {
      nextStep('failed');
    }
  }, [error]);

  return (
    <>
      <div className='link-flow'>
        <p><strong>{t('account.provider.link-flow.refresh-description-1', { platform: platformName })}</strong></p>
        <p className='medium-emphasis'>
          {t('account.provider.link-flow.refresh-description-2', { platform: platformName })}
        </p>

        <LinkLogos platform={platform} icon={<Refresh fontSize='large' />} showUsername />

        <div className='small-text'>
          <Trans
            i18nKey={t('account.provider.link-flow.disclaimer', { platform: platformName })}
            components={{
              linkPrivacyPolicy: (
                <TranslationLink
                  className='policy-link'
                  url={t('account.legal-contents.approvals.privacy-policy.link')}
                />
              ),
              linkTermsOfUse: (
                <TranslationLink
                  className='policy-link'
                  url={t('account.legal-contents.approvals.terms-of-use.link')}
                />
              ),
            }}
          />
        </div>
      </div>
      <ModalFooter
        name='link-flow'
        nextLabel={t('account.provider.link-flow.next-label.refresh-connection')}
        cancelLabel={t('common.cancel')}
        nextHandler={next}
        cancelHandler={cancel}
      />
    </>
  );
}
