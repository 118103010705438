import { TranslatedStringProperty, Definition } from './DefinitionProperty.model';

export interface PageContent {
  order?: string[],
  components: PageComponent[];
}

export interface PagesContent {
  [pageName: string]: PageContent
}

export interface Image {
  src: string
  width?: number
  height?: number
  alt: string
}

export interface Video {
  src: string
  alt: string
  poster: Image
}

export type Asset = Image | Video;

export interface PageComponent {
  id: string;
  name: string;
  order?: string[];
  enabled?: boolean;
  reverseMode?: boolean;
  props?: ComponentProps;
  children?: PageComponent[];
}

export interface ComponentReference {
  id: string;
  name?: string;
  reverseMode?: boolean;
  order?: string[];
  parent?: ComponentReference;
}

export type ComponentProps = Record<string, PropType | unknown>
| { admin?: boolean, children?: JSX.Element | JSX.Element[] };

export const MoveDirection = {
  DOWN: 'down',
  UP: 'up',
} as const;

export type PropType =
  string | boolean | number | Record<string, string>
  | ArrayProp | Asset | TranslatedStringProperty;

export type ArrayProp = Array<
Record<string,
string | number | boolean>>;

export type AllComponentsType = Record<string, {
  default: React.FC<ComponentProps>,
  definition: Definition,
  possibleChildren?: string[] }>;

export interface FormField {
  name: string;
  errorMessage?: string;
}

export interface FormValidationError {
  fields: FormField[];
}

export interface FieldError {
  name: string,
  errorMessage: string,
}
export type Diff = Partial<PagesContent>;

export type Diffs = {
  changes?: Partial<PagesContent>,
  deleted?: Partial<PagesContent>
};
