/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';

export function Image(
  { name, alt, width, height, extraClass = '' }:
  { name: string, alt: string, width?: number, height?: number, extraClass?: string },
): JSX.Element {
  if (!name) {
    return <></>;
  }

  try {
    const exceptionPreview = 'data:image';
    if (name.search(exceptionPreview) >= 0) {
      return <img src={name} alt={alt} width={width} height={height} className={extraClass} />;
    }

    // Import image when on build time
    const image = require(`/src/_assets${name}`);

    if (!image) return <></>;

    return <img src={image} alt={alt} width={width} height={height} className={extraClass} />;
  } catch (error) {
    console.error(`Image with name "${name}" does not exist`);
    return <></>;
  }
}
