export type ClaimGameStep = {
  key: string,
  isCompleted: boolean;
  order: number;
};

export enum StepKey {
  NEWSLETTER = 'newsLetter-step',
  LINK_ACCOUNT = 'linkAccount-step',
  CLAIM_KEY = 'claimKey-step',
}

export interface KeysAvailableResponse {
  keysAvailable: boolean,
}
