/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { GameIds } from './MyGames.model';
import { ProvidePromotion } from '../../contexts/PromotionContext';
import { PageLayout } from '../layout/PageLayout';
import { ComponentsManagerWrapper } from '../../_shared/component-manager/componentManagerWrapper';
import { useRedirectUrl } from '../../redirect-url/ProviderRedirectUrlContext';
import { EditorVersion } from '../../_shared/admin/EditorVersion';
import { gamePageComponents } from '../../_shared/components';
import { ProvidePageActions } from '../../contexts/PageActionsContext';
import { useConfig } from '../../contexts/ConfigContext';
import { PageNames } from '../../contexts/Config.model';

export function GamePage(): JSX.Element {
  const location = useLocation();
  const config = useConfig();
  const { gameId } : { gameId: GameIds } = useParams();
  const { path } = useRedirectUrl();

  const [isLoading, setIsLoading] = useState(true);

  const configGameIds = config.serverConfig?.gamesConfig.map((g) => g.id) || [];

  useEffect(() => {
    setIsLoading(true);
    const loadContent = async (): Promise<void> => {
      if (config.pagesContent.version) {
        await config.loadPagesContent(gameId);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    loadContent();
  }, [location]);

  if (!configGameIds.includes(gameId)) {
    return <Redirect to={path} />;
  }

  return (
    <ProvidePromotion rewardGameId={gameId}>
      <ProvidePageActions gameId={gameId} pageName={PageNames.GAMEPAGE}>
        <PageLayout>
          {
            isLoading ? <div className='loader'><CircularProgress /></div>
              : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.25 } }}
                  exit={{ opacity: 0 }}
                  className='game-container our-game-container'
                >
                  {config.pagesContent.version && (<EditorVersion />)}
                  <ComponentsManagerWrapper gameId={gameId} pageComponents={gamePageComponents} />
                </motion.div>
              )
          }
        </PageLayout>
      </ProvidePageActions>
    </ProvidePromotion>
  );
}
