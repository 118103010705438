/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';

interface Props {
  onSelect: (isAccepted: boolean) => void
  submitting: boolean;
}
export function TermsOfService({ onSelect, submitting }: Props): JSX.Element {
  const [tosAccepted, setTosAccepted] = useState<boolean>(false);
  const history = useHistory();

  const handleSubmit = async (): Promise<void> => {
    onSelect(tosAccepted);
  };

  const handleBack = async (): Promise<void> => {
    history.goBack();
  };

  return (
    <>
      <div className='terms-container'>
        <input
          className='survey-input'
          type='checkbox'
          id='terms'
          name='terms'
          checked={tosAccepted}
          onChange={(e) => setTosAccepted(e.target.checked)}
          data-cy='terms-checkbox'
        />
        <label htmlFor='terms'>
          By checking this box, you agree to BHVR&apos;s&nbsp;
          <a
            href='https://www.bhvr.com/terms-of-use/'
            target='_blank'
            rel='noreferrer'
          >
            Terms of Use
          </a>
                &nbsp;and&nbsp;
          <a href='https://www.bhvr.com/privacy-policy/' target='_blank' rel='noreferrer'>Privacy Policy</a>
        </label>
      </div>
      <motion.div layout className='nav-btn-container'>
        <button
          type='button'
          onClick={handleBack}
          className='btn btn-primary-game btn-block'
          data-cy='survey-back-btn'
        >
          back
        </button>

        <button
          type='button'
          onClick={handleSubmit}
          className='btn btn-primary-game'
          disabled={!tosAccepted || submitting}
          data-cy='survey-submit-btn'
        >
          continue
        </button>
      </motion.div>
    </>
  );
}
