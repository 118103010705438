/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';

import { GoogleSdkLoader } from '../../../_shared/GoogleSdkLoader';
import { useAuth } from '../../AuthenticationContext';
import { getProviderLogo } from '../../../account/providers/ProviderLogo';
import { useConfig } from '../../../contexts/ConfigContext';

interface GoogleAuthApiInitParams {
  client_id: string;
  scope: string;
  ux_mode: string;
  callback(tokenResponse: CodeResponse): void;
}

interface CodeResponse {
  code: string;
}
interface GoogleAuth2Api {
  initCodeClient(params: GoogleAuthApiInitParams): GoogleAuth2Api;
  requestCode(): void;
}

export interface GoogleApiAccounts {
  oauth2?: GoogleAuth2Api;
}

export interface GoogleApi {
  accounts: GoogleApiAccounts;
}

declare global {
  interface Window {
    google?: GoogleApi;
  }
}

export function GoogleButton(): JSX.Element {
  const config = useConfig();
  const auth = useAuth();

  const [isSdkLoaded, setIsSdkLoaded] = useState(false);

  const onSdkLoaded = (): void => {
    setIsSdkLoaded(true);
  };

  const getUser = async (codeResponse: CodeResponse): Promise<void> => {
    try {
      await auth.signinWithProvider({
        idToken: codeResponse.code,
      }, 'google', null);
    } catch (e) {
      console.error((e as Error).message);
      throw e;
    }
  };

  const clickSignIn = async (): Promise<void> => {
    if (!isSdkLoaded) {
      return;
    }

    const client = window.google.accounts.oauth2.initCodeClient({
      client_id: config.serverConfig.providers.google.clientId,
      scope: 'https://www.googleapis.com/auth/userinfo.email',
      ux_mode: 'popup',
      callback: getUser,
    });

    client.requestCode();
  };

  return (
    <>
      <button
        type='button'
        id='register-google'
        data-cy='register-google-btn'
        className='btn btn-social btn-google'
        title='Google'
        onClick={clickSignIn}
      >
        <img src={getProviderLogo('google')} alt='Google Logo' />
      </button>
      <GoogleSdkLoader onLoad={onSdkLoaded} />
    </>
  );
}
