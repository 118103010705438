/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import { CheckCircle, Warning, Error, Info } from '@mui/icons-material';

import { myI18n } from '../i18n';

export type MessageType = 'danger' | 'info' | 'warning' | 'success';

export interface MessageData {
  [key: string]: string | boolean;
}
export interface Message {
  id?: number,
  code: string;
  type: MessageType;
  translateFile: string;
  data?: MessageData;
  unlimittedDuration?: boolean;
}

interface Props {
  message: Message;
  cleanMessage: ()=>void;
}

export function Snackbar(props: Props): JSX.Element {
  const { t } = useTranslation(['translation', 'error']);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageTimeout, setMessageTimeout] = useState<NodeJS.Timeout>(null);

  const DURATION = 5000;

  const dismissMessages = ():void => {
    if (messageTimeout) {
      clearTimeout(messageTimeout);
    }
    setMessages([]);
  };

  const dismissMessage = (id: number): void => {
    const newMessages = messages.filter((message) => message.id !== id);
    setMessages(newMessages);
  };

  const resetTimeout = (): void => {
    if (messageTimeout) {
      clearTimeout(messageTimeout);
    }

    const to = setTimeout(():void => {
      dismissMessages();
    }, DURATION);

    setMessageTimeout(to);
  };

  const getIcon = (type: string): JSX.Element => {
    switch (type) {
      case 'danger':
        return <Error className={`icon ${type}`} />;
      case 'success':
        return <CheckCircle className={`icon ${type}`} />;
      case 'warning':
        return <Warning className={`icon ${type}`} />;
      default:
        return <Info className={`icon ${type}`} />;
    }
  };

  useEffect(() => {
    if (props.message) {
      const newMessages = messages;
      const newMessage = props.message;
      newMessage.id = newMessages.length;
      newMessages.push(props.message);
      setMessages(newMessages);
      props.cleanMessage();
      if (!props.message.unlimittedDuration) {
        resetTimeout();
      }
    }
  }, [props.message]);

  return (
    <div className='Snackbar'>
      <AnimatePresence>
        {messages && messages
          .map((message) => (
            <div aria-live='polite' aria-atomic='true' className='snackbar-container' key={`message-${message.id}`}>
              {message
              && (
                <motion.div
                  layout
                  className={`alert alert-${message.type}`}
                  role='alert'
                  data-cy='snackbar-alert-text'
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 100 }}
                  transition={{
                    type: 'tween',
                    duration: 0.3,
                  }}
                >
                  {getIcon(message.type)}
                  {message.code && (
                    <p>
                      {myI18n.exists(`${message.translateFile}:${message.code}`)
                        ? t(`${message.translateFile}:${message.code}`, { ...message.data })
                        : t('error:UnknownError')}
                    </p>
                  )}

                  <span className='close' onClick={() => dismissMessage(message.id)}>
                    <svg
                      role='button'
                      xmlns='https://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                    >
                      <title>Close</title>
                      {/* eslint-disable-next-line max-len */}
                      <path d='M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z' />
                    </svg>
                  </span>
                </motion.div>
              )}
            </div>
          ))}
      </AnimatePresence>
    </div>
  );
}
