/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../modal/Modal';
import { PlayerCompensations } from '../CrossProgression.model';
import { CompensationRow } from './CompensationRow';
import { ModalFooter } from '../../../modal/ModalFooter';

interface Props {
  playerCompensations: PlayerCompensations;
  isOpen: boolean;
  close: () => void;
}

export function CrossProgressionCompensationModal({
  playerCompensations, isOpen, close,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const currencies = playerCompensations ? Object.keys(playerCompensations.totals) : [];

  return (
    <Modal
      show={isOpen}
      mobileFullScreen
      maxWidth={1150}
      className='cross-progression-compensation--modal'
    >
      <div className='compensation--container' data-cy='compensation-container'>
        <h4>{t('cross-progression.compensation.title')}</h4>
        <p>{t('cross-progression.compensation.description')}</p>
        <TableContainer className='compensation-table--container'>
          <Table className='compensation-table'>
            <TableHead className='compensation-table--header'>
              <TableRow>
                <TableCell>{t('cross-progression.compensation.header.dlc')}</TableCell>
                {currencies.map((currency) => (
                  <TableCell key={`header-${currency}`}>
                    {t(`cross-progression.compensation.header.currencies.${currency}`)}
                  </TableCell>
                ))}
                <TableCell>{t('cross-progression.compensation.header.status')}</TableCell>
                <TableCell>{t('cross-progression.compensation.header.availability')}</TableCell>
                <TableCell>{t('cross-progression.compensation.header.platforms')}</TableCell>
                <TableCell>{t('cross-progression.compensation.header.synced-on')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {playerCompensations?.compensations.map((compensation, index) => (
                <CompensationRow
                  key={`compensation-${index}`}
                  compensation={compensation}
                  currencies={currencies}
                />
              ))}
              <TableRow className='empty-space'>
                <TableCell colSpan={currencies.length + 5}>
                  {(!playerCompensations || playerCompensations?.compensations.length === 0) && (
                    <div className='empty-message'>
                      {t('cross-progression.compensation.empty')}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter className='compensation-table--footer'>
              <TableRow>
                <TableCell>{t('cross-progression.compensation.total')}</TableCell>
                {currencies.map((currency) => (
                  <TableCell key={currency} className='currency-cell'>
                    {playerCompensations.totals[currency].toLocaleString('en-CA')}
                  </TableCell>
                ))}
                <TableCell colSpan={4} />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <ModalFooter
        name='cross-progression-compensation'
        cancelLabel={t('button.close')}
        cancelHandler={close}
      />
    </Modal>
  );
}
