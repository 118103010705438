export const DefinitionPropertyType = {
  String: 'string',
  TranslatedString: 'translatedString',
  Image: 'image',
  Array: 'array',
  Boolean: 'boolean',
  Custom: 'custom',
  Date: 'date',
  Select: 'select',
  Number: 'number',
} as const;

export type DefinitionProperty = {
  type: typeof DefinitionPropertyType[keyof typeof DefinitionPropertyType],
};

export type Definition = {
  [key: string]: ImageProperty
  | StringProperty
  | TranslatedStringProperty
  | ArrayProperty
  | BooleanProperty
  | CustomProperty
  | DateProperty
  | SelectProperty
  | NumberProperty;
};

type DefinitionKey = keyof Definition;
type DefinitionValue = Definition[DefinitionKey];

export type ImageProperty = {
  type: typeof DefinitionPropertyType.Image
  width?: number,
  height?: number,
  maxWeight?: number
} & DefinitionProperty;

export type NumberProperty = {
  type: typeof DefinitionPropertyType.Number
} & DefinitionProperty;

export type StringProperty = {
  type: typeof DefinitionPropertyType.String
} & DefinitionProperty;

export type BooleanProperty = {
  type: typeof DefinitionPropertyType.Boolean
} & DefinitionProperty;

export type TranslatedStringProperty = {
  type: typeof DefinitionPropertyType.TranslatedString
} & DefinitionProperty;

export type ArrayProperty = {
  type: typeof DefinitionPropertyType.Array,
  elements: Record<string, DefinitionValue>
} & DefinitionProperty;

export type CustomProperty = {
  type: typeof DefinitionPropertyType.Custom
} & DefinitionProperty;

export type DateProperty = {
  type: typeof DefinitionPropertyType.Date,
} & DefinitionProperty;

export type SelectProperty = {
  type: typeof DefinitionPropertyType.Select,
  options: Record<string, string>,
} & DefinitionProperty;
