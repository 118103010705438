/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Promotion } from './Promotion.model';

import rewardImgPlaceholder from '../../_assets/reward-placeholder.png';

export function PromotionReward(
  { promotion, promotionClaimed } :
  { promotion: Promotion, promotionClaimed: boolean },
): JSX.Element {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width:576px)');
  const [rewardPicture, setRewardPicture] = useState<string | null>(null);

  useEffect(() => {
    const { combinedRewardPictureUrl, defaultPictureUrl } = promotion.promotionData;
    if (promotionClaimed && combinedRewardPictureUrl) {
      setRewardPicture(isSmallScreen ? combinedRewardPictureUrl.mobile : combinedRewardPictureUrl.desktop);
    } else {
      setRewardPicture(isSmallScreen ? defaultPictureUrl.mobile : defaultPictureUrl.desktop);
    }
  }, [isSmallScreen, promotion, promotionClaimed]);

  return (
    <div className='reward'>
      <img
        className='img-content'
        src={process.env.NODE_ENV === 'development' ? rewardImgPlaceholder : rewardPicture}
        alt={t(promotion.promotionData.title)}
      />
    </div>
  );
}
