import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { ProvidePageActions } from '../contexts/PageActionsContext';
import { EditorVersion } from '../_shared/admin/EditorVersion';
import { ComponentsManagerWrapper } from '../_shared/component-manager/componentManagerWrapper';
import { streamingCampaignPageComponents } from '../_shared/components';
import { PageLayout } from '../account/layout/PageLayout';
import { useStreamingCampaign } from '../contexts/StreamingCampaignContext';
import { GameIds } from '../account/my-games/MyGames.model';
import { ProviderName } from '../account/providers/ProviderEnum';
import { useConfig } from '../contexts/ConfigContext';

export function StreamingCampaignPage(): JSX.Element {
  const location = useLocation();
  const config = useConfig();
  const history = useHistory();
  const { campaign, campaignLoaded } = useStreamingCampaign();
  const { gameId, pageSlug }: { provider: ProviderName, gameId: GameIds, pageSlug: string } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const loadContent = async (): Promise<void> => {
    const version = await config.getVersion();
    if (version && campaign) {
      await config.loadPagesContent(campaign.gameId);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (campaignLoaded) {
      if (!campaign) {
        history.push('/');
      } else {
        setIsLoading(true);
        loadContent();
      }
    }
  }, [campaignLoaded, location]);

  return (
    <ProvidePageActions
      gameId={gameId}
      pageName={pageSlug}
    >
      <PageLayout hideMenu>
        {
          isLoading || !campaign ? <div className='loader'><CircularProgress /></div>
            : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.25 } }}
                exit={{ opacity: 0 }}
              >
                <div className='streaming-campaign'>
                  {config.pagesContent.version && (<EditorVersion />)}
                  <ComponentsManagerWrapper
                    gameId={gameId}
                    pageComponents={streamingCampaignPageComponents}
                  />
                </div>
              </motion.div>
            )
        }
      </PageLayout>
    </ProvidePageActions>
  );
}
