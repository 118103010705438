/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useEffect, useState } from 'react';
import { StreamingCampaign } from '../streaming-campaign/StreamingCampaign.model';
import { GameIds } from '../account/my-games/MyGames.model';
import { ProviderName } from '../account/providers/ProviderEnum';
import { get } from '../utils/Request';
import { RequestError } from '../Error.model';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { useConfig } from './ConfigContext';

export enum StreamingCampaignActivationStep {
  LOGIN, STREAMING_PLATFORM, PROVIDER, CLAIM,
}
interface CampaignContext {
  campaign: StreamingCampaign;
  campaignLoaded: boolean;
  activationStep: StreamingCampaignActivationStep;
  updateActivationStep: (currentStep: StreamingCampaignActivationStep) => void;
}

const StreamingCampaignContext = createContext<CampaignContext>({
  campaign: null,
  campaignLoaded: false,
  activationStep: StreamingCampaignActivationStep.LOGIN,
  updateActivationStep: null,
});

export const useStreamingCampaign = (): CampaignContext => useContext(StreamingCampaignContext);

const useProvideStreamingCampaign = (
  gameId: GameIds,
  streamingPlatform: ProviderName,
  slug?: string,
): CampaignContext => {
  const [campaign, setCampaign] = useState<StreamingCampaign>(null);
  const [campaignLoaded, setCampaignLoaded] = useState(false);
  const [activationStep, setActivationStep] = useState <StreamingCampaignActivationStep>(null);
  const config = useConfig();
  const snackbar = useSnackbar();

  const updateActivationStep = (currentStep: StreamingCampaignActivationStep): void => {
    setActivationStep(currentStep);
  };

  async function getStreamingCampaign(): Promise<void> {
    if (!gameId || !streamingPlatform || !slug) {
      throw new Error('Missing streaming campaign info');
    }

    try {
      const camp: StreamingCampaign = await get(
        `${config.clientConfig.host}/streaming-campaigns/${streamingPlatform}/${gameId}/${slug}`,
      );
      setCampaign(camp);
    } catch (e) {
      const err = e as RequestError;
      snackbar.addErrorMessage(err.code);
      throw e;
    } finally {
      setCampaignLoaded(true);
    }
  }

  useEffect(() => {
    if (slug) {
      getStreamingCampaign();
    }
  }, [gameId, streamingPlatform, slug]);

  return {
    campaign,
    campaignLoaded,
    activationStep,
    updateActivationStep,
  };
};

export function ProvideStreamingCampaign({
  children,
  gameId,
  streamingPlatform,
  slug,
}: {
  children: JSX.Element | React.ReactNode;
  gameId: GameIds;
  streamingPlatform: ProviderName;
  slug?: string;
}): JSX.Element {
  const context = useProvideStreamingCampaign(gameId, streamingPlatform, slug);
  return <StreamingCampaignContext.Provider value={context}>{children}</StreamingCampaignContext.Provider>;
}
