/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { useField } from 'formik';
import React, { useState, MouseEvent, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import { DefaultFieldProps, FieldBase, nameToDataCy } from './FieldBase';

export function PasswordInput({ name, ...props }: DefaultFieldProps): JSX.Element {
  const { t } = useTranslation();
  const [field, meta] = useField({ name });

  const [displayPasswordOptions, setDisplayPasswordOptions] = useState<boolean>(false);
  const [removeOptionsSpacing, setRemoveOptionsSpacing] = useState<boolean>(true);
  const [showPasswordText, setShowPasswordText] = useState<boolean>(false);
  let focusTimeout: ReturnType<typeof setTimeout>;
  let blurTimeout: ReturnType<typeof setTimeout>;

  const handleFocus = (): void => {
    if (focusTimeout) {
      clearTimeout(focusTimeout);
    }
    setRemoveOptionsSpacing(false);
    focusTimeout = setTimeout(() => {
      setDisplayPasswordOptions(true);
    }, 1);
  };

  const handleBlur = (): void => {
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
    setDisplayPasswordOptions(false);
    setShowPasswordText(false);
    blurTimeout = setTimeout(() => {
      setRemoveOptionsSpacing(true);
    }, 200);
  };

  useEffect(() => () => {
    if (focusTimeout) {
      clearTimeout(focusTimeout);
    }
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
  }, []);

  const togglePasswordText = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setShowPasswordText(!showPasswordText);
  };

  const PasswordEye = memo((): JSX.Element => (
    <span onMouseDown={togglePasswordText} className={`show-password-btn ${showPasswordText ? 'enabled' : ''}`}>
      {showPasswordText ? (
        <VisibilityOff />
      ) : (
        <Visibility />
      )}
    </span>
  ));

  return (
    <>
      <FieldBase name={name} meta={meta}>
        <div className='position-relative'>
          <input
            name={name}
            id={name}
            type={showPasswordText ? 'text' : 'password'}
            autoComplete='off'
            data-cy={`${nameToDataCy(name)}-input`}
            className={`form-control ${(meta.error && meta.touched) ? 'is-invalid' : ''}`}
            disabled={props.disabled}
            {...field}
            onFocus={handleFocus}
            onBlur={(e) => {
              field.onBlur(e);
              handleBlur();
            }}
          />
          {displayPasswordOptions && <PasswordEye />}
        </div>
      </FieldBase>
      {(name === 'password' || name === 'newPassword')
      && (
        <div className={
          `register-password
        ${displayPasswordOptions ? 'isDisplay' : ''}
        ${removeOptionsSpacing ? 'd-none' : 'd-block'}
      `
        }
        >
          <span>{t('auth.register.password-title')}</span>
          <ul>
            <li>{t('auth.register.password-line1')}</li>
            <li>{t('auth.register.password-line2')}</li>
            <li>{t('auth.register.password-line3')}</li>
            <li>{t('auth.register.password-line4')}</li>
          </ul>
        </div>
      )}
    </>
  );
}
