/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { GameIds } from '../account/my-games/MyGames.model';
import logoLgDBD from '../_assets/games/dbd/dbd-title.png';
import logoLgHOY from '../_assets/hoy-logo.webp';
import logoLgMYM from '../_assets/mym-logo-white.webp';
import logoLgFM from '../_assets/fm-logo.webp';
import logoLgIOI from '../_assets/logos/games/ioi-lg.png';
import logoLgCOFS from '../_assets/logos/games/cofs-lg.webp';
import logoLgProjectT from '../_assets/logos/games/project-t-lg.webp';

import logoDBD from '../_assets/logos/games/dbd.png';
import logoHOY from '../_assets/logos/games/hoy.png';
import logoMYM from '../_assets/logos/games/mym.png';
import logoFM from '../_assets/logos/games/fm.png';
import logoIOI from '../_assets/logos/games/ioi.png';
import logoWTF from '../_assets/logos/games/WTFLogo.png';
import logoCOFS from '../_assets/logos/games/cofs.png';

import logoNavDBD from '../_assets/games/dbd/dbd-title-long.png';

import thumbnailDBD from '../_assets/thumbnail/dbd.webp';
import thumbnailHOY from '../_assets/thumbnail/hoy.webp';
import thumbnailMYM from '../_assets/thumbnail/mym.webp';
import thumbnailFM from '../_assets/thumbnail/fm.webp';
import thumbnailIOI from '../_assets/thumbnail/sophia.webp';
import thumbnailCOFS from '../_assets/thumbnail/cofs.webp';
import thumbnailProjectT from '../_assets/thumbnail/project-t.webp';
import thumbnailWTF from '../_assets/thumbnail/wtf.jpg';
import thumbnailGeneric from '../_assets/thumbnail/generic.webp';

export function getGameLogo(gameId: GameIds, size: 'small' | 'large' = 'small'): string {
  switch (gameId) {
    case GameIds.DBD:
      return size === 'small' ? logoDBD : logoLgDBD;
    case GameIds.MYM:
      return size === 'small' ? logoMYM : logoLgMYM;
    case GameIds.HOY:
      return size === 'small' ? logoHOY : logoLgHOY;
    case GameIds.FM:
      return size === 'small' ? logoFM : logoLgFM;
    case GameIds.SOPHIA:
      return size === 'small' ? logoIOI : logoLgIOI;
    case GameIds.COFS:
      return size === 'small' ? logoCOFS : logoLgCOFS;
    case GameIds.PROJECTT:
      return logoLgProjectT;
    case GameIds.WTF:
      return size === 'small' ? logoWTF : logoWTF;
    default:
      return '';
  }
}

export function getGameThumbnail(gameId: GameIds): string {
  switch (gameId) {
    case GameIds.DBD:
      return thumbnailDBD;
    case GameIds.MYM:
      return thumbnailMYM;
    case GameIds.HOY:
      return thumbnailHOY;
    case GameIds.FM:
      return thumbnailFM;
    case GameIds.SOPHIA:
      return thumbnailIOI;
    case GameIds.COFS:
      return thumbnailCOFS;
    case GameIds.PROJECTT:
      return thumbnailProjectT;
    case GameIds.WTF:
      return thumbnailWTF;
    default:
      return thumbnailGeneric;
  }
}

export const getNavBarLogo = (gameId: GameIds): string | null => {
  switch (gameId) {
    case GameIds.DBD:
      return logoNavDBD;
    default:
      return null;
  }
};
