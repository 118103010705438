/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkingGameProvider } from '../../account/providers/ProviderEnum';
import { ProviderLink } from '../../account/providers/provider/ProviderLink';
import { ModalFooter } from '../modal/ModalFooter';

interface Props {
  platform: LinkingGameProvider;
  completeFlow: () => void;
  cancel: () => void;
  customCompleteFlowLabel: string | null;
}

export function LinkFlowSuccess({ platform, completeFlow, cancel, customCompleteFlowLabel }: Props): JSX.Element {
  const { t } = useTranslation();

  const platformName = t(`translation:${platform}`);
  return (
    <>
      <div className='link-flow'>
        <div className='link-success'>
          <ProviderLink
            provider={platform}
            showLink={false}
            showUnlink={false}
            showSync={false}
            showFeatures={false}
          />
          <p>{t('account.provider.link-flow.link-success', { platform: platformName })}</p>
        </div>
      </div>
      <ModalFooter
        name='link-flow'
        nextLabel={customCompleteFlowLabel ?? t('button.continue')}
        cancelLabel={t('common.cancel')}
        nextHandler={completeFlow}
        cancelHandler={cancel}
      />
    </>
  );
}
