/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';

import { FacebookButton } from './facebook-button/FacebookButton';
import { GoogleButton } from './google-button/GoogleButton';
import { AuthProviderButton } from './auth-provider-button/AuthProviderButton';

export interface ProviderUserInfo {
  idToken: string;
  email: string;
  firstName?: string;
  lastName?: string;
  nickName?: string;
}

export interface ProviderResponse {
  type: string;
  user: ProviderUserInfo;
  otp?: string;
}

export function Providers(): JSX.Element {
  return (
    <div className='provider-authentication-container'>
      <FacebookButton />
      <GoogleButton />
      <AuthProviderButton provider='twitch' />
      <AuthProviderButton provider='discord' />
    </div>
  );
}
