/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect, useState, ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultFieldProps, FieldBase, nameToDataCy } from './FieldBase';

interface DateOptions {
  day: string[];
  month: string[];
  year: string[];
}

interface DateValues {
  day: string;
  month: string;
  year: string;
}

interface DropdownProps extends ComponentPropsWithoutRef<'input'> {
  period: keyof DateValues;
  options: string[];
}

interface Props extends DefaultFieldProps {
  yearLimit?: number;
}

function DateDropdown({ period, name, options, disabled }: DropdownProps): JSX.Element {
  const { t } = useTranslation();
  const [field, meta] = useField({ name: period });

  return (
    <div className='col'>
      <select
        id={period}
        disabled={disabled}
        className={`form-control ${(meta.error && meta.touched) ? 'is-invalid' : ''}`}
        data-cy={`${nameToDataCy(name)}-${period}-form`}
        {...field}
      >
        <option value=''>
          {t(`form.label.${period}`)}
        </option>
        {options.map((item) => (
          <option value={item} key={`${item}`}>
            {item}
          </option>
        ))}
      </select>
      {meta.error && meta.touched && (
        <div key={`${period}-error`} className='form-error' data-cy={`${period}-error-text`}>
          {t(`${meta.error}`, { field: t(`form.label.${period}`) })}
        </div>
      )}
    </div>
  );
}

export function DateInput({ name, yearLimit = 100, ...props }: Props): JSX.Element {
  const { values } = useFormikContext<DateValues>();
  const [options, setOptions] = useState<DateOptions>({
    day: [],
    month: [],
    year: [],
  });

  const getYears = (): string[] => {
    const years = [];
    const currYear = new Date().getFullYear();

    // eslint-disable-next-line no-plusplus
    for (let i = currYear; i > currYear - yearLimit; i--) {
      years.push(i.toString());
    }

    return years;
  };

  const getMonths = (): string[] => {
    const months = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 12; i++) {
      months.push(i.toString().padStart(2, '0'));
    }

    return months;
  };

  const getDays = (): string[] => {
    const days = [];
    const numDays = moment(`${values.year}-${values.month}`, 'YYYY-MM').daysInMonth() || 31;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= numDays; i++) {
      days.push(i.toString().padStart(2, '0'));
    }

    return days;
  };

  const setDefaultOptions = (): void => {
    const year = getYears();
    const month = getMonths();
    const day = getDays();

    const dateOptions: DateOptions = {
      day,
      month,
      year,
    };

    setOptions(dateOptions);
  };

  const changeDays = (): void => {
    const day = getDays();
    const newOptions = {
      ...options,
      day,
    };
    setOptions(newOptions);
    values.day = values.day && Math.min(Number(values.day), Number(day[day.length - 1])).toString().padStart(2, '0');
  };

  useEffect(() => {
    setDefaultOptions();
  }, []);

  useEffect(() => {
    if (options.year.length > 0 && options.month.length > 0) {
      changeDays();
    }
  }, [values.month, values.year]);

  return (
    <FieldBase name={name}>
      <div className='form-row'>
        {Object.keys(options).map((period) => (
          <DateDropdown
            key={`${period}-dropdown`}
            period={period as keyof DateValues}
            name={name}
            options={options[period as keyof typeof options]}
            disabled={props.disabled}
          />
        ))}
      </div>
    </FieldBase>
  );
}
