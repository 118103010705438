/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { MouseEvent } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import { useTranslation } from 'react-i18next';
import { Stores } from '../../../account/my-games/MyGames.model';
import { getProviderLogo } from '../../../account/providers/ProviderLogo';

export const definition: Definition = {
  isReleased: {
    type: DefinitionPropertyType.Boolean,
  },
  src: {
    type: DefinitionPropertyType.String,
  },
  websiteUrl: {
    type: DefinitionPropertyType.String,
  },
  hasMultiplePlatforms: {
    type: DefinitionPropertyType.Boolean,
  },
  defaultStoreUrl: {
    type: DefinitionPropertyType.String,
  },
  xboxStoreUrl: {
    type: DefinitionPropertyType.String,
  },
  microsoftStoreUrl: {
    type: DefinitionPropertyType.String,
  },
  playstationStoreUrl: {
    type: DefinitionPropertyType.String,
  },
  steamStoreUrl: {
    type: DefinitionPropertyType.String,
  },
  epicStoreUrl: {
    type: DefinitionPropertyType.String,
  },
  websiteCtaText: {
    type: DefinitionPropertyType.TranslatedString,
  },
  ctaText: {
    type: DefinitionPropertyType.TranslatedString,
  },
};

declare interface TrailerProps {
  src: string,
  websiteUrl: string
  isReleased?: boolean,
  hasMultiplePlatforms?: boolean,
  xboxStoreUrl?: string,
  microsoftStoreUrl?: string,
  playstationStoreUrl?: string,
  steamStoreUrl?: string,
  epicStoreUrl?: string
  defaultStoreUrl?: string,
  websiteCtaText?: string,
  ctaText?: string
}

declare interface Provider {
  name: string,
  url: string,
  enabled: boolean,
  logo: string
}

// eslint-disable-next-line complexity
export function Trailer({ src,
  isReleased = true,
  hasMultiplePlatforms = false,
  websiteUrl,
  xboxStoreUrl,
  microsoftStoreUrl,
  playstationStoreUrl,
  steamStoreUrl,
  epicStoreUrl,
  defaultStoreUrl,
  websiteCtaText,
  ctaText }: TrailerProps):JSX.Element {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const goToStore = (storeUrl: string): void => {
    setAnchorEl(null);
    if (storeUrl) {
      window.open(storeUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    if (hasMultiplePlatforms) {
      setAnchorEl(event.currentTarget);
    } else {
      goToStore(defaultStoreUrl || websiteUrl);
    }
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  // TODO: Refactor with providers provide in the server config ? (CT-830)
  const defaultProviders: Provider[] = [
    ...steamStoreUrl || steamStoreUrl === '' ? [{
      name: Stores.STEAM,
      url: steamStoreUrl,
      logo: getProviderLogo('steam'),
      enabled: steamStoreUrl !== '',
    }] : [],
    ...playstationStoreUrl || playstationStoreUrl === '' ? [{
      name: Stores.PLAYSTATION,
      url: playstationStoreUrl,
      logo: getProviderLogo('psn'),
      enabled: playstationStoreUrl !== '',
    }] : [],
    ...xboxStoreUrl || xboxStoreUrl === '' ? [{
      name: Stores.XBOX,
      url: xboxStoreUrl,
      logo: getProviderLogo('xbox'),
      enabled: xboxStoreUrl !== '',
    }] : [],
    ...microsoftStoreUrl || microsoftStoreUrl === '' ? [{
      name: Stores.MICROSOFT,
      url: microsoftStoreUrl,
      logo: getProviderLogo('grdk'),
      enabled: microsoftStoreUrl !== '',
    }] : [],
    ...epicStoreUrl || epicStoreUrl === '' ? [{
      name: Stores.EPIC,
      url: epicStoreUrl,
      logo: getProviderLogo('egs'),
      enabled: epicStoreUrl !== '',
    }] : [],
  ];

  return (
    <div className='trailer-component'>
      <div className='iframe-wrapper'>
        {src && (
          <iframe
            key={src}
            className='reponsive-iframe'
            src={src}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        )}
      </div>
      <div className='button-group'>
        {websiteUrl && (
          <button
            type='button'
            className='btn btn-primary-game'
            onClick={() => { window.open(websiteUrl, '_blank', 'noopener,noreferrer'); }}
          >
            {websiteCtaText || t('button.discoverMore')}
          </button>
        )}

        <>
          <button
            type='button'
            id='stores-button'
            aria-controls={open ? 'stores-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className='btn btn-primary-game'
          >
            {ctaText || (isReleased ? t('button.getItNow') : t('button.wishlistNow'))}
          </button>
          { hasMultiplePlatforms && (
            <Menu
              id='stores-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'stores-button',
              }}
              disableScrollLock
              sx={{ marginTop: 1 }}
            >
              {
                defaultProviders?.map((provider) => (
                  <MenuItem
                    key={provider.name}
                    disabled={!provider.enabled}
                    onClick={() => goToStore(provider.url)}
                  >
                    <img src={provider.logo} alt={provider.name} className='logo' />
                    {t(`button.stores.${provider.name}`)}
                    {provider.url === '' && (
                      <span className='soon'>
                        {t('account.provider.coming')}
                        !
                      </span>
                    )}
                  </MenuItem>
                ))
              }
            </Menu>
          )}
        </>
      </div>
    </div>

  );
}

export default Trailer;
