import React from 'react';
import { useTranslation } from 'react-i18next';
import { RedeemingGameProvider } from '../../../account/providers/ProviderEnum';
import { ProviderListSelector } from '../../../_shared/provider-list-selector/ProviderListSelector';

interface Props {
  providers: RedeemingGameProvider[];
  selectedProvider: RedeemingGameProvider | null;
  selectedProviderHandler: (provider: RedeemingGameProvider) => void;
}

export function ProviderSelection({ providers, selectedProvider, selectedProviderHandler }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='reward-step-provider-selection'>
      <h6 className='reward-step-provider-selection--title'>{t('promotion.marketing-consent.provider')}</h6>
      <ProviderListSelector
        providers={providers}
        selectedProvider={selectedProvider}
        selectedProviderHandler={selectedProviderHandler}
      />
    </div>
  );
}
