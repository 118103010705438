/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable @typescript-eslint/no-explicit-any */
/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { PageComponent,
  ComponentProps,
  Definition,
  DefinitionPropertyType,
  ArrayProperty,
  ArrayProp,
  Asset,
  AllComponentsType,
  PagesContent } from '@bhvr/components-manager';
import cloneDeep from 'lodash.clonedeep';
import { allComponents } from '../_shared/components';

export async function convertBase64(file: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result.toString());
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export async function ValidateImageSize(file: File, maxWidth: number, maxHeight: number) : Promise<File> {
  return new Promise<File>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const image: any = new Image();
      image.src = e.target.result;
      image.onload = (evt: Record<any, any>) => {
        const { height } = evt.target as HTMLImageElement;
        const { width } = evt.target as HTMLImageElement;
        if (height !== maxHeight || width !== maxWidth) {
          const error = `Height and Width must be ${maxWidth}px by ${maxHeight}px.
          Actual is width: ${width}px, height: ${height}px`;
          // eslint-disable-next-line no-alert
          alert(error);
          reject(new Error(error));
        }

        resolve(file);
      };
    };
  });
}

export async function loadAndConvertImage(src: string): Promise<string> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const imgSrc = require(`/src/_assets/${src}`);
    const img = await fetch(imgSrc);
    const imgBlob = await img.blob();
    resolve(await convertBase64(imgBlob));
  });
}

export function downloadFile(filename: string, text: string): void {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const parseProps = async (props: ComponentProps, definition: Definition): Promise<ComponentProps> => {
  const newProps: { [key: string]: unknown } = { ...props };
  const keys = Object.keys(newProps);
  for (let i = 0; i < keys.length; i++) {
    if (definition[keys[i]]?.type === DefinitionPropertyType.Image) {
      const prop = newProps[keys[i]] as Asset;
      if (prop.src && !prop.src.startsWith('data:')) {
        const base64 = await loadAndConvertImage(prop.src);
        prop.src = base64;
      }
    } else if (definition[keys[i]]?.type === DefinitionPropertyType.TranslatedString) {
      newProps[keys[i]] = {
        type: DefinitionPropertyType.TranslatedString,
        value: newProps[keys[i]] as string,
      };
    } else if (definition[keys[i]]?.type === DefinitionPropertyType.Array) {
      newProps[keys[i]] = [];
      for (let j = 0; j < (props[keys[i] as keyof ComponentProps] as ArrayProp).length; j++) {
        const item = props[keys[i] as keyof ComponentProps] as ArrayProp;
        (newProps[keys[i]] as ArrayProp).push(
          await parseProps(
            { ...item[j] },
            (definition[keys[i]] as ArrayProperty).elements as Definition,
          ) as Record<string, string | number | boolean>,
        );
      }
    }
  }

  return newProps as ComponentProps;
};

export const parsePage = async (
  components: Array<PageComponent>,
  pageComponentsRef: AllComponentsType,
): Promise<Array<PageComponent>> => {
  if (!Array.isArray(components)) {
    return components;
  }

  const exportedComponents = [];
  for (let i = 0; i < components.length; i++) {
    const component = cloneDeep(components[i]);
    const props = cloneDeep(component.props);

    if (props) {
      const { definition }: any = pageComponentsRef[component.name];

      component.props = await parseProps(props, definition);
      component.children = await parsePage(component.children, pageComponentsRef);

      exportedComponents.push(component);
    }
  }

  return exportedComponents;
};

export const convertTranslationProps = (t: (key: string) => string, gameId: string, page: string) => (
  componentName: string,
  definitions: Definition,
  props: ComponentProps,
  id: string,
  parentComponentName = '',
): ComponentProps => {
  const properties: { [key: string]: unknown } = { ...props };
  if (!definitions) {
    return properties as ComponentProps;
  }
  const keys = Object.keys(definitions);
  for (let i = 0; i < keys.length; i++) {
    const field = definitions[keys[i]];

    if (field.type === 'array') {
      // Check what prop need to be converted
      const translatedProps = Object.keys(field.elements).filter(
        (k) => field.elements[k].type === 'translatedString',
      );

      if (translatedProps.length && properties[keys[i]]) {
        const prop = [...properties[keys[i]] as Array<Record<string, string>>];
        // We need to parse the props
        for (let index = 0; index < prop?.length; index++) {
          translatedProps.forEach((tp) => {
            prop[index] = {
              ...prop[index],
              [tp]: t(`${gameId}.${page}.${parentComponentName
                ? `${parentComponentName}.`
                : ''}${componentName}.${id}.${tp}${index ?? ''}`),
            };
          });
        }
        properties[keys[i]] = prop;
      }
    } else if (typeof field === 'object' && field.type === 'translatedString') {
      // Generate the key
      const translationKey = `${gameId}.${page}.${parentComponentName
        ? `${parentComponentName}.`
        : ''}${componentName}.${id}.${keys[i]}`;

      if (properties[keys[i]]) {
        properties[keys[i]] = t(translationKey) as string;
      }
    }
  }
  return properties as ComponentProps;
};

export const getTranslatedKeys = (
  props: PageComponent,
  componentName: string,
  gameId: string,
  page: string,
  id: string,
  parentComponentName?: string,
): Partial<Record<string, string>> => {
  if (!props || Object.keys(props).length === 0) {
    return {};
  }

  const translations: Record<string, string> = {};
  const { definition } = (allComponents as unknown as AllComponentsType)[componentName];
  const definitionsKeys: string[] = [];

  // filter definition (props) that has only been changed
  Object.keys(definition).forEach((def) => {
    if (props[def as keyof PageComponent]) {
      definitionsKeys.push(def);
    }
  });

  for (let i = 0; i < definitionsKeys.length; i++) {
    const field = definition[definitionsKeys[i] as string];

    if (field?.type === 'array') {
      // Check what prop need to be converted
      const translatedProps = Object.keys(field.elements).filter(
        (k) => field?.elements?.[k]?.type === 'translatedString',
      );

      if (translatedProps.length) {
        // We need to parse the props
        for (let index = 0; index < Object.keys((props as any)[definitionsKeys[i]]).length || 0; index++) {
          translatedProps.forEach((tp: any) => {
            const updatedIndexFromProps = Object.keys((props as any)[definitionsKeys[i]])[index];

            const translationsKey = `${gameId}.${page}.${parentComponentName
              ? `${parentComponentName}.`
              : ''}${componentName}.${id}.${tp}${updatedIndexFromProps ?? ''}`;

            translations[translationsKey] = (props as any)[definitionsKeys[i]][updatedIndexFromProps][tp];
          });
        }
      }
    } else if (typeof field === 'object' && field.type === 'translatedString') {
      // Generate the key
      const translationKey = `${gameId}.${page}.${parentComponentName
        ? `${parentComponentName}.`
        : ''}${componentName}.${id}.${definitionsKeys[i]}`;
      translations[translationKey] = (props as any)[definitionsKeys[i] as string];
    }
  }

  return translations;
};

export const generateTranslationsStrings = (
  { changes, pagesContent, gameId }:
  { changes: Record<string, any>, pagesContent: PagesContent, gameId: string },
): Record<string, string> => {
  let translations: Record<string, string> = {};
  const pages = Object.keys(changes);
  for (const page of pages) {
    const { components } = changes[page];

    if (!components) {
      return {};
    }

    const pindexes = Object.keys(components);
    pindexes.forEach((index: string) => {
      const c = components[parseInt(index, 10)];
      if (!c) {
        return;
      }
      const parent = pagesContent[page].components[parseInt(index, 10)];
      c.id = parent.id;
      // For each c.props check if it is a TranslatedString and generate the string
      // eslint-disable-next-line max-len
      const componentsTranslationKeys = getTranslatedKeys(c.props, parent.name, gameId, page, parent.id);

      translations = {
        ...componentsTranslationKeys,
        ...translations,
      };
      const { children } = c;
      if (children) {
        const cindexes = Object.keys(children);
        cindexes.forEach((i: string) => {
          const child = children[parseInt(i, 10)];
          const fullChild = parent.children[parseInt(i, 10)];
          if (!child) {
            return;
          }

          // For each child.props check if it is a TranslatedString and generate the string
          translations = {
            ...getTranslatedKeys(
              child.props,
              fullChild.name,
              gameId,
              page,
              fullChild.id,
              parent.name,
            ),
            ...translations,
          };

          child.id = parent.children[parseInt(i, 10)].id;
        });
      }
    });
  }
  return translations;
};

export type TMedia = {
  src: string
  type: string
} | null;

export const getImageUrl = (image: TMedia): string | null => {
  if (!image) {
    return null;
  }

  const exceptionPreview = 'data:image';
  if (image.src.search(exceptionPreview) >= 0) {
    return image?.src;
  }
  return require(`/src/_assets${image.src}`);
};
