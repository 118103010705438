/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import { GameIds } from '../../../account/my-games/MyGames.model';
import { CrossProgressionModal } from './CrossProgressionModal';
import { RedeemingGameProvider, isRedeemingGameProvider } from '../../../account/providers/ProviderEnum';
import { TutorialItem, Props as TutorialItemProps } from '../tutorial/TutorialItem';
import { MergedAccount } from '../../../authentication/Authentication.model';
import { get } from '../../../utils/Request';
import { RequestError } from '../../../Error.model';
import { useSnackbar } from '../../../snackbar/SnackbarContext';
import { SyncedAccountsCircle } from './synced-accounts-circle/SyncedAccountsCircle';
import { SyncedAccountsList } from './synced-accounts-list/SyncedAccountsList';
import CrossProgressionCompensation from './compensations/CrossProgressionCompensation';
import { useConfig } from '../../../contexts/ConfigContext';
import { GameConfig } from '../../../contexts/Config.model';

export const definition: Definition = {
  tutorial: {
    type: DefinitionPropertyType.Array,
    elements: {
      image: { type: DefinitionPropertyType.Image },
      title: { type: DefinitionPropertyType.TranslatedString },
      description: { type: DefinitionPropertyType.TranslatedString },
      caption: { type: DefinitionPropertyType.TranslatedString },
      background: { type: DefinitionPropertyType.Image },
    },
  },
};

export interface Props {
  tutorial?: TutorialItemProps[]
}

export default function CrossProgression({ tutorial = [] }: Props): JSX.Element {
  const { t } = useTranslation();
  const config = useConfig();
  const { gameId }: { gameId: GameIds } = useParams();
  const snackbar = useSnackbar();

  const [gameConfig, setGameConfig] = useState<GameConfig | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [initialPlatform, setInitialPlatform] = useState<RedeemingGameProvider | null>(null);
  const [mergedAccounts, setMergedAccounts] = useState<MergedAccount[]>([]);

  const availablePlatforms = gameConfig ? gameConfig.providers
    .reduce((acc: RedeemingGameProvider[], provider) => {
      // TODO: BA-2943 update the game config structure to configure isMergedImplemented so we can configure xbox/grdk
      // individually. Right now we assume it's enabled for both at the same time
      if (provider.isMergeImplemented) {
        if (provider.name === 'xbl') {
          acc.push('xbox', 'grdk');
        } else {
          acc.push(provider.name as RedeemingGameProvider);
        }
      }
      return acc;
    }, []) : [];

  const isSynced = (platform: RedeemingGameProvider, account: MergedAccount): boolean => (
    account.provider === platform && (account.status === 'merged' || account.status === 'inProgress')
  );

  const platformStatuses = availablePlatforms.map(
    (platform) => ({
      id: platform,
      synced: mergedAccounts.some((account) => isSynced(platform, account)),
    }),
  );

  const getMergedAccounts = async (): Promise<void> => {
    try {
      const accounts = await get<MergedAccount[]>(
        `${config.clientConfig?.host}/players/me/mergedAccounts/${gameId}`,
      );
      setMergedAccounts(accounts.filter((acc) => isRedeemingGameProvider(acc.provider)));
    } catch (e) {
      const err = e as RequestError;
      snackbar.addErrorMessage(err.code);
    }
  };

  const openModal = (platform: RedeemingGameProvider | null): void => {
    if (platform) {
      setInitialPlatform(platform);
    }
    setShowModal(true);
  };

  useEffect(() => {
    const co = config?.serverConfig?.gamesConfig?.find((gc) => gc.id === gameId);
    if (co) {
      setGameConfig(co);
      getMergedAccounts();
    }
  }, []);

  return (
    <>
      {availablePlatforms.length > 0 && (
        <section className='cross-progression-component'>
          <div className='cross-progression--title'>
            <h3>
              {t('cross-progression.title')}
            </h3>
          </div>
          <p className='cross-progression--description'>
            {t('cross-progression.description')}
          </p>

          <div className='cross-progression--container'>
            <div className='cross-progression--state'>
              <div className='cross-progression--card'>
                <SyncedAccountsCircle platforms={platformStatuses} />
                <SyncedAccountsList
                  availablePlatforms={availablePlatforms}
                  mergedAccounts={mergedAccounts}
                  openModal={openModal}
                  getMergedAccounts={getMergedAccounts}
                />
              </div>
              <CrossProgressionCompensation gameId={gameId} />
            </div>
            <div className='how-it-works'>
              {tutorial.map((item, index) => (
                <TutorialItem
                  key={`cross-progression-tutorial-item-${index}`}
                  image={item.image}
                  title={item.title}
                  description={item.description}
                  caption={item.caption}
                  background={item.background}
                />
              ))}
            </div>
          </div>

          <CrossProgressionModal
            isOpen={showModal}
            close={() => setShowModal(false)}
            platformStatuses={platformStatuses}
            initialPlatform={initialPlatform}
            tutorial={tutorial}
            getMergedAccounts={getMergedAccounts}
          />
        </section>
      )}
    </>
  );
}
