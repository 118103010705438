/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../_assets/bhvr-footer.png';

import { SocialIcon } from './SocialIcon';

export function Footer(): JSX.Element {
  const today = new Date();
  const { t } = useTranslation();

  return (
    <footer className='footer'>
      <div className='footer-container' data-cy='footer-container'>
        <div className='footer-links text-uppercase'>
          <ul>
            <li>
              <a
                id='footer-about-us'
                href={t('footer.nav.about-link')}
                className='bhvra-link'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('footer.nav.about')}
              </a>
            </li>
            <li>
              <a
                id='footer-privacy-policy'
                href='/privacy-policy'
                className='bhvra-link'
              >
                {t('footer.nav.privacy-policy')}
              </a>
            </li>
            <li>
              <a
                id='footer-tos'
                href='/terms-of-use'
                className='bhvra-link'
              >
                {t('footer.nav.terms-service')}
              </a>
            </li>
          </ul>
        </div>
        <div className='footer-brand'>
          <a
            id='footer-logo'
            href='https://www.bhvr.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={logo} alt={`${t('footer.logo')} Behaviour Interactive`} width='150' />
            <br />
          </a>
        </div>
        <div className='footer-socials'>
          <p className='h4 font-weight-bold'>{t('footer.talk-games')}</p>
          <ul className='mt-4'>
            <li>
              <SocialIcon social='facebook' href='https://www.facebook.com/behaviourinteractive/' />
            </li>
            <li>
              <SocialIcon social='linkedin' href='https://linkedin.com/company/behaviour-interactive/' />
            </li>
            <li>
              <SocialIcon social='instagram' href='https://instagram.com/behaviour_interactive' />
            </li>
            <li>
              <SocialIcon social='twitter' href='https://twitter.com/behaviour' />
            </li>
            <li>
              <SocialIcon social='youtube' href='https://www.youtube.com/channel/UCD0VJCKNdogzxUXtQPNdIiQ' />
            </li>
          </ul>
        </div>
      </div>
      <div className='text-center copyright'>
        <small>
          {t('footer.copyright', { year: today.getFullYear() })}
        </small>
      </div>
    </footer>
  );
}
