/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useState } from 'react';
import { AllComponentsType, PagesContent } from '@bhvr/components-manager';
import { downloadFile, parsePage } from '../utils/ComponentsManager';
import { getButlerJWT } from '../utils/Iframe';
import { post } from '../utils/Request';
import { GameIds } from '../account/my-games/MyGames.model';
import { useConfig } from './ConfigContext';

interface PAContext {
  pageName: string
  saveContent: (
    { changes, translations, deleted }:
    {
      changes: { [id: string]: PagesContent },
      translations: Record<string, string>,
      deleted: Record<number, string> }) => Promise<void>
  exportFile: (gameContent: PagesContent, pageComponentsRef: AllComponentsType) => void
  isSaving: boolean,
}

const PageActionsContext = createContext<PAContext>({
  pageName: '',
  saveContent: null,
  exportFile: null,
  isSaving: false,
});

export const usePageActions = (): PAContext => useContext(PageActionsContext);

const useProvidePageActions = (gameId: GameIds, pageName: string): PAContext => {
  const appConfig = useConfig();
  const [isSaving, setIsSaving] = useState(false);

  const saveContent = async (
    { changes, translations, deleted }:
    {
      changes: { [id: string]: PagesContent },
      translations: Record<string, string>,
      deleted: Record<number, string> },
  ): Promise<void> => {
    setIsSaving(true);

    await post(
      `${appConfig.clientConfig.host}/management/editor/${appConfig.pagesContent.version}/${gameId}`,
      {
        data: {
          changes,
          translations,
          deleted,
        },
        headers: {
          Authorization: `Bearer ${await getButlerJWT()}`,
        },
      },
    );

    setIsSaving(false);
  };

  const exportFile = async (content: PagesContent, pageComponentsRef: AllComponentsType): Promise<void> => {
    const result = await parsePage(content[pageName].components, pageComponentsRef);

    const newPagesContent = { ...content };
    newPagesContent[pageName].components = result;

    downloadFile(`config-${gameId}.json`, JSON.stringify(newPagesContent));
  };

  return {
    pageName,
    saveContent,
    exportFile,
    isSaving,
  };
};

export function ProvidePageActions(
  { gameId, pageName, children }:
  { gameId: GameIds, pageName: string, children: JSX.Element | React.ReactNode },
):JSX.Element {
  const content = useProvidePageActions(gameId, pageName);

  return <PageActionsContext.Provider value={content}>{children}</PageActionsContext.Provider>;
}
