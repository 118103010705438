/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Collections, InsertLink, Assignment, Mail, VpnKey, Security, SportsEsports } from '@mui/icons-material';
import { Menu } from '../menu/Menu';
import { GameIds } from '../my-games/MyGames.model';
import { Transitions } from '../transitions/Transitions';
import { useMktConsentPromotion } from '../../contexts/MktConsentPromotionContext';
import { PromotionStatus } from '../promotion/Promotion.model';
import { MenuLink } from '../menu/Menu.model';
import { useAuth } from '../../authentication/AuthenticationContext';

interface PageProps {
  children: JSX.Element,
  hideMenu?: boolean
  disableTransitions?: boolean
}

export function PageLayout({ children, hideMenu = false, disableTransitions = false }: PageProps):JSX.Element {
  const { gameId } : { gameId: GameIds } = useParams();
  const { promotions } = useMktConsentPromotion();
  const auth = useAuth();

  const getBackground = (): string => {
    switch (gameId) {
      case GameIds.DBD:
        return 'dbd-bg';
      case GameIds.HOY:
        return 'hoy-bg';
      case GameIds.MYM:
        return 'mym-bg';
      case GameIds.FM:
        return 'fm-bg';
      case GameIds.SOPHIA:
        return 'sophia-bg';
      case GameIds.COFS:
      case GameIds.PROJECTT:
      case GameIds.WTF:
        return 'other-game-bg'; // FIX TO AVOID SHOWING THE GENERIC BG IMAGE ON GAME PAGES
      default:
        return 'generic-bg';
    }
  };

  const localStorageValue = localStorage.getItem('mktPopupRewardDismissed');
  const userDismissedPromotion = localStorageValue && JSON.parse(localStorageValue) === true;

  const displayPromoNotification = userDismissedPromotion && promotions.some(
    (promo) => promo.userData?.status !== PromotionStatus.Claimed,
  );

  const menuLinks: MenuLink[] = [
    {
      id: 'games',
      path: 'my-account/games',
      translation: 'account.game-library.title',
      Icon: memo(() => <Collections className='menu-icon' />),
    },
    {
      id: 'providers',
      path: 'my-account/providers',
      translation: 'account.provider.title',
      Icon: memo(() => <InsertLink className='menu-icon' />),
    },
    {
      id: 'personal-information',
      path: 'personal-information',
      translation: 'account.settings.nav.details',
      Icon: memo(() => <Assignment className='menu-icon' />),
    },
    {
      id: 'communication-preferences',
      path: 'communication-preferences',
      translation: 'account.settings.nav.communication',
      Icon: memo(() => <Mail className='menu-icon' />),
      notification: !!displayPromoNotification,
    },
    {
      id: 'change-password',
      path: 'change-password',
      translation: 'account.settings.nav.password',
      Icon: memo(() => <VpnKey className='menu-icon' />),
      requires: !!auth.user?.requiresPassword,
    },
    {
      id: 'mfa',
      path: 'mfa',
      translation: 'account.settings.nav.mfa',
      Icon: memo(() => <Security className='menu-icon' />),
      requires: !!auth.user?.requiresPassword,
    },
    {
      id: 'playtest',
      isExternal: true,
      url: 'https://playtest.bhvr.com/Portal/behaviourinteractive/default',
      translation: 'account.settings.nav.playtest',
      Icon: memo(() => <SportsEsports className='menu-icon' />),
    },
  ];

  return (
    <>
      <div className={`account-container ${getBackground()}`}>

        {!hideMenu && (<Menu menuLinks={menuLinks} />)}

        <div className='account-content'>
          <Transitions disableTransition={disableTransitions}>
            { children }
          </Transitions>
        </div>
      </div>
    </>
  );
}
