import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProviderName } from '../../../account/providers/ProviderEnum';
import { getProviderLogo } from '../../../account/providers/ProviderLogo';
import { Popup } from '../../../utils/Popup';
import { buildAuthUrl, removeState } from '../../../utils/Providers';
import { useAuth } from '../../AuthenticationContext';
import { useConfig } from '../../../contexts/ConfigContext';

interface Props {
  provider: ProviderName;
}

export function AuthProviderButton({ provider }: Props): JSX.Element {
  const config = useConfig();
  const auth = useAuth();
  const { t } = useTranslation();

  const clientConfig = config.clientConfig.providers[provider];
  const serverConfig = config.serverConfig.providers[provider];

  const openPopup = (): void => {
    const url = buildAuthUrl(clientConfig.authUrl, serverConfig, provider);
    Popup.open(url, `${provider}_auth`);
  };

  const onMessage = (event: MessageEvent): void => {
    if (event.origin === window.location.origin && event.data?.type === provider
        && event.data?.providerData?.code) {
      auth.signinWithProvider({
        idToken: event.data.providerData.code,
      }, provider);
      removeState(provider);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <button
      type='button'
      id={`register-${provider}`}
      className={`btn btn-social btn-${provider}`}
      title={t(provider)}
      data-cy={`register-${provider}-btn`}
      onClick={openPopup}
    >
      <img src={getProviderLogo(provider, 'monochrome')} alt={`${provider} Logo`} />
    </button>
  );
}
