/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkingGameProvider } from '../../account/providers/ProviderEnum';
import { ProviderLink } from '../../account/providers/provider/ProviderLink';
import { myI18n } from '../../i18n';
import { formatDate } from '../../utils/Time';
import { RequestError } from '../../Error.model';
import { ModalFooter } from '../modal/ModalFooter';
import { LinkStep } from './LinkFlow.model';
import { Popup } from '../../utils/Popup';
import { GameAccount } from '../../authentication/Authentication.model';
import { Loading } from '../loading/Loading';

interface Props {
  platform: LinkingGameProvider;
  nextStep: (newStep: LinkStep) => void;
  cancel: () => void;
  platformAuthUrl: string;
  userAccount: GameAccount | null;
  error: RequestError | null;
}

export function LinkFlowFailed(
  { platform, nextStep, cancel, platformAuthUrl, userAccount, error }: Props,
): JSX.Element {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const next = (): void => {
    if (platformAuthUrl) {
      setIsLoading(true);
      Popup.open(platformAuthUrl, `${platform}_auth`);
    } else {
      nextStep('failed');
    }
  };

  useEffect(() => {
    if (userAccount && !error) {
      setIsLoading(false);
      nextStep('success');
    }
  }, [userAccount]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  return (
    <>
      <div className='link-flow'>
        <div className='link-success'>
          <ProviderLink
            provider={platform}
            showLink={false}
            showUnlink={false}
            showSync={false}
            showFeatures={false}
          />
          {isLoading && <Loading fullscreen={false} />}
          {!isLoading && (
            <>
              {error && myI18n.exists(`error:${error.code}`) && (
                <p>
                  <span>
                    {t(
                      'account.provider.modal.error.description1',
                      { error: t(`error:${error.code}`, {
                        provider: t(error.data?.provider as string || platform),
                        date: formatDate(error.data?.date as string, i18n.language),
                      }) },
                    )}
                  </span>
                  <br />
                  <br />
                  <span>
                    {t('account.provider.modal.error.description2')}
                  </span>
                </p>
              )}
              {(!error || !myI18n.exists(`error:${error.code}`)) && (
                <p>
                  {userAccount && t('account.provider.link-flow.refresh-fail-generic')}
                  {!userAccount && t('account.provider.link-flow.link-fail-generic')}
                </p>
              )}
            </>
          )}
        </div>
      </div>
      <ModalFooter
        name='link-flow'
        nextLabel={t('account.provider.link-flow.next-label.failed')}
        cancelLabel={t('common.cancel')}
        nextHandler={next}
        cancelHandler={cancel}
      />
    </>
  );
}
