/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../authentication/AuthenticationContext';
import { PageLayout } from '../layout/PageLayout';
import { ProviderName } from './ProviderEnum';
import { ProviderLinkList } from './provider/ProviderLinkList';
import { ProviderFeaturesTooltip } from './provider/ProviderFeaturesTooltip';
import { useMktConsentPromotion } from '../../contexts/MktConsentPromotionContext';
import { useConfig } from '../../contexts/ConfigContext';

export function Providers(): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuth();
  const config = useConfig();
  const { getPromotions } = useMktConsentPromotion();
  const [linkedProviders, setLinkedProviders] = useState<ProviderName[]>([]);
  const [unlinkedProviders, setUnlinkedProviders] = useState<ProviderName[]>([]);

  useEffect(() => {
    const linked = [] as ProviderName[];
    const unlinked = [] as ProviderName[];
    auth.user.authorizedProviders.forEach((provider) => {
      if (!config.serverConfig.providers[provider] || !config.clientConfig.providers[provider]) {
        return;
      }
      if (auth.user.accounts.some((x) => x.type === provider)
       || auth.user.authenticationMethods.some((x) => x.type === provider)) {
        linked.push(provider);
      } else {
        unlinked.push(provider);
      }
    });
    setLinkedProviders(linked);
    setUnlinkedProviders(unlinked);

    // Marketing consent promotions fetch needed for AB testing.
    // If user links steam account through Connections page, refetch promos
    getPromotions();
  }, [auth.user.accounts]);

  return (
    <PageLayout>
      <div className='my-account-container'>
        <h1>{t('account.provider.title')}</h1>
        <p>{t('account.provider.description')}</p>
        <div>
          <div className='subtitle'>
            <h3>{t('account.provider.title-linked-providers')}</h3>
          </div>
          {linkedProviders.length > 0 && (
            <ProviderLinkList providers={linkedProviders} />
          )}

          {!linkedProviders.length && (
            <div className='providers-container'>
              <div className='provider-card text-center placeholder'>
                <p>{t('account.provider.no-account-linked')}</p>
              </div>
            </div>
          )}
        </div>
        {unlinkedProviders.length > 0 && (
          <div>
            <div className='subtitle'>
              <h3>{t('account.provider.title-unlinked-providers')}</h3>
            </div>
            <ProviderLinkList providers={unlinkedProviders} />
          </div>
        )}
        <ProviderFeaturesTooltip />
      </div>
    </PageLayout>
  );
}
