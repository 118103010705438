/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { motion } from 'framer-motion';

export function BackDrop(
  { onMouseDown, children }:
  { onMouseDown: React.MouseEventHandler, children: JSX.Element },
): JSX.Element {
  return (
    <motion.div
      onMouseDown={onMouseDown}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}
