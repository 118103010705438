/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../_shared/modal/Modal';
import { RequestError } from '../../../Error.model';
import { myI18n } from '../../../i18n';
import { formatDate } from '../../../utils/Time';
import { ProviderName } from '../ProviderEnum';

interface Props {
  error?: RequestError | null;
  close: () => void;
  isOpen: boolean;
  provider: ProviderName
}

export function ProviderErrorModal({ error, close, isOpen, provider }: Props): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <Modal
      className='unknown-error'
      show={isOpen}
      confirm={close}
      textConfirm={t('translation:button.close')}
      classConfirm='btn btn-primary-game'
    >
      <h2 className='text-uppercase'>{t('translation:account.provider.modal.error.title')}</h2>
      {error && myI18n.exists(`error:${error.code}`) ? (
        <p>
          <span>
            {t(
              'translation:account.provider.modal.error.description1',
              { error: t(`error:${error.code}`, {
                provider: t(error.data?.provider as string || provider),
                date: formatDate(error.data?.date as string, i18n.language),
              }) },
            )}
          </span>
          <br />
          <br />
          <span>
            {t('translation:account.provider.modal.error.description2')}
          </span>
        </p>
      ) : (<p>{t('translation:account.provider.modal.error.descriptionGeneric')}</p>)}
    </Modal>
  );
}
