/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { GameIds } from '../../../account/my-games/MyGames.model';
import { getGameLogo, getGameThumbnail } from '../../../utils/Game';

interface Props {
  gameId: GameIds;
}

export function HomepageGameCard({ gameId }: Props): JSX.Element {
  const { t } = useTranslation();
  const isSmall = useMediaQuery('(max-width: 991px)');

  const openWebsite = (): Window => window.open(t(`home.games.${gameId}.website`), '_blank', 'noopener,noreferrer');

  return (
    <>
      {isSmall && (
        <div className='homepage-game-card-mobile'>
          <img
            src={getGameThumbnail(gameId)}
            alt={t(`games.${gameId}`)}
          />
          <p>
            {t(`home.games.${gameId}.description`)}
          </p>
          <button
            type='button'
            className='btn btn-primary-game'
            onClick={openWebsite}
          >
            {t('home.games.discover-game')}
          </button>
        </div>
      )}

      {!isSmall && (
        <div
          className='homepage-game-card'
          style={{ backgroundImage: `url('${getGameThumbnail(gameId)}')` }}
        >
          <div role='button' tabIndex={0} onClick={openWebsite} className='game-card-hover'>
            <div className='game-details'>
              <img
                src={getGameLogo(gameId, 'large')}
                alt={t(`games.${gameId}`)}
              />
              <p>
                {t(`home.games.${gameId}.description`)}
              </p>
            </div>
            <div
              className='btn btn-primary-game'
            >
              {t('home.games.discover-game')}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
