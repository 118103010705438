/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export function ProviderFeaturesTooltip(): JSX.Element {
  const { t } = useTranslation();
  return (
    <ReactTooltip
      id='provider-link-features-tooltip'
      className='link-features-tooltip'
      place='bottom'
      variant='light'
      opacity='1'
      render={({ activeAnchor }) => {
        const provider = activeAnchor?.getAttribute('data-tooltip-provider');
        const features = activeAnchor?.getAttribute('data-tooltip-provider-features')?.split(',');
        return provider && features ? (
          <>
            <div style={{ fontWeight: 600 }}>{t('account.provider.features-title')}</div>
            <ul>
              { features.map((feature: string) => (
                <li key={`${provider}-${feature}`}>{ t(`account.provider.features.${feature}`) }</li>
              )) }
            </ul>
          </>
        ) : <></>;
      }}
    />
  );
}
