/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ProviderName } from '../ProviderEnum';
import { ProviderLink } from './ProviderLink';

interface Props {
  providers: ProviderName[];
}

export function ProviderLinkList({ providers }: Props): JSX.Element {
  const isSmall = useMediaQuery('(max-width: 768px)');

  return (
    <div className='providers-container'>
      {isSmall && (
        <Swiper
          spaceBetween={16}
          slidesPerView='auto'
        >
          {providers.map((provider) => (
            <SwiperSlide key={`card-${provider}`}>
              <ProviderLink provider={provider} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {!isSmall && (
        <>
          {providers.map((provider) => (
            <ProviderLink provider={provider} key={`card-${provider}`} />
          ))}
        </>
      )}
    </div>
  );
}
