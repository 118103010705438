/*
 * (C) Behaviour Interactive Inc. - All Rights Reserved
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 * This file is proprietary and confidential
 */
import React from 'react';
import { motion } from 'framer-motion';

export function MenuMotionSpan({ id }: { id: string }): JSX.Element {
  return (
    <motion.span
      layoutId={id}
      className='menu-bar'
      transition={{
        type: 'spring',
        duration: 0.5,
      }}
    />
  );
}
