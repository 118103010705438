/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

export enum GameIds {
  DBD = 'dbd',
  MYM = 'mym',
  HOY = 'hoy',
  FM = 'fm',
  SOPHIA = 'islands-of-insight',
  COFS = 'cofs',
  PROJECTT = 'project-t',
  WTF = 'what-the-fog',
}

export enum Stores {
  STEAM = 'steam',
  XBOX = 'xbox',
  MICROSOFT = 'microsoft',
  PLAYSTATION = 'playstation',
  EPIC = 'epic',
}

export interface MarketingPlayerSubscriptions {
  project: string;
  subscribedMessages: string[];
}
