/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GameIds } from '../account/my-games/MyGames.model';
import { Promotion, PromotionData } from '../account/promotion/Promotion.model';
import { PromotionCard } from '../account/promotion/PromotionCard';
import { RequestError } from '../Error.model';
import { useRedirectUrl } from '../redirect-url/ProviderRedirectUrlContext';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { get } from '../utils/Request';
import { useConfig } from '../contexts/ConfigContext';

interface Props {
  gameId: GameIds;
}

export function ActivationNotConnected({ gameId }: Props): JSX.Element {
  const { t } = useTranslation();
  const { setRedirect } = useRedirectUrl();
  const history = useHistory();
  const config = useConfig();
  const snackbar = useSnackbar();
  const [promotions, setPromotions] = useState<Promotion[]>([]);

  const goToSignin = (): void => {
    setRedirect(`${location.pathname}${location.search}`);
    history.push('/');
  };

  const getPromotions = async (): Promise<void> => {
    try {
      const response = await get<PromotionData[]>(
        `${config.clientConfig.host}/promotions?requiredGameIds=${gameId}`,
        {
          disableRedirect: true,
        },
      );
      setPromotions(response.map((x) => ({ promotionData: x } as Promotion)));
    } catch (e) {
      const err = e as RequestError;
      snackbar.addErrorMessage(err.code);
    }
  };

  useEffect(() => {
    if (gameId) {
      getPromotions();
    }
  }, [gameId]);

  return (
    <>
      <div className={`activation-wrapper ${gameId}`}>
        <div className='activation-container'>
          <h2>
            {t('promotion.page.title', {
              requiredGame: t(`games.${gameId}`),
              rewardGame: t(`games.${promotions[0]?.promotionData.relatedToGameId}`),
            })}
          </h2>

          <div className='activation-content'>
            {promotions.map((promotion) => (
              <PromotionCard
                key={promotion.promotionData.promotionId}
                promotion={promotion}
                showButton={false}
              />
            )) }
          </div>

          <div className='activation-description'>
            <div className='claim-promotion-content'>
              <p className='claim-promotion-instructions'>
                {t('promotion.page.notConnected')}
              </p>

              <button
                type='button'
                className='btn btn-primary-game m-3'
                onClick={goToSignin}
                data-cy='activation-signin'
              >
                {t('button.sign-in')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
