/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useAuth } from '../../authentication/AuthenticationContext';
import { Modal } from '../../_shared/modal/Modal';
import { put } from '../../utils/Request';
import { useSnackbar } from '../../snackbar/SnackbarContext';
import { RequestError } from '../../Error.model';
import { EditButton } from '../../_shared/edit-button/EditButton';
import { useConfig } from '../../contexts/ConfigContext';

interface OldEmailBody {
  email: string;
}

interface ChangeEmailBody {
  email: string;
  code: string;
  expiryDate: string;
  hash: string;
  isEmailVerified: boolean;
}

export function UpdateEmail(): JSX.Element {
  const auth = useAuth();
  const config = useConfig();
  const { t } = useTranslation();
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [initModal, setInitModal] = useState(false);
  const [errorEmail, setErrorEmail] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [errorCode, setErrorCode] = useState<string>('');
  const [showModalCode, setShowModalCode] = useState(false);
  const [hash, setHash] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const snackbar = useSnackbar();
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [disabledConfirmCode, setDisabledConfirmCode] = useState(false);

  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .email('form.mail-invalid')
      .required('form.field-required'),
  });

  const codeSchema = Yup.object().shape({
    code: Yup.string()
      .required('form.field-required'),
  });

  const openModalEmail = (): void => {
    setShowModalEmail(true);
  };

  const closeModalEmail = (resetEmail = true): void => {
    if (resetEmail) {
      setNewEmail('');
      setExpiryDate(null);
      setHash(null);
    }
    setShowModalEmail(false);
    setErrorEmail('');
  };

  const openModalCode = (): void => {
    setShowModalCode(true);
  };

  const closeModalCode = (): void => {
    setShowModalCode(false);
    setNewEmail('');
    setCode('');
    setErrorEmail('');
    setErrorCode('');
    setExpiryDate(null);
    setHash(null);
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
    setNewEmail(event.target.value);
  };

  const handleChangeCode = (event: ChangeEvent<HTMLInputElement>): void => {
    setCode(event.target.value);
  };

  const sendEmail = async (
    body: OldEmailBody | ChangeEmailBody,
    isCode: boolean,
    reCaptchaToken?: string,
  ): Promise<void> => {
    let headers = {};

    if (reCaptchaToken) {
      headers = {
        'g-recaptcha-response': reCaptchaToken,
      };
    }

    try {
      await put(`${config.clientConfig?.host}/players/me/email`, { data: body, headers });

      if (isCode) {
        auth.getPlayer();
        snackbar.addSuccessMessage('account.settings.success');
        closeModalCode();
      }
    } catch (e) {
      const err = e as RequestError;
      setLoadingSendCode(false);
      setDisabledConfirmCode(false);

      if (err.code === 'MarketingPlatformError') {
        closeModalCode();
      }

      if (err.code === 'CodeOneTimePasswordRequiredError') {
        setExpiryDate(err.data.expiryDate);
        setHash(err.data.hash);
        closeModalEmail(false);
        openModalCode();
        return;
      }

      let translateData = {};
      const { data } = err;

      if (err.code === 'ChangeEmailRestrictionError') {
        translateData = { ...data, timeUnit: t(`time.${data.timeUnit}`) };
      }
      snackbar.addErrorMessage(err.code, translateData);
    }
  };

  const handleConfirmEmail = async (): Promise<void> => {
    try {
      await emailSchema.validate({
        email: newEmail,
      });

      const { available } = await auth.checkPlayerAvailability(newEmail);

      if (!available) {
        snackbar.addErrorMessage('PlayerEmailAlreadyExistsError', t('errors.emailAlreadyExists'));
      } else {
        setErrorEmail('');
        const body: OldEmailBody = {
          email: newEmail,
        };

        if (config.clientConfig?.recaptchaEnabled) {
          window.grecaptcha.execute(config.serverConfig.website.recaptcha.clientId, { action: 'login' })
            .then((token: string) => {
              sendEmail(body, false, token);
            });
        } else {
          sendEmail(body, false);
        }
      }
    } catch (e) {
      const err = e as RequestError;
      setErrorEmail(err.message);
      setInitModal(true);
    }
  };

  const handleSubmitCodeForm = async (): Promise<void> => {
    try {
      await codeSchema.validate({
        code,
      });
      setLoadingSendCode(true);
      setDisabledConfirmCode(true);
      setErrorCode('');
      const body: ChangeEmailBody = {
        email: newEmail,
        code,
        expiryDate,
        hash,
        isEmailVerified: null,
      };

      if (config.clientConfig?.recaptchaEnabled) {
        window.grecaptcha.execute(config.serverConfig.website.recaptcha.clientId, { action: 'login' })
          .then((token: string) => {
            sendEmail(body, true, token).then(() => {
              setDisabledConfirmCode(false);
              setLoadingSendCode(false);
            });
          });
      } else {
        await sendEmail(body, true);
        setDisabledConfirmCode(false);
        setLoadingSendCode(false);
      }
    } catch (e) {
      const err = e as RequestError;
      setErrorCode(err.message);
      setInitModal(true);
    }
  };

  const resendCode = (): void => {
    const body = {
      email: newEmail,
    };

    if (config.clientConfig?.recaptchaEnabled) {
      window.grecaptcha.execute(config.serverConfig.website.recaptcha.clientId, { action: 'login' })
        .then((token: string) => {
          sendEmail(body, false, token);
        });
    } else {
      sendEmail(body, false);
    }
  };

  return (
    <>
      <div className='form-container mb-4'>
        <div className='form-container-header'>
          <h4 className='form-container-title mb-0'>{t('account.settings.details.emailAddress')}</h4>
          <EditButton
            isActive={showModalEmail || showModalCode}
            onClick={openModalEmail}
            aria-label='Edit Email'
            data-cy='edit-email-button'
          />
        </div>
        <div className='form-group form-light'>
          <label htmlFor='currentEmail'>{t('form.label.email')}</label>
          <div className='input-group'>
            <input
              name='currentEmail'
              id='currentEmail'
              className='form-control'
              disabled
              type='text'
              value={auth.user.email}
              data-cy='current-email-text'
            />
          </div>
        </div>
      </div>
      <Modal
        show={showModalEmail}
        confirm={handleConfirmEmail}
        close={closeModalEmail}
        init={initModal}
        textConfirm={t('button.confirm')}
        textClose={t('common.cancel')}
      >
        <div className='form-light'>
          <h4>{t('account.settings.details.email.title')}</h4>
          <p>{t('account.settings.details.email.description')}</p>
          <div className='form-group'>
            <label htmlFor='email'>{t('form.label.email')}</label>
            <input
              name='email'
              id='email'
              onChange={handleChangeEmail}
              className={`form-control ${(errorEmail) ? 'is-invalid' : ''}`}
              type='text'
              data-cy='edit-email-input'
            />
            {errorEmail ? (
              <div className='form-error'>{t(errorEmail)}</div>
            ) : null}
          </div>
        </div>
      </Modal>
      <Modal
        show={showModalCode}
        confirm={handleSubmitCodeForm}
        init={initModal}
        close={closeModalCode}
        textConfirm={t('button.confirm')}
        textClose={t('common.cancel')}
        refresh={loadingSendCode}
        disabled={disabledConfirmCode}
      >
        <div className='form-light'>
          <h4>{t('account.settings.details.email.code')}</h4>
          <p>{t('account.settings.details.email.code_not_receive')}</p>
          <div className='form-group'>
            <label htmlFor='code'>{t('form.label.code')}</label>
            <input
              name='code'
              id='code'
              onChange={handleChangeCode}
              data-cy='verification-code-input'
              className={`form-control ${(errorCode) ? 'is-invalid' : ''}`}
              type='number'
            />
            {errorCode ? (
              <div className='form-error'>{t(errorCode)}</div>
            ) : null}
            <div
              onClick={resendCode}
              className='text-right font-weight-bold mt-1 pointer underline-dotted'
            >
              {t('auth.otp.resend-code')}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
