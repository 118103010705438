import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { LinkingGameProvider } from '../../../account/providers/ProviderEnum';
import { useAuth } from '../../../authentication/AuthenticationContext';
import { buildAuthUrl } from '../../../utils/Providers';
import { isRequestError } from '../../../Error.model';
import { patch } from '../../../utils/Request';
import { ProviderLink } from '../../../account/providers/provider/ProviderLink';
import { useSnackbar } from '../../../snackbar/SnackbarContext';
import { DecodedToken } from '../../MarketingConsent.model';
import { useConfig } from '../../../contexts/ConfigContext';

interface Props {
  provider: LinkingGameProvider;
  onLinkCallback?: () => void;
  customTitle?: string;
  customDetails?: string;
  decodedToken?: DecodedToken;
}

type LinkStatus = 'linked' | 'unlinked' | 'unsynced';

export function ProviderLinkStatus(
  { provider, onLinkCallback, customTitle, customDetails, decodedToken }: Props,
): JSX.Element {
  const auth = useAuth();
  const config = useConfig();
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [linkStatus, setLinkStatus] = useState<LinkStatus | null>(null);

  const providerAccount = auth.user?.accounts.find((account) => account.type === provider);
  const providerAuthUrl = buildAuthUrl(
    config?.clientConfig?.providers[provider]?.authUrl || null,
    config.serverConfig?.providers[provider] || null,
    provider,
  );

  const validateProviderToken = async (): Promise<void> => {
    try {
      await patch(`${config.clientConfig?.host}/players/me/accounts/providers/${provider}/games`);
      setLinkStatus('linked');
      if (onLinkCallback) {
        onLinkCallback();
      }
    } catch (e) {
      if (isRequestError(e)) {
        if (e.code === 'InvalidToken' && providerAuthUrl) {
          setLinkStatus('unsynced');
        } else {
          snackbar.addErrorMessage(e.code, e.data);
        }
      } else {
        snackbar.addErrorMessage('UnknownError');
      }
    }
  };

  useEffect(() => {
    setLinkStatus(null);
    const providerLinked = !!providerAccount;
    if (providerLinked) {
      validateProviderToken();
    } else {
      setLinkStatus('unlinked');
    }
  }, [provider, auth.user]);

  const providerName = t(`translation:${provider}`);
  const title = customTitle || t(`account.my-account.${linkStatus}-detailed`, { provider: providerName });

  const unlinked = linkStatus === 'unlinked';
  const details = customDetails || (unlinked ? t('account.my-account.link-info', { provider: providerName }) : null);

  return (
    <div className='reward-step-provider-link-status'>
      { linkStatus
        ? (
          <>
            <div>
              {title && <h6 className='reward-step-provider-link-status--title'>{title}</h6>}
              {details && <span className='reward-step-provider-link-status--details'>{details}</span>}
            </div>

            <ProviderLink
              provider={provider}
              showLink={linkStatus === 'unlinked'}
              showSync={linkStatus === 'unsynced'}
              showFeatures={false}
              showUnlink={false}
              className={`reward-step-provider-link-status--card
              ${linkStatus === 'linked' ? 'reward-step-provider-link-status--card-linked' : ''}
              `}
              decodedToken={decodedToken}
            />
          </>
        )
        : <div className='loader'><CircularProgress /></div> }
    </div>
  );
}
