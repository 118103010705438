/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState, useEffect } from 'react';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { get, patch } from '../../../utils/Request';
import { useSnackbar } from '../../../snackbar/SnackbarContext';
import { GameIds, MarketingPlayerSubscriptions } from '../../../account/my-games/MyGames.model';
import { useConfig } from '../../../contexts/ConfigContext';

export const definition: Definition = {
  sectionTitle: {
    type: DefinitionPropertyType.TranslatedString,
  },
  title: {
    type: DefinitionPropertyType.TranslatedString,
  },
  description: {
    type: DefinitionPropertyType.TranslatedString,
  },
  tos: {
    type: DefinitionPropertyType.TranslatedString,
  },
  subscribeButtonText: {
    type: DefinitionPropertyType.TranslatedString,
  },
  subscriptionConfirmation: {
    type: DefinitionPropertyType.TranslatedString,
  },
  subscribedMessages: {
    type: DefinitionPropertyType.Array,
    elements: {
      message: {
        type: DefinitionPropertyType.String,
      },
    },
  },
};

interface Props {
  sectionTitle?: string;
  title?: string;
  description?: string;
  subscribeButtonText?: string;
  subscriptionConfirmation?: string;
  tos?: string;
  subscribedMessages: Array<{ message: string }>;
}

export default function GameNewsletters(props: Props): JSX.Element {
  const config = useConfig();
  const snackbar = useSnackbar();
  const { gameId }: { gameId: GameIds } = useParams();

  const [tosAccepted, setTosAccepted] = useState<boolean>(false);
  const [isPlayerSubscribed, setIsPlayerSubscribed] = useState<boolean>(false);
  const [subscriptionsPerProjects, setSubscriptionsPerProjects] = useState<MarketingPlayerSubscriptions[]>([]);
  const [gameSubscriptions, setGameSubscriptions] = useState<MarketingPlayerSubscriptions>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const messages = props.subscribedMessages?.map(({ message }) => message);

  const getPlayerSubscriptions = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const subscriptions = await get<MarketingPlayerSubscriptions[]>(
        `${config.clientConfig?.host}/players/me/marketing/subscriptions`,
      );
      setSubscriptionsPerProjects(subscriptions);
      const currentGameSubscriptions = subscriptions.find((sub) => sub.project === gameId);
      setGameSubscriptions(currentGameSubscriptions);
      if (currentGameSubscriptions) {
        const messagesAvailable = messages
          .every((message) => currentGameSubscriptions.subscribedMessages.includes(message));
        setIsPlayerSubscribed(messagesAvailable);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      snackbar.addErrorMessage('UnknownError');
    }
  };

  const handleSubscription = async (): Promise<void> => {
    let currentGameSub: MarketingPlayerSubscriptions;

    try {
      if (gameSubscriptions) {
        const subs = [...gameSubscriptions.subscribedMessages, ...messages];
        currentGameSub = {
          project: gameId,
          subscribedMessages: subs.filter((c, index) => subs.indexOf(c) !== index),
        };
      } else {
        currentGameSub = {
          project: gameId,
          subscribedMessages: messages,
        };
      }
      currentGameSub = {
        project: gameId,
        subscribedMessages: messages,
      };
      await patch<MarketingPlayerSubscriptions[]>(`${config.clientConfig?.host}/players/me/marketing/subscriptions`, {
        data: {
          subscriptionsPerProjects: [
            ...subscriptionsPerProjects.filter((sub) => sub.project !== gameId),
            currentGameSub,
          ],
        },
      });
      setIsPlayerSubscribed(true);
    } catch (e) {
      snackbar.addErrorMessage('UnknownError');
    }
  };

  useEffect(() => {
    getPlayerSubscriptions();
  }, [gameId]);

  return (
    <div className='game-newsletter' data-cy={`${gameId}-newsletters`}>
      <h3 className='section-title'>{props?.sectionTitle}</h3>
      <div className='items-container text-center'>
        {isLoading ? <CircularProgress color='inherit' /> : (
          <>
            <h4>{props?.title}</h4>

            <div className='description'>
              {props?.description}
            </div>
            {!isPlayerSubscribed
              && (
                <div className='tos'>
                  <input
                    className='survey-input'
                    type='checkbox'
                    id='terms'
                    name='terms'
                    checked={tosAccepted}
                    onChange={(e) => setTosAccepted(e.target.checked)}
                    data-cy={`${gameId}-terms-checkbox`}
                  />
                  <label htmlFor='terms'>
                    {props?.tos}
                  </label>
                </div>
              )}
            <button
              type='button'
              data-cy={`${gameId}-newsletters-btn`}
              disabled={!tosAccepted || isPlayerSubscribed}
              onClick={handleSubscription}
              className='btn btn-primary-game btn-block mx-auto btn-newsletter'
            >
              {isPlayerSubscribed ? props?.subscriptionConfirmation : props?.subscribeButtonText}
            </button>
          </>
        )}
      </div>
    </div>
  );
}
