/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { Alert, Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Modal } from '../../../modal/Modal';
import thumbnailWTF from '../../../../_assets/thumbnail/wtf.jpg';
import { NewsletterClaimStep } from '../steps/newsletters/NewsletterClaimStep';
import { ClaimGameStep, KeysAvailableResponse, StepKey } from '../models';
import { ClaimFreeGameStep } from '../steps/claim-game/ClaimFreeGameStep';
import { LinkFlow } from '../../../account-link/LinkFlow';
import {
  RedeemingGameProvider,
  getLinkingProviderFromRedeemingProvider,
} from '../../../../account/providers/ProviderEnum';
import { ClaimStepper } from '../stepper/ClaimStepper';
import { get, post } from '../../../../utils/Request';
import { useSnackbar } from '../../../../snackbar/SnackbarContext';
import { GameIds } from '../../../../account/my-games/MyGames.model';
import { useAuth } from '../../../../authentication/AuthenticationContext';
import { useConfig } from '../../../../contexts/ConfigContext';

interface ClaimFlowModalProps {
  isOpen: boolean;
  preRegId: string;
  handleClose : () => void;
  platform: RedeemingGameProvider;
}

const steps: ClaimGameStep[] = [
  {
    key: 'newsLetter-step',
    isCompleted: false,
    order: 1,
  },
  {
    key: 'linkAccount-step',
    isCompleted: false,
    order: 2,
  },
  {
    key: 'claimKey-step',
    isCompleted: false,
    order: 3,
  },
];

export function ClaimFlowModal({ isOpen, preRegId, handleClose, platform }: ClaimFlowModalProps): JSX.Element {
  const [showClaimModal, setShowClaimModal] = useState<boolean>(isOpen);
  const [activeStep, setActiveStep] = useState(0);
  const [isIntro, setIsIntro] = useState<boolean>(true);
  const [currentStepCompleted, setCurrentStepCompleted] = useState<boolean>(true);
  const [isKeyClaimed, setIsKeyClaimed] = useState<boolean>(false);
  const [flowSteps, setFlowSteps] = useState<ClaimGameStep[]>(steps);
  const [noKeysAvailable, setNoKeysAvailable] = useState<boolean>(false);
  const { gameId, source }: { gameId: GameIds, source: string } = useParams();
  const { t } = useTranslation();
  const config = useConfig();
  const snackbar = useSnackbar();
  const auth = useAuth();

  const platformName = t(platform);

  const handleContinue = (): void => {
    setCurrentStepCompleted(false);
    if (isIntro) {
      setIsIntro(false);
    } else if (activeStep + 1 <= flowSteps.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const claimGame = async (): Promise<void> => {
    if (!config.clientConfig || isKeyClaimed) {
      return;
    }
    const baseRoute = `${config.clientConfig.host}/players/me/games/${gameId}/pre-registrations/${preRegId}`;
    const { approvals } = auth?.user || {};
    const { newsletter } = approvals;
    const ads = approvals['personalized-ads-offers'];
    let keysAvailable = true;
    try {
      const response = await get<KeysAvailableResponse>(
        `${config.clientConfig.host}/players/me/games/${gameId}/pre-registrations/${preRegId}/${platform}/available`,
      );
      keysAvailable = response?.keysAvailable;
    } catch (e) {
      setIsKeyClaimed(false);
      setNoKeysAvailable(true);
      snackbar.addErrorMessage('UnknownError');
    }

    if (!keysAvailable) {
      setNoKeysAvailable(true);
      return;
    }
    try {
      await post(`${baseRoute}/claim`, {
        data: {
          provider: platform,
          isConsentGiven: newsletter && ads,
        },
      });

      try {
        await post(`${baseRoute}/surveys`, {
          data: {
            registeredFromPaidFlow: {
              source: source || 'bhvraccount',
            },
          },
        });
        setIsKeyClaimed(true);
        auth.getPlayer();
      } catch (error) {
        snackbar.addErrorMessage('UnknownError');
      }
    } catch (e) {
      setIsKeyClaimed(false);
      snackbar.addErrorMessage('UnknownError');
    }
  };

  const handleCurrentStepCompleted = (key: string): void => {
    setCurrentStepCompleted(true);
    const updateCompletion = { isCompleted: true };
    setFlowSteps(flowSteps.map((item) => (item.key === key ? { ...item, ...updateCompletion } : item)));
    if (key === StepKey.LINK_ACCOUNT) {
      claimGame();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleCloseModal = (key?: string): void => {
    handleClose();
    setCurrentStepCompleted(false);

    if (key) { // NOT Intro
      const stepIndex = flowSteps.findIndex((step) => step.key === key);
      if (stepIndex !== -1 && flowSteps[stepIndex].isCompleted) {
        return;
      }
      const updateCompletion = { isCompleted: false };
      setFlowSteps(flowSteps.map((item) => (item.key === key ? { ...item, ...updateCompletion } : item)));
    }
  };
  const renderFlowSteps = (): ReactNode => {
    switch (activeStep) {
      case 0:
        return <NewsletterClaimStep onComplete={handleCurrentStepCompleted} onClose={handleCloseModal} />;
      case 1:
        return (
          <LinkFlow
            platform={getLinkingProviderFromRedeemingProvider(platform)}
            cancelFlow={handleCloseModal}
            completeFlow={() => { handleCurrentStepCompleted(StepKey.LINK_ACCOUNT); }}
            customCompleteFlowLabel={t('claimFreeGame.linkAccount.getSteamKey')}
            customUnlinkedMessage={t('claimFreeGame.linkAccount.accountNotLinkedDescription', { platformName })}
          />
        );
      case 2:
        return (
          <ClaimFreeGameStep
            preRegId={preRegId}
            isKeyClaimed={isKeyClaimed}
            onComplete={handleCurrentStepCompleted}
            onClose={handleCloseModal}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setShowClaimModal(isOpen);
  }, [isOpen]);

  return (
    <Modal
      show={showClaimModal}
      close={handleClose}
      disabledBackDrop
      maxWidth={580}
      className='wtf-modal-container'
      data-cy='wtf-claim-modal'
      mobileFullScreen
    >
      <>
        {isIntro ? (
          <Box flexGrow={1}>
            <Typography variant='h6' mb={2} sx={{ fontWeight: '500' }}>
              {t('claimFreeGame.card.title')}
            </Typography>
            <img src={thumbnailWTF} alt='what the fog' height={190} className='game-img' />
            <ClaimStepper />
          </Box>
        ) : (
          <>
            <Stepper nonLinear activeStep={activeStep}>
              {flowSteps.map((step) => (
                <Step
                  key={step.key}
                  completed={step.isCompleted}
                >
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
            <Typography sx={{ mt: 2, mb: 1, color: 'rgba(255, 255, 255, 0.70)' }} variant='body2'>
              {t('claimFreeGame.card.step', { index: activeStep + 1, total: flowSteps.length })}
            </Typography>

            {
              renderFlowSteps()
            }
            {noKeysAvailable
              ? (
                <Box my={4}>
                  <Alert severity='error' variant='outlined' sx={{ color: '#d53f3f' }}>
                    {t('claimFreeGame.errors.noKeyDuringTheFlow')}
                  </Alert>
                </Box>
              ) : null}
          </>
        )}
        {currentStepCompleted && activeStep !== flowSteps.length - 1
          ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: 5, justifyContent: 'space-between' }}>
              <button
                type='button'
                className='btn btn-link'
                onClick={handleClose}
                data-cy='wtf-claim-modal-close'
              >
                {t('button.close')}
              </button>
              <button
                type='button'
                disabled={!isIntro && !currentStepCompleted}
                className='btn btn-primary-game'
                data-cy='wtf-claim-modal-button'
                onClick={handleContinue}
              >
                {t('button.next')}
              </button>
            </Box>
          ) : null}
      </>
    </Modal>
  );
}
