/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Promotion, PromotionStatus } from '../../account/promotion/Promotion.model';
import { RewardImageList } from '../reward-image-list/RewardImageList';
import { RewardModal } from '../reward-modal/RewardModal';
import { buildTranslatedListOfStrings } from '../../utils/Translations';

interface Props {
  promotion: Promotion;
}
export function PromotionCard({ promotion }:Props): JSX.Element {
  const { t } = useTranslation(['promotions']);
  const [showModal, setShow] = useState(false);

  const { rewards, relatedToGameId } = promotion.promotionData;

  const onClaimClick = (): void => {
    setShow(true);
  };

  const closeHandler = (): void => {
    setShow(false);
  };

  const promotionClaimed = promotion.userData?.status === PromotionStatus.Claimed;

  const rewardsName = rewards.map((reward) => t(`${reward.displayName}`));
  const rewardsListTranslation = buildTranslatedListOfStrings(rewardsName);

  const details = promotionClaimed ? t(
    'translation:promotion.marketing-consent.redeemed',
    { rewardGame: t(`translation:games.${relatedToGameId}`) },
  ) : t(
    'translation:promotion.marketing-consent.description',
    { reward: rewardsListTranslation },
  );

  return (
    <div className='mkt-consent-promotion-card'>
      <RewardImageList rewards={rewards} />

      <span className='mkt-consent-promotion-card--details'>
        {details}
      </span>

      {!promotionClaimed && (
        <>
          <button
            type='button'
            onClick={onClaimClick}
            className='btn btn-primary-game mkt-consent-promotion-card--button'
            data-cy='mkt-consent-promotion-card-subscribe-and-claim-button'
          >
            <span>{t('translation:promotion.modal.claim')}</span>
          </button>

        </>
      )}
      <RewardModal promotion={promotion.promotionData} show={showModal} closeHandler={closeHandler} />
    </div>
  );
}
