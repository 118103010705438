/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { ProviderName } from '../account/providers/ProviderEnum';
import { ProviderConfig } from '../contexts/Config.model';

function nonce(length: number): string {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function storeState(provider: ProviderName, state: string): void {
  localStorage.setItem(`auth-${provider}-state`, state);
}

export function removeState(provider: ProviderName): void {
  localStorage.removeItem(`auth-${provider}-state`);
}

export function getState(provider: ProviderName): string | null {
  return localStorage.getItem(`auth-${provider}-state`);
}

export function buildAuthUrl(
  authUrl: string | null,
  serverConfig: ProviderConfig | null,
  provider: ProviderName | null,
): string {
  const state = nonce(15);
  const params = authUrl ? new URL(authUrl).searchParams : null;

  if (provider && !!params?.get('state')) {
    storeState(provider, state);
  }

  return authUrl
    ? authUrl
      .replaceAll('{{clientId}}', serverConfig?.clientId || '')
      .replaceAll('{{redirectUrl}}', serverConfig?.redirectUrl || '')
      .replaceAll('{{origin}}', location.origin)
      .replaceAll('{{nonce}}', state) : '';
}
