import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Definition, DefinitionPropertyType, PageComponent } from '@bhvr/components-manager';
import { useParams } from 'react-router-dom';
import { ComponentType, allComponents, components } from '../index';
import { GameIds } from '../../../account/my-games/MyGames.model';
import { usePageActions } from '../../../contexts/PageActionsContext';
import { useConfig } from '../../../contexts/ConfigContext';

export const possibleChildren = [
  components.PROMOTION,
  components.TRAILER,
  components.CROSS_PROG,
  components.STREAMING_CAMPAIGNS,
  components.FAQ,
  components.NEWSLETTER,
  components.CLAIM_FREE_GAME,
  components.PRE_REG,
  components.LINK_BUTTON,
  components.PROVIDER_LINK_CARD,
];

const selectComponents = {
  [components.PROMOTION]: components.PROMOTION,
  [components.CROSS_PROG]: components.CROSS_PROG,
  [components.TRAILER]: components.TRAILER,
  [components.STREAMING_CAMPAIGNS]: components.STREAMING_CAMPAIGNS,
  [components.FAQ]: components.FAQ,
  [components.NEWSLETTER]: components.NEWSLETTER,
  [components.CLAIM_FREE_GAME]: components.CLAIM_FREE_GAME,
  [components.PRE_REG]: components.PRE_REG,
  [components.LINK_BUTTON]: components.LINK_BUTTON,
  [components.PROVIDER_LINK_CARD]: components.PROVIDER_LINK_CARD,
};

export const definition: Definition = {
  tabs: {
    type: DefinitionPropertyType.Array,
    elements: {
      component: {
        type: DefinitionPropertyType.Select,
        options: selectComponents,
      },
      label: {
        type: DefinitionPropertyType.TranslatedString,
      },
      isDefault: {
        type: DefinitionPropertyType.Boolean,
      },
      order: {
        type: DefinitionPropertyType.Number,
      },
    },
  },
};

function a11yProps(index: string): Record<string, string> {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function TabsComponents(
  { admin, children, tabs }:
  {
    admin: boolean,
    children: React.ReactNode,
    tabs: { component: string, label: string, isDefault: boolean, order: number }[]
  },
): JSX.Element {
  const [currentTab, setCurrentTab] = useState<string | undefined>(undefined);

  const appConfig = useConfig();
  const { gameId } : { gameId: GameIds } = useParams();
  const pageActions = usePageActions();
  const { pagesContent } = appConfig;
  const currentPagesContent = pagesContent.pages[gameId][pageActions.pageName];
  const tabsComponent = currentPagesContent.components.find((component) => component.name === components.TABS);

  const ComponentAttr = useMemo((): PageComponent | undefined => tabsComponent?.children?.find(
    (child) => child.name === currentTab,
  ), [currentTab]);

  const handleChange = useCallback((_event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue);
  }, [tabs]);

  const createTabs = (): JSX.Element[] => tabs.sort((a, b) => a.order - b.order).map(({ component, label }) => (
    <Tab
      key={component}
      value={component}
      label={label}
      {...a11yProps(component)}
    />
  ));

  useEffect(() => {
    if (!tabs?.length) {
      return;
    }

    const defaultTab = tabs.find((component) => component.isDefault) || tabs[0];
    setCurrentTab(defaultTab.component);
  }, []);

  return (
    <>
      { admin ? children : <></> }
      {
        currentTab ? (
          <div className='tabs-nav'>
            <div className='tabs-container'>
              <Tabs
                value={currentTab}
                onChange={handleChange}
                aria-label='basic tabs example'
                textColor='secondary'
                variant='scrollable'
              >
                { createTabs() }
              </Tabs>
            </div>
            {
              tabs.map((tab) => {
                const Component = (allComponents[tab.component as ComponentType]).default;

                return (
                  <div
                    key={tab.component}
                    role='tabpanel'
                    hidden={tab.component !== currentTab}
                    id={`simple-tabpanel-${tab.component}`}
                    aria-labelledby={`simple-tab-${tab.component}`}
                  >
                    <div className='tab-wrapper'>
                      <div className='container'>
                        {
                          tab.component === currentTab ? <Component {...ComponentAttr?.props} /> : <></>
                        }
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        ) : <></>
      }
    </>
  );
}

export default TabsComponents;
