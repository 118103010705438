/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { InfoOutlined } from '@mui/icons-material';
import {
  RedeemingGameProvider,
  getLinkingProviderFromRedeemingProvider,
} from '../../../../account/providers/ProviderEnum';
import { getProviderLogo } from '../../../../account/providers/ProviderLogo';
import { MergeStatus, MergedAccount } from '../../../../authentication/Authentication.model';
import { useAuth } from '../../../../authentication/AuthenticationContext';
import { SyncInProgressAlert } from '../sync-in-progress-alert/SyncInProgressAlert';

interface Props {
  availablePlatforms: RedeemingGameProvider[];
  mergedAccounts: MergedAccount[];
  openModal: (platform: RedeemingGameProvider | null) => void;
  getMergedAccounts: () => Promise<void>;
}

export function SyncedAccountsList(
  { availablePlatforms, mergedAccounts, openModal, getMergedAccounts }: Props,
): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuth();

  const orderPlatforms = (platforms: RedeemingGameProvider[]): {
    id: RedeemingGameProvider;
    status: MergeStatus;
  }[] => platforms.map((platform) => ({
    id: platform,
    status: mergedAccounts.find((x) => x.provider === platform)?.status ?? 'unmerged',
  })).sort((a, b) => {
    if (a.status === 'merged' || a.status === 'inProgress') {
      return -1;
    }
    if (b.status === 'merged' || b.status === 'inProgress') {
      return 1;
    }
    return 0;
  });

  const orderedPlatforms = useMemo(() => orderPlatforms(availablePlatforms), [availablePlatforms, mergedAccounts]);
  const syncedAccountLength = useMemo(
    () => orderedPlatforms.filter((x) => x.status === 'merged' || x.status === 'inProgress').length,
    [orderedPlatforms],
  );
  const platformSyncInProgress = useMemo(
    () => orderedPlatforms.find((x) => x.status === 'inProgress'),
    [orderedPlatforms],
  );

  useEffect(() => {
    let req: NodeJS.Timeout;
    if (mergedAccounts.find((account) => account.status === 'inProgress')) {
      req = setInterval(() => {
        getMergedAccounts();
      }, 5000);
    }
    return () => {
      if (req) {
        clearInterval(req);
      }
    };
  }, [mergedAccounts]);

  return (
    <>
      {syncedAccountLength === 0 && (
        <div>
          <button
            type='button'
            className='btn btn-primary-game'
            onClick={() => openModal(null)}
            data-cy='cross-progression-enable-btn'
          >
            {t('cross-progression.enable-button')}
          </button>
        </div>
      )}

      {syncedAccountLength > 0 && (
        <div className='synced-platform-list'>
          {orderedPlatforms.map((platform) => (
            <div key={platform.id} className='platform-item' data-cy={`${platform.id}-platform-item`}>
              <div className='platform-details'>
                <img
                  src={getProviderLogo(platform.id)}
                  alt={platform.id}
                  className='platform-logo'
                />
                <div className='platform-info'>
                  <div>{t(platform.id)}</div>
                  <div className='account-username'>
                    {auth.user.accounts.find(
                      (account) => account.type === getLinkingProviderFromRedeemingProvider(platform.id),
                    )?.userName ?? ''}
                  </div>
                </div>
              </div>
              <AnimatePresence>
                {platform.status === 'inProgress' && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0, overflow: 'hidden' }}
                  >
                    <SyncInProgressAlert />
                  </motion.div>
                )}
              </AnimatePresence>
              {platform.status === 'unmerged' && (
                <>
                  <button
                    type='button'
                    className='btn btn-primary-game'
                    onClick={() => openModal(platform.id)}
                    disabled={!!platformSyncInProgress}
                    data-cy={`${platform.id}-cross-progression-sync-btn`}
                  >
                    {t('cross-progression.synchronization.button')}
                  </button>
                  {!!platformSyncInProgress && (
                    <div className='sync-disabled-info'>
                      <InfoOutlined />
                      <span>
                        {t(
                          'cross-progression.another-sync-in-progress-warning',
                          { platform: t(platformSyncInProgress.id) },
                        )}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
