/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { ContentCopy } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DiscordLogo } from '../../../_assets/discord.svg';
import { useSnackbar } from '../../../snackbar/SnackbarContext';

interface Props {
  steamKey: string | null;
  accessCode: string | null;
  preRegId: string;
  gameId: string;
}

interface KeyProps {
  label: string;
  keyData: string;
  keyValue: string;
}

function KeyContainer({ label, keyData, keyValue }: KeyProps): JSX.Element {
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [revealed, setRevealed] = useState<boolean>(false);

  const copyText = (text: string): void => {
    navigator.clipboard.writeText(text);
    snackbar.addSuccessMessage('account.my-account.steamInstructions.copied');
  };

  return (
    <div className='key-container'>
      <p>{label}</p>
      <div className={`key-content ${revealed ? 'revealed' : ''}`}>
        <div onClick={() => setRevealed(true)} className='key'>
          <p className='reveal-text'>{t('account.my-account.steamInstructions.reveal')}</p>
          <p className='reveal-text mobile'>{t('account.my-account.steamInstructions.revealMobile')}</p>
          <div className='key-blur' data-cy={keyData}>
            {keyValue}
          </div>
        </div>
        <span data-cy={`copy-${keyData}`} className='key-copy-btn' onClick={() => copyText(keyValue)}>
          <ContentCopy />
        </span>
      </div>
    </div>
  );
}

export function ClaimSteamComponent({ steamKey, accessCode, preRegId, gameId }: Props): JSX.Element {
  const { t, i18n } = useTranslation(['mym', 'translation']);
  const [translationPrefix] = useState('translation:account.my-account.steamInstructions');
  const [gameTranslationPrefix] = useState(`${gameId}:preRegistration.${preRegId}.steamInstructions`);

  return (
    <div className='claim-steam' data-cy='steam-modal'>
      <div className='header'>
        <h3>{t(`${translationPrefix}.header`)}</h3>
        <p>{t(`${translationPrefix}.congratulations`)}</p>
      </div>
      <div className='instructions-container'>
        {steamKey
          && (
            <div className='instructions' data-cy='steamKey-instructions'>
              <h5>{t(`${translationPrefix}.steamKey.title`)}</h5>
              <ol>
                {(t(`${translationPrefix}.steamKey.instructions`, { returnObjects: true }) as string[])
                  .map((instruction) => (
                    <li key={instruction}>{instruction}</li>
                  ))}
              </ol>

              <KeyContainer label={t(`${translationPrefix}.steamKey.key`)} keyData='steam-key' keyValue={steamKey} />
            </div>
          )}

        {accessCode
          && (
            <div className='instructions' data-cy='accessCode-instructions'>
              <h5>{t(`${translationPrefix}.accessCode.title`)}</h5>
              <ol>
                {(t(
                  `${translationPrefix}.accessCode.instructions`,
                  { returnObjects: true, title: t(`${gameTranslationPrefix}.title`) },
                ) as string[])
                  .map((instruction) => (
                    <li key={instruction}>{instruction}</li>
                  ))}
              </ol>

              <KeyContainer
                label={t(`${translationPrefix}.accessCode.key`)}
                keyData='access-code'
                keyValue={accessCode}
              />
            </div>
          )}
      </div>
      {/* {i18n.exists(`${gameTranslationPrefix}.video`) && (
        <div className='video-instructions'>
          <h5>{t(`${gameTranslationPrefix}.video.text`)}</h5>
          <div className='video-wrapper'>
            <iframe
              width='100%'
              height='100%'
              src={t(`${gameTranslationPrefix}.video.link`)}
              title='Video Instructions'
              frameBorder={0}
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </div>
        </div>
      )} */}
      {i18n.exists(`${gameTranslationPrefix}.discord`) && (
        <a
          className='btn discord-btn'
          href={t(`${gameTranslationPrefix}.discord.link`)}
          target='_blank'
          rel='noreferrer'
        >
          <DiscordLogo />
          {t(`${gameTranslationPrefix}.discord.text`)}
        </a>
      )}
    </div>
  );
}
