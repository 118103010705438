/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultFieldProps, FieldBase } from './FieldBase';
import { get } from '../../utils/Request';
import { useConfig } from '../../contexts/ConfigContext';

interface Options {
  name: string;
  value: string;
}

interface LocIp {
  country: string;
}

export function CountryInput({ name = 'country', setFieldValue, values, ...props }: DefaultFieldProps): JSX.Element {
  const { t } = useTranslation();
  const [field, meta] = useField({ name });
  const config = useConfig();

  const [countriesOptions, setCountriesOptions] = useState<Options[]>([]);

  const createCountriesOptions = (countries: string[]): void => {
    const co: Options[] = [];
    countries.forEach((value) => {
      co.push({ name: t(`countries.code.${value}`), value });
    });

    co.sort((a, b) => Intl.Collator().compare(a.name, b.name));
    setCountriesOptions(co);
  };

  const getLoc = async (): Promise<LocIp> => {
    try {
      const response = await get<LocIp>(`${config.clientConfig?.host}/players/location`);
      return response;
    } catch (error) {
      console.error((error as Error).message);
      throw error;
    }
  };

  // Get countries
  useEffect(() => {
    get<string[]>(`${config.clientConfig?.host}/countries`).then((countries) => {
      createCountriesOptions(countries);
    });
  }, [t]);

  useEffect(() => {
    if (!values && setFieldValue) {
      getLoc().then((loc) => {
        if (!values) {
          setFieldValue(name, loc?.country?.toUpperCase() || '');
        }
      });
    }
  }, []);

  return (
    <FieldBase name={name} meta={meta}>
      <select
        id={name}
        data-cy={`${name}-form`}
        className={`form-control
        ${(meta.error && meta.touched) ? 'is-invalid' : ''}`}
        disabled={props.disabled}
        {...field}
      >
        <option value='' aria-label='Empty' />
        {countriesOptions.map((item) => (
          <option value={item.value} key={`${item.value}`}>
            {item.name}
          </option>
        ))}
      </select>
    </FieldBase>
  );
}
