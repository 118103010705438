/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import moment, { unitOfTime } from 'moment';

export function convertSeconds(seconds: number): string {
  const now = moment();
  const expiredAt = moment().add(seconds, 'seconds');

  return `${expiredAt.diff(now, 'days')}`;
}

export function formatDate(date: string | Date, locale: string, options?: Intl.DateTimeFormatOptions): string {
  return new Date(date).toLocaleDateString(locale, options);
}

/**
 * Returns the time if date is today and the date if it's another day
 * @param date Date to format
 * @param format Format of the string date
 * @param locale locale to use to format the date
 * @returns local formatted date
 */
export function formatDateTime(dateStr: string, format: string, locale: string): string {
  const date = moment(dateStr, format).toDate();
  const localeDate = date.toLocaleDateString(locale);

  if (localeDate === new Date(Date.now()).toLocaleDateString(locale)) {
    return date.toLocaleTimeString(locale);
  }

  return localeDate;
}

/**
 * Returns the smallest time unit (minute, hour or day) for the duration
 * @param minutes Time in minutes
 * @returns Converted time and time unit corresponding
 */
export function getTimeUnitFromDurationInMinutes(minutes: number, decimalToKeep = 3)
  : { time: number, timeUnit: string } {
  const roundingValue = (10 ** decimalToKeep);
  let time;
  let timeUnit;
  if (minutes === 0 || minutes === 1) {
    time = minutes;
    timeUnit = 'minute';
  } else if (minutes < 60) {
    time = minutes;
    timeUnit = 'minutes';
  } else if (minutes < 60 * 2) {
    time = 1;
    timeUnit = 'hour';
  } else if (minutes < 60 * 24) {
    time = minutes / 60;
    timeUnit = 'hours';
  } else if (minutes < 60 * 48) {
    time = 1;
    timeUnit = 'day';
  } else {
    time = minutes / (60 * 24);
    timeUnit = 'days';
  }
  return { time: Math.round(time * roundingValue) / roundingValue, timeUnit };
}

/**
 * Calculate remaining from a date (most of the time, will be today's date) to another
 * @returns Remaining time (default is days)
 */
export function timeRemaining(startDate: string, endDate: string, unit: unitOfTime.Diff = 'days'): number {
  return moment(endDate).diff(moment(startDate), unit);
}
