/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { useField } from 'formik';
import React, { ComponentPropsWithoutRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultFieldProps, FieldBase } from './FieldBase';

interface GenderRadioProps extends ComponentPropsWithoutRef<'input'> {
  value: string,
  label: string,
}

export function GenderInput({ name = 'gender', ...props }: DefaultFieldProps): JSX.Element {
  const { t } = useTranslation();
  const genders = [
    {
      value: 'male',
      label: 'man',
    },
    {
      value: 'female',
      label: 'woman',
    },
    {
      value: 'other',
      label: 'neutral',
    },
  ];

  const GenderRadio = memo(({ value, label }: GenderRadioProps): JSX.Element => {
    const [field] = useField({ name, type: 'radio', value });
    return (
      <div className='form-check'>
        <input
          type='radio'
          name={name}
          className='form-check-input'
          id={value}
          data-cy={`${name}-${value}-checkbox`}
          {...field}
          disabled={props.disabled}
        />
        <label className='form-check form-check-label' htmlFor={value}>
          {t(`form.label.${label}`)}
        </label>
      </div>
    );
  });

  return (
    <FieldBase name={name}>
      {genders.map(({ value, label }) => <GenderRadio key={value} value={value} label={label} />)}
    </FieldBase>
  );
}
