/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../authentication/AuthenticationContext';
import { useRedirectUrl } from '../redirect-url/ProviderRedirectUrlContext';
import { useGameTheme } from '../contexts/GameThemeContext';

interface Props {
  children: JSX.Element | JSX.Element[];
  authRoute: boolean
}

export function GuardRoute({ children, authRoute, ...rest }: Props & RouteProps): JSX.Element {
  const [authorized, setAuthorized] = useState<boolean>(false);

  const auth = useAuth();
  const theme = useGameTheme();
  const location = useLocation();
  const history = useHistory();
  const { setRedirect } = useRedirectUrl();

  useEffect(() => {
    if (auth.isUserLoad) {
      setAuthorized(false);
      if ((auth?.user && authRoute) || (!auth?.user && !authRoute)) {
        setAuthorized(true);
      } else {
        if (!auth?.user) {
          setRedirect(`${location.pathname}${location.search}`);
        }

        if (authRoute) {
          history.replace(`/auth/signin/${theme.gameId}`);
        } else {
          history.replace('/account');
        }
      }
    }
  }, [location.pathname, auth.isUserLoad, auth?.user]);

  return (
    <>
      {auth.isUserLoad && (!authRoute || auth?.user) && (
        <Route {...rest}>
          {authorized && children}
        </Route>
      )}
    </>
  );
}
