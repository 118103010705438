/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkingGameProvider } from '../../account/providers/ProviderEnum';
import { useAuth } from '../../authentication/AuthenticationContext';
import { LinkFlowUnlinked } from './LinkFlowUnlinked';
import { LinkStep } from './LinkFlow.model';
import { LinkFlowRefreshConnection } from './LinkFlowRefreshConnection';
import { LinkFlowWarning } from './LinkFlowWarning';
import { LinkFlowSuccess } from './LinkFlowSuccess';
import { LinkFlowFailed } from './LinkFlowFailed';
import { ProviderLinkWrapper } from '../../account/providers/provider/button/ProviderLinkWrapper';
import { RequestError } from '../../Error.model';
import { patch } from '../../utils/Request';
import { Loading } from '../loading/Loading';
import { buildAuthUrl } from '../../utils/Providers';
import { ModalFlowContainer } from '../modal/modal-flow-container/ModalFlowContainer';
import { useConfig } from '../../contexts/ConfigContext';

interface Props {
  platform: LinkingGameProvider;
  completeFlow: () => void;
  cancelFlow: () => void;
  customUnlinkedMessage?: ReactNode;
  customWarningMessage?: ReactNode;
  customCompleteFlowLabel?: string | null;
}

export function LinkFlow({
  platform, completeFlow, cancelFlow,
  customUnlinkedMessage = null,
  customWarningMessage = null,
  customCompleteFlowLabel = null,
}: Props): JSX.Element {
  const auth = useAuth();
  const config = useConfig();
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState<LinkStep | null>(null);
  const [error, setError] = useState<RequestError | null>(null);

  const userAccount = auth.user?.accounts.find((acc) => acc.type === platform) || null;

  const platformAuthUrl = buildAuthUrl(
    config?.clientConfig?.providers[platform]?.authUrl || null,
    config.serverConfig?.providers[platform] || null,
    platform,
  );

  const updateCurrentStep = (newStep: LinkStep): void => {
    setCurrentStep(newStep);
  };

  const checkRefreshStatus = async (): Promise<void> => {
    try {
      await patch(`${config.clientConfig?.host}/players/me/accounts/providers/${platform}/games`);
      auth.getPlayer();
      setCurrentStep('success');
    } catch (e) {
      const err = e as RequestError;
      if (err?.code === 'InvalidToken') {
        setCurrentStep('refresh-connection');
      } else {
        setCurrentStep('failed');
      }
    }
  };

  useEffect(() => {
    if (userAccount) {
      checkRefreshStatus();
    } else {
      setCurrentStep('unlinked');
    }
  }, []);
  return (
    <ModalFlowContainer title={currentStep !== 'warning' ? t('account.provider.link-flow.title') : ''}>
      <ProviderLinkWrapper
        provider={platform}
        isAuthProvider={false}
        errorHandler={setError}
        providerAccount={userAccount}
      >
        {currentStep === 'unlinked' && (
          <LinkFlowUnlinked
            platform={platform}
            nextStep={updateCurrentStep}
            cancel={cancelFlow}
            customDescription={customUnlinkedMessage}
          />
        )}
        {currentStep === 'refresh-connection' && userAccount && (
          <LinkFlowRefreshConnection
            platform={platform}
            nextStep={updateCurrentStep}
            cancel={cancelFlow}
            platformAuthUrl={platformAuthUrl}
            userAccount={userAccount}
            error={error}
          />
        )}
        {currentStep === 'warning' && (
          <LinkFlowWarning
            platform={platform}
            nextStep={updateCurrentStep}
            cancel={cancelFlow}
            platformAuthUrl={platformAuthUrl}
            userAccount={userAccount}
            error={error}
            customWarningMessage={customWarningMessage}
          />
        )}
        {currentStep === 'success' && (
          <LinkFlowSuccess
            platform={platform}
            completeFlow={completeFlow}
            cancel={cancelFlow}
            customCompleteFlowLabel={customCompleteFlowLabel}
          />
        )}
        {currentStep === 'failed' && (
          <LinkFlowFailed
            platform={platform}
            nextStep={updateCurrentStep}
            cancel={cancelFlow}
            platformAuthUrl={platformAuthUrl}
            userAccount={userAccount}
            error={error}
          />
        )}
        {currentStep === null && (
          <Loading fullscreen={false} />
        )}
      </ProviderLinkWrapper>
    </ModalFlowContainer>
  );
}
