export type Labels = {
  toggleEdit?: string
  export?: string
  save?: string
  componentEnable?: string,
  apply?: string
  cancel?: string
  delete?: string
};

export const defaultLabels: Labels = {
  toggleEdit: 'Toggle Edit',
  save: 'Save',
  export: 'Export',
  apply: 'Apply',
  cancel: 'Cancel',
  delete: 'Delete',
};
