/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect } from 'react';
import { GameIds } from '../../../../my-games/MyGames.model';
import { ProviderName,
  getLinkingProviderFromRedeemingProvider,
  isLinkingGameProvider } from '../../../../providers/ProviderEnum';
import { PromotionStep } from '../promotionSteps.model';
import { GameProviderRow } from './gameProviderRow/GameProviderRow';
import { useAuth } from '../../../../../authentication/AuthenticationContext';
import { ProviderByGame, ProvidersByGame } from './GameProviders.model';

interface Props {
  currentStep: PromotionStep;
  providersByGame: ProvidersByGame;
  selectedProviderByGame: ProviderByGame;
  setSelectedProviderByGame: (providers: ProviderByGame) => void;
  setNextStepEnabled: (enabled: boolean) => void;
}

export function GameProviders(
  { currentStep,
    providersByGame,
    selectedProviderByGame,
    setSelectedProviderByGame,
    setNextStepEnabled }: Props,
): JSX.Element {
  const auth = useAuth();

  const requiredGames = Object.keys(providersByGame);

  const checkPlatformsStep = (): void => {
    const hasProviderForAllGames = Object.keys(selectedProviderByGame).length === requiredGames.length;
    setNextStepEnabled(hasProviderForAllGames);
  };

  const checkSyncStep = (): void => {
    const providerByGame = Object.entries(selectedProviderByGame);
    const ownsAllGames = providerByGame.every(
      ([game, provider]) => !!auth.user.gameLibrary[game]?.providerData
        .find((x) => x.providerName === (isLinkingGameProvider(provider) ? provider
          : getLinkingProviderFromRedeemingProvider(provider))),
    );
    setNextStepEnabled(ownsAllGames);
  };

  useEffect(() => {
    if (selectedProviderByGame && currentStep === PromotionStep.Platforms) {
      checkPlatformsStep();
    }
    if (selectedProviderByGame && currentStep === PromotionStep.Sync) {
      checkSyncStep();
    }
  }, [selectedProviderByGame, currentStep, auth.user.gameLibrary]);

  const setSelectedProvider = (game: GameIds, provider: ProviderName): void => {
    setSelectedProviderByGame({ ...selectedProviderByGame, [game]: provider });
  };

  return (
    <div className='promotion-game-providers'>
      {Object.entries(providersByGame).map(([game, providers]) => (
        <GameProviderRow
          key={game}
          gameId={game as GameIds}
          providers={providers}
          currentStep={currentStep}
          selectedProvider={selectedProviderByGame?.[game as GameIds] || null}
          setSelectedProvider={setSelectedProvider}
        />
      ))}
    </div>
  );
}
