/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { ShareCode } from '../../referral/ReferralCode.model';
import { GenerateReferralLinkComponent } from './GenerateReferralLinkComponent';
import { ShareReferralLinkComponent } from './ShareReferralLinkComponent';

interface Props {
  eventId: string;
  generateCode: () => Promise<ShareCode>;
  getCode: () => Promise<ShareCode>;
}

export function ReferralComponent({ eventId, generateCode, getCode }: Props): JSX.Element {
  const [referralCode, setReferralCode] = useState<ShareCode | null>(null);

  useEffect(() => {
    getCode().then((response) => {
      setReferralCode(response);
    });
  }, []);

  const generateAndUpdateCode = async (): Promise<void> => {
    const response = await generateCode();
    setReferralCode(response);
  };

  return (
    <>
      { referralCode ? (
        <ShareReferralLinkComponent referralCode={referralCode} eventName={eventId} data-cy='share-referral-link' />
      )
        : (
          <GenerateReferralLinkComponent
            eventId={eventId}
            generateLink={generateAndUpdateCode}
            data-cy='generate-referral-link'
          />
        )}
    </>
  );
}
