/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { useField } from 'formik';
import React from 'react';
import { DefaultFieldProps, FieldBase } from './FieldBase';

export function EmailInput({ name = 'email', containerClassName, ...props }: DefaultFieldProps): JSX.Element {
  const [field, meta] = useField({ name });

  return (
    <FieldBase containerClassName={containerClassName} name='email' meta={meta}>
      <input
        name={name}
        id={name}
        data-cy={`${name}-input`}
        disabled={props.disabled}
        className={`form-control
            ${(meta.error && meta.touched) ? 'is-invalid' : ''}`}
        type='email'
        readOnly={props.readOnly}
        {...field}
      />
    </FieldBase>
  );
}
