/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowForward, CheckCircle, Error as ErrorIcon } from '@mui/icons-material';
import { Alert, Chip } from '@mui/material';
import { PromotionStep } from '../../promotionSteps.model';
import {
  GameProvider, ProviderName, getLinkingProviderFromRedeemingProvider,
  isLinkingGameProvider,
} from '../../../../../providers/ProviderEnum';
import { GameIds } from '../../../../../my-games/MyGames.model';
import { getGameLogo } from '../../../../../../utils/Game';
import { LinkStatus, ProviderDropdown } from '../../../../../../_shared/components/provider-dropdown/ProviderDropdown';
import { ProviderSyncButton, SyncResponse } from '../../../../../providers/provider/button/ProviderSyncButton';
import { ProviderLinkButton } from '../../../../../providers/provider/button/ProviderLinkButton';
import { useAuth } from '../../../../../../authentication/AuthenticationContext';
import { patch } from '../../../../../../utils/Request';
import { RequestError } from '../../../../../../Error.model';
import { buildAuthUrl } from '../../../../../../utils/Providers';
import { useConfig } from '../../../../../../contexts/ConfigContext';
import { myI18n } from '../../../../../../i18n';

interface Props {
  gameId: GameIds;
  providers: GameProvider[];
  currentStep: PromotionStep;
  selectedProvider: GameProvider | null;
  setSelectedProvider: (gameId: GameIds, provider: GameProvider) => void;
}
export function GameProviderRow(
  { gameId,
    providers,
    currentStep,
    selectedProvider,
    setSelectedProvider: setSelectedProviderForGame }: Props,
): JSX.Element {
  const auth = useAuth();
  const config = useConfig();
  const { t } = useTranslation();

  const [linkStatus, setLinkStatus] = useState<LinkStatus>();
  const [isMissingGame, setIsMissingGame] = useState<boolean>();
  const [operationError, setOperationError] = useState<string | null>(null);

  const linkingProvider = isLinkingGameProvider(selectedProvider) ? selectedProvider
    : getLinkingProviderFromRedeemingProvider(selectedProvider);
  const providerAccount = auth.user.accounts.find((account) => account.type === linkingProvider);

  const authUrl = linkingProvider
    ? config.clientConfig?.providers[linkingProvider]?.authUrl || null
    : null;
  const serverConfig = linkingProvider && config.serverConfig
    ? config.serverConfig.providers[linkingProvider] || null
    : null;

  const providerAuthUrl = buildAuthUrl(authUrl, serverConfig, linkingProvider);

  useEffect(() => {
    const getLinkStatus = async (): Promise<void> => {
      const linked = auth.user.accounts.find((account) => account.type === linkingProvider);
      setLinkStatus(linked ? LinkStatus.LINKED : LinkStatus.UNLINKED);

      const missingGame = linked
      && !!linkingProvider
      && !auth.user.gameLibrary[gameId]?.providerData.find((x) => (x.providerName === linkingProvider));
      setIsMissingGame(missingGame);

      try {
        if (linked) {
          await patch(`${config.clientConfig?.host}/players/me/accounts/providers/${linkingProvider}/games`);
        }
      } catch (err) {
        const reqErr = err as RequestError;
        if (reqErr?.code === 'InvalidToken') {
          setLinkStatus(LinkStatus.UNSYNCED);
        }
      }
    };

    if (currentStep === PromotionStep.Sync) {
      getLinkStatus();
    }
  }, [currentStep, linkingProvider, auth.user.accounts.length, auth.user.gameLibrary[gameId]]);

  const handleSyncResponse = (response: SyncResponse): void => {
    if (response.type === 'error') {
      setOperationError(response.code);
    }
  };

  const setSelectedProvider = (provider: ProviderName): void => {
    setSelectedProviderForGame(gameId, provider as GameProvider);
  };

  return (
    <div className='game-provider-row'>

      {currentStep === PromotionStep.Platforms && (
        <div className='game-provider-row-content'>
          <img className='game-provider-row-img' src={getGameLogo(gameId)} alt={gameId} width={52} height={52} />
          <span className='game-provider-row-title'>{t(`translation:games.${gameId}`)}</span>
          <ArrowForward fontSize='small' className='game-provider-row-icon' />
          <ProviderDropdown
            providers={providers}
            selectedProvider={selectedProvider}
            setSelectedProvider={setSelectedProvider}
          />
        </div>
      )}

      {currentStep === PromotionStep.Sync && selectedProvider && (
        <>
          <div className='game-provider-row-sync-container'>

            <div className='game-provider-row-sync-content'>
              <img className='game-provider-row-img' src={getGameLogo(gameId)} alt={gameId} width={52} height={52} />
              <ArrowForward fontSize='small' className='game-provider-row-sync-icon' />
              <ProviderDropdown
                providers={providers}
                selectedProvider={selectedProvider}
                setSelectedProvider={setSelectedProvider}
                linkStatus={linkStatus}
                displayLinkStatus
              />
            </div>

            <div className='game-provider-row-sync-action'>
              {linkStatus === LinkStatus.LINKED && (
                <Chip
                  className='game-provider-row-chip'
                  icon={!isMissingGame ? (
                    <CheckCircle className='game-provider-row-chip-icon game-provider-row-chip-icon-success' />
                  ) : <ErrorIcon className='game-provider-row-chip-icon game-provider-row-chip-icon-error' />}
                  label={!isMissingGame
                    ? t('translation:promotion.game-ownership.owned')
                    : t('translation:promotion.game-ownership.missing')}
                  data-cy='claim-promotion-ownership-status'
                />
              )}
              {linkStatus === LinkStatus.UNLINKED && (
                <ProviderLinkButton
                  provider={linkingProvider}
                  providerAuthUrl={providerAuthUrl}
                  providerAccount={providerAccount}
                  setError={(e) => setOperationError(e?.code || null)}
                  className='game-provider-row-sync-action-btn'
                  isAuthProvider={false}
                />
              )}
              {linkStatus === LinkStatus.UNSYNCED && (
                <ProviderSyncButton
                  provider={linkingProvider}
                  providerAccount={providerAccount}
                  setError={(e) => setOperationError(e?.code || null)}
                  buttonStyle='btn-outline-light'
                  providerAuthUrl={providerAuthUrl}
                  onSyncResponse={(response) => handleSyncResponse(response)}
                  className='game-provider-row-sync-action-btn'
                  isAuthProvider={false}
                />
              )}
            </div>
          </div>

          {isMissingGame && linkStatus === LinkStatus.LINKED && (
            <Alert severity='error' icon={<ErrorIcon />} className='game-provider-row-sync-error'>
              {t(
                'translation:promotion.game-ownership.error',
                { game: t(`translation:games.${gameId}`), provider: t(`translation:${selectedProvider}`) },
              )}
            </Alert>
          )}
          {linkStatus === LinkStatus.UNSYNCED && (
            <Alert severity='warning' icon={<ErrorIcon />} className='game-provider-row-sync-error'>
              {t('translation:account.provider.sync-warning')}
            </Alert>
          )}
          {operationError && (
            <Alert severity='error' icon={<ErrorIcon />} className='game-provider-row-sync-error'>
              {myI18n.exists(`error:${operationError}`) ? t(`error:${operationError}`, {
                provider: t(linkingProvider),
              })
                : t('translation:errors.500')}
            </Alert>
          )}
        </>
      )}
    </div>
  );
}
