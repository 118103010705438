/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Chip, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { InfoOutlined, Add } from '@mui/icons-material';
import { GameIds } from '../../../account/my-games/MyGames.model';
import { useAuth } from '../../../authentication/AuthenticationContext';
import { getProviderLogo } from '../../../account/providers/ProviderLogo';

const BlueTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#4863fc',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#4863fc',
    color: 'rgba(255, 255, 255, 0.87)',
    fontSize: 11,
  },
}));

export default function OwnedOn(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const { gameId }: { gameId: GameIds } = useParams();
  const auth = useAuth();
  const ownedGame = auth.user.gameLibrary[gameId];
  return (
    <div className='providers-owned-on'>
      <div className='subtitle'>
        <h5>{t('account.game-library.owned-on')}</h5>
      </div>
      <div className='providers-chip-container'>

        {
          auth.user.accounts.length > 0 ? (
            <>
              {
                !ownedGame ? (
                  <div className='provider-box'>
                    <InfoOutlined />
                    {t('account.game-library.game-not-owned')}
                  </div>
                ) : (
                  <>
                    {
                      ownedGame?.providerData && ownedGame.providerData.map(
                        (provider) => (
                          <BlueTooltip
                            key={provider.providerName}
                            title={provider.edition ? `${t('promotion.edition')}: ${provider.edition}` : ''}
                            arrow
                            disableFocusListener
                          >
                            <Chip
                              label={t(provider.providerName)}
                              className='provider-box'
                              avatar={(
                                <Avatar
                                  alt={provider.providerName}
                                  src={getProviderLogo(provider.providerName)}
                                />
                              )}
                            />
                          </BlueTooltip>
                        ),
                      )
                    }
                  </>
                )
              }
            </>
          ) : (
            <Button
              onClick={() => history.push('/account/my-account/providers')}
              className='provider-box'
            >
              <Add />
              {t('account.game-library.link-helper')}
            </Button>
          )
        }
      </div>
    </div>
  );
}
