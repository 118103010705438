/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { ProviderName } from './ProviderEnum';

import logoSteam from '../../_assets/logos/steam.svg';
import logoEgs from '../../_assets/logos/egs.svg';
import logoPsn from '../../_assets/logos/psn.svg';
import logoXbox from '../../_assets/logos/xbl.svg';
import logoGoogle from '../../_assets/logos/google.svg';
import logoDiscordColor from '../../_assets/logos/discord-color.svg';
import logoDiscordMonochrome from '../../_assets/logos/discord-monochrome.svg';
import logoFacebookColor from '../../_assets/logos/facebook-color.svg';
import logoFacebookMonochrome from '../../_assets/logos/facebook-monochrome.svg';
import logoTwitchColor from '../../_assets/logos/twitch-color.svg';
import logoTwitchMonochrome from '../../_assets/logos/twitch-monochrome.svg';
import logoMicrosoft from '../../_assets/logos/microsoft.svg';
import logoMicrosoftStore from '../../_assets/logos/microsoftstore.svg';
import logoNintendo from '../../_assets/logos/nintendo.svg';

export type LogoColorType = 'color' | 'monochrome';

export function getProviderLogo(provider: ProviderName, color: LogoColorType = 'color'): string {
  switch (provider) {
    case 'steam':
      return logoSteam;
    case 'egs':
      return logoEgs;
    case 'psn':
      return logoPsn;
    case 'xbl':
      return logoMicrosoft;
    case 'xbox':
      return logoXbox;
    case 'google':
      return logoGoogle;
    case 'facebook':
      return color === 'color' ? logoFacebookColor : logoFacebookMonochrome;
    case 'twitch':
      return color === 'color' ? logoTwitchColor : logoTwitchMonochrome;
    case 'discord':
      return color === 'color' ? logoDiscordColor : logoDiscordMonochrome;
    case 'grdk':
      return logoMicrosoftStore;
    case 'nintendo':
      return logoNintendo;
    default:
      return '';
  }
}
