/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useParams } from 'react-router-dom';
import { ProvideReferralCode } from './ReferralCodeContext';
import { ReferralCodeManager } from './ReferralCodeManager';

export function ReferralCodeManagerPage(): JSX.Element {
  const { referralCode }: { referralCode: string } = useParams();
  return (
    <ProvideReferralCode referralCode={referralCode}>
      <ReferralCodeManager />
    </ProvideReferralCode>
  );
}
