/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { RedeemingGameProvider } from '../../../../account/providers/ProviderEnum';
import syncedPlatformCircle from '../../../../_assets/content/synced-platforms-active.svg';
import syncedPlatformCircleEmpty from '../../../../_assets/content/synced-platforms-empty.svg';
import logoBhvr from '../../../../_assets/bhvr.svg';
import { getProviderLogo } from '../../../../account/providers/ProviderLogo';

interface Props {
  platforms: Array<{
    id: RedeemingGameProvider,
    synced: boolean,
  }>;
  selectedPlatform?: RedeemingGameProvider | null,
  circleSize?: number;
  itemSize?: number;
}

export function SyncedAccountsCircle(
  { platforms, selectedPlatform = null, circleSize = 218, itemSize = 48 }: Props,
): JSX.Element {
  const { t } = useTranslation();

  const getAngle = (index: number): number => (360 / platforms.length) * (index);
  const noSyncedAccount = platforms.every((x) => !x.synced);

  return (
    <>
      {noSyncedAccount && (
        <div
          className='circle-container'
          style={{ width: circleSize, height: circleSize, backgroundImage: `url(${syncedPlatformCircleEmpty})` }}
        >
          <div className='status--container'>
            <div className='status empty'>
              <p>{t('cross-progression.no-progress-synchronized')}</p>
            </div>
          </div>
        </div>
      )}

      {!noSyncedAccount && (
        <div
          className='circle-container'
          style={{ width: circleSize, height: circleSize, backgroundImage: `url(${syncedPlatformCircle})` }}
        >
          {platforms.map((platform, index) => (
            <div key={`platform-circle-${platform.id}`}>
              {platform.synced && (
                <div
                  className='radius'
                  style={{ transform: `rotate(${getAngle(index)}deg)`, height: `${circleSize / 2}px` }}
                />
              )}
              <div
                className={`circle-item ${platform.id === selectedPlatform ? 'selected' : ''}`}
                style={{
                  height: itemSize,
                  width: itemSize,
                  transform: `rotate(${getAngle(index)}deg) 
                  translateY(-${circleSize / 2}px)
                  rotate(-${getAngle(index)}deg)
                `,
                  margin: `-${itemSize / 2}px`,
                  padding: `${itemSize * 0.2}px`,
                }}
              >
                <img
                  src={getProviderLogo(platform.id)}
                  alt={platform.id}
                  className={!platform.synced && platform.id !== selectedPlatform ? 'not-synced' : ''}
                />
              </div>
            </div>
          ))}
          <div className='status--container'>
            <div className='status synced'>
              <div className='status-logo'>
                <img src={logoBhvr} alt='Behaviour Interactive' />
              </div>
              <p>{t('cross-progression.progress-synchronized')}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
