/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Close, Launch, Menu } from '@mui/icons-material';

import { useLocation, matchPath } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { LocalizedLink } from '../_shared/LocalizedLink';
import logoBhvr from '../_assets/bhvr.svg';
import { LanguageSelector } from './language-selector/LanguageSelector';
import { getNavBarLogo } from '../utils/Game';
import { GameIds } from '../account/my-games/MyGames.model';
import { useMenu } from '../contexts/MenuContext';
import { useAuth } from '../authentication/AuthenticationContext';

export function Header(): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAuth();
  const { toggleMenu, open } = useMenu();

  const twitchCampaignPageMatch = matchPath<{ gameId: GameIds }>(location.pathname, {
    path: '/twitch/:gameId/',
  });
  const gameId = twitchCampaignPageMatch?.params.gameId;
  const gameNavBarLogo = twitchCampaignPageMatch && gameId ? getNavBarLogo(gameId) : null;

  return (
    <header>
      <div className='d-flex align-items-center w-100'>
        {gameNavBarLogo ? (
          <img
            src={gameNavBarLogo}
            className='twitch-drop-logo'
            alt={t(`games.${gameId}`)}
          />
        )
          : (
            <>
              <LocalizedLink className='navbar-brand ml-3' to='/'>
                <img src={logoBhvr} width='53' alt={`${t('footer.logo')} Behaviour Interactive`} />
              </LocalizedLink>
              <div className='mr-auto ml-3 logo-account'>
                <div>{t('header.bhvr-account.title1')}</div>
                <div>{t('header.bhvr-account.title2')}</div>
              </div>
            </>
          ) }
      </div>
      <div className='support-container'>
        <a
          id='header-support'
          data-cy='support-page-link'
          href={t('header.nav.support.link')}
          target='_blank'
          className='support-link'
          rel='noopener noreferrer'
        >
          <Launch className='icon' />
          <span className='support-text'>{t('header.nav.support.title')}</span>
        </a>
      </div>
      <LanguageSelector />
      {
        user ? (
          <div className='menu-buttons'>
            {
              open ? (
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='close drawer'
                  onClick={toggleMenu}
                  className='button-close'
                  data-cy='close-menu-drawer-button'
                >
                  <Close />
                </IconButton>
              ) : (
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='open drawer'
                  onClick={toggleMenu}
                  className='button-open'
                  data-cy='open-menu-drawer-button'
                >
                  <Menu />
                </IconButton>
              )
            }
          </div>
        ) : <></>
      }
    </header>
  );
}
