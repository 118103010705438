/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { useField } from 'formik';
import React from 'react';
import { DefaultFieldProps, FieldBase, nameToDataCy } from './FieldBase';

export function TextInput({ name, containerClassName, ...props }: DefaultFieldProps): JSX.Element {
  const [field, meta] = useField({ name });

  return (
    <FieldBase name={name} meta={meta} containerClassName={containerClassName}>
      <input
        name={name}
        id={name}
        data-cy={`${nameToDataCy(name)}-input`}
        className={`form-control ${(meta.error && meta.touched) ? 'is-invalid' : ''}`}
        type='text'
        disabled={props.disabled}
        aria-describedby={props['aria-describedby']}
        {...field}
      />
      {props.children}
    </FieldBase>
  );
}
