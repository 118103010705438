/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useParams } from 'react-router-dom';

import { GameIds } from '../account/my-games/MyGames.model';
import { ProvideStreamingCampaign } from '../contexts/StreamingCampaignContext';
import { ProviderName } from '../account/providers/ProviderEnum';
import { StreamingCampaignPage } from './StreamingCampaignPage';

export function StreamingCampaignPageWrapper(): JSX.Element {
  const { provider, gameId, pageSlug }: { provider: ProviderName, gameId: GameIds, pageSlug: string } = useParams();

  return (
    <ProvideStreamingCampaign gameId={gameId} streamingPlatform={provider} slug={pageSlug}>
      <StreamingCampaignPage />
    </ProvideStreamingCampaign>
  );
}
