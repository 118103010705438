/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FacebookRounded, Share, WhatsApp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { useSnackbar } from '../../snackbar/SnackbarContext';
import { useAuth } from '../../authentication/AuthenticationContext';
import { ShareCode } from '../../referral/ReferralCode.model';
import { formatDateTime } from '../../utils/Time';
import { useConfig } from '../../contexts/ConfigContext';

interface Props {
  eventName: string;
  referralCode?: ShareCode;
  shareLink?: string;
}

export function ShareReferralLinkComponent({ eventName, referralCode, shareLink }: Props): JSX.Element {
  const { t, i18n } = useTranslation();
  const snackbar = useSnackbar();
  const auth = useAuth();
  const config = useConfig();
  const [referralLink, setReferralLink] = useState<string>('');
  const isMaxUsesReached = !!(referralCode && referralCode.maxUse - referralCode.timesUsed === 0);
  const expirationDate = referralCode?.expirationDate
    ? formatDateTime(referralCode.expirationDate, moment.defaultFormat, i18n.language)
    : null;

  useEffect(() => {
    if (shareLink) {
      setReferralLink(`${window.location.origin}${shareLink}`);
    } else if (referralCode) {
      setReferralLink(`${window.location.origin}${config.serverConfig?.shareCode.relativeUrlPath}${referralCode.code}`);
    }
  }, [referralCode, shareLink]);

  const copyText = (text: string): void => {
    navigator.clipboard.writeText(text);
    snackbar.addSuccessMessage('account.my-account.referrals.copied');
  };

  const mobileShare = async (): Promise<void> => {
    await navigator.share({
      url: referralLink,
      title: t(`translation:account.my-account.referrals.${eventName}.shareTitle`),
      text: t(
        `translation:account.my-account.referrals.${eventName}.shareDescription`,
        { player: auth.user.nickName },
      ),
    });
  };

  const shareOnWhatsApp = (): void => {
    const message = `${t(
      `translation:account.my-account.referrals.${eventName}.shareDescription`,
      { player: auth.user.nickName },
    )}: ${referralLink}`;
    window.open(`https://wa.me/?text=${encodeURI(message)}`, '_blank');
  };

  const shareOnFacebook = (): void => {
    const appId = config.serverConfig?.providers.facebook.appId;
    window.open(
      `http://www.facebook.com/dialog/send?app_id=${appId}&link=${referralLink}&redirect_uri=${window.location.href}`,
      '_blank',
    );
  };

  return (
    <div className='referral-container'>
      <h3>{t('account.my-account.referrals.referTitle')}</h3>
      <div>{t(`account.my-account.referrals.${eventName}.shareModalDescription`)}</div>
      <div className='referral-link form-light'>
        <input
          readOnly
          disabled={isMaxUsesReached}
          className='referral-link-input form-control input-lg'
          type='url'
          value={referralLink}
          id='referral-link-input'
          data-cy='referral-link-input'
        />
        <button
          type='button'
          disabled={isMaxUsesReached}
          className='btn btn-primary-game'
          onClick={() => copyText(referralLink)}
          data-cy='copy-link-btn'
        >
          <ContentCopyIcon />
          {t('account.my-account.referrals.copyLink')}
        </button>

      </div>

      <div className='referral-expiration-date'>
        {isMaxUsesReached && <span>{t('account.my-account.referrals.maxUses')}</span> }
        {!isMaxUsesReached && expirationDate && (
          <span>
            {t('account.my-account.referrals.expirationDate', { expirationDate })}
          </span>
        )}
      </div>

      {isMobile && navigator.share && (
        <div className='mobile-share' data-cy='mobile-share'>
          <button
            type='button'
            disabled={isMaxUsesReached}
            className='btn btn-primary-game'
            onClick={mobileShare}
            data-cy='mobile-share-btn'
          >
            <Share />
            {t('account.my-account.referrals.share')}
          </button>
        </div>
      )}
      {(!isMobile || !navigator.share) && (
        <div className='social-media-share' data-cy='social-media-share'>
          <div className='social-media-title'>{t('account.my-account.referrals.shareSocialMedia')}</div>
          <div className='social-media-buttons'>
            <button
              type='button'
              disabled={isMaxUsesReached}
              className='btn btn-primary-game'
              onClick={shareOnFacebook}
              data-cy='facebook-share-btn'
            >
              <FacebookRounded fontSize='large' />
            </button>
            <button
              type='button'
              disabled={isMaxUsesReached}
              className='btn btn-primary-game'
              onClick={shareOnWhatsApp}
              data-cy='whatsapp-share-btn'
            >
              <WhatsApp />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
