/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useParams, Switch, useRouteMatch, Route, Redirect, useHistory } from 'react-router-dom';
import { RegistrationStatus } from '../account/Account.model';
import { PageLayout } from '../account/layout/PageLayout';
import { GameIds } from '../account/my-games/MyGames.model';
import { useAuth } from '../authentication/AuthenticationContext';
import { PreregistrationClaim } from './PreregistrationClaim';
import { PreregistrationReferral } from './PreregistrationReferral';
import { PreregistrationSurvey } from './PreregistrationSurvey';
import { useConfig } from '../contexts/ConfigContext';

export function Preregistration(): JSX.Element {
  const [preRegId, setPreRegId] = useState<string>('');
  const { gameId }: { gameId: GameIds } = useParams();
  const match = useRouteMatch();
  const auth = useAuth();
  const history = useHistory();
  const config = useConfig();

  const getPreRegId = (id: GameIds): string => (
    config.serverConfig.gamesConfig.find((game) => game.id === id).preRegistration.id
  );

  useEffect(() => {
    const id = getPreRegId(gameId);
    const userPreReg = auth.user.preRegistrations[gameId];
    // TODO: skip if coming from referral landing page
    if ((id && userPreReg && userPreReg[id]?.status === RegistrationStatus.GRANTED) || !id) {
      history.push({
        pathname: `/account/my-account/games/${gameId}`,
        state: { showClaimModal: true },
      });
    } else {
      setPreRegId(id);
    }
  }, [auth.user]);

  if (preRegId) {
    return (
      <PageLayout hideMenu disableTransitions>
        <Switch>
          <Route path={`${match.path}/survey`} exact>
            <PreregistrationSurvey gameId={gameId} preRegId={preRegId} />
          </Route>
          <Route path={`${match.path}/claim`} exact>
            <PreregistrationClaim gameId={gameId} preRegId={preRegId} />
          </Route>
          <Route path={`${match.path}/referral/:referralCode`} exact>
            <PreregistrationReferral gameId={gameId} preRegId={preRegId} />
          </Route>
          <Redirect to={`/account/my-account/games/${gameId}`} />
        </Switch>
      </PageLayout>
    );
  }

  return <></>;
}
