import { AddBox } from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Fragment, useRef, useState } from 'react';
import { AdminBar } from './lib/admin/AdminBar';
import { ActionButton } from './lib/buttons/ActionButton';
import { ComponentProps,
  ComponentReference,
  PageComponent,
  PagesContent,
  AllComponentsType,
  FormValidationError, Diffs } from './lib/Config.model';
import { DefinitionPropertyType, Definition } from './lib/DefinitionProperty.model';
import { ModalAddComponent } from './lib/modals/ModalAddComponent';
import { ModalEditContent } from './lib/modals/ModalEditContent';
import { EditControls } from './lib/admin/EditControls';
import { ChildComponents } from './lib/child/ChildComponents';
import { ComponentInputProps } from './lib/admin/ComponentInput';
import { orderComponents } from './lib/utils/ComponentsManager';
import { set } from './lib/utils/set';
import { Labels, defaultLabels } from './lib/admin/Labels';

export type ComponentsManagerProps<T = never> = {
  allComponents: AllComponentsType,
  configId: string,
  page: string
  propsTransformer: (componentName: string,
    definitions: Definition,
    props: ComponentProps,
    id: string,
    parentComponentName?: string) => ComponentProps
  isSaving: boolean,
  isAdminMode?: boolean,
  pagesContent?: PagesContent
  context?: T,
  formValidationErrors?: FormValidationError
  saveEnabled?: boolean
  /**
   * Updated labels for admin actions
   */
  adminLabels?: Partial<Labels>
  getCustomInput?: (props: ComponentInputProps) => JSX.Element,
  onEdit?: (newProps: ComponentProps) => void
  onUpdate?: (newContent: PagesContent, find?: boolean) => void
  onSave?: (newContent: PagesContent, diff: Diffs) => Promise<void>
  exportFile?: (content: PagesContent) => void
  /** @deprecated Use {@link ComponentsManagerProps.onEdit} instead. */
  onValuesChanged?: (newProps: ComponentProps) => void
  /** @deprecated Use {@link ComponentsManagerProps.onSave} instead. */
  saveContent?: (newContent: PagesContent) => Promise<void>
  /** @deprecated Use {@link ComponentsManagerProps.onUpdate} instead. */
  update?: (newContent: PagesContent, find?: boolean) => void
  /** @deprecated Use {@link ComponentsManagerProps.propsTransformer} instead */
  convertTranslationProps?: (componentName: string,
    definitions: Definition,
    props: ComponentProps,
    id: string,
    parentComponentName?: string) => ComponentProps
};

export function ComponentsManager<T>(props: ComponentsManagerProps<T>): JSX.Element {
  const { onUpdate,
    update,
    onSave,
    saveContent,
    onEdit,
    onValuesChanged,
    exportFile,
    propsTransformer,
    getCustomInput,
    isAdminMode = false,
    isSaving,
    saveEnabled = true,
    pagesContent,
    page,
    context,
    formValidationErrors, adminLabels } = props;
  const [labels] = useState<Labels>({ ...defaultLabels, ...adminLabels });
  const [isEditting, setEditOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [newComponent, setNewComponent] = useState<string>();
  const [diff, setDiff] = useState<Diffs>({});
  const edittingComponent = useRef<ComponentReference>({ id: '' });
  if (!pagesContent[page]) {
    return <></>;
  }

  const getComponentList = (
    componentRef: ComponentReference,
  ): PageComponent[] => {
    const newConfig = { ...pagesContent };
    if (componentRef?.parent) {
      const parentRef = getComponentList(componentRef.parent);
      const currentRef = parentRef.find((p) => p?.id === componentRef.parent.id);

      if (currentRef && !currentRef.children) {
        currentRef.children = [];
      }

      return currentRef?.children;
    }

    if (!newConfig[page]) {
      newConfig[page] = {
        order: [],
        components: [],
      };
    }

    const pageObject = newConfig[page];

    return pageObject?.components as PageComponent[];
  };

  const getCurrentComponent = (): PageComponent => {
    if (!edittingComponent || !edittingComponent.current) {
      return null;
    }
    const componentList = getComponentList(edittingComponent.current);
    return componentList?.find((c) => c?.id === edittingComponent.current?.id);
  };

  const toggleAddModal = (componentRef?: ComponentReference): void => {
    edittingComponent.current = componentRef;
    setAddModalOpen(!isAddModalOpen);
  };

  const toggleEditModal = (componentRef?: ComponentReference): void => {
    if (componentRef) {
      edittingComponent.current = componentRef;
    }
    setEditModalOpen(!isEditModalOpen);
  };

  const confirmEdit = (
    newProps: ComponentProps,
    newStateEnabled: boolean,
    changes: Diffs,
    deleted: Diffs,
  ): void => {
    const newConfig = { ...pagesContent };
    const currentComponent = getCurrentComponent();
    const componentList = getComponentList(edittingComponent.current);
    const index = componentList?.findIndex((c) => c?.id === edittingComponent.current?.id);

    // Set the diff from the config POV
    if (edittingComponent.current.parent) {
      const componentListParent = getComponentList(edittingComponent.current.parent);
      const parentIndex = componentListParent?.findIndex((cp) => cp?.id === edittingComponent.current.parent.id);
      if (Object.keys(changes).length) {
        setDiff((prev) => set(prev, [
          'changes',
          page,
          'components',
          parentIndex,
          'children',
          index], changes, { keepSource: true }));
      }
      if (Object.keys(deleted).length) {
        setDiff((prev) => set(prev, [
          'deleted',
          page,
          'components',
          parentIndex,
          'children',
          index], deleted, { keepSource: true }));
      }
    } else {
      if (Object.keys(changes).length) {
        setDiff((prev) => set(prev, [
          'changes',
          page,
          'components',
          index], changes, { keepSource: true }));
      }
      if (Object.keys(deleted).length) {
        setDiff((prev) => set(prev, [
          'deleted',
          page,
          'components',
          index], deleted, { keepSource: true }));
      }
    }

    currentComponent.props = newProps;
    currentComponent.enabled = newStateEnabled;
    onUpdate?.(newConfig);
    toggleEditModal();
    // deprecated callback
    update?.(newConfig);
  };

  const addComponentAfter = (componentRef = edittingComponent.current): void => {
    const newConfig = { ...pagesContent };
    let newOrder = newConfig[page].order;

    if (!pagesContent[page].order) {
      newOrder = [];
    }

    const componentList = getComponentList(componentRef) || [];

    const componentProps: ComponentProps = {};
    const definition = props.allComponents[newComponent]?.definition;
    if (definition) {
      Object.entries(definition).forEach((([key, value]) => {
        if (value.type === DefinitionPropertyType.Array) {
          componentProps[key as keyof ComponentProps] = [];
        } else if (value.type === DefinitionPropertyType.Boolean) {
          componentProps[key as keyof ComponentProps] = false;
        }
      }));
    }
    const component: PageComponent = {
      name: newComponent,
      id: Math.random().toString(36).substring(2, 7),
      enabled: true,
      props: componentProps,
    };
    if (!componentRef?.parent) {
      // If is it a root component, add an empty order array
      component.order = [];
      if (!componentRef?.id) {
        newOrder.unshift(component.id);
      } else {
        const currentIndex = newOrder.findIndex((id) => id === componentRef.id);
        const length = componentList.length || 0;

        if ((currentIndex + 1) >= length - 1) {
          newOrder.push(component?.id);
        } else {
          newOrder.splice(currentIndex + 1, 0, component.id);
        }
      }
    } else if (!componentRef?.id) {
      componentRef?.parent?.order.unshift(component.id);
    } else {
      const currentIndex = componentRef?.parent?.order?.findIndex((id) => id === componentRef.id);
      const length = componentRef?.parent?.order.length || 0;
      if ((currentIndex + 1) >= length - 1) {
        componentRef?.parent?.order.push(component.id);
      } else {
        componentRef?.parent?.order.splice(currentIndex + 1, 0, component.id);
      }
    }

    componentList.push(component);

    if (componentRef?.parent) {
      const componentListParent = getComponentList(edittingComponent.current.parent);
      const parentIndex = componentListParent?.findIndex((cp) => cp?.id === componentRef?.parent?.id);

      // Add order to the diff if is it a child
      setDiff((prev) => set(prev, [
        'changes',
        page,
        'components',
        parentIndex,
        'order',
      ], componentRef?.parent?.order));

      setDiff((prev) => set(prev, [
        'changes',
        page,
        'components',
        parentIndex,
        'children',
        componentList.length - 1,
      ], component));
    } else {
      setDiff((prev) => set(prev, [
        'changes',
        page,
        'components',
        componentList.length - 1,
      ], component));
      setDiff((prev) => set(prev, [
        'changes',
        page,
        'order',
      ], newOrder));
    }

    toggleAddModal();
    onUpdate?.(newConfig);
    // deprecated callback
    update?.(newConfig);
  };

  const removeComponent = (): void => {
    if (!confirm('Are you sure?')) {// eslint-disable-line
      return;
    }

    const newConfig = { ...pagesContent };
    const order = edittingComponent.current.parent?.order ?? newConfig[page].order;
    const currentIndex = order
      .findIndex((id: string) => id === edittingComponent.current?.id);

    if (currentIndex < 0) {
      return;
    }

    let orderDiff: (string | number)[];
    let componentDiff: (string | number)[];
    if (edittingComponent.current.parent) {
      // This is a child component
      const parentIndex = newConfig[page].components.findIndex(
        (c) => c?.id === edittingComponent.current.parent.id,
      );
      const childComponentIndex = newConfig[page].components[parentIndex].children.findIndex(
        (c) => c?.id === edittingComponent.current.id,
      );
      componentDiff = ['deleted', page, 'components', parentIndex, 'children', childComponentIndex];
      orderDiff = ['deleted', page, 'components', parentIndex, 'order', currentIndex];
    } else {
      //  This is a root component
      const rootComponentIndex = newConfig[page].components.findIndex(
        (c) => c?.id === edittingComponent.current.id,
      );
      componentDiff = ['deleted', page, 'components', rootComponentIndex];
      orderDiff = ['deleted', page, 'order', currentIndex];
    }

    setDiff((prev) => set(prev, componentDiff, undefined));
    setDiff((prev) => set(prev, orderDiff, undefined));

    order.splice(currentIndex, 1);
    onUpdate?.(newConfig);
    toggleEditModal();
    // deprecated callback
    update?.(newConfig);
  };

  const toggleEdit = (): void => {
    setEditOpen(!isEditting);
  };

  const onNewComponentSelect = (e: SelectChangeEvent): void => {
    setNewComponent(e.target.value);
  };

  return (
    <div>
      { process.env.ADMIN_MODE && isAdminMode && (
        <>
          <AdminBar
            isEditting={isEditting}
            toggleEdit={toggleEdit}
            isSaving={isSaving}
            saveEnabled={saveEnabled}
            onSave={async () => {
              await (onSave?.(pagesContent, diff) || saveContent?.(pagesContent));
              // If the save is successfull, we clear the diffs
              setDiff({});
            }}
            exportFile={() => exportFile(pagesContent)}
            labels={labels}
          />

          <ModalEditContent
            isOpen={isEditModalOpen}
            confirm={confirmEdit}
            toggleEditModal={toggleEditModal}
            selectedCpn={getCurrentComponent()}
            selectedCpnRef={edittingComponent.current}
            selectedCpnProps={getCurrentComponent()?.props || {}}
            selectedCpnDef={props.allComponents[edittingComponent.current?.name]?.definition}
            removeComponent={removeComponent}
            pagesContent={pagesContent}
            page={page}
            getCustomInput={getCustomInput}
            validationErrors={formValidationErrors}
            onEdit={onEdit || onValuesChanged}
            labels={labels}
          />

          <ModalAddComponent
            allComponents={props.allComponents}
            isOpen={isAddModalOpen}
            confirm={addComponentAfter}
            handleClose={toggleAddModal}
            onChange={onNewComponentSelect}
            edittingComponent={edittingComponent}
          />

          { isEditting && (
            <div style={{ display: 'flex', alignItems: 'center', padding: '2rem' }}>
              <ActionButton disabled={isSaving} extraClass='add' action={() => toggleAddModal()}>
                <AddBox color='primary' />
                Add
              </ActionButton>
            </div>
          )}
        </>
      )}
      {(orderComponents(
        pagesContent[page].order,
        pagesContent[page].components,
      ))?.map((component: PageComponent) => {
        const ParentComponent = props.allComponents[component.name]?.default;
        const componentRef: ComponentReference = {
          id: component.id,
          name: component.name,
          reverseMode: component.reverseMode,
          order: component.order,
        };
        const definition = props.allComponents[component.name]?.definition;

        if (!ParentComponent) {
          return null;
        }

        // This code need to be removed in prod with an env var
        if (process.env.ADMIN_MODE && isAdminMode && isEditting) {
          return (
            <Fragment key={`editable-${component.name}-${component.id}`}>
              <div
                className='is-editable'
                style={{ display: 'flex', flexDirection: 'column', opacity: component.enabled ? 1 : 0.5 }}
                data-enabled={component.enabled}
              >
                <EditControls
                  component={component}
                  componentRef={componentRef}
                  toggleEditModal={toggleEditModal}
                  isSaving={isSaving}
                  onUpdate={onUpdate || update}
                  pagesContent={pagesContent}
                  page={page}
                  setDiff={setDiff}
                  diff={diff}
                />

                <ParentComponent {...component.props} context={context} admin>
                  <ActionButton
                    disabled={isSaving}
                    extraClass='add my-4'
                    action={() => toggleAddModal({ id: undefined, parent: componentRef })}
                  >
                    <AddBox color='primary' />
                    Add
                  </ActionButton>
                  <ChildComponents<T>
                    admin
                    context={context}
                    isSaving={isSaving}
                    allComponents={props.allComponents}
                    component={component}
                    componentRef={componentRef}
                    toggleAddModal={toggleAddModal}
                    toggleEditModal={toggleEditModal}
                    onUpdate={onUpdate}
                    pagesContent={pagesContent}
                    reverseMode={component.reverseMode}
                    page={page}
                    setDiff={setDiff}
                    diff={diff}
                  />
                </ParentComponent>
              </div>

              {!component.reverseMode && (
                <ActionButton disabled={isSaving} extraClass='add my-4' action={() => toggleAddModal(componentRef)}>
                  <AddBox color='primary' />
                  Add
                </ActionButton>
              )}
            </Fragment>
          );
        }

        if (!component.enabled) {
          return null;
        }

        return (
          <Fragment key={`${component.name}-${component.id}`}>
            <ParentComponent
              context={context}
              id={component.id}
              {...propsTransformer(component.name, definition, component.props, component.id)}
            >
              {(component.reverseMode
                ? component.children.slice().reverse()
                : orderComponents(component.order, component.children))
                ?.map((child: PageComponent) => {
                  if (!child) return null;

                  const ChildComponent = props.allComponents[child.name]?.default;
                  const childDefinition = props.allComponents[child.name]?.definition;

                  if (!ChildComponent || !child.enabled) return null;

                  return (
                    <ChildComponent
                      context={context}
                      parentId={component.id}
                      id={child.id}
                      key={`${child.name}-${component.id}-${child.id}`}
                      enabled={child.enabled}
                      {...propsTransformer(child.name, childDefinition, child.props, child.id, component.name)}
                    />
                  );
                })}
            </ParentComponent>
          </Fragment>
        );
      })}
    </div>
  );
}
