import React from 'react';

export function ActionButton(
  { children, action, extraClass = 'edit', ...props }:
  { children: JSX.Element | string | (JSX.Element | string)[],
    action: React.MouseEventHandler,
    extraClass?: string,
    disabled?: boolean,
  },
):JSX.Element {
  return (
    <button type='button' className={extraClass} onClick={action} {...props}>
      {children}
    </button>
  );
}
