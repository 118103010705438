/*
 * (C) Behaviour Interactive Inc. - All Rights Reserved
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 * This file is proprietary and confidential
 */

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import {
  Logout,
} from '@mui/icons-material';

import { useMediaQuery } from '@mui/material';
import { LocalizedLink } from '../../_shared/LocalizedLink';
import { useAuth } from '../../authentication/AuthenticationContext';
import { MenuMotionSpan } from './MenuMotionSpan';
import { MenuLink } from './Menu.model';
import { useMenu } from '../../contexts/MenuContext';

interface Props {
  menuLinks: MenuLink[]
}

const variants = {
  open: {
    opacity: 1,
    x: 0,
    transition: {
      x: { ease: 'circOut' },
    },
  },
  closed: { opacity: 0, x: '100%' },
};

export function Menu({ menuLinks }: Props): JSX.Element {
  const { open, toggleMenu } = useMenu();
  const auth = useAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [currentActive, setCurrentActive] = useState<string>(location.pathname);

  const logout = (): void => {
    auth.logout();
    if (isMobile) {
      toggleMenu();
    }
  };

  const getCommonProps = (menuOption: { id: string }): Record<string, unknown> => ({
    key: menuOption.id,
    className: 'menu-link',
    'data-cy': `${menuOption.id}-link`,
    onMouseLeave: () => setCurrentActive(location.pathname),
    onClick: isMobile ? () => toggleMenu() : null,
  });

  useEffect(() => {
    setCurrentActive(location.pathname);
  }, [location.pathname]);

  return (
    <motion.div
      className='account-menu'
      animate={open ? 'open' : 'closed'}
      variants={variants}
    >
      <div className='text-center menu-header'>
        <h3 data-cy='welcome-title-text'>{t('auth.register.sign-up')}</h3>
        <h3 className='username' data-cy='desktop-nickname-text'>
          {auth.user.nickName}
        </h3>
        <button type='button' data-cy='desktop-logout-button' className='btn btn-secondary' onClick={logout}>
          <Logout className='icon' />
          {t('button.logout')}
        </button>
      </div>

      <div className='menu-links'>
        {menuLinks.map((menuOption) => {
          if (menuOption.requires == null || menuOption.requires) {
            if ('isExternal' in menuOption) {
              return (
                <a
                  {...getCommonProps(menuOption)}
                  onMouseEnter={() => setCurrentActive(menuOption.url)}
                  href={menuOption.url}
                  target='_blank'
                  rel='noreferrer'
                >
                  {currentActive.includes(menuOption.url) && (
                    <MenuMotionSpan id='menu-bar' />
                  )}
                  <menuOption.Icon />
                  <div className='menu-link--text'>
                    <span>
                      {t(menuOption.translation)}
                    </span>
                  </div>
                </a>
              );
            }

            const fullPath = `/account/${menuOption.path}`;
            return (
              <LocalizedLink
                {...getCommonProps(menuOption)}
                isNavLink
                to={fullPath}
                onMouseEnter={() => setCurrentActive(fullPath)}
              >
                {currentActive.includes(fullPath) && (
                  <MenuMotionSpan id='menu-bar' />
                )}
                <menuOption.Icon />
                <div className='menu-link--text'>
                  <span>
                    {t(menuOption.translation)}
                  </span>
                  {menuOption.notification && (
                    <div className='blink-alert menu-link--alert' />
                  )}
                </div>
              </LocalizedLink>
            );
          }
          return null;
        })}
      </div>
    </motion.div>
  );
}
