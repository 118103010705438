/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CheckCircle } from '@mui/icons-material';
import { useAuth } from '../../../../../authentication/AuthenticationContext';
import { StepKey } from '../../models';
import { get } from '../../../../../utils/Request';
import { useSnackbar } from '../../../../../snackbar/SnackbarContext';
import { TranslationLink } from '../../../../translation/TranslationLink';
import { Approval, ApprovalRequest, Approvals } from '../../../../../authentication/Authentication.model';
import { SingleCheckbox } from '../../../../form/SingleCheckbox';
import { useConfig } from '../../../../../contexts/ConfigContext';
import { LegalContent } from '../../../../../contexts/Config.model';

const confirmationMessageStyle = {
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  width: '80%',
  textAlign: 'center',
  color: 'rgba(255, 255, 255, 0.70)',
  marginTop: '0.5rem',
  marginBottom: '3rem',
};

interface Props {
  onComplete: (stepKey: StepKey) => void
  onClose: (stepKey: StepKey) => void
}

export function NewsletterClaimStep({ onComplete, onClose } : Props): JSX.Element {
  const auth = useAuth();
  const config = useConfig();
  const snackbar = useSnackbar();

  const { t } = useTranslation();
  const [isNewsletterChecked, setIsNewsletterChecked] = useState<boolean>(false);

  const [isAdsChecked, setIsAdsChecked] = useState<boolean>(false);
  const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false);
  const [isConsentConfirmed, setIsConsentConfirmed] = useState<boolean>(false);
  const [legalContentData, setLegalContentData] = useState<LegalContent[]>();
  const [boxChanges, setBoxChanges] = useState<string[]>([]);
  const [userApprovals, setUserApprovals] = useState<Approvals>();

  const handleNewsletterCheckBox = (checked: boolean): void => {
    if (userApprovals?.newsletter?.approved !== checked) {
      if (!boxChanges.includes('newsletter')) {
        setBoxChanges([...boxChanges, 'newsletter']);
      }
    } else {
      setBoxChanges(boxChanges.filter((box) => box !== 'newsletter'));
    }
    setIsNewsletterChecked(checked);
  };

  const handleAdsCheckBox = (checked: boolean): void => {
    if (userApprovals?.['personalized-ads-offers']?.approved !== checked) {
      if (!boxChanges.includes('personalized-ads-offers')) {
        setBoxChanges([...boxChanges, 'personalized-ads-offers']);
      }
    } else {
      setBoxChanges(boxChanges.filter((box) => box !== 'personalized-ads-offers'));
    }
    setIsAdsChecked(checked);
  };

  const getLegalContent = async (): Promise<void> => {
    const legalContents = await Promise.all(
      boxChanges?.map(async (legalContentId): Promise<LegalContent> => {
        const legalContent = await get<LegalContent>(`${config.clientConfig?.host}/legal-contents/${legalContentId}`);
        return {
          ...legalContent,
        };
      }),
    );
    setLegalContentData(legalContents);
  };

  const handleGiveConsent = async (): Promise<void> => {
    if (!legalContentData.length) {
      onClose(StepKey.NEWSLETTER);
      return;
    }
    const approvalRequests: ApprovalRequest[] = legalContentData?.map((content) => ({
      version: content.version,
      legalContentId: content.id,
      approved: content.id === 'newsletter' ? isNewsletterChecked : isAdsChecked,
    }));
    try {
      const approvalsSuccess = await auth.addApprovals(approvalRequests);
      if (!approvalsSuccess) {
        snackbar.addErrorMessage('account.legal-contents.modal.mandatory.failure', undefined, 'translation');
        return;
      }
      if (isNewsletterChecked && isAdsChecked) {
        setIsConsentConfirmed(true);
        onComplete(StepKey.NEWSLETTER);
      } else {
        onClose(StepKey.NEWSLETTER);
      }
      auth.getPlayer();
    } catch (e) {
      // TODO: Get error translations
      snackbar.addErrorMessage('UnknownError');
      setIsConsentConfirmed(false);
    }
  };

  const handleContinue = (): void => {
    handleGiveConsent();
  };

  useEffect(() => {
    setIsConsentGiven(isAdsChecked && isNewsletterChecked);
  }, [isAdsChecked, isNewsletterChecked]);

  useEffect(() => {
    getLegalContent();
  }, [boxChanges]);

  useEffect(() => {
    const { approvals } = auth?.user || null;

    if (!approvals) {
      return;
    }

    const newsletter: Approval | null = approvals?.newsletter || null;
    const ads: Approval | null = approvals?.['personalized-ads-offers'] || null;

    setIsNewsletterChecked(newsletter?.approved || false);
    setIsAdsChecked(ads?.approved || false);

    setUserApprovals(approvals);

    if (Object.keys(approvals).length && newsletter?.approved && ads?.approved) {
      setIsConsentConfirmed(true);
      onComplete(StepKey.NEWSLETTER);
    }
  }, [auth.user]);

  return (
    <>
      <Typography variant='h6' sx={{ fontWeight: '500' }}>
        {t('promotion.marketing-consent.form-title')}
      </Typography>
      <Typography
        variant='body2'
        component='span'
        mt={0}
        mb={2}
        sx={{ fontWeight: '400', color: 'rgba(255, 255, 255, 0.70)' }}
      >
        {t('claimFreeGame.newsletter.subtitle')}
      </Typography>

      { !isConsentConfirmed ? (
        <>
          <TextField
            id='outlined-basic'
            label={t('claimFreeGame.newsletter.yourEmail')}
            variant='outlined'
            defaultValue={auth.user?.email}
            disabled
            InputLabelProps={{
              sx: { WebkitTextFillColor: 'rgba(255, 255, 255, 0.38)',
                fontSize: '14px',
                textTransform: 'none',
                marginLeft: '0.25rem' },
            }}
            sx={{
              marginTop: '40px',
              marginBottom: '1rem',
              width: '100%',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'rgba(255, 255, 255, 0.70)',
                fontWeight: 300,
              },
              '& .MuiInputBase-root.Mui-disabled': {
                '& > fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.38)',
                  borderWidth: 'thin',
                },
              },
            }}
            data-cy='newsletter-email'
          />
          <Box gap={3}>
            <SingleCheckbox
              name='check-newsletter-wtf'
              label={t('marketing-consent.approvals.newsletter')}
              isChecked={isNewsletterChecked}
              onChangeHandler={handleNewsletterCheckBox}
            />
            <SingleCheckbox
              name='check-ads-wtf'
              label={t('marketing-consent.approvals.personalized-ads-offers')}
              isChecked={isAdsChecked}
              onChangeHandler={handleAdsCheckBox}
            />
          </Box>
          <Box gap={1}>
            <Typography sx={{ fontSize: '14px', fontWeight: '300', color: 'rgba(255, 255, 255, 0.70)' }}>
              {t('marketing-consent.opt-out')}
            </Typography>
          </Box>
          <Box
            sx={{
              background: '#292a2c',
              borderRadius: '4px',
              padding: '0.5rem',
              marginTop: '1rem',
            }}
          >
            <Box sx={{ fontSize: '12px', fontWeight: '300', color: 'rgba(255, 255, 255, 0.70)' }}>
              <Trans
                i18nKey={t('claimFreeGame.newsletter.termsConfirmation', { platform: 'steam' })}
                components={{
                  linkPrivacyPolicy: (
                    <TranslationLink
                      className='hyperlink'
                      url={t('account.legal-contents.approvals.privacy-policy.link')}
                    />
                  ),
                  linkTermsOfUse: (
                    <TranslationLink
                      className='hyperlink'
                      url={t('account.legal-contents.approvals.terms-of-use.link')}
                    />
                  ),
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', mt: 5, justifyContent: 'space-between' }}>
            <button
              type='button'
              className='btn btn-link'
              onClick={() => { onClose(StepKey.NEWSLETTER); }}
            >
              {t('button.close')}
            </button>
            <button
              type='button'
              className='btn btn-primary-game'
              data-cy='wtf-newsletter-modal-button'
              onClick={handleContinue}
            >
              <>
                {isConsentGiven
                  ? (
                    <>
                      {t('button.continue')}
                    </>
                  ) : (
                    <>
                      {t('claimFreeGame.newsletter.continueWithoutRewards')}
                    </>
                  )}
              </>
            </button>
          </Box>
        </>
      ) : (
        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' flexGrow={1}>
          <CheckCircle
            className='icon success'
            sx={{ width: '80px',
              height: '80px',
              margin: '2rem 0',
              fontWeight: '700' }}
          />
          <Typography variant='h5'>
            {t('claimFreeGame.newsletter.thanksSubscribing')}
          </Typography>
          <Typography
            component='span'
            sx={confirmationMessageStyle}
          >
            {t('claimFreeGame.newsletter.almostThere')}
          </Typography>
        </Box>
      )}

    </>
  );
}
