/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, IconButton, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { BackDrop } from './BackDrop';

interface Props {
  show: boolean;
  textClose?: string;
  classClose?: string;
  textConfirm?: string;
  classConfirm?: string;
  className?: string;
  refresh?: boolean;
  disabled?: boolean;
  init?: boolean;
  isInfoModal?: boolean;
  maxWidth?: number;
  disabledBackDrop? : boolean;
  minWidth?: number;
  minHeight?: number;
  children?: JSX.Element | JSX.Element[];
  close?: () => void;
  confirm?: () => void;
  customHeader?: JSX.Element;
  mobileFullScreen?: boolean;
}

export function Modal(props: Props): JSX.Element {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isSmall = useMediaQuery('(max-width: 575px)');

  const hasFooter = !!(props.textClose || props.textConfirm);

  const close = (): void => {
    setIsSubmitted(true);
    if (props.close) {
      props.close();
    }
  };

  const handleEscape = (evt: KeyboardEvent): void => {
    let isEscape = false;
    if ('key' in evt) {
      isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
      if (props.show && isEscape) {
        close();
      }
    }
  };

  const confirm = (): void => {
    setIsSubmitted(true);
    props.confirm();
  };

  useEffect(() => {
    if (isSubmitted && props.init) {
      setIsSubmitted(false);
    }
  }, [props.init]);

  useEffect(() => {
    if (props.show) {
      setIsSubmitted(false);
      document.body.classList.add('open-modal');
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.body.classList.remove('open-modal');
      document.removeEventListener('keydown', handleEscape);
    };
  }, [props.show]);

  return ReactDOM.createPortal(
    <>
      {props.show && (
        <BackDrop onMouseDown={!props.disabledBackDrop ? props.close : () => {}}>
          <div
            className={`modal ${props.className || ''} ${props.mobileFullScreen ? 'modal-mobile-fullscreen' : ''}`}
            data-cy={`${props.className || ''}`}
          >
            <motion.div
              onMouseDown={(e) => e.stopPropagation()}
              key='modal'
              initial={{ opacity: 0, scale: 0.95, y: 10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, type: 'tween', ease: 'easeOut' }}
              className='modal-container'
            >
              <div
                className='modal-dialog'
                style={{ maxWidth: isSmall ? '' : props.maxWidth,
                  minWidth: props.minWidth,
                  flexBasis: !isSmall ? props.minHeight : '' }}
              >
                {props.customHeader && (
                  <div className='modal-custom-header'>{props.customHeader}</div>
                )}

                {props.isInfoModal && (
                  <IconButton data-cy='modal-close' aria-label='close' className='info-close-btn' onClick={close}>
                    <CloseIcon />
                  </IconButton>
                )}

                <div className='modal-dialog-content'>
                  <div className='modal-body' data-cy='modal-body-div'>
                    {props.children}
                  </div>

                  {!props.isInfoModal && hasFooter && (
                    <div className='modal-footer'>
                      {props.textClose && (
                        <button
                          type='button'
                          disabled={isSubmitted}
                          className={props.classClose || 'btn btn-link'}
                          onClick={close}
                        >
                          {props.textClose}
                        </button>
                      )}
                      {props.textConfirm && (
                        <button
                          type='button'
                          disabled={isSubmitted || props.disabled}
                          className={props.classConfirm || 'btn btn-primary-game'}
                          onClick={confirm}
                          data-cy='modal-confirm-button'
                        >
                          {isSubmitted && props.refresh && (
                            <CircularProgress size='1.5rem' className='btn-loading' />
                          )}
                          {props.textConfirm}
                        </button>
                      )}
                    </div>
                  )}

                </div>
              </div>
            </motion.div>
          </div>
        </BackDrop>
      )}
    </>,
    document.getElementById('modal-root'),
  );
}
