/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Modal } from '../_shared/modal/Modal';
import { ForgotPassword } from './forgot-password/ForgotPassword';
import { Otp } from './otp/Otp';
import { Register } from './register/Register';
import { Signup } from './signup/Signup';
import { Signin } from './signin/Signin';
import { useAuth } from './AuthenticationContext';

export function AuthenticationModal(): JSX.Element {
  const auth = useAuth();
  return (
    <Modal
      show={!!auth.visibleModal}
      isInfoModal
      close={() => auth.updateVisibleModal(null)}
      maxWidth={500}
    >
      { auth.visibleModal === 'loginOptions' && <Signin isModal /> }
      { auth.visibleModal === 'registerOptions' && <Signup isModal /> }
      { auth.visibleModal === 'register' && <Register /> }
      {
        auth.visibleModal === 'forgotPassword'
            && <ForgotPassword onSuccess={() => auth.updateVisibleModal(null)} />
      }
      { auth.visibleModal === 'otp' && <Otp /> }
    </Modal>
  );
}
