import { PageComponent } from '../Config.model';

export async function convertBase64(file: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result.toString());
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export async function ValidateImageSize(
  file: File,
  maxWidth: number,
  maxHeight: number,
  maxWeight: number,
) : Promise<File> {
  return new Promise<File>((resolve, reject) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();

    const fileSize = Math.floor(file.size / 1000000);
    if (maxWeight && fileSize > maxWeight) {
      const error = `Mex weight is ${maxWeight}mb.
      Actual is weight: ${fileSize}mb`;
      // eslint-disable-next-line no-alert
      alert(error);
      reject(new Error(error));
      return;
    }

    reader.readAsDataURL(file);
    reader.onload = (e) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const image: any = new Image();
      image.src = e.target.result;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      image.onload = (evt: Record<any, any>) => {
        const { height } = evt.target as HTMLImageElement;
        const { width } = evt.target as HTMLImageElement;
        if (maxHeight && maxWidth && (height !== maxHeight || width !== maxWidth)) {
          const error = `Height and Width must be ${maxWidth}px by ${maxHeight}px.
          Actual is width: ${width}px, height: ${height}px`;
          // eslint-disable-next-line no-alert
          alert(error);
          reject(new Error(error));
          return;
        }

        resolve(file);
      };
    };
  });
}

export function downloadFile(filename: string, text: string): void {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function orderComponents(order: string[], components: PageComponent[]): PageComponent[] {
  // Retro compatibility
  if (!order) {
    return components;
  }

  return order.map((id) => components.find((c) => c?.id === id));
}

export const containsString = (
  value: string,
  searchedValue: string,
): boolean => value?.includes(searchedValue);
