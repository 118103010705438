/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';

import { Trans } from 'react-i18next';
import { TranslationLink } from '../../translation/TranslationLink';

export const definition: Definition = {
  title: { type: DefinitionPropertyType.TranslatedString },
  description: { type: DefinitionPropertyType.TranslatedString },
  questions: {
    type: DefinitionPropertyType.Array,
    elements: {
      question: {
        type: DefinitionPropertyType.TranslatedString,
      },
      answer: {
        type: DefinitionPropertyType.TranslatedString,
      },
    },
  },
  endTitle: { type: DefinitionPropertyType.TranslatedString },
  endSubtitle: { type: DefinitionPropertyType.TranslatedString },
  endLink: { type: DefinitionPropertyType.TranslatedString },
  endLinkText: { type: DefinitionPropertyType.TranslatedString },
};

export interface Props {
  title: string,
  questions: Array<{ question: string, answer: string }>,
  description?: string,
  endTitle?: string,
  endSubtitle?: string,
  endLink?: string,
  endLinkText?: string,
}

export default function Faq(
  { title,
    description,
    questions,
    endTitle,
    endSubtitle,
    endLinkText,
    endLink }: Props,
): JSX.Element {
  const toggleCollapse = (index: number): void => {
    const card = document.getElementById(`card-${index}`);

    if (card) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const collapse: any = card.getElementsByClassName('collapse')[0];
      const collapseHeight = collapse.scrollHeight;

      if (card.classList.contains('show')) {
        collapse.style.height = '0px';
        card.classList.remove('show');
      } else {
        collapse.style.height = `${collapseHeight}px`;
        card.classList.add('show');
      }
    }
  };

  return (
    <div className='faq-container'>
      <div className='container-title'>
        <h2>{title}</h2>
        {description && <p>{description}</p>}
      </div>

      <div className='faq-component'>
        <ul className='accordion'>
          {questions?.map((element, i) => (
            <li className='card bg-transparent' key={`card-${i}`} id={`card-${i}`}>
              <div className='card-header bg-transparent'>
                <h2 className='mb-0'>
                  <button
                    id={`card-faq-${i}`}
                    className='btn btn-faq'
                    type='button'
                    onClick={() => toggleCollapse(i)}
                  >
                    {element.question}
                  </button>
                </h2>
              </div>

              <div className='collapse'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-12 col-lg-11 description h-auto'>
                      <Trans
                        i18nKey={element.answer}
                        components={{
                          faqLink: <TranslationLink />,
                          list: <ul className='faq--list' />,
                          listItem: <li className='faq--list-item' />,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {endLink && (
        <div className='mt-4 text-center'>
          <h3 className='h5'>{endTitle}</h3>
          <p>
            {endSubtitle}
            <a
              href={endLink}
              target='_blank'
              className='faq-end-link'
              rel='noopener noreferrer'
            >
              {endLinkText}
            </a>
          </p>
        </div>
      )}
    </div>
  );
}
