/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from '../snackbar/SnackbarContext';

import { del, get, post, setToken, request, patch } from '../utils/Request';
import {
  Availability,
  BhvrUser,
  CodePayload,
  SigninPayload,
  SigninWithProviderPayload,
  SignupPayload,
  UserContext,
  TokenResponse,
  PlayerRegistrationResponse,
  AuthenticationModalData,
  AuthModalType,
  ApprovalRequest,
  Approvals,
} from './Authentication.model';
import { RequestError, isRequestError } from '../Error.model';
import { useRedirectUrl } from '../redirect-url/ProviderRedirectUrlContext';
import { useUtm } from '../_shared/utm/useUtm';
import { useGameTheme } from '../contexts/GameThemeContext';
import { useConfig } from '../contexts/ConfigContext';

interface OTPErrorData { isEmailVerified: boolean, expiryDate: string, hash: string }

const AuthenticationContext = createContext<UserContext>({
  user: null,
  isUserLoad: false,
  signin: null,
  signinWithProvider: null,
  getPlayer: null,
  checkPlayerAvailability: null,
  logout: null,
  updateUser: null,
  register: null,
  visibleModal: null,
  visibleModalData: null,
  updateVisibleModal: null,
  addApprovals: null,
});

function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useAuth = (): UserContext => useContext(AuthenticationContext);

const useProvideAuth = (): UserContext => {
  const history = useHistory();
  const query = useQuery();
  const snackbar = useSnackbar();
  const redirect = useRedirectUrl();
  const theme = useGameTheme();
  const config = useConfig();
  const utm = useUtm();

  const [user, setUser] = useState<BhvrUser>(null);
  const [isUserLoad, setIsUserLoad] = useState(false);
  const [isLibrarySynced, setIsLibrarySynced] = useState(false);
  const [visibleModal, setVisibleModal] = useState<AuthModalType>(null);
  const [visibleModalData, setVisibleModalData] = useState<AuthenticationModalData>(null);

  // Use this method instead of the setter setVisibleModal to make sure data is updated at the same time as visibleModal
  const updateVisibleModal = (modalType: AuthModalType, data?: AuthenticationModalData): void => {
    setVisibleModalData(data);
    setVisibleModal(modalType);
  };

  const displayError = (err: RequestError): void => {
    snackbar.addErrorMessage(err.code);
  };

  const getPlayer = async (): Promise<void> => {
    try {
      const response = await request<BhvrUser>(
        'GET',
        `${config.clientConfig.host}/players/me`,
        { disableRedirect: true },
      );

      utm.completeFlow();

      setUser(response);

      const ssoQueryString = sessionStorage.getItem('ssoQueryString');
      if (ssoQueryString) {
        sessionStorage.removeItem('ssoQueryString');
        location.replace(`${config.clientConfig.host}/sso/authorize${ssoQueryString}`);
      }
    } catch (e) {
      const err = e as RequestError;
      if (err.message !== 'Authentication required') {
        displayError(err);
      }
    }

    setIsUserLoad(true);

    if (redirect.path) {
      history.replace(redirect.path);
      redirect.setRedirect('');
    }
  };

  const checkPlayerAvailability = async (email: string): Promise<Availability> => {
    const available = await get<Availability>(`${config.clientConfig.host}/players/check-email/${email}`);
    return available;
  };

  const signinWithProvider = async (
    payload: SigninWithProviderPayload,
    type: string,
    email?: string,
    code?: CodePayload,
  ): Promise<void> => {
    let body = {
      idToken: payload.idToken,
    };

    if (code) {
      body = {
        ...body,
        ...code,
      };
    }

    try {
      const resp: TokenResponse = await post(`${config.clientConfig.host}/auth/${type}`, { data: body });
      setToken(resp.token, resp.expiresIn);
      getPlayer();
      updateVisibleModal(null);
    } catch (e) {
      if (isRequestError(e) && e.message === 'Player not found') {
        updateVisibleModal('register', {
          email: e.data?.email || email,
          idToken: e.data?.token || payload.idToken,
          type,
        });
      } else if (isRequestError<OTPErrorData>(e) && e.message === 'Code is required') {
        updateVisibleModal('otp', {
          provider: type,
          idToken: payload.idToken,
          isEmailVerified: e.data?.isEmailVerified,
          expiryDate: e.data?.expiryDate,
          hash: e.data?.hash,
        });
      } else {
        displayError(e as RequestError);
      }
    }
  };

  const signin = async (
    payload: SigninPayload,
    reCaptchaToken: string,
    code?: CodePayload,
  ): Promise<void> => {
    let headers = {};
    if (reCaptchaToken) {
      headers = {
        'g-recaptcha-response': reCaptchaToken,
      };
    }

    let body = {
      ...payload,
    };

    if (code) {
      body = {
        ...body,
        ...code,
      };
    }

    try {
      const resp: TokenResponse = await post(`${config.clientConfig.host}/auth/password`, { data: body, headers });
      setToken(resp.token, resp.expiresIn);
      getPlayer();
      updateVisibleModal(null);
      if (query.get('clientId') || query.get('client_id')) {
        location.href = `${config.clientConfig.host}/sso/authorize?${query.toString()}`;
        return;
      }
    } catch (e) {
      if (isRequestError<OTPErrorData>(e) && e.message === 'Code is required') {
        updateVisibleModal('otp', {
          email: body.email,
          password: body.password,
          isEmailVerified: e.data?.isEmailVerified,
          expiryDate: e.data?.expiryDate,
          hash: e.data?.hash,
        });
      } else {
        displayError(e as RequestError);
      }
    }
  };

  const logout = async (): Promise<void> => {
    try {
      theme.setGameId('');
      await del(`${config.clientConfig.host}/auth`);
      setUser(null);
      redirect.setRedirect('');
    } catch (e) {
      const err = e as RequestError;
      snackbar.addErrorMessage(err.code);
    }
  };

  const updateUser = (newUser: BhvrUser): void => {
    setUser(newUser);
  };

  const register = async (
    payload: SignupPayload,
    email: string,
    password: string,
    reCaptchaToken?: string,
  ): Promise<void> => {
    let headers = {};
    if (reCaptchaToken) {
      headers = {
        'g-recaptcha-response': reCaptchaToken,
      };
    }

    try {
      const response = await post<PlayerRegistrationResponse>(
        `${config.clientConfig.host}/players`,
        { data: payload, headers },
      );
      setToken(response.token?.token, response.token.expiresIn);
      setUser(response.player);
      updateVisibleModal(null);

      if (redirect.path) {
        history.replace(redirect.path);
        redirect.setRedirect('');
      }
    } catch (e) {
      const err = e as RequestError<OTPErrorData>;
      if (err.message === 'Code is required') {
        updateVisibleModal('otp', {
          email,
          password,
          isEmailVerified: err.data?.isEmailVerified,
          expiryDate: err.data?.expiryDate,
          hash: err.data?.hash,
        });
      } else {
        snackbar.addErrorMessage(err.code);
      }
    }
  };

  const syncGameLibrary = async (): Promise<void> => {
    try {
      await patch(`${config.clientConfig?.host}/players/me/accounts/providers/games`);
      getPlayer();
    } catch {
      // this operation is transparent for the player so we do nothing if there is an error
    } finally {
      setIsLibrarySynced(true);
    }
  };

  useEffect(() => {
    getPlayer();
  }, []);

  useEffect(() => {
    if (!isLibrarySynced && user) {
      syncGameLibrary();
    }
  }, [user]);

  const addApprovals = async (approvalRequests: ApprovalRequest[]): Promise<boolean> => {
    try {
      await post<Approvals[number][]>(
        `${config.clientConfig?.host}/players/approvals`,
        {
          data: approvalRequests,
        },
      );

      const updatedUser = await get<BhvrUser>(`${config.clientConfig?.host}/players/me`);

      updateUser(updatedUser);

      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    user,
    isUserLoad,
    signin,
    signinWithProvider,
    getPlayer,
    checkPlayerAvailability,
    logout,
    updateUser,
    register,
    visibleModal,
    visibleModalData,
    updateVisibleModal,
    addApprovals,
  };
};

interface Props {
  children?: JSX.Element | JSX.Element[];
}

export function ProvideAuthentication(props: Props): JSX.Element {
  const auth = useProvideAuth();

  return (
    <AuthenticationContext.Provider value={auth}>
      {props.children}
    </AuthenticationContext.Provider>
  );
}
