/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/
import React, { useState } from 'react';
import { Sync } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ProviderName } from '../../ProviderEnum';
import { RequestError } from '../../../../Error.model';
import { useAuth } from '../../../../authentication/AuthenticationContext';
import { Popup } from '../../../../utils/Popup';
import { patch } from '../../../../utils/Request';
import { GameAccount } from '../../../../authentication/Authentication.model';
import { ProviderLinkWrapper } from './ProviderLinkWrapper';
import { useConfig } from '../../../../contexts/ConfigContext';

export interface SyncResponse {
  type: 'success' | 'error';
  code: string;
}

interface Props {
  provider: ProviderName;
  providerAccount: GameAccount;
  providerAuthUrl: string;
  isAuthProvider: boolean;
  onSyncResponse: (resp: SyncResponse) => void;
  setError: (e: RequestError | null) => void;
  buttonStyle?: string;
  className?: string;
}

export function ProviderSyncButton(
  { provider,
    providerAccount,
    providerAuthUrl,
    onSyncResponse,
    setError,
    buttonStyle,
    className,
    isAuthProvider }: Props,
): JSX.Element {
  const config = useConfig();
  const auth = useAuth();

  const { t } = useTranslation(['translation', 'error']);

  const [isSyncing, setIsSyncing] = useState(false);

  const sync = async (): Promise<void> => {
    setIsSyncing(true);
    try {
      await patch(`${config.clientConfig?.host}/players/me/accounts/providers/${provider}/games`);
      auth.getPlayer();
      onSyncResponse({ type: 'success', code: 'account.provider.sync-success' });
    } catch (e) {
      const err = e as RequestError;
      if (err?.code === 'InvalidToken') {
        Popup.open(providerAuthUrl, `${provider}_auth`);
      } else {
        onSyncResponse({ type: 'error', code: err?.code });
      }
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <ProviderLinkWrapper
      provider={provider}
      isAuthProvider={isAuthProvider}
      errorHandler={setError}
      providerAccount={providerAccount}
    >
      <button
        type='button'
        className={`btn ${buttonStyle || 'btn-link'} ${className}`}
        data-cy={`${provider}-sync-button`}
        onClick={sync}
        disabled={isSyncing}
      >
        <Sync className={isSyncing ? 'syncing' : ''} />
        {t('translation:account.my-account.sync')}
      </button>
    </ProviderLinkWrapper>
  );
}
