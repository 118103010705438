/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Step, StepContent, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import webPage1 from '../../../_assets/webpage-1.png';
import webPage2 from '../../../_assets/webpage-2.png';
import { useAuth } from '../../AuthenticationContext';

interface HowItWorksStep {
  title: string;
  description: string;
  cta: string;
}

export function HomepageHowItWorks(): JSX.Element {
  const auth = useAuth();
  const { t } = useTranslation('translation');
  const isLarge = useMediaQuery('(min-width: 992px)');

  const steps: HowItWorksStep[] = t('home.how-it-works.steps', { returnObjects: true });

  const signUpBhvr = (): void => { auth.updateVisibleModal('registerOptions'); };

  return (
    (Array.isArray(steps) && steps.length) ? (
      <div className='homepage-how-it-works'>
        <div className='homepage-how-it-works--container'>
          <h2>{t('home.how-it-works.title')}</h2>

          <div className='homepage-how-it-works--content'>
            <Stepper
              activeStep={0}
              orientation='vertical'
              className='homepage-how-it-works--stepper'
            >
              {steps.map((step) => (
                <Step key={step.title} expanded>
                  <StepLabel>
                    <span className='homepage-how-it-works--stepper--step-title'>
                      {step.title}
                    </span>
                  </StepLabel>

                  <StepContent>
                    <span className='homepage-how-it-works--stepper--step-description'>
                      {step.description}
                    </span>

                    {step.cta && (
                      <button
                        type='button'
                        onClick={signUpBhvr}
                        className='btn btn-primary-game homepage-how-it-works--stepper--step-cta'
                      >
                        {step.cta}
                      </button>
                    )}
                  </StepContent>
                </Step>
              ))}
            </Stepper>

            {isLarge && (
              <div className='homepage-how-it-works--imgs'>
                <img src={webPage1} alt='webpage-1' className='homepage-how-it-works--imgs--webpage1' />
                <img src={webPage2} alt='webpage-2' className='homepage-how-it-works--imgs--webpage2' />
              </div>
            )}
          </div>
        </div>
      </div>
    ) : <></>
  );
}
