/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../layout/PageLayout';
import { useAuth } from '../../authentication/AuthenticationContext';

import { GameList } from './GameList';
import { EditorVersion } from '../../_shared/admin/EditorVersion';
import { useConfig } from '../../contexts/ConfigContext';
import { GameConfig } from '../../contexts/Config.model';

export function GameLibrary(): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuth();
  const config = useConfig();

  const ownedGames = useRef<GameConfig[]>([]);
  const notOwnedGames = useRef<GameConfig[]>([]);
  const unreleasedGames = useRef<GameConfig[]>([]);
  const [gamesLoaded, setGamesLoaded] = useState(false);

  const ownAllGames = !notOwnedGames.current.length;

  useEffect(() => {
    ownedGames.current = [];
    notOwnedGames.current = [];
    unreleasedGames.current = [];
    config.serverConfig?.gamesConfig.forEach((game) => {
      if (!game.released) {
        unreleasedGames.current.push(game);
      } else if (auth.user.gameLibrary[game.id] && auth.user.gameLibrary[game.id].providers.length) {
        ownedGames.current.push(game);
      } else {
        notOwnedGames.current.push(game);
      }
    });
    setGamesLoaded(true);
  }, [auth.user.gameLibrary]);

  return (
    <PageLayout>
      <>
        {config.pagesContent.version && (<EditorVersion />)}
        {gamesLoaded && (
          <div className='my-account-container'>
            <h1>{t('account.game-library.title')}</h1>

            <div className='your-games'>
              <h3>{t('account.game-library.title-owned')}</h3>
              <GameList games={ownedGames.current} showPlaceholder={!ownAllGames} category='owned' />
            </div>

            {!ownAllGames && (
              <div className='available-games'>
                <h3>{t('account.game-library.title-not-owned')}</h3>
                <GameList games={notOwnedGames.current} category='available' />
              </div>
            )}

            {unreleasedGames.current.length > 0 && (
              <div className='available-games'>
                <h3>{t('account.game-library.title-upcoming')}</h3>
                <GameList games={unreleasedGames.current} category='upcomming' />
              </div>
            )}
          </div>
        )}
      </>

    </PageLayout>
  );
}
