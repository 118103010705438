/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { Edit } from '@mui/icons-material';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isActive: boolean;
  onClick: () => unknown;
  'aria-label'?: string;
  'data-cy'?: string;
};

const editContainerVariants = {
  default: {
    x: '-100%',
    transition: {
      type: 'spring',
      duration: 0.4,
      delay: 0.3,
    },
  },
  editting: {
    x: 0,
    transition: {
      type: 'spring',
      duration: 0.3,
      staggerChildren: 0.3,
    },
  },
};

const editIconVariants = {
  default: {
    rotate: 0,
  },
  editting: {
    rotate: -90,
  },
};

const editLineVariants = {
  default: {
    x: 20,
    y: -20,
    rotate: -45,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  editting: {
    x: 1,
    y: 0,
    rotate: -45,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
};

export function EditButton({ isActive, onClick, 'data-cy': dataCy, 'aria-label': ariaLabel }: Props): JSX.Element {
  const { t } = useTranslation();
  const controls = useAnimation();

  useEffect(() => {
    if (isActive) {
      controls.start('editting');
    } else {
      controls.start('default');
    }
  }, [isActive]);

  return (
    <button
      className={`btn btn-link btn-sm form-edit ${isActive ? 'is-active' : ''}`}
      type='button'
      onClick={onClick}
      data-cy={dataCy}
      aria-label={ariaLabel}
    >
      <motion.span
        className='icon-container'
        variants={editContainerVariants}
        initial='default'
        animate={controls}
      >
        <motion.span variants={editIconVariants}>
          <Edit className='edit-icon' />
        </motion.span>
        <motion.span
          className='icon-cross'
          variants={editLineVariants}
        />
      </motion.span>

      <span className='text-container'>
        {t('translation:account.settings.details.edit')}
      </span>
    </button>
  );
}
