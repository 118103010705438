/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useState } from 'react';

interface IRedirectUrlContext {
  path: string;
  setRedirect: (redirectPath: string) => void;
}

interface Props {
  children: JSX.Element;
}

const RedirectUrlContext = createContext<IRedirectUrlContext>({
  path: '',
  setRedirect: null,
});

const useProvideRedirectUrl = (): IRedirectUrlContext => {
  const [path, setPath] = useState<string>('');

  const setRedirect = (redirectPath: string): void => {
    setPath(redirectPath);
  };

  return {
    path,
    setRedirect,
  };
};

export const useRedirectUrl = (): IRedirectUrlContext => useContext(RedirectUrlContext);

export function ProvideRedirectUrl({ children }: Props): JSX.Element {
  const redirect = useProvideRedirectUrl();

  return (
    <RedirectUrlContext.Provider value={redirect}>
      { children }
    </RedirectUrlContext.Provider>
  );
}
