/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

export enum PromotionStep {
  Description = 0,
  Platforms,
  Sync,
  Confirmation,
  Redeemed,
}

export const PROMOCODE_STEPS = [
  PromotionStep.Description,
  PromotionStep.Platforms,
  PromotionStep.Sync,
  PromotionStep.Redeemed,
];

export const DIRECT_STEPS = [
  PromotionStep.Description,
  PromotionStep.Platforms,
  PromotionStep.Sync,
  PromotionStep.Confirmation,
  PromotionStep.Redeemed,
];

export const isRequirementFulfillmentStep = (step: PromotionStep): boolean => step === PromotionStep.Platforms
  || step === PromotionStep.Sync
  || step === PromotionStep.Confirmation;
