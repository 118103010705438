import React from 'react';
import { Reward } from '../../account/promotion/Promotion.model';
import rewardImgPlaceholder from '../../_assets/bhvr-footer.png';

interface Props {
  rewards: Reward[]
}

export function RewardImageList({ rewards }: Props): JSX.Element {
  return (
    <div className='mkt-consent-reward-image-list'>
      {rewards.map((reward) => (
        <img
          key={reward.displayName}
          src={process.env.NODE_ENV === 'development' ? rewardImgPlaceholder : reward.pictureURL}
          alt={reward.displayName}
          className='mkt-consent-reward-image-list--img'
        />
      ))}
    </div>
  );
}
