import React, { useEffect, useState } from 'react';
import { Close, Delete } from '@mui/icons-material';
import lodashSet from 'lodash.set';
import get from 'lodash.get';
import { set } from '../utils/set';

import { ComponentReference,
  ComponentProps,
  ArrayProp,
  PropType,
  PagesContent,
  FormValidationError,
  FieldError,
  PageComponent, Diff } from '../Config.model';
import { ActionButton } from '../buttons/ActionButton';
import { ArrayProperty, Definition } from '../DefinitionProperty.model';
import { Modal } from './Modal';
import { ComponentInput, ComponentInputProps } from '../admin/ComponentInput';
import { ToggleButtonCustom } from '../buttons/ToggleButton';
import { Labels } from '../admin/Labels';

export type ConfirmEditFunction = (
  newProps: ComponentProps,
  newStateEnabled: boolean,
  diff: Diff,
  deleted: Diff
) => void;

interface ModalAdminProps {
  pagesContent: PagesContent,
  page: string,
  selectedCpnRef: ComponentReference;
  selectedCpnProps: ComponentProps;
  selectedCpnDef: Definition;
  selectedCpn?: PageComponent;
  isOpen: boolean;
  validationErrors?: FormValidationError;
  toggleEditModal: () => void;
  confirm: ConfirmEditFunction,
  removeComponent: () => void,
  getCustomInput: (props: ComponentInputProps) => JSX.Element,
  onEdit?: (values: unknown) => void
  labels: Labels
}

export function ModalEditContent({
  pagesContent,
  page,
  selectedCpnRef,
  selectedCpnProps,
  selectedCpn,
  selectedCpnDef,
  isOpen,
  validationErrors,
  toggleEditModal,
  confirm,
  removeComponent,
  getCustomInput,
  onEdit,
  labels,
}: ModalAdminProps): JSX.Element {
  const [currentProps, setCurrentProps] = useState<ComponentProps>(selectedCpnProps);
  const [componentEnabled, setComponentEnabled] = useState<boolean | undefined>(undefined);
  const [changes, setChanges] = useState<Diff>({});
  const [deleted, setDeleted] = useState<Diff>({});

  const onChange = (name: string | Array<string | number>, value: PropType): void => {
    const path = Array.isArray(name) ? name : [name];
    const oldValue = get(currentProps, path);
    let newProps: ComponentProps = { ...currentProps };
    newProps = lodashSet(newProps, path, value);

    if (process.env.NODE_ENV === 'development') {
      console.debug('[onChange]:', { oldValue, value, path, currentProps });
    }

    if (Array.isArray(value) && Array.isArray(oldValue) && !value.length) {
      // We remove all the value in the array, we can remove all the entry in gridly
      setDeleted(set(deleted, ['props', ...path], []) as Diff);
    } else {
      setChanges(set(changes, ['props', ...path], value) as Diff);
    }
    setCurrentProps(newProps);

    if (onEdit) {
      onEdit(newProps);
    }
  };

  const addElement = (name: string): void => {
    const arrayCpnDef = selectedCpnDef[name] as ArrayProperty;
    const newProps = { ...currentProps };
    const newElement = {} as Record<string, string | number | boolean>;
    Object.keys(arrayCpnDef.elements).forEach((x: string) => {
      newElement[x] = undefined;
    });
    const arrayProp = newProps[name as keyof ComponentProps];
    (arrayProp as ArrayProp).push(newElement);

    setChanges(set({ ...changes }, ['props', name], arrayProp) as Diff);

    setCurrentProps(newProps);
  };

  const removeElement = (name: string, index: number): void => {
    const newProps = { ...currentProps };
    const arrayProp = newProps[name as keyof ComponentProps] as ArrayProp;

    arrayProp.splice(index);

    setChanges(set({ ...changes }, ['props', name], arrayProp) as Diff);

    setCurrentProps(newProps);
  };

  const onEnabledChange = (): void => {
    const newValue = !componentEnabled;

    setChanges(set({ ...changes }, 'enabled', newValue) as Diff);

    setComponentEnabled(newValue);
  };

  const handleClose = (): void => {
    setCurrentProps(selectedCpnProps);
    setComponentEnabled(selectedCpn?.enabled);
    toggleEditModal();
  };

  // TODO: bad pattern to fix
  useEffect(() => {
    setCurrentProps(selectedCpnProps);
    setChanges({});// Reset the changes when we change components
  }, [selectedCpnProps]);

  useEffect(() => {
    if (selectedCpn) {
      setComponentEnabled(selectedCpn.enabled);
    }
  }, [selectedCpn]);

  return (
    <Modal
      show={isOpen && !!selectedCpn}
      textConfirm={labels.apply}
      textClose={labels.cancel}
      confirm={() => confirm(currentProps, componentEnabled, changes, deleted)}
      close={handleClose}
      disabled={!!validationErrors?.fields.length}
    >
      <ToggleButtonCustom
        enabled={componentEnabled}
        onStateChange={onEnabledChange}
        label={labels.componentEnable || undefined}
      />
      <h2>{`Editting ${selectedCpnRef?.name}`}</h2>
      {currentProps && (
        <div className='Admin'>
          {selectedCpnDef && Object.keys(selectedCpnDef).map((k, i) => (
            <div className='py-3' key={i}>
              <ComponentInput
                key={i}
                page={page}
                pagesContent={pagesContent}
                propertyDef={selectedCpnDef[k]}
                name={k}
                path={[k]}
                value={currentProps[k as keyof ComponentProps] as string}
                onChange={onChange}
                addElement={addElement}
                removeElement={removeElement}
                component={selectedCpnProps}
                componentRef={selectedCpnRef}
                getCustomInput={getCustomInput}
                error={validationErrors?.fields?.find((field) => field.name === k) as FieldError}
              />
            </div>
          ))}
        </div>
      )}
      <ActionButton extraClass='btn btn-secondary btn-block my-3' action={() => removeComponent()}>
        <Delete className='icon danger' />
        { labels.delete }
      </ActionButton>
      <Close
        onClick={handleClose}
        style={{ cursor: 'pointer',
          position: 'absolute',
          top: '-1rem',
          right: '-1rem' }}
      />
    </Modal>
  );
}

export default ModalEditContent;
