/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import axios, { Method, AxiosRequestConfig, AxiosResponse, AxiosRequestHeaders } from 'axios';

type AuthenticationHeader = {
  Authorization?: string
};

type Token = {
  jwt: string,
  expirationDate: number
};

interface APIError {
  response: AxiosResponse;
}

type Headers = AxiosRequestHeaders & AuthenticationHeader;

interface RequestOptions<D = unknown> extends AxiosRequestConfig<D> {
  headers?: Headers,
  disableRedirect?: boolean,
}

export function setToken(jwt: string, expiresIn: string): void {
  localStorage.setItem('token', jwt);
  localStorage.setItem('expirationDate', `${Date.now() + (parseInt(expiresIn, 10) * 1000)}`);
}

export function getToken(): Token {
  const jwt = localStorage.getItem('token');

  if (jwt) {
    const expirationDate = parseInt(localStorage.getItem('expirationDate'), 10);

    return { jwt, expirationDate };
  }

  return null;
}

function clearToken(): void {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
}

function getAuthenticationheader(): AuthenticationHeader {
  const token = getToken();
  return {
    Authorization: `Bearer ${token?.jwt}`,
  };
}

function clearTokenAndredirectToLogin():void {
  clearToken();
  if (!window.location.pathname.startsWith('/auth') && window.location.pathname !== '/forgot-password') {
    window.location.pathname = '/';
  }
}

export async function request<T>(method: Method, url: string, {
  data = {},
  headers = {},
  withCredentials = true,
  disableRedirect = false,
}: RequestOptions): Promise<T> {
  try {
    const resp = await axios.request({
      method,
      url,
      headers: { ...getAuthenticationheader(), ...headers },
      withCredentials,
      data,
    });

    return resp.data;
  } catch (err) {
    const error = (err as APIError).response;
    if (!disableRedirect && error?.status === 401) {
      clearTokenAndredirectToLogin();
    }
    throw error?.data;
  }
}

export async function get<T>(url: string, options?: RequestOptions<never>): Promise<T> {
  return request<T>('GET', url, options || {});
}

export async function post<T>(url: string, options?: RequestOptions): Promise<T> {
  return request<T>('POST', url, options || {});
}

export async function del<T>(url: string, options?: RequestOptions<never>): Promise<T> {
  return request<T>('DELETE', url, options || {});
}

export async function patch<T>(url: string, options?: RequestOptions): Promise<T> {
  return request<T>('PATCH', url, options || {});
}

export async function put<T>(url: string, options?: RequestOptions): Promise<T> {
  return request<T>('PUT', url, options || {});
}
