import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Promotion, PromotionStatus } from '../account/promotion/Promotion.model';
import { RewardModal } from './reward-modal/RewardModal';
import { useMktConsentPromotion } from '../contexts/MktConsentPromotionContext';
import { useAuth } from '../authentication/AuthenticationContext';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { ProviderLinkModal } from './ABTesting/ProviderLinkModal';
import { post } from '../utils/Request';
import { GameIds } from '../account/my-games/MyGames.model';
import { ProviderErrorModal } from './ABTesting/ProviderErrorModal';
import { ABTestingError, DecodedToken, isABTestingError } from './MarketingConsent.model';
import { useConfig } from '../contexts/ConfigContext';

export function MktConsentPromotionPopup(): JSX.Element {
  const auth = useAuth();
  const config = useConfig();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { promotions, mandatoryLCApproved, getPromotions } = useMktConsentPromotion();
  const [decodedToken, setDecodedToken] = useState<DecodedToken | null>(null);
  const [displayedPromotion, setDisplayedPromotion] = useState<Promotion | null>(null);
  const [providerError, setProviderError] = useState<ABTestingError | null>(null);

  const availablePromos = promotions.filter((promo) => promo.userData?.status !== PromotionStatus.Claimed);
  const latestAvailablePromo = availablePromos.length ? availablePromos[availablePromos.length - 1] : null;

  const localStorageValue = localStorage.getItem('mktPopupRewardDismissed') || 'false';
  const userDismissedPopup = JSON.parse(localStorageValue);
  const sessionToken = sessionStorage.getItem('mktToken');

  const isABTestingModalDisplayed = !!decodedToken;
  const ABTestingControlGroupId = config.serverConfig?.marketingConsent?.controlGroup;

  const providerAccount = auth.user?.accounts.find((acc) => acc.type === decodedToken?.providerName) || null;

  const closeModalHandler = (rewardClaimed: boolean): void => {
    if (!rewardClaimed) {
      localStorage.setItem('mktPopupRewardDismissed', 'true');
    }
    setDisplayedPromotion(null);
  };

  const providerLinkModalCloseHandler = (): void => {
    sessionStorage.removeItem('mktToken');
    setDecodedToken(null);
  };

  const providerLinkModalNextHandler = async (): Promise<void> => {
    const token = sessionStorage.getItem('mktToken');
    if (token) {
      try {
        await getPromotions(token, true);
        providerLinkModalCloseHandler();
      } catch (e) {
        if (isABTestingError(e)) {
          setProviderError(e);
        } else {
          snackbar.addErrorMessage('UnknownError');
        }
      }
    }
  };

  const providerErrorModalCloseHandler = (): void => {
    setProviderError(null);
  };

  const decodeToken = async (token: string): Promise<void> => {
    try {
      const tokenInfo = await post<DecodedToken>(
        `${config?.clientConfig?.host}/promotions/marketing-consent/decode`,
        { data: { token, gameId: GameIds.DBD } },
      );
      if (tokenInfo.rewardId === ABTestingControlGroupId) {
        // Redirect AB testing Control Group to communication preferences and update splintered state
        history.replace('/account/communication-preferences');
        await getPromotions(token);
        sessionStorage.removeItem('mktToken');
      } else {
        setDecodedToken(tokenInfo);
      }
    } catch {
      setDecodedToken(null);
    }
  };

  useEffect(() => {
    if (!auth.user) {
      setDisplayedPromotion(null);
    }

    if (auth.user && sessionToken && !decodedToken) {
      decodeToken(sessionToken);
    }
  }, [auth.user?.email, sessionToken, decodedToken]);

  useEffect(() => {
    if (latestAvailablePromo && !displayedPromotion && !userDismissedPopup) {
      setDisplayedPromotion(latestAvailablePromo);
    }
    if (latestAvailablePromo && userDismissedPopup && !isABTestingModalDisplayed) {
      snackbar.addInfoMessage(
        'promotion.marketing-consent.snackbar-reminder',
      );
    }
  }, [latestAvailablePromo?.promotionData.promotionId, displayedPromotion]);

  if (!auth.user || !mandatoryLCApproved) {
    return <></>;
  }

  return (
    <>
      {isABTestingModalDisplayed && (
        <>
          <ProviderLinkModal
            show
            decodedToken={decodedToken}
            closeHandler={providerLinkModalCloseHandler}
            nextHandler={providerLinkModalNextHandler}
          />
          <ProviderErrorModal
            isOpen={!!providerError}
            error={providerError}
            decodedToken={decodedToken}
            providerAccount={providerAccount}
            close={providerErrorModalCloseHandler}
          />
        </>
      )}

      {displayedPromotion && !isABTestingModalDisplayed && (
        <RewardModal
          show
          promotion={displayedPromotion.promotionData}
          closeHandler={closeModalHandler}
        />
      )}
    </>
  );
}
