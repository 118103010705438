/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import './loading.scss';
import { CircularProgress } from '@mui/material';

export function Loading({ fullscreen = true }: { fullscreen?: boolean }): JSX.Element {
  return (
    <div className='loading' data-fullscreen={fullscreen}>
      <CircularProgress />
    </div>
  );
}
