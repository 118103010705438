/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Modal } from '../../../_shared/modal/Modal';
import { Promotion } from '../Promotion.model';
import { PromotionHeader } from './promotionHeader/PromotionHeader';
import { ClaimPromotion } from './ClaimPromotion';

interface Props {
  close: () => void;
  isOpen: boolean;
  promotion: Promotion;
}

export function ClaimPromotionDialog({ close, isOpen, promotion }: Props): JSX.Element {
  return (
    <Modal
      show={isOpen}
      maxWidth={650}
      data-cy='claim-promo-modal'
      close={close}
      customHeader={<PromotionHeader promotion={promotion} />}
      mobileFullScreen
      disabledBackDrop
    >
      <ClaimPromotion promotion={promotion} cancel={close} />
    </Modal>
  );
}
