/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { GameIds } from '../my-games/MyGames.model';
import { RedeemingGameProvider } from '../providers/ProviderEnum';

export enum PromotionStatus {
  Claimed = 'claimed',
  Claimable = 'claimable',
  InProgress = 'inProgress',
}

export interface Reward {
  displayName: string;
  pictureURL: string;
  gameSpecificData: {
    universalStatus: string;
    rarity: string;
  };
}

export enum RewardDeliveryType {
  Promocode = 'PROMOCODE',
  Direct = 'DIRECT',
}

type PromotionType = 'ACHIEVEMENT' | 'OWNERSHIP' | 'MARKETING-CONSENT';

type PictureUrl = {
  mobile: string
  desktop: string
};

export interface PromotionData {
  combinedRewardPictureUrl?: PictureUrl
  marketingConsents?: {
    krakenRewardIds: string[],
    requiredApprovals: string[],
  };
  requiredGameId: GameIds
  requiredGameIds?: GameIds[];
  promotionId: string;
  relatedToGameId: GameIds;
  redeemableOnPlatformIds: RedeemingGameProvider[];
  rewardDeliveryType: RewardDeliveryType;
  type: PromotionType;
  rewards: Reward[];
  defaultPictureUrl: PictureUrl,
  title: string
}

export interface UserData {
  status: PromotionStatus;
  promocode: { code: string };
}
export interface Promotion {
  promotionData: PromotionData;
  userData?: UserData;
}

export interface GamePromotions {
  requiredGameId: string;
  promotions: Promotion[];
}
