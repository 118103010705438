import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { ValidateImageSize, containsString, convertBase64 } from '../utils/ComponentsManager';
import { FieldError } from '../Config.model';

interface UploadImageProps {
  maxWidth: number,
  maxHeight: number,
  maxWeight: number,
  name: string,
  error?: FieldError | undefined,
  onChange: (name: string | Array<string | number>, value: Record<string, string>) => void,
}

// TODO: extract the logic of the image updload
export function UploadImage({ maxWidth, maxHeight, name, error, maxWeight, onChange }: UploadImageProps): JSX.Element {
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const invalidFileName = containsString(file?.name, '\\');
    const mustValidate = (maxHeight && maxWidth) || maxWeight;
    setHasValidationError(invalidFileName);

    try {
      if (mustValidate) {
        await ValidateImageSize(file, maxWidth, maxHeight, maxWeight);
      }

      const base64 = await convertBase64(file);
      onChange(name, { src: base64, type: 'image' });
    } catch (err) {
      e.target.value = null;
    }
  };

  return (
    <>
      <small>
        {`Upload ${name}`}
      </small>
      <small> (PNG, JPG, WEBP) </small>
      <Button variant='contained' component='label'>
        <input accept='.png, .jpeg, .jpg, .webp' type='file' id='image_uploads' onChange={handleChangeImage} />
      </Button>

      { maxHeight && maxWidth && (
        <small className='text-danger'>
          {`Maximum size: ${maxWidth} px x ${maxHeight} px`}
        </small>
      )}

      {error && (
        <Box sx={{ color: '#C00' }}>
          {error?.errorMessage}
        </Box>
      )}
      {hasValidationError && (
        <Box sx={{ color: '#C00' }}>
          The uploaded Image is Not valid, Please verify the file name. (it should not contain a slash or a backslash)
        </Box>
      )}
    </>
  );
}
