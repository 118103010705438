/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ModalFlowContainer } from '../../modal/modal-flow-container/ModalFlowContainer';
import { ModalFlowContent } from '../../modal/modal-flow-container/ModalFlowContent';
import { ModalFooter } from '../../modal/ModalFooter';
import { TranslationLink } from '../../translation/TranslationLink';
import { SingleCheckbox } from '../../form/SingleCheckbox';
import { post } from '../../../utils/Request';
import { RedeemingGameProvider } from '../../../account/providers/ProviderEnum';
import { GameIds } from '../../../account/my-games/MyGames.model';
import { useConfig } from '../../../contexts/ConfigContext';

interface Props {
  next: (success: boolean) => void;
  cancel: () => void;
  platform: RedeemingGameProvider;
  gameId: GameIds;
}

export function ConfirmSynchronization({ next, cancel, platform, gameId }: Props): JSX.Element {
  const { t } = useTranslation();
  const config = useConfig();

  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const synchronize = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await post(`${config.clientConfig?.host}/players/me/merge`, {
        data: {
          providerName: platform,
          gameId,
        },
      });
      next(true);
    } catch (e) {
      next(false);
    }
  };

  return (
    <ModalFlowContainer title={t('cross-progression.synchronization.title')}>
      <ModalFlowContent name='cross-progression-confirm-sync'>
        <p className='cross-progression--subtitle'>
          {t('cross-progression.synchronization.description')}
        </p>
        <ol className='synchronization-details'>
          {(t('cross-progression.synchronization.details', { returnObjects: true }) as string[])
            .map((instruction, index) => (
              <li key={index}>
                <Trans
                  i18nKey={instruction}
                  components={{
                    b: <strong />,
                  }}
                />
              </li>
            ))}
        </ol>
        <p>
          <Trans
            i18nKey={t('cross-progression.synchronization.learn-more')}
            components={{
              linkFAQ: <TranslationLink url={t('cross-progression.synchronization.faq-link')} />,
            }}
          />
        </p>
        <SingleCheckbox
          name='sync-confirmation'
          label={t('cross-progression.synchronization.confirmation')}
          isChecked={isChecked}
          onChangeHandler={(newValue: boolean) => setIsChecked(newValue)}
        />
      </ModalFlowContent>
      <ModalFooter
        name='cross-progression'
        nextLabel={t('cross-progression.synchronization.button')}
        nextHandler={synchronize}
        nextIsLoading={isLoading}
        nextDisabled={!isChecked}
        cancelLabel={t('common.cancel')}
        cancelHandler={cancel}
      />
    </ModalFlowContainer>
  );
}
