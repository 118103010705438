declare global {
  interface Window { wtCtx: Record<string, Function>; }
}

type IframeEvent = {
  data: {
    type: string,
    data: any
  }
} & MessageEvent;

class WalkieTalkIframe {
    private target: Window;
    private context: Record<string, Function>;
    private queue = {};
    private initPromise: Promise<any>;
    
    constructor(iframeWindow: Window = undefined, context: Record<string, Function> = undefined) {
      this.target = iframeWindow;
      this.context = context;
    }
  
    randomId() {
      return window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);
    }
  
    getTarget(e: IframeEvent) {
      if (e && e.source) {
        return e.source;
      }
  
      return this.target;
    }
  
    send(type: string, data: any, e: IframeEvent = undefined) {
      const target = this.getTarget(e);
  
      target.postMessage({
        type,
        data
      }, {
        targetOrigin: '*'
      });
    }
  
    functionGenerator(name: string) {
      return (...args: any[]) => {
        const id = this.randomId();
  
        this.send('function', {
          id,
          name,
          args
        });
  
        return new Promise((resolve, reject) => { this.queue[id] = { resolve, reject }; });
      };
    }
  
    initIframeHandler(contextFunctions: string[]): Record<string, Function> {
      window.wtCtx = {};
  
      (contextFunctions || []).forEach(cf => {
        window.wtCtx[cf] = this.functionGenerator(cf);
      });
  
      return window.wtCtx;
    }
  
    async functionCallHandler(e: IframeEvent, data: any) {
      try {
        const resp = await this.context[data.name](...data.args);
  
        this.send('response', {
          id: data.id,
          resp,
          success: true
        }, e);
      } catch (error) {
        this.send('response', {
          id: data.id,
          error,
          success: false
        }, e);
      }
    }
  
    responseHandler(data: any) {
      if (!this.queue[data.id]) {
        console.error('Not in the response queue: ' + data.id);
      }
      if (data.success) {
        this.queue[data.id].resolve(data.resp);
      } else {
        this.queue[data.id].reject(data.error);
      }
  
      this.queue[data.id] = undefined;
    }
  
    initContext() {
      const contextFunctions = Object.keys(this.context);
  
      this.send('init-iframe', contextFunctions);
  
      const receiveMessage = (e: IframeEvent) => {
        if (!e.data) {
          return false;
        }
        switch (e.data.type) {
          case 'function':
            this.functionCallHandler(e, e.data.data);
            break;
        }
      }
  
      window.addEventListener('message', receiveMessage, false);
    }
  
    initIframe() {
      this.initPromise = new Promise(resolve => {
        const receiveMessage = (e: IframeEvent) => {
          if (!e.data) {
            return false;
          }
  
          switch (e.data.type) {
            case 'init-iframe':
              this.target = e.source as Window;
              resolve(this.initIframeHandler(e.data.data));
              break;
            case 'response':
              this.responseHandler(e.data.data);
              break;
          }
        }
  
        window.addEventListener('message', receiveMessage, false);
      });
  
      return this.initPromise;
    }
  
    waitForInit() {
      return this.initPromise;
    }
  };
  
  export default WalkieTalkIframe;