/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useMemo, useState } from 'react';

import { CheckCircle, Redeem } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { Promotion,
  PromotionStatus,
  RewardDeliveryType } from './Promotion.model';
import { PromotionReward } from './PromotionReward';
import { ClaimedCode } from './ClaimedCode';
import { ClaimPromotionDialog } from './claim/ClaimPromotionDialog';
import { formatDate, timeRemaining } from '../../utils/Time';
import { useConfig } from '../../contexts/ConfigContext';
import { GameConfig } from '../../contexts/Config.model';

interface Props {
  promotion: Promotion;
  showButton?: boolean;
}

export function PromotionCard({ promotion, showButton = true }: Props): JSX.Element {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const config = useConfig();

  const now = new Date().toString();

  const [status, setStatus] = useState(null);
  const [requiredGameConfig, setRequiredGameConfig] = useState<GameConfig>(null);
  const [gameReleaseDate, setGameReleaseDate] = useState(null);
  const [open, setOpen] = useState(false);

  const getRequiredGameConfig = (): GameConfig => {
    const requiredGame = promotion.promotionData.requiredGameId;
    return config.serverConfig.gamesConfig.find((game) => game.id === requiredGame);
  };

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const displayClaimedCode = useMemo(() => promotion.promotionData?.rewardDeliveryType === RewardDeliveryType.Promocode
    && status === PromotionStatus.Claimed
    && !open, [promotion, status, open]);

  const displayClaimedStatus = useMemo(() => promotion.promotionData?.rewardDeliveryType === RewardDeliveryType.Direct
    && status === PromotionStatus.Claimed, [promotion, status, open]);

  const displayClaimable = useMemo(() => (showButton
      && (status !== PromotionStatus.Claimed || (status === PromotionStatus.Claimed && open)))
      && promotion.promotionData, [promotion, status, open]);

  const isInTeasingPhase = useMemo(
    () => requiredGameConfig?.isInPreorderPhase && !requiredGameConfig?.released,
    [requiredGameConfig],
  );

  useEffect(() => {
    if (promotion) {
      const gameConfig = getRequiredGameConfig();

      if (gameConfig) {
        setRequiredGameConfig(gameConfig);

        if (gameConfig.releaseDate) {
          setGameReleaseDate(formatDate(gameConfig.releaseDate, locale));
        }
      }

      if (promotion.userData) {
        setStatus(promotion.userData.status);
      }
    }
  }, [promotion]);

  return (
    <>

      <div
        className='promotion-container'
        data-cy={`promo-${promotion.promotionData.requiredGameId}-${promotion.promotionData.rewardDeliveryType}`}
      >
        {
          (isInTeasingPhase) ? (
            <span className='availability-badge'>
              { gameReleaseDate ? t('promotion.available-in', { time: timeRemaining(now, gameReleaseDate) })
                : t('promotion.available-soon')}
            </span>
          ) : <></>
        }

        <div className='rewards-container'>
          <PromotionReward promotionClaimed={displayClaimedStatus} promotion={promotion} />
        </div>

        <div className={`promotion-footer ${displayClaimedCode ? 'reward-code' : ''}`}>

          <h3 className='promotion-title'>
            { displayClaimedStatus ? (<CheckCircle />) : <></> }
            { t(promotion.promotionData.title) }
          </h3>

          { displayClaimedCode ? <ClaimedCode userData={promotion.userData} /> : <></>}

          { displayClaimable ? (
            <div className='reward-button'>
              <button
                type='button'
                data-cy='claim-reward-btn'
                className={`btn btn-primary-game ${status === PromotionStatus.InProgress ? 'inProgress' : ''}`}
                onClick={handleClickOpen}
                disabled={isInTeasingPhase}
              >
                {status !== PromotionStatus.InProgress
                  ? (
                    <>
                      <Redeem />
                      {t('button.claim-now')}
                    </>
                  )
                  : <div className='loader'><CircularProgress /></div>}
              </button>
              <ClaimPromotionDialog
                isOpen={open}
                close={handleClose}
                promotion={promotion}
              />
            </div>
          ) : <></>}
        </div>
      </div>
      {
        isInTeasingPhase ? (
          <p className='promotion-container promotion-legal'>
            {t('promotion.legal', {
              requiredGame: t(`games.${requiredGameConfig.id}`),
              relatedToGameId: t(`games.${promotion.promotionData.relatedToGameId}`),
              date: formatDate(
                requiredGameConfig.releaseDate,
                locale,
                { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
              ),
            })}
          </p>
        ) : (<></>)
      }
    </>
  );
}
