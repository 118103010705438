/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ProviderName } from './ProviderEnum';
import { getState } from '../../utils/Providers';
import { useConfig } from '../../contexts/ConfigContext';

export function ProviderRedirect(): JSX.Element {
  const history = useHistory();
  const config = useConfig();
  const { provider }: { provider: ProviderName } = useParams();

  useEffect(() => {
    const providerConfig = config.clientConfig.providers[provider];
    // To make window.postMessage work on local environment
    if ((process.env.NODE_ENV !== 'development' && !window.opener) || !providerConfig) {
      history.push('/account');
      return;
    }

    let params;
    if (providerConfig.redirectParamMethod === 'searchParam') {
      params = new URL(window.location.href).searchParams;
    }
    if (providerConfig.redirectParamMethod === 'hash') {
      const stringParams = window.location.hash.split('#')[1];
      params = new URLSearchParams(stringParams);
    }

    const redirectUrlState = params.get('state');
    const storageState = getState(provider);

    if (storageState && redirectUrlState !== storageState) {
      window.opener.postMessage({
        type: provider,
        error: 'State mismatch',
      });
    } else {
      const providerData = { code: params.get(providerConfig.redirectParamName) };
      window.opener.postMessage({ type: provider, providerData }, config.clientConfig.origin);
    }

    window.close();
  }, []);

  return (
    <div className='container'>
      <div className='auth-container'>
        <div className='auth-card'>
          <div className='row'>
            <div className='col text-center'>
              <p>Redirect...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
