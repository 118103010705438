import { AddBox } from '@mui/icons-material';
import React, { Fragment } from 'react';
import { motion } from 'framer-motion';
import { ComponentReference, PagesContent, PageComponent, AllComponentsType, Diffs } from '../Config.model';
import { ActionButton } from '../buttons/ActionButton';
import { EditControls } from '../admin/EditControls';
import { orderComponents } from '../utils/ComponentsManager';

interface ChildComponentProps<T> {
  context: T,
  admin?: boolean,
  isSaving: boolean,
  allComponents: AllComponentsType,
  component: PageComponent,
  componentRef: ComponentReference,
  toggleEditModal: (componentRef?: ComponentReference) => void
  toggleAddModal: (componentRef?: ComponentReference) => void,
  onUpdate: (newContent: PagesContent, find?: boolean) => void,
  pagesContent: PagesContent,
  reverseMode?: boolean,
  page: string,
  setDiff: (diff: Diffs) => void,
  diff: Diffs
}

export function ChildComponents<T>(props: ChildComponentProps<T>): JSX.Element {
  const { isSaving, onUpdate, pagesContent, page } = props;
  return (
    <>
      {(props.reverseMode
        ? props.component.children.slice().reverse()
        : orderComponents(props.component.order, props.component.children))?.map((child) => {
        if (!child) return null;
        const ChildComponent = props.allComponents[child.name]?.default;
        const childRef: ComponentReference = { id: child.id, name: child.name, parent: props.componentRef };

        if (!ChildComponent) return null;

        return (
          <Fragment key={`editable-${props.component.name}-${child.id}`}>
            <motion.div layout className='is-editable' data-enabled={child.enabled}>
              <ChildComponent {...child.props} enabled={child.enabled} admin context={props.context} />

              <EditControls
                component={child}
                componentRef={childRef}
                toggleEditModal={props.toggleEditModal}
                isSaving={isSaving}
                onUpdate={onUpdate}
                pagesContent={pagesContent}
                page={page}
                setDiff={props.setDiff}
                diff={props.diff}
              />
            </motion.div>
            {!props.reverseMode && (
              <ActionButton disabled={isSaving} extraClass='add my-4' action={() => props.toggleAddModal(childRef)}>
                <AddBox color='primary' />
                Add
              </ActionButton>
            )}
          </Fragment>
        );
      })}
    </>
  );
}
