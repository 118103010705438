/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import ComponentsManager, { AllComponentsType, Diffs, PageComponent, PagesContent } from '@bhvr/components-manager';
import { useTranslation } from 'react-i18next';
import { flatten } from 'flat';
import cloneDeep from 'lodash.clonedeep';

import { useSnackbar } from '../../snackbar/SnackbarContext';
import { GameIds } from '../../account/my-games/MyGames.model';
import { usePageActions } from '../../contexts/PageActionsContext';
import { convertTranslationProps, generateTranslationsStrings } from '../../utils/ComponentsManager';
import { useConfig } from '../../contexts/ConfigContext';

export function ComponentsManagerWrapper({ gameId, pageComponents }:
{ gameId: GameIds, pageComponents: AllComponentsType }): JSX.Element {
  const appConfig = useConfig();
  const { pagesContent } = appConfig;
  const pageActions = usePageActions();
  const snackbar = useSnackbar();
  const { t } = useTranslation('generated');

  const [currentPagesContent, setcurrentPagesContent] = useState(pagesContent.pages[gameId]);

  const onSave = async (newPagesContent: PagesContent, diff: Diffs): Promise<void> => {
    try {
      const { changes = {}, deleted = {} } = diff;
      const recordsToDelete = deleted && Object.keys(flatten(deleted));

      if (Object.keys(changes).length === 0 && recordsToDelete.length === 0) {
        snackbar.addErrorMessage('EditorNoChangesError');
        throw new Error('Nothing to save');
      }
      const translations = generateTranslationsStrings(
        { changes,
          pagesContent: newPagesContent,
          gameId },
      );

      const changesToSend = Object.keys(changes).length > 0 ? { [gameId]: changes } : {};

      await pageActions.saveContent(
        { changes: changesToSend,
          deleted: recordsToDelete?.map((r: string) => `${gameId}.${r}`),
          translations },
      );
      snackbar.addSuccessMessage('editor.update.success');
      setcurrentPagesContent(newPagesContent);
    } catch (error) {
      console.error(error);
      snackbar.addErrorMessage('EditorGenericError');
    }
  };

  const filterComponents = (components: PageComponent[]): PageComponent[] => {
    const filteredComponents = components.reduce((acc: PageComponent[], component) => {
      if (component && !component?.children) {
        acc.push(component);
      } else if (component && component.children) {
        const filteredChildren = filterComponents(component.children);
        const filteredComponent = component;
        filteredComponent.children = filteredChildren;
        acc.push(filteredComponent);
      }
      return acc;
    }, []);

    return filteredComponents;
  };

  const formatPagesContent = (pageContent: PagesContent): PagesContent => {
    const filteredPC = cloneDeep(pageContent);
    const filteredComponents = filterComponents(filteredPC[pageActions.pageName].components);
    filteredPC[pageActions.pageName].components = filteredComponents;
    return filteredPC;
  };

  return (
    <ComponentsManager
      allComponents={pageComponents}
      configId={gameId}
      pagesContent={formatPagesContent(currentPagesContent)}
      isSaving={pageActions.isSaving}
      page={pageActions.pageName}
      propsTransformer={convertTranslationProps(t, gameId, pageActions.pageName)}
      exportFile={(gc: PagesContent) => pageActions.exportFile(gc, pageComponents)}
      isAdminMode={!!appConfig.pagesContent.version}
      onSave={onSave}
    />
  );
}
