/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../AuthenticationContext';
import { HomepageHeroFeature } from './HomepageHeroFeature';

export function HomepageHero(): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuth();

  const signUpBhvr = (): void => { auth.updateVisibleModal('registerOptions'); };
  const signInBhvr = (): void => { auth.updateVisibleModal('loginOptions'); };

  return (
    <div className='homepage-hero-container'>
      <div className='homepage-hero-content'>
        <div className='homepage-hero-intro'>
          <h1 data-cy='homepage-hero-title'>{t('home.hero.title')}</h1>
          <p className='py-3'>{t('home.hero.subtitle')}</p>
          <div className='auth-buttons'>
            <button type='button' onClick={signUpBhvr} className='btn btn-primary-game' data-cy='homepage-signup-btn'>
              {t('translation:button.join-now')}
            </button>
            <button type='button' onClick={signInBhvr} className='btn btn-secondary' data-cy='homepage-signin-btn'>
              {t('translation:button.sign-in')}
            </button>
          </div>
        </div>

        <div className='homepage-hero-features'>
          <h2>{t('home.hero.features.title')}</h2>
          <div className='features'>
            <HomepageHeroFeature name='account-linking' />
            <HomepageHeroFeature name='cross-progression' />
            <HomepageHeroFeature name='exclusive-rewards' />
          </div>
        </div>
      </div>
    </div>
  );
}
