import { ApprovalRequest } from '../authentication/Authentication.model';
import { LegalContentModel } from '../legal-contents/LegalContent.model';
import { getChangedFormFields } from './Form';

type SpecificLegalContentModel<LegalContentId> = Omit<LegalContentModel, 'id'> & { id: LegalContentId };

export function getApprovalsRequestsFromForm<ApprovalId extends string>(
  legalContents: SpecificLegalContentModel<ApprovalId>[],
  initialApprovalFormValues: Record<ApprovalId, boolean>,
  approvalFormValues: Record<ApprovalId, boolean>,
): ApprovalRequest[] {
  const changedApprovals = getChangedFormFields(initialApprovalFormValues, approvalFormValues);

  const approvalsRequests: ApprovalRequest[] = legalContents.reduce((
    acc: ApprovalRequest[],
    legalContent,
  ) => {
    const { id, version } = legalContent;
    if (initialApprovalFormValues[id] !== approvalFormValues[id] && changedApprovals.includes(id)) {
      acc.push({ legalContentId: id, approved: approvalFormValues[id], version });
    }
    return acc;
  }, []);

  return approvalsRequests;
}
