/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { UserData } from './Promotion.model';

interface Props {
  userData: UserData;
}

export function ClaimedCode({ userData }: Props): JSX.Element {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const copy = (): void => {
    navigator.clipboard.writeText(userData?.promocode.code);
    setIsCopied(true);
  };

  return (
    <div className='reward-claimed'>
      <input
        readOnly
        className='promoCode-input form-control bg-dark text-white input-lg'
        type='text'
        value={userData.promocode.code}
        id='promoCode-input'
        data-cy='reward-code-text'
      />

      {isCopied
            && (
              <button
                className='copy-btn btn btn-primary-game copied'
                type='button'
                data-cy='reward-copy'
                onClick={copy}
              >
                <ContentCopyIcon />
                {t('button.copied')}
              </button>
            )}

      {!isCopied
            && (
              <button
                className='copy-btn btn btn-primary-game'
                type='button'
                data-cy='reward-copy'
                onClick={copy}
              >
                <ContentCopyIcon />
                {t('button.copy')}
              </button>
            )}

    </div>
  );
}
