/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { ProviderConfirmationModal } from '../ProviderConfirmationModal';
import { Popup } from '../../../../utils/Popup';
import { ProviderName } from '../../ProviderEnum';
import { RequestError } from '../../../../Error.model';
import { AuthenticationMethod, GameAccount } from '../../../../authentication/Authentication.model';
import { useSnackbar } from '../../../../snackbar/SnackbarContext';
import { ProviderLinkWrapper } from './ProviderLinkWrapper';

interface Props {
  provider: ProviderName;
  providerAuthUrl: string;
  isAuthProvider: boolean;
  setError: (e: RequestError | null) => void;
  providerAccount?: GameAccount | AuthenticationMethod;
  customLabel?: string;
  buttonStyle?: string;
  className?: string;
  useToken?: boolean
}

export function ProviderLinkButton(
  { provider,
    providerAuthUrl,
    isAuthProvider,
    setError,
    providerAccount,
    customLabel,
    buttonStyle,
    className,
    useToken }: Props,
): JSX.Element {
  const { t } = useTranslation(['translation', 'error']);
  const snackbar = useSnackbar();

  const [showConfirmModalProvider, setShowConfirmModalProvider] = useState(false);

  const openLinkModal = (): void => setShowConfirmModalProvider(true);

  const closeLinkModal = (): void => {
    setShowConfirmModalProvider(false);

    ReactGA.event({
      category: 'User',
      action: `confirmation-linking-cancel-${provider}`,
    });
  };

  const confirmLinkModal = (): void => {
    setShowConfirmModalProvider(false);

    ReactGA.event({
      category: 'User',
      action: `confirmation-linking-confirm-${provider}`,
    });

    if (providerAuthUrl) {
      Popup.open(providerAuthUrl, `${provider}_auth`);
    } else {
      snackbar.addErrorMessage('UnknownError');
    }
  };

  return (
    <ProviderLinkWrapper
      provider={provider}
      isAuthProvider={isAuthProvider}
      errorHandler={setError}
      providerAccount={providerAccount}
      useToken={useToken}
    >
      <button
        type='button'
        onClick={openLinkModal}
        className={`btn ${buttonStyle || 'btn-primary-game'} btn-block ${className}`}
        data-cy={`${provider}-link-button`}
      >
        {customLabel || t('translation:account.my-account.link')}
      </button>

      <ProviderConfirmationModal
        close={closeLinkModal}
        confirm={confirmLinkModal}
        show={showConfirmModalProvider}
        message={(
          <>
            <p>
              {t('translation:account.provider.confirm-provider', {
                provider: t(`translation:${provider}`),
              })}
            </p>
            <p>
              {t('translation:account.provider.legal-confirm-provider-18-1')}
              <a
                className='font-weight-bold'
                target='_blank'
                rel='noopener noreferrer'
                href={t('translation:account.provider.legal-confirm-provider-18-link1')}
              >
                {t('translation:account.provider.legal-confirm-provider-18-2')}
              </a>
              {t('translation:account.provider.legal-confirm-provider-18-3')}
              <a
                className='font-weight-bold'
                target='_blank'
                rel='noopener noreferrer'
                href={t('translation:account.provider.legal-confirm-provider-18-link2')}
              >
                {t('translation:account.provider.legal-confirm-provider-18-4')}
              </a>
            </p>
          </>
        )}
      />
    </ProviderLinkWrapper>
  );
}
