/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { CheckboxInput } from '../_shared/form/CheckboxInput';
import { SubmitButton } from '../_shared/form/SubmitButton';
import { Modal } from '../_shared/modal/Modal';
import { useAuth } from '../authentication/AuthenticationContext';
import { get } from '../utils/Request';
import { Approvals } from '../authentication/Authentication.model';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { TranslationLink } from '../_shared/translation/TranslationLink';
import { useMktConsentPromotion } from '../contexts/MktConsentPromotionContext';
import { useConfig } from '../contexts/ConfigContext';
import { LegalContent } from '../contexts/Config.model';

type FormValues = {
  'privacy-policy': boolean;
  'terms-of-use': boolean;
};

export function MandatoryLegalContentsModal(): JSX.Element {
  const formikRef = useRef<FormikProps<FormValues>>();
  const { t } = useTranslation();
  const auth = useAuth();
  const config = useConfig();
  const snackbar = useSnackbar();
  const { updateMandatoryLCApproved } = useMktConsentPromotion();
  const [mandatoryLegalContentsSchema, setMandatoryLegalContentsSchema] = useState<Yup.AnyObjectSchema>();
  const [mandatoryLegalContents, setMandatoryLegalContents] = useState<LegalContent[]>([]);
  const [initialValues, setInitialValues] = useState<FormValues>({
    'privacy-policy': false,
    'terms-of-use': false,
  });

  const setYupSchema = (): void => {
    const fields = {
      privacyPolicy: Yup.boolean()
        .oneOf([true]),
      termsOfUse: Yup.boolean()
        .oneOf([true]),
    };

    setMandatoryLegalContentsSchema(Yup.object().shape(fields));
  };

  const getMandatoryLegalContents = async (approvals: Approvals = {}): Promise<void> => {
    const mandatoryLegalContentsIds = ['privacy-policy', 'terms-of-use'];

    const legalContents = await Promise.all(
      mandatoryLegalContentsIds.map(async (legalContentId): Promise<LegalContent> => {
        const legalContent = await get<LegalContent>(`${config.clientConfig?.host}/legal-contents/${legalContentId}`);

        const isApproved = (): boolean => {
          if (!approvals[legalContentId]) {
            return false;
          }

          if (approvals[legalContentId].version !== legalContent.version) {
            return false;
          }

          return approvals[legalContentId].approved;
        };

        return {
          ...legalContent,
          isApproved: isApproved(),
        };
      }),
    );

    setMandatoryLegalContents(legalContents);
    // eslint-disable-next-line max-len
    setInitialValues((previousValues) => legalContents.reduce((acc, legalContent) => ({ ...acc, [legalContent.id]: legalContent.isApproved }), { ...previousValues }));
  };

  const legalContentsApproved = mandatoryLegalContents.every((legalContent) => legalContent.isApproved);

  useEffect(() => {
    updateMandatoryLCApproved(legalContentsApproved);
  }, [legalContentsApproved]);

  useEffect(() => {
    setYupSchema();
  }, []);

  useEffect(() => {
    getMandatoryLegalContents(auth?.user?.approvals);
  }, [auth]);

  if (!auth?.user) {
    return <></>;
  }

  const handleSubmitForm = async (values: FormValues): Promise<void> => {
    const approvalRequests = Object.entries(values).map(([legalContentId, approved]) => ({
      legalContentId,
      version: mandatoryLegalContents.find((legalContent) => legalContent.id === legalContentId)?.version,
      approved,
    }));

    const succeeded = await auth.addApprovals(approvalRequests);

    if (!succeeded) {
      snackbar.addErrorMessage('account.legal-contents.modal.mandatory.failure');
    }
  };

  const isValid = (values: FormValues): boolean => values['privacy-policy'] && values['terms-of-use'];

  return (
    <Modal
      show={!legalContentsApproved}
      maxWidth={500}
    >
      <div className='account-data-container'>
        <h2>{t('account.legal-contents.modal.mandatory.title')}</h2>
        <p className='settings-subtitle'>{t('account.legal-contents.modal.mandatory.description')}</p>
        <div>
          <Formik
            initialValues={initialValues}
            innerRef={formikRef}
            enableReinitialize
            validationSchema={mandatoryLegalContentsSchema}
            onSubmit={handleSubmitForm}
          >
            {({ values, isSubmitting }) => (
              <Form className='form-light'>
                <CheckboxInput
                  required
                  name='privacy-policy'
                  label={(
                    <Trans
                      i18nKey={t('account.legal-contents.approvals.privacy-policy.text')}
                      components={{
                        linkPrivacyPolicy: (
                          <TranslationLink
                            className='font-weight-bold'
                            url={t('account.legal-contents.approvals.privacy-policy.link')}
                          />
                        ),
                      }}
                    />
                  )}
                />
                <CheckboxInput
                  required
                  name='terms-of-use'
                  label={(
                    <Trans
                      i18nKey={t('account.legal-contents.approvals.terms-of-use.text')}
                      components={{
                        linkTermsOfUse: (
                          <TranslationLink
                            className='font-weight-bold'
                            url={t('account.legal-contents.approvals.terms-of-use.link')}
                          />
                        ),
                      }}
                    />
                  )}
                />
                {!isValid(values) && (
                  <div className='form-error' data-cy='legal-register-error-text'>
                    {t('account.legal-contents.modal.mandatory.error')}
                  </div>
                )}
                <SubmitButton
                  disabled={!isValid(values)}
                  name={isSubmitting ? 'waiting' : 'approve'}
                  containerClassName='my-5'
                  btnClassName={!isValid(values) ? 'red' : ''}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
