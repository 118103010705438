/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import accountLinkingIcon from '../../../_assets/icons/account-linking.svg';
import crossProgressionIcon from '../../../_assets/icons/cross-progression.svg';
import exclusveRewardsIcon from '../../../_assets/icons/exclusive-rewards.svg';

interface Props {
  name: string;
}

function getFeatureIcon(feature: string): string {
  switch (feature) {
    case 'account-linking':
      return accountLinkingIcon;
    case 'cross-progression':
      return crossProgressionIcon;
    case 'exclusive-rewards':
      return exclusveRewardsIcon;
    default:
      return '';
  }
}

export function HomepageHeroFeature({ name }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className='feature-container'>
      <div className='feature-header'>
        <img src={getFeatureIcon(name)} alt={`${name}-icon`} />
        <h4>{t(`home.hero.features.${name}.title`)}</h4>
      </div>
      <p><Trans>{t(`home.hero.features.${name}.description`)}</Trans></p>
    </div>
  );
}
