/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultFieldProps, FieldBase } from './FieldBase';

type CheckboxProps = {
  title?: string,
  label?: JSX.Element
} & Omit<DefaultFieldProps, 'label'>;

export function CheckboxInput({ name, title, label, containerClassName, ...props }: CheckboxProps): JSX.Element {
  const { t } = useTranslation();
  const [field] = useField(name);
  const checked = field?.value || false;

  return (
    <FieldBase name={name} containerClassName={containerClassName}>
      <div className={`form-check-flex ${props.disabled ? 'disabled' : ''}`}>
        <input
          id={name}
          data-cy={`toggle-${name}`}
          className='form-check-input'
          type='checkbox'
          checked={checked}
          disabled={props.disabled}
          {...field}
        />

        <label htmlFor={name}>
          {title && <div className='form-check-label'>{title}</div>}
          {!!label && (
            <span className='form-check form-check-label'>
              {label || t(`form.label.checkbox.${name}`)}
            </span>
          )}
        </label>
      </div>
    </FieldBase>
  );
}
