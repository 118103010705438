/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

/* eslint-disable max-len */
import { motion, LayoutGroup } from 'framer-motion';
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../authentication/AuthenticationContext';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { post } from '../utils/Request';
import { RegistrationStatus } from '../account/Account.model';

import mymLogo from '../_assets/mym-logo-white.webp';
import { GameIds } from '../account/my-games/MyGames.model';
import { useConfig } from '../contexts/ConfigContext';

export function PreregistrationClaim({ gameId, preRegId }: { gameId: GameIds, preRegId: string }): JSX.Element {
  const [accepted, setAccepted] = useState<boolean>(false);

  const { t } = useTranslation();
  const auth = useAuth();
  const config = useConfig();
  const snackbar = useSnackbar();
  const history = useHistory();

  const handleCheck = (e: ChangeEvent): void => {
    if (((e.target as HTMLInputElement).checked && !accepted) || (!(e.target as HTMLInputElement).checked && accepted)) {
      setAccepted(!accepted);
    }
  };

  const handleClaim = async (): Promise<void> => {
    try {
      await post(`${config.clientConfig.host}/players/me/games/${gameId}/pre-registrations/${preRegId}/claim`, {
        data: {
          provider: 'steam',
          isNdaAccepted: accepted,
        },
      });

      auth.getPlayer();
    } catch (e) {
      // TODO: Get error translations
      snackbar.addErrorMessage('UnknownError');
    }

    // TODO: Remove once steam key works
    auth.getPlayer();
  };

  const Logo = memo((): JSX.Element => {
    switch (gameId) {
      case GameIds.MYM:
        return <img className='logo-img' src={mymLogo} alt='Meet Your Maker Logo - White' />;
      default:
        return <></>;
    }
  });

  useEffect(() => {
    const userPreReg = auth.user.preRegistrations[gameId];
    if (!userPreReg || userPreReg[preRegId].status !== RegistrationStatus.SELECTED) {
      history.push(`/account/my-account/games/${gameId}`);
    }
  }, []);

  return (
    <div className={`claim-page-container ${gameId}`}>
      <div className='claim-flow-container'>
        <LayoutGroup>
          <motion.div
            layout
            className='logo-container'
          >
            <Logo />
          </motion.div>
          <div className='nda-container' data-cy='nda-section'>
            <h4>{t('preregistration.claim.header')}</h4>
            <div className='nda-absolute-container'>
              <div className='nda-text'>
                <p>
                  <strong>WHEREAS </strong>
                  TESTER has accepted to participate in the testing of a videogame which is currently in development by BEHAVIOUR INTERACTIVE INC. (“
                  <strong>BHVR</strong>
                  ”) known as “Meet Your Maker” (the “
                  <strong>GAME</strong>
                  ”)
                </p>
                <br />
                <p>
                  <strong>WHEREAS</strong>
                  , in such context, TESTER may have access and obtain certain information which are of a confidential nature and BHVR would not have agreed to disclose such information if TESTER had not accepted the following terms and conditions;
                </p>
                <br />
                <p><strong>THE TESTER HEREBY AGREES AS FOLLOWS:</strong></p>
                <br />
                <ol>
                  <li>
                    TESTER recognizes that any and all information, secrets, documents and materials pertaining to BHVR that is provided to, or otherwise acquired by, TESTER which are not generally known to the public through legitimate origins, including without limitation any non-public information about the GAME (collectively, the “
                    <strong>INFORMATION</strong>
                    ”) is and will remain confidential.
                  </li>
                  <li>All INFORMATION shall be treated by TESTER as highly confidential. The INFORMATION shall not be disclosed to a third party or duplicated without the prior written authorization of BHVR.</li>
                  <li>Except as provided by BHVR, TESTER agrees not to copy any INFORMATION during the testing, including but not limited to drawings, sketches, models, or any other element of the GAME. TESTER agrees not to take any screenshots, photographs or other images during the testing.</li>
                  <li>TESTER recognizes that the Steam keys provided by BHVR for the testing of the Game are for personal use only. TESTER agrees not to sell, exchange, trade, give, or otherwise transfer the Steam keys to third parties. Furthermore, TESTER agrees not to access or play the GAME in public places, including but not limited to restaurants, cafés, public transportation, etc.</li>
                  <li>TESTER agrees that unauthorized copying and/or disclosure of the GAME (and/or any other INFORMATION) may result in irreparable harm to the developer of the GAME, and that TESTER may be held responsible for breach of the present agreement.</li>
                  <li>TESTER agrees that, he/she will be asked to provide to BHVR certain feedback and suggestions regarding the TESTER&apos;s experience while reviewing and evaluating the GAME. As such, TESTER acknowledges that any copyrights, patents, trade secrets or other intellectual property rights associated with any idea, concept, feedback, technique, invention, method or work developed or created by TESTER and pertaining to the Information, or made, created or otherwise suggested as part of the testing (the “Realizations”) are the exclusive property of BHVR. Therefore, TESTER hereby irrevocably cedes, transfers and assigns, to BHVR, without any limitation whatsoever and without any further consideration, all rights, titles, and interest, including but not limited to, any intellectual property rights, including all copyrights in the Realizations. TESTER expressly waives all rights, titles, and interest in the Realizations and, to the fullest extent permitted by applicable law, any moral right that TESTER might hold in the Realizations. TESTER acknowledges that BHVR has no obligation to acknowledge the TESTER as the author of the Realizations in any circumstances.</li>
                  <li>No failure or delay by BHVR in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall any single or partial exercise thereof preclude any other or further exercise of any right, power or privilege hereunder.</li>
                  <li>TESTER acknowledges having requested and being satisfied that this confidentiality agreement be drawn up in English. Le testeur ou la testeuse reconnaît avoir demandé que cette entente de confidentialité soit rédigée en anglais et s&apos;en déclare satisfait/e.</li>
                </ol>
              </div>
            </div>
            <div className='checkbox-container'>
              <input
                id='ndaCheckbox'
                type='checkbox'
                className='survey-input'
                onChange={handleCheck}
                data-cy='check-nda'
              />
              <label htmlFor='ndaCheckbox'>
                {t('preregistration.claim.accept')}
              </label>
            </div>
            <button
              type='button'
              className='btn btn-primary-game btn-block'
              disabled={!accepted}
              data-cy='nda-submit-btn'
              onClick={handleClaim}
            >
              {t('preregistration.claim.claim')}
            </button>
          </div>
        </LayoutGroup>
      </div>
    </div>
  );
}
