import React, { MutableRefObject } from 'react';
import { Close } from '@mui/icons-material';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Modal } from './Modal';
import { ComponentReference, AllComponentsType } from '../Config.model';

interface ModalAddComponentProps {
  allComponents: AllComponentsType
  isOpen: boolean,
  confirm: () => void,
  handleClose: () => void,
  onChange: (e: SelectChangeEvent) => void,
  edittingComponent: MutableRefObject<ComponentReference>
}

export function ModalAddComponent(props: ModalAddComponentProps):JSX.Element {
  const initialAvailableComponents = Object.keys(props.allComponents);

  const getParentCpnAvailable = (name: string): string[] => props.allComponents[name]?.possibleChildren;

  let availableComponents = initialAvailableComponents;
  if (props.edittingComponent.current) {
    const { parent } = props.edittingComponent.current;
    if (parent) {
      const { name } = parent;
      availableComponents = getParentCpnAvailable(name);
    }
  }

  return (
    <Modal
      show={props.isOpen}
      textConfirm='Apply'
      confirm={props.confirm}
      close={props.handleClose}
    >
      <h2 className='pb-3'>Add component</h2>
      <FormControl sx={{ m: 1, minWidth: 250, zIndex: 1000 }}>
        <Select
          defaultValue=''
          onChange={props.onChange}
          sx={{
            color: 'white',
          }}
        >
          {availableComponents.map((cpnName: string) => (
            <MenuItem key={`menu-item-${cpnName}`} value={cpnName}>{cpnName}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Close
        onClick={() => props.handleClose()}
        style={{ cursor: 'pointer',
          position: 'absolute',
          top: '-1rem',
          right: '-1rem' }}
      />
    </Modal>
  );
}
