/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Promotion } from '../Promotion.model';
import { DIRECT_STEPS,
  PROMOCODE_STEPS,
  PromotionStep,
  isRequirementFulfillmentStep } from './promotionSteps/promotionSteps.model';
import { PromotionDescription } from './promotionSteps/promotionDescription/PromotionDescription';
import { PromotionRedeemed } from './promotionSteps/promotionRedeemed/PromotionRedeemed';
import { PromotionRequirementsFulfillment } from './promotionSteps/PromotionRequirementsFulfillment';
import { RedeemingGameProvider } from '../../providers/ProviderEnum';
import { usePromotion } from '../../../contexts/PromotionContext';
import { isRequestError } from '../../../Error.model';

interface Props {
  promotion: Promotion;
  cancel: () => void;
}
export function ClaimPromotion(
  { promotion, cancel }:Props,
):JSX.Element {
  const { t } = useTranslation();
  const { claim } = usePromotion();

  const [currentStepIdx, setCurrentStepIdx] = useState<number>(0);
  const [nextStepEnabled, setNextStepEnabled] = useState(true);
  const [redeemProvider, setRedeemProvider] = useState<RedeemingGameProvider>();
  const [redeemErrorCode, setRedeemErrorCode] = useState<string | null>(null);

  const isPromocode = promotion.promotionData.rewardDeliveryType === 'PROMOCODE';
  const steps = isPromocode ? PROMOCODE_STEPS : DIRECT_STEPS;
  const actionSteps = steps.filter(
    (step) => isRequirementFulfillmentStep(step),
  );

  const currentStep = steps[currentStepIdx];
  const displayConfirmButton = currentStep !== PromotionStep.Redeemed;

  const getNextLabel = (): string => {
    switch (currentStep) {
      case PromotionStep.Description:
        return t('translation:promotion.modal.claim');
      case PromotionStep.Confirmation:
        return t('translation:promotion.modal.redeem');
      case PromotionStep.Sync:
        return isPromocode
          ? t('translation:promotion.modal.redeem')
          : t('translation:button.next');
      default:
        return t('translation:button.next');
    }
  };

  const claimPromo = async (): Promise<void> => {
    try {
      await claim(promotion.promotionData.promotionId, redeemProvider);
    } catch (e) {
      if (isRequestError(e)) {
        setRedeemErrorCode(e.code);
      } else {
        setRedeemErrorCode('UnknownError');
      }
    }
  };

  const nextStepHandler = async (): Promise<void> => {
    const isClaimPromoStep = currentStep === PromotionStep.Confirmation
      || (isPromocode && currentStep === PromotionStep.Sync);

    if (isClaimPromoStep) {
      await claimPromo();
    }
    const nextStep = currentStepIdx + 1;
    setCurrentStepIdx(nextStep);

    if (steps[nextStep] === PromotionStep.Platforms || steps[nextStep] === PromotionStep.Sync) {
      setNextStepEnabled(false);
    }
  };

  const modifyProvider = (): void => {
    setCurrentStepIdx(PromotionStep.Platforms);
  };

  const closeHandler = (): void => {
    setCurrentStepIdx(0);
    cancel();
  };

  return (
    <div className='claim-promotion'>
      {currentStep === PromotionStep.Description && (
        <PromotionDescription promotion={promotion} />
      )}

      {isRequirementFulfillmentStep(currentStep) && (
        <PromotionRequirementsFulfillment
          promotion={promotion}
          steps={actionSteps}
          currentStep={currentStep}
          modifyProvider={modifyProvider}
          redeemProvider={redeemProvider || null}
          setRedeemProvider={setRedeemProvider}
          setNextStepEnabled={setNextStepEnabled}
        />
      )}

      {currentStep === PromotionStep.Redeemed && (
        <PromotionRedeemed promotion={promotion} errorCode={redeemErrorCode} />)}

      <div className='claim-promotion-footer'>
        <button
          type='button'
          className='btn btn-outline-light claim-promotion-footer-button'
          onClick={closeHandler}
          data-cy='claim-promotion-close-btn'
        >
          {t('translation:button.close')}
        </button>

        {displayConfirmButton && (
          <button
            type='button'
            className='btn btn-primary-game claim-promotion-footer-button'
            disabled={!nextStepEnabled}
            onClick={nextStepHandler}
            data-cy='claim-promotion-next-btn'
          >
            {getNextLabel()}
          </button>
        )}
      </div>
    </div>
  );
}
