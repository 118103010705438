import { Check, Unpublished } from '@mui/icons-material';
import { Box, ToggleButton } from '@mui/material';
import { grey } from '@mui/material/colors';

const iconColor = { color: grey[50] };

export function ToggleButtonCustom({
  enabled, onStateChange, label,
}: { enabled: boolean, onStateChange: (state: boolean) => void, label?: string }): JSX.Element {
  return (
    <ToggleButton
      value='enabled'
      selected={enabled}
      disableFocusRipple
      onChange={() => onStateChange(!enabled)}
      sx={{
        borderColor: enabled ? 'rgb(101, 196, 102)' : 'rgb(204, 0, 0)',
        backgroundColor: 'rgba(204, 0, 0, 0.08)',
        color: 'white',
        '&.Mui-selected': {
          color: 'white',
          backgroundColor: 'rgba(101, 196, 102, 0.08)',
        },
      }}

    >
      <Box mr={2}>
        { label || (
          <>
            Component
            {enabled ? ' enabled' : ' disabled' }
          </>
        )}

      </Box>

      { enabled ? <Check sx={iconColor} /> : <Unpublished sx={iconColor} />}
    </ToggleButton>
  );
}
