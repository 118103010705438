/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Should be moved out and cutumized when we have time
export function ClaimStepper(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box className='wtf-stepper'>
      <Stepper orientation='vertical'>
        <Step key={1} className='claim-free-game-step' active>
          <StepLabel>
            {t('claimFreeGame.card.claimStep1')}
          </StepLabel>
        </Step>
        <Step key={2} className='claim-free-game-step' active>
          <StepLabel>
            {t('claimFreeGame.card.claimStep2')}
          </StepLabel>
        </Step>
        <Step key={3} className='claim-free-game-step' active>
          <StepLabel>
            {t('claimFreeGame.card.claimStep3')}
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
}
