/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { CircularProgress } from '@mui/material';

interface Props {
  name: string;
  cancelLabel: string;
  cancelHandler: () => void;
  nextDisabled?: boolean;
  nextLabel?: string;
  nextHandler?: () => void;
  nextIsLoading?: boolean;
  previousLabel?: string;
  previousHandler?: (() => void) | null;
}

export function ModalFooter({
  name,
  cancelLabel,
  cancelHandler,
  nextDisabled = false,
  nextLabel,
  nextIsLoading = false,
  nextHandler,
  previousLabel,
  previousHandler,
}:Props): JSX.Element {
  return (
    <div className={`modal-footer ${name}-modal-footer`}>
      <button
        type='button'
        className='btn btn-link'
        data-cy={`${name}-modal-close-button`}
        onClick={cancelHandler}
      >
        {cancelLabel}
      </button>
      <div className='navigation-buttons'>
        {previousLabel && previousHandler && (
          <button
            type='button'
            className='btn btn-secondary'
            onClick={previousHandler}
            data-cy={`${name}-modal-previous-button`}
          >
            {previousLabel}
          </button>
        )}
        {nextLabel && nextHandler && (
          <button
            type='button'
            disabled={nextDisabled || nextIsLoading}
            className='btn btn-primary-game'
            onClick={nextHandler}
            data-cy={`${name}-modal-confirm-button`}
          >
            {nextIsLoading ? <CircularProgress size='1.5rem' className='btn-loading' /> : nextLabel}
          </button>
        )}
      </div>
    </div>
  );
}
