/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass, Pagination } from 'swiper';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from '../../modal/ModalFooter';
import { ModalFlowContent } from '../../modal/modal-flow-container/ModalFlowContent';
import { ModalFlowContainer } from '../../modal/modal-flow-container/ModalFlowContainer';

interface Props {
  name: string;
  title: string;
  subtitle: string;
  next: () => void;
  cancel: () => void;
  items: Array<JSX.Element>;
}

export function Tutorial({ name, title, subtitle, next, cancel, items }: Props): JSX.Element {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);

  const swiperRef = useRef<SwiperClass>();

  const showPrevious = currentSlide > 0;

  const nextHandler = (): void => {
    if (currentSlide < (items.length - 1)) {
      swiperRef?.current?.slideNext();
    } else {
      next();
    }
  };

  const previousHandler = (): void => {
    if (swiperRef?.current && currentSlide > 0) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <ModalFlowContainer title={title}>
      <ModalFlowContent name={name}>
        <div className='tutorial--header'>
          <h2>{subtitle}</h2>
          <button type='button' className='btn' onClick={next} data-cy='tutorial-skip-btn'>{t('button.skip')}</button>
        </div>
        <div className='tutorial--content'>
          <Swiper
            onSwiper={(swiper) => { swiperRef.current = swiper; }}
            pagination
            modules={[Pagination]}
            onActiveIndexChange={(swiperCore) => { setCurrentSlide(swiperCore.activeIndex); }}
          >
            {items.map((item, index) => (
              <SwiperSlide key={`${name}-item-${index}`}>{item}</SwiperSlide>
            ))}
          </Swiper>
        </div>
      </ModalFlowContent>
      <ModalFooter
        name={name}
        cancelHandler={cancel}
        cancelLabel={t('common.cancel')}
        nextLabel='next'
        nextHandler={nextHandler}
        previousHandler={showPrevious ? previousHandler : null}
        previousLabel={t('button.previous')}
      />
    </ModalFlowContainer>
  );
}
