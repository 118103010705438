/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CrossProgressionStep, NumberedSteps } from './CrossProgression.model';

interface Props {
  currentStep: CrossProgressionStep;
}

export function CrossProgressionModalStepper({ currentStep }: Props): JSX.Element {
  const { t } = useTranslation();

  const currentStepNumber = NumberedSteps[currentStep];

  return (
    <>
      {currentStepNumber > 0 && (
        <div className='cross-progression-stepper'>
          <Stepper
            nonLinear
          >
            {[1, 2, 3].map((key) => (
              <Step
                key={key}
                active={key === currentStepNumber}
                completed={key < currentStepNumber || currentStep === 'success'}
              >
                <StepLabel />
              </Step>
            ))}
          </Stepper>

          <div className='subtitle'>{t('cross-progression.step', { index: currentStepNumber, total: 3 })}</div>
        </div>
      )}
    </>
  );
}
