/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { memo } from 'react';
import { useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../authentication/AuthenticationContext';
import { GameCard } from '../my-account/game-card/GameCard';
import { getGameThumbnail } from '../../utils/Game';
import { GameConfig } from '../../contexts/Config.model';

interface Props {
  games: GameConfig[];
  showPlaceholder?: boolean;
  category: 'owned' | 'available' | 'upcomming',
}

export function GameList({ games, showPlaceholder = false, category }: Props): JSX.Element {
  const auth = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const isSmall = useMediaQuery('(max-width: 768px)');

  const CardPlaceholder = memo((): JSX.Element => (
    <div className='game-card placeholder'>
      <span>{t('account.game-library.owned-games-placeholder')}</span>
      <button
        type='button'
        onClick={() => history.push('/account/my-account/providers')}
        className='btn btn-primary-game mx-auto mt-3 mb-0'
        data-cy='games-sync-button'
      >
        {t('account.game-library.sync-gaming-accounts')}
      </button>
    </div>
  ));

  return (
    <div className='games-container'>
      {isSmall && (
        <Swiper
          spaceBetween={16}
          slidesPerView='auto'
        >
          {games.map((gameConfig) => (
            <SwiperSlide key={`card-${gameConfig.id}`}>
              <GameCard
                category={category}
                isInPreorderPhase={gameConfig.isInPreorderPhase && !gameConfig.released}
                gameConfig={gameConfig}
                gameImage={getGameThumbnail(gameConfig.id)}
                userPreRegistration={auth.user.preRegistrations[gameConfig.id]}
                ownedOnProviders={auth.user.gameLibrary[gameConfig.id]?.providers || []}
              />
            </SwiperSlide>
          ))}
          {showPlaceholder && (
            <SwiperSlide key='card-placeholder'>
              <CardPlaceholder />
            </SwiperSlide>
          )}

        </Swiper>
      )}
      {!isSmall && (
        <>
          {games.map((gameConfig) => (
            <GameCard
              key={`card-${gameConfig.id}`}
              category={category}
              isInPreorderPhase={gameConfig.isInPreorderPhase && !gameConfig.released}
              gameConfig={gameConfig}
              gameImage={getGameThumbnail(gameConfig.id)}
              userPreRegistration={auth.user.preRegistrations[gameConfig.id]}
              ownedOnProviders={auth.user.gameLibrary[gameConfig.id]?.providers || []}
            />
          ))}
          {showPlaceholder && <CardPlaceholder />}
        </>
      )}
    </div>
  );
}
