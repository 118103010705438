/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@mui/material';

export function SyncInProgressAlert(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className='cross-progression-in-progress' data-cy='cross-progression-in-progress'>
      <Alert
        severity='info'
        classes={{
          root: 'cross-progression-in-progress--alert',
          icon: 'cross-progression-in-progress--alert-icon',
        }}
      >
        <AlertTitle className='cross-progression-in-progress--alert-title'>
          {t('cross-progression.success.alert-title')}
        </AlertTitle>
        {t('cross-progression.success.alert-message')}
      </Alert>
    </div>
  );
}
