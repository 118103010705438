import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../authentication/AuthenticationContext';
import { ProviderLinkStatus } from '../reward-modal/reward-steps/ProviderLinkStatus';
import { Modal } from '../../_shared/modal/Modal';
import { DecodedToken } from '../MarketingConsent.model';
import { ModalFooter } from '../../_shared/modal/ModalFooter';

interface Props {
  show: boolean;
  decodedToken: DecodedToken;
  nextHandler:() => void;
  closeHandler: () => void;
}

export function ProviderLinkModal(
  { show, decodedToken, nextHandler, closeHandler }:Props,
): JSX.Element {
  const auth = useAuth();
  const { t } = useTranslation();

  const { playerName, providerName } = decodedToken;
  const nextDisabled = !auth.user?.accounts.find((account) => account.type === providerName);

  const customTitle = nextDisabled
    ? t('promotion.marketing-consent.ab-testing.link-provider', { provider: t(providerName) })
    : t('promotion.marketing-consent.ab-testing.linked-provider', { provider: t(providerName) });
  const customDetails = !nextDisabled ? t('promotion.marketing-consent.ab-testing.linked-provider-details') : undefined;

  return (
    <Modal
      show={show}
      close={closeHandler}
      maxWidth={600}
    >
      <div className='mkt-consent-reward-modal'>
        <h4 className='mkt-consent-reward-modal--title'>
          {t('auth.login.welcome-player', { player: playerName })}
        </h4>
        <ProviderLinkStatus
          customTitle={customTitle}
          customDetails={customDetails}
          provider={providerName}
          decodedToken={decodedToken}
        />
        <ModalFooter
          name='mkt-consent-reward'
          nextLabel={t('button.next')}
          nextHandler={nextHandler}
          nextDisabled={nextDisabled}
          cancelLabel={t('common.cancel')}
          cancelHandler={closeHandler}
        />
      </div>
    </Modal>
  );
}
