/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { motion } from 'framer-motion';

export function Transitions(
  { children, disableTransition = false }:
  { children: JSX.Element, disableTransition?: boolean },
): JSX.Element {
  const onComplete = (): void => {
    window.scrollTo(0, 0);
  };

  const transitionConfig = {
    initial: {
      opacity: 0,
      scale: 0.98,
    },
    onEnter: {
      opacity: 1,
      scale: 1,
      transition: {
        type: 'tween',
        duration: disableTransition ? 0 : 0.3,
        ease: 'circOut',
      },
    },
    onExit: {
      opacity: 0,
      scale: 0.98,
      transition: {
        type: 'tween',
        duration: disableTransition ? 0 : 0.3,
        ease: 'circIn',
      },
    },
  };

  return (
    <motion.div
      variants={transitionConfig}
      initial='initial'
      animate='onEnter'
      exit='onExit'
      onAnimationComplete={onComplete}
    >
      { children }
    </motion.div>
  );
}
