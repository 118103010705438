/* eslint-disable complexity */
import React from 'react';
import { Delete, AddBox } from '@mui/icons-material';
import { IconButton,
  Button,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText } from '@mui/material';
import { ArrayProp, ComponentProps, ComponentReference, FieldError, PagesContent, PropType } from '../Config.model';
import {
  DefinitionProperty,
  DefinitionPropertyType,
  ImageProperty,
  ArrayProperty,
  SelectProperty,
} from '../DefinitionProperty.model';
import { UploadImage } from './UploadImage';
import { SwitchCustom } from '../buttons/Switch';

export interface ComponentInputProps {
  pagesContent: PagesContent,
  page: string,
  propertyDef: DefinitionProperty,
  name: string,
  path: Array<string | number>,
  value: PropType,
  onChange: (
    name: string | Array<string | number>,
    value: PropType) => void,
  component: ComponentProps,
  componentRef: ComponentReference,
  error?: FieldError | undefined,
  addElement?: (name: string) => void,
  removeElement?: (name: string, index: number) => void,
  getCustomInput?: (props: ComponentInputProps) => JSX.Element,
}

export function ComponentInput({
  pagesContent,
  page,
  propertyDef,
  name,
  path,
  value,
  onChange,
  component,
  componentRef,
  error,
  addElement,
  removeElement,
  getCustomInput,
}: ComponentInputProps): JSX.Element {
  const { id: gameId } = pagesContent;

  switch (propertyDef.type as unknown as string) {
    case DefinitionPropertyType.TranslatedString:
      return (
        <div key={name}>
          <label htmlFor={`${path.join('-')}-input`} style={{ color: 'white', marginRight: '1rem' }}>
            {name}
            (
            { `${gameId}.${page}.${componentRef.parent
              ? `${componentRef.parent.name}.`
              : ''}${componentRef.name}.${name}`}
            )
            :
          </label>
          <input
            id={`${path.join('-')}-input`}
            style={error ? { border: '2px solid #C00' } : {}}
            value={value as string || ''}
            onChange={(e) => onChange(path, e.target.value)}
          />
          {error && (
            <Box sx={{ color: '#C00' }}>
              {error?.errorMessage}
            </Box>
          )}
        </div>
      );

    case DefinitionPropertyType.String:
      return (
        <div key={name}>
          <label htmlFor={`${path.join('-')}-input`} style={{ color: 'white', marginRight: '1rem' }}>
            {name}
            :
          </label>
          <input
            id={`${path.join('-')}-input`}
            style={error ? { border: '2px solid #C00' } : {}}
            value={value as string || ''}
            onChange={(e) => onChange(path, e.target.value)}
          />
          {error && (
            <Box sx={{ color: '#C00' }}>
              {error?.errorMessage}
            </Box>
          )}
        </div>
      );

    case DefinitionPropertyType.Number:
      return (
        <div key={name}>
          <label htmlFor={`${path.join('-')}-input`} style={{ color: 'white', marginRight: '1rem' }}>
            {name}
            :
          </label>
          <input
            id={`${path.join('-')}-input`}
            style={error ? { border: '2px solid #C00' } : {}}
            value={value as number || 0}
            onChange={(e) => onChange(path, e.target.value)}
            type='number'
          />
          {error && (
            <Box sx={{ color: '#C00' }}>
              {error?.errorMessage}
            </Box>
          )}
        </div>
      );

    case DefinitionPropertyType.Image:
      return (
        <UploadImage
          maxWeight={(propertyDef as ImageProperty).maxWeight}
          maxWidth={(propertyDef as ImageProperty).width}
          maxHeight={(propertyDef as ImageProperty).height}
          name={name}
          error={error}
          onChange={(_name, imgValue) => onChange(path, imgValue)}
        />
      );

    case DefinitionPropertyType.Array: {
      const arrayDef = propertyDef as ArrayProperty;
      return (
        <div key={name} className='array-component-input'>
          <span>{name}</span>
          {(component[name as keyof ComponentProps] as ArrayProp)?.map(
            (element: Record<string, string | number | boolean>, i: number) => (
              <div key={`${path.join('-')}-${i}`} className='array-element'>
                <div>
                  {Object.keys(element).map((key: string, j: number) => (
                    <div key={`${path.join('-')}-${key}`} className='py-2'>
                      <ComponentInput
                        key={`${key}-${j}`}
                        propertyDef={arrayDef.elements[key]}
                        name={key}
                        path={[name, i, key]}
                        value={element[key]}
                        onChange={onChange}
                        component={component}
                        componentRef={componentRef}
                        pagesContent={pagesContent}
                        page={page}
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <IconButton size='small' onClick={() => removeElement(name, i)}>
                    <Delete className='icon danger' />
                  </IconButton>
                </div>
              </div>
            ),
          )}
          <Button onClick={() => addElement(name)}>
            <AddBox className='icon' />
            Add element
          </Button>
        </div>
      );
    }

    case DefinitionPropertyType.Boolean:
      return (
        <div key={name}>
          <FormGroup>
            <FormControlLabel
              label={name}
              control={(
                <SwitchCustom
                  checked={!!value as boolean}
                  onChange={(e) => onChange(path, e.target.checked)}
                />
              )}
            />
          </FormGroup>
        </div>
      );

    case DefinitionPropertyType.Custom:
      return getCustomInput({
        pagesContent,
        page,
        propertyDef,
        name,
        path,
        value,
        onChange,
        component,
        componentRef,
        addElement,
        removeElement,
        getCustomInput,
      });

    case DefinitionPropertyType.Date:
      return (
        <div key={name}>
          <label htmlFor={`${path.join('-')}-input`} style={{ color: 'white' }}>
            {name}
            (
            { `${gameId}.${page}.${componentRef.parent
              ? `${componentRef.parent.name}.`
              : ''}${componentRef.name}.${name}`}
            )
            :
          </label>
          <input
            id={`${path.join('-')}-input`}
            type='date'
            style={error ? { border: '2px solid #C00' } : {}}
            name={name}
            value={value as string || ''}
            onChange={(e) => onChange(path, e.target.value)}
          />
          {error && (
            <Box sx={{ color: '#C00' }}>
              {error?.errorMessage}
            </Box>
          )}
        </div>
      );

    case DefinitionPropertyType.Select: {
      const selectDef = propertyDef as SelectProperty;
      return (
        <FormControl
          variant='filled'
          fullWidth
          error={!!error}
          sx={{
            m: 1,
            minWidth: 120,
            '& .MuiFormLabel-root': {
              color: 'rgba(0, 0, 0, 0.6) !important',
              '&.Mui-focused': {
                color: 'rgba(0, 0, 0, 0.6)',
              },
            },
            '& .MuiInputBase-root': {
              backgroundColor: 'white',
              '&:hover, &.Mui-focused': {
                backgroundColor: 'rgba(255, 255, 255, 0.82)',
                color: 'rgba(0, 0, 0, 0.6)',
              },
            },
          }}
        >
          <InputLabel>{name}</InputLabel>
          <Select
            id={`${path.join('-')}-input`}
            value={value ?? ''}
            label={name}
            onChange={(e) => onChange(path, e.target.value)}

          >
            {Object.keys(selectDef.options).map((key: string) => (
              <MenuItem key={key} value={key}>{selectDef.options[key]}</MenuItem>
            ))}
          </Select>
          {error && (<FormHelperText>{error.errorMessage}</FormHelperText>)}
        </FormControl>
      );
    }

    default:
      return null;
  }
}
