/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useMediaQuery } from '@mui/material';
import { Promotion } from '../../../Promotion.model';

interface Props {
  promotion: Promotion
}

interface CheckListItem {
  id: string;
  text: string;
}

export function PromotionDescription({ promotion }: Props): JSX.Element {
  const isSmall = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation(['translation', 'promotions']);
  const { rewards, requiredGameIds, relatedToGameId } = promotion.promotionData;
  const baseRequirement = {
    id: 'base',
    text: t('translation:promotion.claim-reward.requirements.accounts'),
  };

  const gameRequirements: CheckListItem[] = [relatedToGameId, ...(requiredGameIds || [])].map((gameId) => (
    {
      id: gameId,
      text: t('translation:promotion.claim-reward.requirements.game', {
        game: t(`games.${gameId}`),
      }),
    }
  ));
  const requirements = [...gameRequirements, baseRequirement];

  return (
    <div className='promo-description'>
      <div className='promo-description-details'>
        <div className='promo-description-reward-container'>
          <Swiper
            autoHeight
            slidesPerView={isSmall || rewards.length === 1 ? 1 : 2}
            pagination={{
              el: '.rewards-swiper-pagination',
              clickable: true,
            }}
            modules={[Pagination]}
          >
            {rewards.filter((reward, index) => rewards.findIndex((x) => x.displayName === reward.displayName) === index)
              .map((reward) => (
                <SwiperSlide key={`reward-${reward.displayName}`}>
                  <div
                    key={reward.displayName}
                    className='promo-description-reward'
                  >
                    {reward.gameSpecificData ? (
                      <div className='promotion-badges-container'>
                        {
                          reward.gameSpecificData.rarity ? (
                            <span className='promotion-rarity-content'>
                              {t(`promotions:${reward.gameSpecificData.rarity}`)}
                            </span>
                          ) : <></>
                        }
                        {
                          reward.gameSpecificData.universalStatus ? (
                            <span className='promotion-universal-status'>
                              {t(`promotions:${reward.gameSpecificData.universalStatus}`)}
                            </span>
                          ) : <></>
                        }
                      </div>
                    ) : <></>}
                    <h3 className='promo-description-reward-display-name'>
                      {t(`promotions:${reward.displayName}`)}
                    </h3>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
          <div className='rewards-swiper-pagination' />
        </div>
      </div>

      <div className='promo-description-requirements'>
        <p className='promo-description-requirements-title'>
          {t('translation:promotion.claim-reward.requirements.title')}
        </p>

        {requirements.map((requirement) => (
          <div key={requirement.id} className='promo-description-requirements-item'>
            <CheckCircle className='icon promo-description-requirements-item-icon' />
            <span>{requirement.text}</span>
          </div>
        ))}
      </div>

    </div>
  );
}
