/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Providers } from '../providers/Providers';
import { RouteParams } from '../Authentication.model';
import { useAuth } from '../AuthenticationContext';
import { useGameTheme } from '../../contexts/GameThemeContext';

interface Props {
  isModal?: boolean;
}

export function Signup({ isModal = false }: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useGameTheme();
  const params = useParams<RouteParams>();
  const auth = useAuth();

  useEffect(() => {
    if (params.gameId) {
      theme.setGameId(params.gameId);
    }
  }, [params]);

  return (
    <div className='card-bhvra'>
      <h2 className='my-2'>{t('account.welcome')}</h2>

      <Providers />

      <button
        type='submit'
        className='btn btn-primary-game btn-block mt-4 mx-auto'
        data-cy='signup-email-button'
        onClick={() => auth.updateVisibleModal('register')}
      >
        {t('auth.register.sign-up-with-email')}
      </button>

      <p className='text-center mt-3'>
        {t('auth.register.have-account')}
        {isModal && (
          <button
            type='button'
            className='btn-link font-weight-bold'
            onClick={() => auth.updateVisibleModal('loginOptions')}
          >
            <u>{t('auth.register.sign-in-page')}</u>
          </button>
        )}
        {!isModal && (
          <Link
            className='font-weight-bold text-nowrap'
            to={params?.gameId
              ? `/auth/signin/${params.gameId}` : '/'}
          >
            <u>{t('auth.register.sign-in-page')}</u>
          </Link>
        )}
      </p>
    </div>
  );
}
