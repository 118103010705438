/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HomepageHero } from './hero/HomepageHero';
import { HomepageHowItWorks } from './how-it-works/HomepageHowItWorks';
import { HomepageGames } from './games/HomepageGames';
import { useAuth } from '../AuthenticationContext';

export function Homepage(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (location.search && location.pathname === '/rewards/marketing-consent') {
      const { search } = location;
      const params = new URLSearchParams(search);
      const jwt = params.get('jwt');

      if (jwt) {
        history.replace('/');
        sessionStorage.setItem('mktToken', jwt);
      }

      auth.updateVisibleModal('registerOptions');
    }
  }, [location]);

  return (
    <>
      <HomepageHero />
      <HomepageHowItWorks />
      <HomepageGames />
    </>
  );
}
