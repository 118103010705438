/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { CheckCircleOutlined, ContentCopy } from '@mui/icons-material';
import { Alert, Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  steamKey: string,
  isInFlow: boolean,
}
// Should be moved out and cutumized when we have time
export function ClaimInstructions({ steamKey, isInFlow } : Props): JSX.Element {
  const [isKeyCopied, setIsKeyCopied] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleCopyKey = (): void => {
    try {
      navigator.clipboard.writeText(steamKey);
      setIsKeyCopied(true);
    } catch (e) {
      setIsKeyCopied(false);
    }
  };

  return (
    <>
      <TextField
        id='outlined-basic-steam-key'
        label={t('claimFreeGame.claimGame.steamKey')}
        variant='outlined'
        defaultValue={steamKey}
        disabled
        InputLabelProps={{
          sx: { WebkitTextFillColor: 'rgba(255, 255, 255, 0.70)',
            textTransform: 'none' },
        }}
        sx={{
          marginTop: '2rem',
          marginBottom: '1rem',
          width: '100%',
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: '#FFF',
            fontWeight: 300,
            fontSize: '15px',
          },
          '& .MuiInputBase-root.Mui-disabled': {
            '& > fieldset': {
              borderColor: '#666666',
              borderWidth: 1,
            },
          },
        }}
        data-cy='steam-key'
      />
      {!isInFlow
        ? (
          <button
            type='button'
            disabled={!steamKey}
            className='btn btn-primary-game w-100'
            data-cy='wtf-claim-modal-button'
            onClick={handleCopyKey}
          >
            <ContentCopy />
            {t('claimFreeGame.claimGame.copyKey')}
          </button>
        )
        : null}

      <Box display='flex' justifyContent='center' alignItems='center' gap={4} className='wtf-instructions-list'>
        <Typography
          component='div'
          sx={{ fontWeight: '400',
            fontSize: '14px',
            lineHeight: '24px',
            color: 'rgba(255, 255, 255, 0.60)' }}
        >
          {t('claimFreeGame.claimGame.instructions.label')}
          <ol type='1'>
            <li>
              {t('claimFreeGame.claimGame.instructions.first')}
            </li>
            <li>
              {t('claimFreeGame.claimGame.instructions.second')}
            </li>
            <li>
              {t('claimFreeGame.claimGame.instructions.third')}
            </li>
          </ol>
        </Typography>
      </Box>

      {isKeyCopied ? (
        <Alert icon={<CheckCircleOutlined />} sx={{ width: '100%', color: '#28a745' }} variant='outlined'>
          {t('claimFreeGame.claimGame.steamKeyCopied')}
        </Alert>
      ) : null }
    </>
  );
}
