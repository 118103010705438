/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { Typography } from '@mui/material';

import { Tooltip } from '../../tooltip/Tooltip';
import { useAuth } from '../../../authentication/AuthenticationContext';
import { Modal } from '../../modal/Modal';
import { ReferralStatus, RegistrationStatus } from '../../../account/Account.model';
import { GameIds, Stores } from '../../../account/my-games/MyGames.model';
import { get, post } from '../../../utils/Request';
import { useSnackbar } from '../../../snackbar/SnackbarContext';
import { ClaimSteamComponent } from './ClaimSteam';
import { ProviderLink } from '../../../account/providers/provider/ProviderLink';
import { GameAccount } from '../../../authentication/Authentication.model';
import { ShareReferralLinkComponent } from '../../referral/ShareReferralLinkComponent';
import { ShareCode } from '../../../referral/ReferralCode.model';
import { ReferralComponent } from '../../referral/ReferralComponent';
import { useConfig } from '../../../contexts/ConfigContext';
import { GameConfig } from '../../../contexts/Config.model';

export const definition: Definition = {
  eventName: {
    type: DefinitionPropertyType.String,
  },
};

export default function PreRegistration({ eventName }: { eventName: string }): JSX.Element {
  const { t } = useTranslation(['translation']);
  const history = useHistory();
  const location = useLocation<{ showClaimModal: boolean }>();
  const config = useConfig();
  const auth = useAuth();
  const match = useRouteMatch();
  const snackbar = useSnackbar();
  const { gameId }: { gameId: GameIds } = useParams();
  const [gameConfig, setGameConfig] = useState<GameConfig | null>(null);
  const [userRegistrationStatus, setUserRegistrationStatus] = useState<RegistrationStatus | null>(null);
  const [showClaimModal, setShowClaimModal] = useState<boolean>(location.state?.showClaimModal);
  const [showReferralModal, setShowReferralModal] = useState<boolean>(false);
  const [showlinkModal, setShowLinkModal] = useState<boolean>(false);

  const host = config.clientConfig?.host;

  const preRegistration = gameConfig?.preRegistration;
  const eventId = preRegistration?.referral?.eventId;

  const currentPreReg = preRegistration
    ? auth.user?.preRegistrations?.[gameConfig.id]?.[preRegistration.id] || null
    : null;

  const NDA_DISABLED = true;

  const getProviderAccount = (providerName: string): GameAccount | null => {
    const pa = auth.user.accounts.find((account) => account.type === providerName);
    return pa || null;
  };

  const handleClaim = async (): Promise<void> => {
    try {
      await post(
        `${config?.clientConfig?.host}/players/me/games/${gameId}/pre-registrations/${preRegistration?.id}/claim`,
        {
          data: {
            provider: Stores.STEAM,
            isNdaAccepted: true,
          },
        },
      );

      auth.getPlayer();
      setShowClaimModal(true);
    } catch (e) {
      // TODO: Get error translations
      snackbar.addErrorMessage('UnknownError');
    }
  };

  const closeClaimModal = (): void => {
    setShowClaimModal(false);
  };
  const getUserPreRegistrations = (): void => {
    if (preRegistration) {
      if (!Object.keys(preRegistration).length) {
        setUserRegistrationStatus(RegistrationStatus.UNAVAILABLE);
        return;
      }
      const userGamePreRegistration = auth.user.preRegistrations?.[gameConfig.id];
      if (!userGamePreRegistration?.[preRegistration.id]?.status) {
        setUserRegistrationStatus(RegistrationStatus.UNREGISTERED);
      } else {
        setUserRegistrationStatus(userGamePreRegistration[preRegistration.id].status as RegistrationStatus);
      }
    }
  };

  const handleCloseLinkModal = (): void => {
    if (getProviderAccount(Stores.STEAM)) {
      handleClaim();
    }
    setShowLinkModal(false);
  };

  const handlePreregClick = (): void => {
    if (userRegistrationStatus === RegistrationStatus.GRANTED) {
      setShowClaimModal(true);
    }
    if (userRegistrationStatus === RegistrationStatus.SELECTED) {
      if (NDA_DISABLED) {
        if (getProviderAccount(Stores.STEAM)) {
          handleClaim();
        } else {
          setShowLinkModal(true);
        }
      } else {
        history.push(`${match.url}/preregistration/claim`);
      }
    }
  };

  const generateReferralLink = async (): Promise<ShareCode> => {
    const id = gameConfig?.id;
    const preRegId = preRegistration?.id;
    return post(`${host}/players/me/games/${id}/pre-registrations/${preRegId}/generate-refer-link/${eventId}`);
  };

  const getReferralLink = async (): Promise<ShareCode> => get<ShareCode>(`${host}/sharecodes/eventId/${eventId}`);

  useEffect(() => {
    const co = config.serverConfig?.gamesConfig?.find(((gc) => gc.id === gameId));
    if (co) {
      setGameConfig(co);
    }
  }, []);

  useEffect(() => {
    getUserPreRegistrations();
  }, [gameConfig]);

  const renderRegistrationStatusSection = (): JSX.Element => {
    const isReferred = currentPreReg?.referredBy?.status === ReferralStatus.ACCEPTED;

    if (userRegistrationStatus === RegistrationStatus.UNREGISTERED) {
      return (
        <>
          <div className='description'>
            {t('translation:account.my-account.registration.registerPlaytestDescription')}
          </div>
          <button
            key='mym-unregistered-btn'
            type='button'
            data-cy='register-playtest-btn'
            className='btn btn-primary-game btn-block mx-auto'
            onClick={() => history.push(`${match.url}/preregistration/survey`)}
          >
            {t('translation:account.my-account.registration.registerForPlaytest')}
          </button>
        </>
      );
    }
    return (
      <>
        <div className='item-status' data-cy={`game-${gameId}-${userRegistrationStatus}`}>
          <div>{t(`translation:account.my-account.registration.${userRegistrationStatus}`)}</div>
          <div className={`status ${userRegistrationStatus}`} />
        </div>
        {isReferred && (
          <div className='registration-statius-referred'>
            <span>
              {t('translation:account.my-account.registration.referred')}
            </span>
          </div>
        )}
        <button
          disabled={(userRegistrationStatus !== RegistrationStatus.SELECTED
            && userRegistrationStatus !== RegistrationStatus.GRANTED)}
          style={{ pointerEvents: userRegistrationStatus === RegistrationStatus.REGISTERED
            || userRegistrationStatus === RegistrationStatus.SELECTED_NO_CLAIM
            ? 'none' : 'auto' }}
          type='button'
          key='status-button'
          data-cy='registration-status-button'
          className='btn btn-primary-game btn-block mx-auto'
          onClick={handlePreregClick}
        >
          {userRegistrationStatus === RegistrationStatus.GRANTED
            ? t('translation:account.my-account.registration.seeSteam')
            : t('translation:account.my-account.registration.claimGame')}
        </button>
      </>
    );
  };
  return (
    <>
      {userRegistrationStatus !== RegistrationStatus.UNAVAILABLE
      && (
        <>
          <div data-cy={`${gameId}-playtest-registration`}>
            <div className='subtitle'>
              <h3 className='register-playtest-title'>{t('translation:account.my-account.closedPlaytest')}</h3>
              <Tooltip id='tooltip.registerPlaytest' message='tooltip.registerPlaytest' />
            </div>
            <div className='items-container text-center'>
              {userRegistrationStatus === RegistrationStatus.UNREGISTERED ? (
                <h4>{t('translation:account.my-account.registration.registration')}</h4>
              ) : (
                <h4>{t('translation:account.my-account.registration.registrationStatus')}</h4>
              )}

              {renderRegistrationStatusSection()}
              <button
                type='button'
                data-cy='referral-btn'
                className='btn btn-secondary full-width btn-refer'
                onClick={() => setShowReferralModal(true)}
              >
                {t('translation:account.my-account.referrals.referBtn')}
              </button>
            </div>
          </div>
        </>
      )}

      {preRegistration?.id && (
        <>
          <Modal
            show={showClaimModal}
            isInfoModal
            close={closeClaimModal}
            textConfirm={t('translation:button.confirm')}
            textClose={t('translation:common.cancel')}
            maxWidth={800}
          >
            <div className='form-light'>
              <ClaimSteamComponent
                accessCode={currentPreReg?.providerKey?.accessCode || null}
                steamKey={currentPreReg?.providerKey?.key || null}
                preRegId={preRegistration.id}
                gameId={gameId}
              />
            </div>
          </Modal>
          <Modal
            show={showlinkModal}
            close={handleCloseLinkModal}
            textClose={t('translation:button.close')}
            maxWidth={800}
          >
            <div className='form-light'>
              <Typography variant='h5' className='mb-3'>
                {t('translation:preregistration.claim.providerLink')}
              </Typography>
              <ProviderLink provider='steam' />
            </div>
          </Modal>
          <Modal show={showReferralModal} isInfoModal close={() => setShowReferralModal(false)} maxWidth={625}>
            {userRegistrationStatus === RegistrationStatus.GRANTED
              && (preRegistration.referral?.eventId && preRegistration.referral?.referralUrl)
              && (!currentPreReg?.referredBy || preRegistration.referral?.chaining)
              && preRegistration.referral?.limit > 0 ? (
                <ReferralComponent
                  eventId={preRegistration.referral?.eventId}
                  generateCode={generateReferralLink}
                  getCode={getReferralLink}
                />
              ) : (
                <ShareReferralLinkComponent
                  eventName={eventName}
                  shareLink={`/${gameId}/${preRegistration.id}`}
                />
              )}
          </Modal>
        </>
      )}
    </>
  );
}
