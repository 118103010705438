import { CircularProgress } from '@mui/material';
import React from 'react';
import { ActionButton } from '../buttons/ActionButton';
import { Labels } from './Labels';

interface AdminBarProps {
  isSaving: boolean,
  onSave: () => Promise<void>,
  exportFile: () => void
  isEditting: boolean,
  toggleEdit: () => void
  saveEnabled?: boolean
  labels?: Labels
}

export function AdminBar(props: AdminBarProps): JSX.Element {
  const { isEditting, isSaving, saveEnabled, onSave, exportFile } = props;

  const saveGameConfig = async (): Promise<void> => {
    await onSave();
  };

  return (
    <div className='toggle-editor'>
      <ActionButton
        disabled={isSaving}
        extraClass={`toggle ${isEditting ? 'is-light' : ''} `}
        action={() => props.toggleEdit()}
      >
        { props.labels.toggleEdit }
      </ActionButton>
      {isEditting && (
        <ActionButton extraClass={`toggle ${isEditting ? 'is-light' : ''} `} action={() => exportFile()}>
          { props.labels.export }
        </ActionButton>
      )}
      {isEditting && (
        isSaving
          ? <div style={{ minWidth: 73 }}><CircularProgress /></div>
          : (
            <ActionButton
              extraClass={`toggle ${isEditting ? 'is-light' : ''} ${!saveEnabled ? 'is-disabled' : ''}`}
              action={() => saveGameConfig()}
              disabled={!saveEnabled}
            >
              { props.labels.save }
            </ActionButton>
          )
      )}
    </div>
  );
}
