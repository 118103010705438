/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from '../../modal/ModalFooter';
import { ModalFlowContainer } from '../../modal/modal-flow-container/ModalFlowContainer';
import { ModalFlowContent } from '../../modal/modal-flow-container/ModalFlowContent';
import { RedeemingGameProvider } from '../../../account/providers/ProviderEnum';
import { SyncedAccountsCircle } from './synced-accounts-circle/SyncedAccountsCircle';

interface Props {
  next: () => void;
  cancel: () => void;
  platformStatuses: {
    id: RedeemingGameProvider;
    synced: boolean;
  }[];
  selectedPlatform: RedeemingGameProvider;
}

export function StepList({ next, cancel, platformStatuses, selectedPlatform }: Props): JSX.Element {
  const { t } = useTranslation();
  const platformName = t(selectedPlatform);
  const steps = t('cross-progression.step-list.steps', {
    returnObjects: true,
    platform: platformName,
  }) as string[];

  return (
    <ModalFlowContainer title={t('cross-progression.step-list.title', { platform: platformName })}>
      <ModalFlowContent name='cross-progression-step-list'>
        <SyncedAccountsCircle platforms={platformStatuses} selectedPlatform={selectedPlatform} />
        <p className='cross-progression--subtitle'>
          {t('cross-progression.step-list.subtitle')}
        </p>
        <div className='cross-progression-stepper'>
          <Stepper orientation='vertical'>
            {Array.isArray(steps) && steps.map((step, index) => (
              <Step key={`cross-progression-step-${index}`}>
                <StepLabel>
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </ModalFlowContent>
      <ModalFooter
        name='cross-progression'
        nextDisabled={false}
        nextLabel={t('button.start')}
        cancelLabel={t('common.cancel')}
        nextHandler={next}
        cancelHandler={cancel}
      />
    </ModalFlowContainer>
  );
}
