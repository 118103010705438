/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import * as H from 'history';
import WalkieTalkIframe from '@bhvr/walkie-talkiframe';

let iframe: WalkieTalkIframe;

export default (history: H.History): void => {
  if (window.self === window.top) {
    return;
  }

  iframe = new WalkieTalkIframe(window.parent, {
    changeLocation: (pathname: string) => {
      if (history.location.pathname !== pathname) {
        history.push(pathname);
      }
    },
  });

  iframe.initIframe().then(() => {
    iframe.initContext();
  });
};

export async function getButlerJWT(reset?: boolean): Promise<string> {
  if (!iframe) {
    return undefined;
  }
  await iframe.waitForInit();

  return window.wtCtx.getButlerJWT(reset);
}
