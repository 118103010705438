/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LinkingGameProvider, RedeemingGameProvider,
} from '../../../../../account/providers/ProviderEnum';
import {
  ActivationStepComplete,
  StatusMessage,
} from './ActivationStepComplete';
import { getProviderLogo } from '../../../../../account/providers/ProviderLogo';
import { ProviderSelection } from './ProviderSelection';
import { RequestError, isRequestError } from '../../../../../Error.model';
import { put } from '../../../../../utils/Request';
import { useSnackbar } from '../../../../../snackbar/SnackbarContext';
import { useAuth } from '../../../../../authentication/AuthenticationContext';
import { StreamingCampaign } from '../../../../../streaming-campaign/StreamingCampaign.model';
import { useConfig } from '../../../../../contexts/ConfigContext';

interface Props {
  campaign: StreamingCampaign;
  selectedProvider: RedeemingGameProvider | null;
  isGameStatusValid: (provider: RedeemingGameProvider) => Promise<boolean>;
  setProviderError: (providerError: StatusMessage | null) => void;
  gameNotOpenedError: StatusMessage;
  setCheckedForProviderError: (checkedForProviderError: boolean) => void;
  providerError: StatusMessage;
  setError: (e: RequestError | null) => void;
  setSelectedProvider: (selectedProvider: RedeemingGameProvider | null) => void;
  relatedLinkProvider: LinkingGameProvider;
}

export function ActivationStepProvider({
  campaign,
  selectedProvider,
  isGameStatusValid,
  setProviderError,
  gameNotOpenedError,
  setCheckedForProviderError,
  providerError,
  setError,
  setSelectedProvider,
  relatedLinkProvider,
}: Props): JSX.Element {
  const { gameId, supportedProviders } = campaign;
  const { t } = useTranslation();
  const config = useConfig();
  const snackbar = useSnackbar();
  const { user } = useAuth();

  const userGameAccount = user?.accounts.find((account) => account.type === relatedLinkProvider) || null;
  const gameAccountUserName = userGameAccount?.userName;

  const resetProvider = (): void => { setSelectedProvider(null); };

  const setPreferredProvider = async (provider: RedeemingGameProvider): Promise<void> => {
    try {
      await put(`${config.clientConfig?.host}/players/me/inventory/preferred-provider/${gameId}/${provider}`);
      setSelectedProvider(provider);
    } catch (e) {
      if (isRequestError(e)) {
        snackbar.addErrorMessage(e.code);
      } else {
        snackbar.addErrorMessage('UnknownError');
      }
    }
  };

  useEffect(() => {
    const validateProvider = async (): Promise<void> => {
      if (selectedProvider) {
        const gameStatusValid = await isGameStatusValid(selectedProvider);
        if (!gameStatusValid) {
          setProviderError(gameNotOpenedError);
        }
        setCheckedForProviderError(true);
      } else {
        setProviderError(null);
        setCheckedForProviderError(false);
      }
    };

    validateProvider();
  }, [selectedProvider]);

  return (
    <>
      {selectedProvider && (
        <ActivationStepComplete
          imgSrc={getProviderLogo(selectedProvider)}
          imgAlt={`${selectedProvider}-img`}
          details={gameAccountUserName}
          chipLabel={t('account.provider.selected')}
          statusMessages={providerError ? [providerError] : null}
          imgLayoutId={`${selectedProvider}-preferred-provider`}
        >
          <button
            type='button'
            onClick={resetProvider}
            className='btn btn-secondary'
            data-cy='change-provider-btn'
          >
            {t('button.change')}
          </button>
        </ActivationStepComplete>
      )}
      {!selectedProvider && (
        <ProviderSelection
          providers={supportedProviders}
          setSelectedProvider={setPreferredProvider}
          setProviderError={setError}
        />
      )}
    </>
  );
}
