/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';

import { ReactComponent as Facebook } from '../_assets/facebook.svg';
import { ReactComponent as Twitter } from '../_assets/twitter.svg';
import { ReactComponent as Linkedin } from '../_assets/linkedin.svg';
import { ReactComponent as Youtube } from '../_assets/youtube.svg';
import { ReactComponent as Instagram } from '../_assets/instagram.svg';

type Social = 'facebook' | 'linkedin' | 'twitter' | 'youtube' | 'instagram';

interface Props {
  social: Social;
  href: string;
}

export function SocialIcon({ social, href }: Props): JSX.Element {
  const logo = (name: Social): JSX.Element => {
    switch (name) {
      case 'facebook':
        return <Facebook />;
      case 'linkedin':
        return <Linkedin />;
      case 'twitter':
        return <Twitter />;
      case 'instagram':
        return <Instagram />;

      default:
        return <Youtube />;
    }
  };

  return (
    <a
      id={`footer-socials-${social}`}
      className='social-icon-link'
      href={href}
      target='_blank'
      rel='noopener noreferrer'
    >
      {logo(social)}
      {/* <img src= alt={`${t('footer.logo')} ${social}`} width='30' /> */}
    </a>
  );
}
