/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTranslation } from 'react-i18next';
import { GameIds } from '../../../../../account/my-games/MyGames.model';

interface Props {
  gameId: GameIds;
  participatingChannelsLink: string;
}

export function ActivationStepClaim({ gameId, participatingChannelsLink }: Props): JSX.Element {
  const { t } = useTranslation(['streamingCampaigns']);
  return (
    <div className='streaming-campaign-claim-buttons'>
      <a
        href={t(`${gameId}.linkClaim`)}
        target='_blank'
        rel='noreferrer'
        className='btn btn-primary-game'
        data-cy='claim-rewards-link'
      >
        <OpenInNewIcon />
        {t('button.claim-rewards')}
      </a>
      {participatingChannelsLink && (
        <a
          href={participatingChannelsLink}
          target='_blank'
          rel='noreferrer'
          className='btn btn-secondary'
          data-cy='participating-streams-link'
        >
          <OpenInNewIcon />
          {t('button.participating-streams')}
        </a>
      )}
    </div>
  );
}
