import React from 'react';
import { useMktConsentPromotion } from '../../contexts/MktConsentPromotionContext';
import { PromotionCard as MktConsentPromotionCard } from '../../marketing-consent/promotion-card/PromotionCard';

export function CommunicationPreferencesPromotions(): JSX.Element {
  const { promotions } = useMktConsentPromotion();

  if (!promotions.length) {
    return <></>;
  }

  return (
    <div className='communication-preferences-mkt-consent-promotions'>
      {promotions.map((promotion) => (
        <MktConsentPromotionCard
          key={promotion.promotionData.promotionId}
          promotion={promotion}
        />
      ))}
    </div>
  );
}
