/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import { isRequestError } from '../../../../Error.model';
import { useAuth } from '../../../../authentication/AuthenticationContext';
import { patch } from '../../../../utils/Request';
import { formatDate, getTimeUnitFromDurationInMinutes } from '../../../../utils/Time';
import { MediaCardStatus, MediaCardCtaOptions, MediaCard } from '../../../media-card/MediaCard';
import { useSnackbar } from '../../../../snackbar/SnackbarContext';
import { TMedia, getImageUrl } from '../../../../utils/ComponentsManager';
import { useConfig } from '../../../../contexts/ConfigContext';
import { StreamingCampaignActivationStep, useStreamingCampaign } from '../../../../contexts/StreamingCampaignContext';

export const definition: Definition = {
  itemId: {
    type: DefinitionPropertyType.String,
  },
  name: {
    type: DefinitionPropertyType.TranslatedString,
  },
  picture: {
    type: DefinitionPropertyType.Image,
  },
  watchTimeInMinutes: {
    type: DefinitionPropertyType.String,
  },
  badges: {
    type: DefinitionPropertyType.Array,
    elements: {
      name: { type: DefinitionPropertyType.TranslatedString },
    },
  },
  claimableFrom: {
    type: DefinitionPropertyType.Date,
  },
  claimableTo: {
    type: DefinitionPropertyType.Date,
  },
};

interface Props {
  itemId: string,
  name: string,
  picture: TMedia,
  watchTimeInMinutes: string,
  badges: Array<{ name: string }>,
  claimableFrom: Date,
  claimableTo: Date,
}

export function Reward({ itemId,
  name,
  picture,
  watchTimeInMinutes,
  badges,
  claimableFrom,
  claimableTo }: Props)
  : JSX.Element {
  const { user } = useAuth();
  const config = useConfig();
  const { getPlayer } = useAuth();
  const { t, i18n } = useTranslation(['streamingCampaigns', 'translation']);
  const snackbar = useSnackbar();
  const { campaign, activationStep } = useStreamingCampaign();

  const locale = i18n.language;
  const dateFormat: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
  const convertedWatchTime = getTimeUnitFromDurationInMinutes(+watchTimeInMinutes);

  const gameId = campaign?.gameId || null;
  const inventory = user?.inventory?.[campaign?.gameId];

  const inventoryItem = inventory?.items.find((item) => item.itemId === itemId) || null;

  const retryClaim = async (item: string): Promise<void> => {
    try {
      await patch(`${config.clientConfig?.host}/players/me/inventory/${gameId}/${item}/claim`);
      await getPlayer();
      snackbar.addSuccessMessage('rewards.retry-claim-success', {}, 'streamingCampaigns');
    } catch (e) {
      if (isRequestError(e)) {
        snackbar.addErrorMessage(e.code, e.data);
      } else {
        snackbar.addErrorMessage('UnknownError');
      }
    }
  };

  const getRewardClaimStatus = (): MediaCardStatus => {
    if (inventoryItem?.status === 'claimed') {
      return { type: 'success', text: t('rewards.status.claimed'), showIcon: true };
    } if (inventoryItem?.status === 'pending') {
      return { type: 'warning', text: t('rewards.status.pending'), showIcon: true };
    } if (campaign?.currentPhase === 'teasing') {
      return { type: 'error', text: t('rewards.status.upcoming') };
    } if (campaign?.currentPhase === 'started') {
      return { type: 'error', text: t('rewards.status.in-progress') };
    }
    return { type: 'error', text: t('rewards.status.finished') };
  };

  const getCtaOptions = (): MediaCardCtaOptions | null => (inventoryItem?.status === 'pending'
    ? {
      cta: () => retryClaim(inventoryItem.itemId),
      label: t('button.retry-claim'),
      disabled: activationStep !== StreamingCampaignActivationStep.CLAIM,
    }
    : null);

  return (
    <MediaCard
      key={itemId}
      status={getRewardClaimStatus()}
      imgUrl={getImageUrl(picture)}
      imgAlt={name}
      title={name}
      badges={badges.flatMap((x) => x.name)}
      details={t(
        'rewards.duration',
        {
          startDate: formatDate(claimableFrom, locale, dateFormat),
          endDate: formatDate(claimableTo, locale, dateFormat),
        },
      )}
      text={t(
        'rewards.unlock-description',
        {
          time: convertedWatchTime?.time || 0,
          timeUnit: t(`translation:time.${convertedWatchTime?.timeUnit}`),
          provider: t(`translation:${campaign?.streamingPlatform}`),
        },
      )}
      ctaOptions={getCtaOptions()}
      className='reward--media-card'
    />

  );
}

export default Reward;
