/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { post } from '../../utils/Request';
import { useConfig } from '../../contexts/ConfigContext';

type Return = {
  saveCurrent: () => Promise<void>;
  completeFlow: () => Promise<void>;
  isLoading: boolean;
};

const areValidUtmParams = (params: URLSearchParams): boolean => (
  params.has('utm_source')
  && params.has('utm_medium')
  && params.has('utm_campaign')
);

export const useUtm = (): Return => {
  const { search } = useLocation();
  const config = useConfig();
  const [isLoading, setIsLoading] = useState(true);

  const saveCurrent = useCallback(async (): Promise<void> => {
    if (isLoading) return;
    // if (!window.Osano?.cm?.analytics) return;

    const params = new URLSearchParams(search);

    if (!areValidUtmParams(params) || !config.isClientConfigLoad) return;

    const utm = {
      utm_source: params.get('utm_source'),
      utm_medium: params.get('utm_medium'),
      utm_campaign: params.get('utm_campaign'),
      utm_term: params.get('utm_term'),
      utm_content: params.get('utm_content'),
    };

    // Retrieve general domain from hostname to allow setting cookies across subdomains
    const { hostname } = window.location;
    const hnParts = hostname.split('.');
    const domain = hnParts.slice(-2).join('.');

    Cookies.set(`utm_${params.get('utm_campaign')}`, JSON.stringify(utm), {
      expires: 1000 * 60 * 60 * 24 * 30, // 30 days
      sameSite: 'strict',
      domain: `.${domain}`,
    });
  }, [config]);

  useEffect(() => {
    if (config.isClientConfigLoad) setIsLoading(false);

    const onAnalyticsAccepted = (): void => {
      saveCurrent();
    };
    window.Osano.cm.addEventListener('osano-cm-analytics', onAnalyticsAccepted);

    return () => {
      window.Osano.cm.removeEventListener('osano-cm-analytics', onAnalyticsAccepted);
    };
  }, [config]);

  const completeFlow = useCallback(async (): Promise<void> => {
    // if (!window.Osano?.cm.analytics || !config.isClientConfigLoad) return;

    await post(`${config.clientConfig.host}/segmentation/utm`);
  }, [config]);

  return { saveCurrent, completeFlow, isLoading };
};
