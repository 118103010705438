/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect } from 'react';
import { Alert, AlertTitle, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReferralCode } from './ReferralCodeContext';
import './referral.scss';
import { myI18n } from '../i18n';

export function ReferralCodeManager(): JSX.Element {
  const { t } = useTranslation();
  const { referralCodeData, errorCode } = useReferralCode();

  useEffect(() => {
    if (referralCodeData && !errorCode) {
      window.location.href = `${referralCodeData.action}/${referralCodeData.id}`;
    }
  }, [referralCodeData]);

  return (
    <div>
      {!referralCodeData && !errorCode && (
        <div className='loader' data-cy='loader'>
          <CircularProgress />
        </div>
      )}
      {errorCode && (
        <div className='error-container' data-cy='error-container'>
          <Alert severity='error'>
            <AlertTitle>{t('referral.error.title')}</AlertTitle>
            {myI18n.exists(`error:${errorCode}`)
              ? t(`error:${errorCode}`)
              : t('error:ReferralCodeInvalidOrExpired')}
          </Alert>
        </div>
      )}
    </div>
  );
}
