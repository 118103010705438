/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

const mymAlphaQuestions = [
  {
    id: 'q1',
    title: 'What is your preferred gameplay experience?',
    choices: [
      'Exclusively Solo',
      'Mainly Solo',
      'Both Equally',
      'Mainly Multiplayer or Co-op',
      'Exclusively Multiplayer or Co-op',
    ],
  },
  {
    id: 'q2',
    title: 'Which of these peripherals are you comfortable playing with on PC?',
    subtitle: '(Check all that apply)',
    choices: ['Mouse and Keyboard', 'Playstation Controller', 'Xbox Controller', 'Other'],
    multiple: true,
  },
  {
    id: 'q3',
    title: 'In a typical week, how many hours do you play video games?',
    subtitle: '(Mobile playtime included)',
    choices: [
      '1 to 5 hours',
      '6 to 10 hours',
      '11 to 15 hours',
      '16 to 20 hours',
      '21 to 25 hours',
      'More than 25 hours',
    ],
  },
  {
    id: 'q4',
    title:
      'Read each pair of statements, then move the slider towards the one that fits you best or most often if both statements fit.',
    choices: ['Much More', 'More', 'Slightly More', 'Slightly More', 'More', 'Much More'],
    rows: [
      {
        id: 0,
        value: ['Solving puzzles in game can easily get boring.', 'It feels rewarding to complete puzzles in game.'],
      },
      {
        id: 1,
        value: ['I prefer to play games that challenge my mind.', 'I prefer to play games that challenge my dexterity.'],
      },
      {
        id: 2,
        value: [
          'I play games mainly to compete against other players.',
          'Competition is simply one of many aspects of the games I play.',
        ],
      },
      {
        id: 3,
        value: ['The narrative is what draws me to a game.', 'The gameplay is what draws me to a game'],
      },
      {
        id: 4,
        value: [
          'I prefer games with a story that I can immerse myself within.',
          'I prefer games that let me express my creativity on the world.',
        ],
      },
      {
        id: 5,
        value: [
          'I prefer solving complex problems with creativity.',
          'I focus on reaching the next level the most efficiently.',
        ],
      },
      {
        id: 6,
        value: ['I aim to achieve the highest ranking.', 'I prefer to play just for the entertainment.'],
      },
    ],
  },
  {
    id: 'q5',
    title: 'How important are the following for you when playing?',
    choices: ['Not At All', 'Slightly', 'Moderately', 'Very', 'Extremely'],
    rows: [
      { id: 0, value: 'Collecting (e.g. gathering all kinds of items important to you)' },
      { id: 1, value: 'Exploring (e.g. discovering new places and worlds)' },
      { id: 2, value: 'Mastery (e.g. challenging myself with content requiring high skill levels)' },
      { id: 3, value: 'Narrative Immersion (e.g. having a deep experience with a story)' },
      { id: 4, value: 'Problem solving (e.g. puzzles and logic based content)' },
      { id: 5, value: 'Progression (e.g. earning currencies, upgrading and leveling up)' },
      { id: 6, value: 'Creating (e.g. designing, planning, and building things of my own)' },
      { id: 7, value: 'Discovery (e.g. learning something new through experiments)' },
      { id: 8, value: 'Social (e.g. playing or discussing with players)' },
    ],
  },
  {
    id: 'q6',
    title: 'Which of the following game genres are you the most interested in playing on console or PC?',
    subtitle: '(Check all that apply - max : 5)',
    choices: [
      'Action-Adventure',
      'Action-RPG',
      'Dungeon Crawlers',
      'MMO',
      'Card Battler',
      'Puzzle',
      'MOBA',
      'Fighting',
      'Sports',
      'Racing',
      'Strategy',
      'Action Shooters',
      'Tactical Shooters',
      'Survival',
      'Horror',
      'Battle Royale',
      'Platformers',
      'Sandbox',
    ],
    multiple: true,
    max: 5,
  },
  {
    id: 'q7',
    title: 'Of the following games, which have you played for more than 20 hours, or completed over 50% of the story?',
    subtitle: '(Check all that apply)',
    choices: [
      'Dead by Daylight',
      'Minecraft',
      'Mario Maker',
      'Satisfactory',
      'Portal 2',
      'Doom Eternal',
      'Rainbow 6: Siege',
      'Deep Rock Galactic',
      'Pay Day 2',
      'Fallout 4',
      'None',
    ],
    multiple: true,
  },
  {
    id: 'q8',
    title: 'How would you identify your skill level in Dead by Daylight?',
    choices: ['Beginner', 'Intermediate', 'Veteran', 'Expert'],
    requires: {
      questionId: 'q7',
      choice: 0,
    },
  },
  {
    id: 'q9',
    title: 'Are you a content creator?',
    choices: ['Yes', 'No'],
  },
  {
    id: 'q10',
    title: 'What is your content creator name?',
    requires: {
      questionId: 'q9',
      choice: 0,
    },
    textFormat: 'text',
  },
  {
    id: 'q11',
    title: 'Where can we find your work (platform link / main channel)?',
    requires: {
      questionId: 'q9',
      choice: 0,
    },
    textFormat: 'url',
  },
  {
    id: 'q12',
    title: 'Please provide a link to your main social channel media page (Twitter, Instagram, etc)',
    requires: {
      questionId: 'q9',
      choice: 0,
    },
    textFormat: 'url',
  },
  {
    id: 'q13',
    title: 'Please provide us with your business email address',
    requires: {
      questionId: 'q9',
      choice: 0,
    },
    textFormat: 'email',
  },
];

export { mymAlphaQuestions };
