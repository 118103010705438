// We reimplemented the set function because lodash is considering that a number key correspond to an array
// https://github.com/lodash/lodash/blob/main/src/.internal/baseSet.ts#L39
// We want to keep an object an object even if the key is a number
import deepMerge from 'deepmerge';

type SetOptions = {
  keepSource: boolean
};

// The fields can be a string if it is a single field, x.y.z will not work in this implementation
// This implementation consider that a non existing key in a object is an object
export const set = (
  source: unknown,
  fields: string | (string | number)[],
  value: unknown,
  options?: SetOptions,
  index = 0,
): unknown => {
  if (typeof source !== 'object') {
    return source;
  }

  const target = { ...source };

  if (typeof fields === 'string') {
    // We only support a single field as string
    target[fields] = value;
    return target;
  }

  const currentField = fields[index];

  if (index === fields.length - 1) {
    if (options?.keepSource && typeof value === 'object' && !Array.isArray(value)) {
      target[currentField] = deepMerge(target[currentField], value);
    } else {
      target[currentField] = value;
    }
  } else {
    if (!target[currentField]) {
      // We only need objects
      target[currentField] = {};
    }

    target[currentField] = set(target[currentField], fields, value, options, index + 1);
  }

  return target;
};
