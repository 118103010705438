export function getChangedFormFields(
  initialFormValues: Record<string, unknown>,
  formValues: Record<string, unknown>,
): string[] {
  const changedFields = Object.keys(formValues).reduce((
    acc: string[],
    field,
  ) => {
    if (initialFormValues[field] !== formValues[field]) {
      acc.push(field);
    }
    return acc;
  }, []);
  return changedFields;
}
