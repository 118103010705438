import React from 'react';
import { useTranslation } from 'react-i18next';
import { Reward } from '../../../account/promotion/Promotion.model';
import { RewardImageList } from '../../reward-image-list/RewardImageList';
import { buildTranslatedListOfStrings } from '../../../utils/Translations';

interface Props {
  rewards: Reward[]
}

export function Description({ rewards }: Props): JSX.Element {
  const { t } = useTranslation(['promotions']);

  const rewardsName = rewards.map((reward) => t(`${reward.displayName}`));
  const rewardsListTranslation = buildTranslatedListOfStrings(rewardsName);

  const description = t(
    'translation:promotion.marketing-consent.description',
    { reward: rewardsListTranslation },
  );

  return (
    <div className='reward-step-description'>
      <h6 className='reward-step-description--title'>{description}</h6>
      <RewardImageList rewards={rewards} />
    </div>
  );
}
