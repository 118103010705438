/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { MouseEvent, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import globe from '../../_assets/globe.svg';

export function LanguageSelector(): JSX.Element {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [isShow, setIsShow] = useState(false);

  const toggleDropdown = (): void => {
    setIsShow(!isShow);
  };

  const changeLanguage = (ev: MouseEvent, lng: string): void => {
    ev.preventDefault();
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
    document.documentElement.lang = lng;

    if (window.Osano) {
      window.Osano('locale', lng);
    }

    toggleDropdown();
  };

  return (
    <div className='translation-dropdown'>
      <button
        id='header-language'
        data-cy='select-language-button'
        type='button'
        className='btn btn-fa'
        onClick={toggleDropdown}
      >
        <img
          src={globe}
          className='globe-language'
          alt={t(`countries.iso-to-name.${i18n.language}`)}
        />
      </button>
      <div data-cy='languages-dropdown' className={`dropdown-menu translation-dropdown-menu ${isShow ? 'show' : ''}`}>
        <ul className='m-0 py-2 px-4 text-uppercase'>
          <li id='header-language-en' className={`nav-item ${i18n.language === 'en' ? 'active' : ''}`}>
            <Link
              className='nav-link'
              to={location.pathname}
              onClick={(ev) => changeLanguage(ev, 'en')}
            >
              { t('countries.iso-to-name.en') }
            </Link>
          </li>

          <li id='header-language-fr' className={`nav-item ${i18n.language === 'fr' ? 'active' : ''}`}>
            <Link
              className='nav-link'
              to={location.pathname}
              onClick={(ev) => changeLanguage(ev, 'fr')}
            >
              { t('countries.iso-to-name.fr') }
            </Link>
          </li>

          <li id='header-language-es' className={`nav-item ${i18n.language === 'es' ? 'active' : ''}`}>
            <Link
              className='nav-link'
              to={location.pathname}
              onClick={(ev) => changeLanguage(ev, 'es')}
            >
              { t('countries.iso-to-name.es') }
            </Link>
          </li>

          <li id='header-language-de' className={`nav-item ${i18n.language === 'de' ? 'active' : ''}`}>
            <Link
              className='nav-link'
              to={location.pathname}
              onClick={(ev) => changeLanguage(ev, 'de')}
            >
              { t('countries.iso-to-name.de') }
            </Link>
          </li>

          <li
            id='header-language-pt'
            className={`nav-item ${i18n.language === 'pt' ? 'active' : ''}`}
          >
            <Link
              className='nav-link'
              to={location.pathname}
              onClick={(ev) => changeLanguage(ev, 'pt')}
            >
              { t('countries.iso-to-name.pt') }
            </Link>
          </li>

          <li id='header-language-ja' className={`nav-item ${i18n.language === 'ja' ? 'active' : ''}`}>
            <Link
              className='nav-link'
              to={location.pathname}
              onClick={(ev) => changeLanguage(ev, 'ja')}
            >
              { t('countries.iso-to-name.ja') }
            </Link>
          </li>

          <li id='header-language-ru' className={`nav-item ${i18n.language === 'ru' ? 'active' : ''}`}>
            <Link
              className='nav-link'
              to={location.pathname}
              onClick={(ev) => changeLanguage(ev, 'ru')}
            >
              { t('countries.iso-to-name.ru') }
            </Link>
          </li>

          <li
            id='header-language-zh'
            className={`nav-item ${i18n.language === 'zh' ? 'active' : ''}`}
          >
            <Link
              className='nav-link'
              to={location.pathname}
              onClick={(ev) => changeLanguage(ev, 'zh')}
            >
              { t('countries.iso-to-name.zh') }
            </Link>
          </li>

          <li
            id='header-language-zht'
            className={`nav-item ${i18n.language === 'zht' ? 'active' : ''}`}
          >
            <Link
              className='nav-link'
              to={location.pathname}
              onClick={(ev) => changeLanguage(ev, 'zht')}
            >
              { t('countries.iso-to-name.zht') }
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
