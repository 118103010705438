/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import { LINKING_GAME_PROVIDERS, LinkingGameProvider } from '../../../account/providers/ProviderEnum';
import { ProviderLink } from '../../../account/providers/provider/ProviderLink';

const providerOptions = Object.values(LINKING_GAME_PROVIDERS).reduce(
  (acc, provider) => (
    { ...acc, [provider]: provider.toLocaleUpperCase() }),
  {},
);

export const definition: Definition = {
  title: {
    type: DefinitionPropertyType.TranslatedString,
  },
  provider: {
    type: DefinitionPropertyType.Select,
    options: providerOptions,
  },
};

interface Props {
  title: string;
  provider: LinkingGameProvider;
}

export function ProviderLinkCard({ title, provider }: Props) : JSX.Element {
  return (
    <div className='provider-link-card-container'>
      {title ? (
        <div className='subtitle'>
          <h3>{title}</h3>
        </div>
      ) : <></>}
      <ProviderLink provider={provider || 'steam'} />
    </div>
  );
}

export default ProviderLinkCard;
