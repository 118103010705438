/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GameIds } from '../account/my-games/MyGames.model';
import { Promotion } from '../account/promotion/Promotion.model';
import { usePromotion } from '../contexts/PromotionContext';
import { useAuth } from '../authentication/AuthenticationContext';
import { PromotionCard } from '../account/promotion/PromotionCard';

interface Props {
  requiredGameId: GameIds;
}

export function Activation({ requiredGameId }: Props): JSX.Element {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [promotion, setPromotion] = useState<Promotion>(null);

  const { groupedPromotions } = usePromotion();

  useEffect(() => {
    const gamePromos = groupedPromotions?.find((x) => x.requiredGameId === requiredGameId);
    if (gamePromos?.promotions?.length) {
      setPromotion(gamePromos.promotions[0]);
    }
  }, [groupedPromotions]);

  return (
    <>
      {promotion
        && (
          <div className={`activation-wrapper ${requiredGameId}`}>
            <div className='activation-container'>
              <h3>
                {`${t('promotion.page.welcome')} ${user ? user.nickName : ''}`}
              </h3>
              <h2 className='activation-title'>
                {t(
                  'promotion.page.title',
                  {
                    requiredGame: t(`games.${requiredGameId}`),
                    rewardGame: t(`games.${promotion.promotionData.relatedToGameId}`),
                  },
                )}
              </h2>
              <div className='activation-description'>
                <PromotionCard
                  promotion={promotion}
                />
              </div>
            </div>
          </div>
        )}
    </>
  );
}
