/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { RequestError } from '../../Error.model';
import { useSnackbar } from '../../snackbar/SnackbarContext';
import { put } from '../../utils/Request';
import { PasswordInput } from '../../_shared/form/PasswordInput';
import { EmailInput } from '../../_shared/form/EmailInput';
import { TextInput } from '../../_shared/form/TextInput';
import { SubmitButton } from '../../_shared/form/SubmitButton';
import { RouteParams } from '../Authentication.model';
import { useConfig } from '../../contexts/ConfigContext';

interface EmailValues {
  email: string
}

interface ResetPasswordValues {
  code: string,
  newPassword: string,
  confirmNewPassword: string,
}

interface ResetPasswordBody {
  email: string,
  code: string,
  expiryDate: string,
  hash: string,
  isEmailVerified: boolean,
  password: string,
}

interface FormValues {
  email: string;
}

export function ForgotPassword({ onSuccess }: { onSuccess: () => void }): JSX.Element {
  const { t } = useTranslation();
  const config = useConfig();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [forgotEmail, setForgotEmail] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [expiryDate, setExpiryDate] = useState<string>('');
  const [hash, setHash] = useState<string>('');
  const params = useParams<RouteParams>();

  const initialValuesFormik = {
    code: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const initialValuesFormikEmail: FormValues = {
    email: '',
  };
  const passwordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('form.field-required')
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,33}$/,
        'form.matches-invalid',
      ).matches(
        new RegExp(`^(?!${forgotEmail}$)`, 'i'),
        'form.matches-invalid',
      ),
    confirmNewPassword: Yup.string()
      .required('form.field-required'),
    code: Yup.string()
      .required('form.field-required'),
  });

  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email('form.mail-invalid')
      .required('form.field-required'),
  });

  const changePassword = (
    body: ResetPasswordBody,
    setSubmitting: (isSubmitting: boolean) => void,
    reCaptchaToken?: string,
  ): void => {
    let headers = {};

    if (reCaptchaToken) {
      headers = {
        'g-recaptcha-response': reCaptchaToken,
      };
    }

    put(`${config.clientConfig?.host}/auth/password`, { data: body, headers }).then(() => {
      /**
       * Note: This is a temporary success message.
       * TODO: Get translation for password change success.
       */
      snackbar.addSuccessMessage('account.settings.success');

      history.push(params?.gameId
        ? `/auth/signin/${params.gameId}` : '/');
    }).catch((err: RequestError) => {
      snackbar.addErrorMessage(err.code);
      setSubmitting(false);
    });
  };

  const handleSubmitForm = (
    values: ResetPasswordValues,
    { setFieldError, setSubmitting }: FormikHelpers<ResetPasswordValues>,
  ): void => {
    const { confirmNewPassword, newPassword } = values;

    if (newPassword !== confirmNewPassword) {
      setFieldError('confirmNewPassword', 'form.password-not-identical');
      setSubmitting(false);
      return;
    }

    const body = {
      email: forgotEmail,
      code: values.code,
      expiryDate,
      hash,
      isEmailVerified,
      password: values.newPassword,
    };

    if (config.clientConfig?.recaptchaEnabled) {
      window.grecaptcha.execute(config.serverConfig.website.recaptcha.clientId, { action: 'login' })
        .then((token: string) => {
          changePassword(body, setSubmitting, token);
          onSuccess();
        });
    } else {
      changePassword(body, setSubmitting);
      onSuccess();
    }
  };

  const sendEmail = (
    body: EmailValues,
    setSubmitting: ((isSubmitting: boolean) => void) | null,
    reCaptchaToken?: string,
  ): void => {
    let headers = {};

    if (reCaptchaToken) {
      headers = {
        'g-recaptcha-response': reCaptchaToken,
      };
    }

    put(`${config.clientConfig?.host}/auth/password`, { data: body, headers }).catch((err) => {
      if (err?.message === 'Code is required') {
        setForgotEmail(body.email);
        setShowPassword(true);
        setHash(err.data.hash);
        setExpiryDate(err.data.expiryDate);
        setIsEmailVerified(err.data.isEmailVerified);
      }
      if (setSubmitting) {
        setSubmitting(false);
      }
    });
  };

  const handleSubmitEmail = (
    values: EmailValues | null,
    formValues: FormikHelpers<FormValues> | null,
  ): void => {
    const { setSubmitting = null } = formValues || {};
    const body = {
      email: values ? values.email : forgotEmail,
    };

    if (config.clientConfig?.recaptchaEnabled) {
      window.grecaptcha.execute(config.serverConfig.website.recaptcha.clientId, { action: 'login' })
        .then((token: string) => {
          sendEmail(body, setSubmitting, token);
        });
    } else {
      sendEmail(body, setSubmitting);
    }
  };

  return (
    <div className='m-4'>
      <div className='header-container'>
        <h2 className='header'>
          {forgotEmail ? t('auth.forgot-password-component.update-password')
            : t('auth.forgot-password-component.forgot-password')}
        </h2>
      </div>

      <div>
        {!forgotEmail && (
          <Formik
            initialValues={initialValuesFormikEmail}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={EmailSchema}
            onSubmit={handleSubmitEmail}
          >
            <Form className='form-light'>
              <EmailInput disabled={forgotEmail.length > 0} />
              <SubmitButton />
            </Form>
          </Formik>
        )}

        {showPassword && (
          <Formik
            initialValues={initialValuesFormik}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={passwordSchema}
            enableReinitialize
            onSubmit={handleSubmitForm}
          >
            <Form className='form-light'>
              <div className='form-group'>
                <h4 className='resume-forgot-mail'>{forgotEmail}</h4>
                <p className='email-details'>{t('auth.forgot-password-component.detail')}</p>
              </div>

              <TextInput name='code' aria-describedby='codeHelp' containerClassName='code-input'>
                <small id='codeHelp' className='form-text float-right'>
                  <button
                    type='button'
                    onClick={() => handleSubmitEmail(null, null)}
                  >
                    {t('auth.otp.resend-code')}
                  </button>
                </small>
              </TextInput>
              <PasswordInput name='newPassword' />
              <PasswordInput name='confirmNewPassword' />
              <SubmitButton />
            </Form>
          </Formik>
        )}
      </div>
    </div>
  );
}
