/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ReactElement } from 'react';
import { SvgIconProps } from '@mui/material';

interface Props {
  color?: 'light' | 'dark' | 'success' | 'error' | 'warning' | 'info', // todo: fix type
  label: string,
  customIcon?: ReactElement<SvgIconProps>,
  className?: string,
}

export function Chip({ color = 'dark', label, customIcon, className }: Props): JSX.Element {
  const iconClass = `chip--icon chip--icon--${color}`;

  const customIconWithClass = customIcon ? React.cloneElement(customIcon, {
    className: iconClass,
  }) : null;

  return (
    <div className={`chip ${className ?? ''} ${color}`}>
      {customIconWithClass}
      <span>{label}</span>
    </div>
  );
}
