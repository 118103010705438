/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import mymLogo from '../../../_assets/mym-logo-white.webp';
import { useGameTheme } from '../../../contexts/GameThemeContext';

export function MymWelcomePage(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useGameTheme();

  const handleRegister = ():void => {
    theme.setGameId('mym');
    history.push('/account/my-account/games/mym/preregistration/survey');
  };

  useEffect(() => {
    handleRegister();
  }, []);

  return (
    <div className='mym-welcome-container'>
      <div className='card'>
        <img className='logo-img' src={mymLogo} alt='Meet Your Maker Logo - White' />
        <p>
          {t('account.my-account.registration.registerPlaytestDescription')}
        </p>
        <button
          type='button'
          data-cy='register-playtest-btn'
          className='btn btn-primary-game btn-block'
          onClick={handleRegister}
        >
          {t('account.my-account.registration.registerForPlaytest')}
        </button>
      </div>
    </div>
  );
}
