/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useAuth } from '../../authentication/AuthenticationContext';
import { PromotionData } from '../../account/promotion/Promotion.model';
import { CheckboxInput } from '../../_shared/form/CheckboxInput';
import { RewardImageList } from '../reward-image-list/RewardImageList';
import { RedeemingGameProvider } from '../../account/providers/ProviderEnum';
import { useSnackbar } from '../../snackbar/SnackbarContext';
import { isRequestError } from '../../Error.model';
import { buildTranslatedListOfStrings } from '../../utils/Translations';
import { useMktConsentPromotion } from '../../contexts/MktConsentPromotionContext';
import { get } from '../../utils/Request';
import { ApprovalRequest } from '../../authentication/Authentication.model';
import { getApprovalsRequestsFromForm } from '../../utils/Approvals';
import { MktConsentLegalContentId, MktLegalContentModel } from '../MarketingConsent.model';
import { useConfig } from '../../contexts/ConfigContext';

interface Props {
  provider: RedeemingGameProvider;
  promotion: PromotionData;
  cancelLabel?: string;
  cancelHandler?: () => void;
  submitCallback: (rewardClaimed: boolean) => void;
}

type FormApprovalValues = {
  [approval in MktConsentLegalContentId]: boolean;
};

const falseApprovals: FormApprovalValues = {
  newsletter: false,
  'personalized-ads-offers': false,
};

export function RewardForm({ provider, promotion, cancelLabel, cancelHandler, submitCallback }: Props): JSX.Element {
  const { t } = useTranslation(['promotions']);
  const config = useConfig();
  const auth = useAuth();
  const snackbar = useSnackbar();
  const { claim } = useMktConsentPromotion();
  const [requiredLegalContents, setRequiredLegalContents] = useState<MktLegalContentModel[]>([]);

  const { rewards } = promotion;

  const initialValues = requiredLegalContents
    .reduce((acc: FormApprovalValues, legalContent) => {
      const userApproval = auth.user?.approvals[legalContent.id];
      if (legalContent.version !== userApproval?.version) {
        acc[legalContent.id] = false;
      } else {
        acc[legalContent.id] = !!auth.user?.approvals[legalContent.id]?.approved;
      }
      return acc;
    }, falseApprovals);

  const email = auth.user?.email;
  const rewardNames = rewards.map((reward) => t(`${reward.displayName}`));
  const rewardsListTranslation = buildTranslatedListOfStrings(rewardNames);
  const description = t(
    'translation:promotion.marketing-consent.form-description',
    { reward: rewardsListTranslation },
  );

  const submit = async (values: FormApprovalValues): Promise<void> => {
    try {
      const approvalRequests: ApprovalRequest[] = getApprovalsRequestsFromForm(
        requiredLegalContents,
        initialValues,
        values,
      );
      if (approvalRequests.length) {
        const approvalsChanged = await auth.addApprovals(approvalRequests);

        if (!approvalsChanged) {
          snackbar.addErrorMessage('account.legal-contents.modal.mandatory.failure', undefined, 'translation');
          return;
        }
      }

      const rewardClaimable = !!values.newsletter && !!values['personalized-ads-offers'];

      if (rewardClaimable) {
        await claim(promotion.promotionId, provider);
      }
      submitCallback(rewardClaimable);
    } catch (e) {
      if (isRequestError(e)) {
        snackbar.addErrorMessage(e.code, e.data);
      } else {
        snackbar.addErrorMessage('UnknownError');
      }
    }
  };

  const getRequiredLegalContents = async (promoRequiredApprovals: string[]): Promise<void> => {
    const reqLegalContents = await Promise.all(
      promoRequiredApprovals.map(
        async (legalContentId): Promise<MktLegalContentModel> => get<MktLegalContentModel>(
          `${config.clientConfig?.host}/legal-contents/${legalContentId}`,
        ),
      ),
    );
    setRequiredLegalContents(reqLegalContents);
  };

  useEffect(() => {
    if (promotion.marketingConsents?.requiredApprovals) {
      getRequiredLegalContents(promotion.marketingConsents?.requiredApprovals);
    }
  }, [promotion]);

  const someInitialValuesUnchecked = !initialValues.newsletter || !initialValues['personalized-ads-offers'];

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={submit}
    >

      {({ dirty, values }) => (
        <Form className='form-light'>
          <div className='mkt-consent-reward-form'>
            <h6 className='mkt-consent-reward-form--description'>{description}</h6>
            <RewardImageList rewards={rewards} />
            <div>
              <label
                htmlFor='email'
                className='mkt-consent-reward-form--email'
              >
                {t('translation:account.settings.details.emailAddress')}
              </label>
              <input
                id='email'
                className='form-control'
                type='text'
                name='email'
                value={email}
                disabled
              />
            </div>

            <div>
              {requiredLegalContents.map((legalContent) => (
                <CheckboxInput
                  key={legalContent.id}
                  name={legalContent.id}
                  title={t(`translation:marketing-consent.approvals.${legalContent.id}`)}
                  containerClassName='mkt-consent-reward-form--checkbox'
                />
              ))}
            </div>

            <p className='mkt-consent-reward-form--data'>
              {t('translation:promotion.marketing-consent.form-note')}
            </p>
            <p className='mkt-consent-reward-form--data'>
              {t('translation:promotion.marketing-consent.form-withdrawl')}
            </p>
          </div>

          <div className='mkt-consent-reward-form-footer'>
            {cancelHandler && cancelLabel && (
              <button
                type='button'
                className='btn btn-link'
                data-cy='mkt-consent-later-button'
                onClick={cancelHandler}
              >
                {cancelLabel}
              </button>
            )}
            <button
              type='submit'
              disabled={someInitialValuesUnchecked && !dirty}
              data-cy='mkt-consent-claim-reward-button'
              className='btn btn-primary-game'
            >
              {!!values.newsletter && !!values['personalized-ads-offers']
                ? t('translation:promotion.modal.claim') : t('translation:button.next')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
