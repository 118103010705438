/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';

export const definition: Definition = {
  title: {
    type: DefinitionPropertyType.TranslatedString,
  },
  description: {
    type: DefinitionPropertyType.TranslatedString,
  },
};

interface Props {
  title: string;
  description?: string;
}

export function Header({ title, description }: Props): JSX.Element {
  return (
    <div className='section-header'>
      <div className='section-header--container'>
        <h1 className='section-header--title'>
          {title}
        </h1>
        <p className='section-header--details'>{description}</p>
      </div>
    </div>

  );
}

export default Header;
