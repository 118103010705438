/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GameIds } from '../../../account/my-games/MyGames.model';
import { useAuth } from '../../../authentication/AuthenticationContext';
import { ClaimFlowModal } from './claim-flow-modal/ClaimFlowModal';
import { useSnackbar } from '../../../snackbar/SnackbarContext';
import { get } from '../../../utils/Request';
import { Modal } from '../../modal/Modal';
import { ClaimStepper } from './stepper/ClaimStepper';
import { ClaimInstructions } from './claim-instuctions/ClaimInstructions';
import { KeysAvailableResponse } from './models';
import { useConfig } from '../../../contexts/ConfigContext';

export const definition: Definition = {
  isReleased: {
    type: DefinitionPropertyType.Boolean,
  },
};

export function ClaimFreeGame(): JSX.Element {
  const [preRegId, setPreRegId] = useState<string>();
  const [showClaimModal, setShowClaimModal] = useState<boolean>(false);
  const [showNoKeysModal, setShowNoKeysModal] = useState<boolean>(false);
  const [keysAreAvailable, setKeysAreAvailable] = useState<boolean>(false);
  const [steamKey, setSteamKey] = useState<string>();

  const { gameId }: { gameId: GameIds } = useParams();
  const auth = useAuth();
  const config = useConfig();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  // TODO: get platform from backend preregistration config
  const platform = 'steam';

  const getPreRegId = (id: GameIds): string => (
    config.serverConfig.gamesConfig.find((game) => game.id === id).preRegistration?.id
  );

  const checkAvailableKeys = async (): Promise<void> => {
    if (!preRegId) {
      return;
    }
    try {
      const response = await get<KeysAvailableResponse>(
        `${config.clientConfig.host}/players/me/games/${gameId}/pre-registrations/${preRegId}/${platform}/available`,
      );
      setKeysAreAvailable(response.keysAvailable);
    } catch (e) {
      snackbar.addErrorMessage(t('claimFreeGame.errors.noKeyBeforeFlow'));
    }
  };

  const handleClaimBtnClick = (): void => {
    if (keysAreAvailable) {
      setShowClaimModal(true);
    } else {
      setShowNoKeysModal(true);
    }
  };

  useEffect(() => {
    setPreRegId(getPreRegId(gameId));
  }, [config?.serverConfig]);

  useEffect(() => {
    if (!gameId) {
      return;
    }
    const id = getPreRegId(gameId);
    const userPreReg = auth?.user?.preRegistrations?.[gameId];
    if (userPreReg && id) {
      const gamePreRegs = auth?.user?.preRegistrations?.[gameId];
      setSteamKey(gamePreRegs?.[id]?.providerKey?.key);
    }
  }, [auth?.user]);

  useEffect(() => {
    checkAvailableKeys();
  }, [preRegId]);

  return (
    <>
      {preRegId ? (
        <>
          <Box className='claim-free-game-container' data-cy='claim-free-game-component'>
            {/* Keys are Available, normal flow */}
            {keysAreAvailable ? (
              <>
                {steamKey
                  ? (
                    <>
                      {/* Key already claimed */}
                      <Typography variant='h5' sx={{ fontWeight: '800' }} data-cy='wtf-claimed-key-title'>
                        {t('claimFreeGame.card.title')}
                      </Typography>
                      <ClaimInstructions steamKey={steamKey} isInFlow={false} />
                    </>
                  )
                  : (
                    <Box>
                      {/* Key not claimed */}
                      <Typography variant='h5' sx={{ fontWeight: '800' }} mb={3}>
                        {t('claimFreeGame.card.title')}
                      </Typography>
                      <Typography variant='subtitle2' sx={{ fontSize: '1rem', fontWeight: '400' }} my={2}>
                        {t('claimFreeGame.card.subtitle')}
                      </Typography>
                      <ClaimStepper />
                    </Box>
                  )}
              </>
            ) : (
              <Box>
                {/* No Keys are Available */}
                <Typography variant='h5' sx={{ fontWeight: '800' }} mb={3}>
                  {t('games.what-the-fog')}
                </Typography>
                <Typography variant='subtitle2' sx={{ fontWeight: '400' }} mt={2} data-cy='claim-what-the-fog'>
                  {t('claimFreeGame.errors.noKeyBeforeFlow')}
                </Typography>
              </Box>
            )}
            {/* One button for all the cases, the text and link changes depending on the case */}
            <Box width='100%' display='flex' justifyContent='center'>
              <button
                type='button'
                className={steamKey
                  ? 'btn btn-primary btn-link' : 'btn btn-primary-game btn-block mx-auto mt-3'}
                data-cy='wtf-button'
                onClick={steamKey || !keysAreAvailable ? () => {
                  window.open(
                    'https://store.steampowered.com/app/2139020/What_the_Fog',
                    '_blank',
                    'noopener,noreferrer',
                  );
                } : handleClaimBtnClick}
              >
                {!keysAreAvailable ? (
                  <>
                    <OpenInNewIcon sx={{ color: 'rgba(255, 255, 255, 0.56)' }} />
                    {t('claimFreeGame.claimGame.buyOnSteam')}
                  </>
                ) : (
                  <>
                    {steamKey ? (
                      <>
                        <OpenInNewIcon sx={{ color: 'rgba(255, 255, 255, 0.56)' }} />
                        {t('claimFreeGame.claimGame.goToSteam')}
                      </>
                    ) : (
                      <>
                        {t('claimFreeGame.card.claimBtn')}
                      </>
                    )}
                  </>
                )}
              </button>
            </Box>
          </Box>

          {/* Modals */}
          {preRegId
            ? (
              <ClaimFlowModal
                isOpen={showClaimModal}
                handleClose={() => { setShowClaimModal(false); }}
                preRegId={preRegId}
                platform={platform}
              />
            )
            : null}

          <Modal
            show={showNoKeysModal}
            close={() => { setShowNoKeysModal(false); }}
            isInfoModal
            maxWidth={550}
          >
            <Box my={4}>
              <Alert severity='info' variant='outlined'>
                {t('claimFreeGame.errors.noKeyBeforeFlow')}
              </Alert>
            </Box>
          </Modal>
          {/* END Modals */}
        </>
      ) : null }
    </>
  );
}

export default ClaimFreeGame;
