/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ReactNode } from 'react';
import { useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import { components } from '../index';

export const possibleChildren = [
  components.STREAMING_CAMPAIGN_REWARD,
];

export const definition: Definition = {
  title: {
    type: DefinitionPropertyType.TranslatedString,
  },
};

interface Props {
  title: string;
  children: ReactNode,
}

export function ItemList({ title, children }: Props) : JSX.Element {
  const isSmall = useMediaQuery('(max-width: 991px)');
  const childrenList = Array.isArray(children) ? children as Array<ReactNode> : [];

  return (
    <div className='item-list--container'>
      <h3 className='item-list--title'>{title}</h3>
      <div className='item-list'>
        {isSmall && (
          <Swiper spaceBetween={18} slidesPerView='auto'>
            {childrenList.map((child, i) => (
              <SwiperSlide key={`card-${title}-${i}`}>
                {child}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {!isSmall && (<>{childrenList.map((x) => x)}</>)}
      </div>
    </div>
  );
}

export default ItemList;
