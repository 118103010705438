/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { patch } from '../../utils/Request';
import { useAuth } from '../../authentication/AuthenticationContext';
import { BhvrUser } from '../../authentication/Authentication.model';
import { UpdateEmail } from './UpdateEmail';
import { useSnackbar } from '../../snackbar/SnackbarContext';
import { TextInput } from '../../_shared/form/TextInput';
import { CountryInput } from '../../_shared/form/CountryInput';
import { GenderInput } from '../../_shared/form/GenderInput';
import { RequestError } from '../../Error.model';
import { EditButton } from '../../_shared/edit-button/EditButton';

import { PageLayout } from '../layout/PageLayout';
import { useConfig } from '../../contexts/ConfigContext';

interface FormValues {
  gender: string;
  country: string;
  firstName: string;
  lastName: string;
  nickName: string;
}

export function PersonalInformation(): JSX.Element {
  const { t } = useTranslation(['translation', 'error']);
  const auth = useAuth();
  const config = useConfig();
  const yupSchema = Yup.object().shape({
    gender: Yup.string(),
    country: Yup.string()
      .required('form.field-required'),
    firstName: Yup.string(),
    lastName: Yup.string(),
    nickName: Yup.string()
      .min(2, 'form.field-error-nickname-length')
      .max(32, 'form.field-error-nickname-length')
      .matches(/^[A-Za-z].*$/, 'form.field-error-nickname-letter-start')
      .matches(/[\w-]$/, 'form.field-error-nickname-alphanumeric'),
  });
  const snackbar = useSnackbar();
  const formikRef = useRef<FormikProps<FormValues>>();

  const [initialValuesFormik, setInitialValuesFormik] = useState<FormValues>({
    gender: '',
    country: '',
    nickName: '',
    firstName: '',
    lastName: '',
  });

  const [editEnabled, setEditEnabled] = useState(false);

  const initFormik = (): void => {
    setInitialValuesFormik({
      gender: auth.user.gender,
      country: auth.user.country,
      nickName: auth.user.nickName,
      firstName: auth.user.firstName || '',
      lastName: auth.user.lastName || '',
    });
  };

  const handleSubmitForm = async (values: FormValues): Promise<void> => {
    try {
      const response = await patch<BhvrUser>(`${config.clientConfig.host}/players/me`, {
        data: {
          country: values.country,
          gender: values.gender,
          firstName: values.firstName,
          lastName: values.lastName,
          nickName: values.nickName,
        },
      });

      auth.updateUser(response);
      snackbar.addSuccessMessage('account.settings.success');
      setEditEnabled(false);
    } catch (e) {
      const err = e as RequestError;
      const { data } = err;
      let translateData = {};
      if (err.code === 'ChangeNickNameRestrictionError') {
        translateData = { ...data, timeUnit: t(`time.${data.timeUnit}`) };
      }
      snackbar.addErrorMessage(err.code, translateData);
    }
  };

  // Set Formik
  useEffect(() => {
    initFormik();
  }, [auth.user]);

  const toggleEdit = (): void => {
    if (editEnabled) {
      setEditEnabled(false);
      formikRef.current?.resetForm();
    } else {
      setEditEnabled(true);
    }
  };

  return (
    <PageLayout>
      <div className='personal-information-container'>
        <div className='account-data-container'>
          <div className='row'>
            <div className='col-12 mb-2'>
              <h2>{t('translation:account.settings.details.title')}</h2>
              <p className='settings-subtitle'>
                {t('translation:account.settings.details.description1')}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='font-weight-bold'
                  href={t('translation:account.settings.details.link')}
                >
                  {t('translation:account.settings.details.description2')}
                </a>
              </p>
            </div>
          </div>
          <UpdateEmail />
          <div className='form-container'>
            <div className='form-container-header'>
              <h4 className='form-container-title mb-0'>
                {t('translation:account.settings.details.personalInformation')}
              </h4>
              <EditButton
                isActive={editEnabled}
                onClick={toggleEdit}
                data-cy='edit-personal-info-button'
                aria-label='Edit Personal Information'
              />
            </div>
            <Formik
              initialValues={initialValuesFormik}
              innerRef={formikRef}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={yupSchema}
              enableReinitialize
              onSubmit={handleSubmitForm}
            >
              {({ isSubmitting, handleReset }) => (
                <Form className='form-light'>
                  <TextInput name='nickName' disabled={!editEnabled || isSubmitting} />
                  <TextInput name='firstName' disabled={!editEnabled || isSubmitting} />
                  <TextInput name='lastName' disabled={!editEnabled || isSubmitting} />
                  <GenderInput disabled={!editEnabled || isSubmitting} />
                  <CountryInput disabled={!editEnabled || isSubmitting} />

                  {editEnabled && (
                    <div className='form-group mt-5 d-flex justify-content-between'>
                      <button
                        type='button'
                        className='btn btn-link'
                        onClick={() => { handleReset(); toggleEdit(); }}
                      >
                        {t('translation:common.cancel')}
                      </button>
                      <button
                        type='submit'
                        data-cy='submit-button'
                        className='btn btn-primary-game'
                        disabled={isSubmitting}
                      >
                        {t('translation:button.save')}
                      </button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
