/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState, useEffect } from 'react';
import {
  Switch,
  Redirect,
  Route,
  useLocation,
  useHistory,
  matchPath,
} from 'react-router-dom';

import { Account } from '../account/Account';
import { ProvideSnackbar } from '../snackbar/SnackbarContext';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Authentication } from '../authentication/Authentication';
import { Homepage } from '../authentication/homepage/Homepage';
import { ProvideAuthentication } from '../authentication/AuthenticationContext';
import { GuardRoute } from '../_shared/GuardRoute';
import { ReCaptcha } from '../_shared/ReCaptcha';
import { ProvideRedirectUrl } from '../redirect-url/ProviderRedirectUrlContext';
import { MymWelcomePage } from '../account/my-games/mym-game/MymWelcome';
import { ReferralCodeManagerPage } from '../referral/ReferralCodeManagerPage';
import { CommunicationPreferences } from '../account/communication-preferences/CommunicationPreferences';
import { ActivationPage } from '../activation/ActivationPage';
import initIframe from '../utils/Iframe';
import { ProviderRedirect } from '../account/providers/ProviderRedirect';
import { AuthenticationModal } from '../authentication/AuthenticationModal';
import { MandatoryLegalContentsModal } from '../legal-contents/MandatoryLegalContentsModal';
import { LegalContent } from '../legal-contents/LegalContent';
import { SsoAccept } from '../singleSignOn/sso';
import { StreamingCampaignPageWrapper } from '../streaming-campaign/StreamingCampaignPageWrapper';
import { ProvideMktConsentPromotion } from '../contexts/MktConsentPromotionContext';
import { MktConsentPromotionPopup } from '../marketing-consent/MktConsentPromotionPopup';
import { useUtm } from '../_shared/utm/useUtm';
import { useConfig } from '../contexts/ConfigContext';
import { ProvideGameTheme } from '../contexts/GameThemeContext';

export function BasePage(): JSX.Element {
  const config = useConfig();
  const location = useLocation();
  const history = useHistory();
  const { saveCurrent, isLoading } = useUtm();
  const [isReCaptchaLoaded, setIsReCaptchaLoaded] = useState(false);

  const handleReCaptchaLoaded = (isLoaded: boolean): void => {
    setIsReCaptchaLoaded(isLoaded);
  };

  useEffect(() => {
    // Init the iframe for BElive
    // Will do nothing if not in BElive
    initIframe(history);
  }, []);

  useEffect(() => {
    if (location.search && location.pathname !== '/sso/accept-client') {
      const queryString = new URLSearchParams(location.search);
      if (queryString.get('client_id') || queryString.get('clientId')) {
        sessionStorage.setItem('ssoQueryString', location.search);
      }
    }

    if (!isLoading) {
      saveCurrent();
    }
  }, [isLoading, saveCurrent]);

  const pagesWithoutLayout = ['/sso/accept-client'];
  const showLayout = !matchPath(location.pathname, {
    path: pagesWithoutLayout,
    exact: true,
  });

  return (
    <div className='app-container'>
      {config.isClientConfigLoad && config.isServerConfigLoad
        && (
          <>
            {((isReCaptchaLoaded && config.clientConfig?.recaptchaEnabled)
              || !config.clientConfig?.recaptchaEnabled)
              && (
                <div className={showLayout ? 'route-container' : ''}>
                  <ProvideSnackbar>
                    <ProvideRedirectUrl>
                      <ProvideGameTheme>
                        <ProvideAuthentication>
                          <ProvideMktConsentPromotion>
                            {showLayout && <Header />}

                            <Switch>
                              <GuardRoute path='/auth' authRoute={false}>
                                <Authentication />
                              </GuardRoute>
                              <Route
                                path='/account/activate-game/1866180'
                                exact
                              >
                                <Redirect to='/account/my-account/games/hoy/activation?provider=steam' />
                              </Route>
                              <Route
                                path='/account/my-account/games/:gameId/activation'
                                exact
                              >
                                <ActivationPage />
                              </Route>
                              <Route path='/unsubscribe' exact>
                                <CommunicationPreferences noAuth />
                              </Route>
                              <Route path='/account/:provider/redirect' exact component={ProviderRedirect} />
                              <GuardRoute path='/account' authRoute>
                                <Account />
                              </GuardRoute>
                              <Route path='/mym/beta' exact>
                                <MymWelcomePage />
                              </Route>
                              <Route path='/:provider/:gameId/:pageSlug' exact>
                                <StreamingCampaignPageWrapper />
                              </Route>
                              <GuardRoute path='/s/:referralCode' authRoute>
                                <ReferralCodeManagerPage />
                              </GuardRoute>
                              <GuardRoute path={['/', '/rewards/marketing-consent']} authRoute={false} exact>
                                <Homepage />
                              </GuardRoute>
                              <GuardRoute path='/sso/accept-client' authRoute>
                                <SsoAccept />
                              </GuardRoute>
                              <Route
                                path='/privacy-policy'
                                exact
                              >
                                <LegalContent id='privacy-policy' />
                              </Route>
                              <Route
                                path='/terms-of-use'
                                exact
                              >
                                <LegalContent id='terms-of-use' />
                              </Route>
                              <Redirect to='/' />
                            </Switch>
                            {/* TODO: Find a better way to exclude paths for the modal */}
                            <Switch>
                              <Route path={['/privacy-policy', '/terms-of-use']}>
                                <></>
                              </Route>
                              <Route>
                                <MandatoryLegalContentsModal />
                              </Route>
                            </Switch>
                            <MktConsentPromotionPopup />
                            <AuthenticationModal />
                          </ProvideMktConsentPromotion>
                        </ProvideAuthentication>
                      </ProvideGameTheme>
                    </ProvideRedirectUrl>
                  </ProvideSnackbar>
                </div>
              )}
            {showLayout && <Footer />}
            {config.clientConfig?.recaptchaEnabled && (
              <ReCaptcha
                loaded={handleReCaptchaLoaded}
              />
            )}
          </>
        )}
    </div>
  );
}
