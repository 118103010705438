/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../_shared/modal/Modal';
import { RequestError } from '../../../Error.model';
import { del } from '../../../utils/Request';
import { useAuth } from '../../../authentication/AuthenticationContext';
import { ProviderName } from '../ProviderEnum';
import { useConfig } from '../../../contexts/ConfigContext';

interface Props {
  provider: ProviderName;
  isAuth: boolean;
  close: () => void;
  isOpen: boolean;
  setError: (error: RequestError) => void;
}

export function ProviderUnlinkConfirmationModal({ provider, isAuth, close, isOpen, setError }: Props): JSX.Element {
  const { t } = useTranslation();
  const config = useConfig();
  const auth = useAuth();
  const [confirmUnlink, setConfirmUnlink] = useState('');

  const unlink = (): void => {
    close();
    setError(null);
    const url = isAuth ? `${config.clientConfig.host}/players/me/unlink/provider/${provider}`
      : `${config.clientConfig.host}/players/me/unlink/providers/${provider}`;
    del(url)
      .then(() => {
        auth.getPlayer();
      }).catch((err: RequestError) => {
        setError(err);
      });
  };

  const closeHandler = (): void => {
    setConfirmUnlink('');
    close();
  };

  return (
    <Modal
      show={isOpen}
      confirm={unlink}
      close={closeHandler}
      textConfirm={t('translation:button.confirm')}
      textClose={t('translation:common.cancel')}
      disabled={confirmUnlink !== t('account.my-account.unlink').toUpperCase()}
    >
      <div>
        {t('translation:account.provider.confirm-disconnect1', {
          provider: t(`translation:${provider}`),
        })}

        <p className='font-weight-bold confirm-disconnect-block'>
          {t('translation:account.provider.confirm-disconnect2')}
        </p>
        <div className='form-light'>
          <div className='form-group'>
            <label className='none' htmlFor='confirm-unlink'>
              {t(
                'translation:account.provider.confirm-unlink',
                { word: t('account.my-account.unlink').toUpperCase() },
              )}
            </label>
            <input
              name='confirm-unlink'
              className='form-control'
              type='text'
              value={confirmUnlink}
              data-cy='input-confirm-unlink'
              onChange={(e) => { setConfirmUnlink(e.target.value); }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
