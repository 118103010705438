/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useEffect, useState } from 'react';
import { RequestError } from '../Error.model';
import { get } from '../utils/Request';
import { ShareCode } from './ReferralCode.model';
import { useConfig } from '../contexts/ConfigContext';

interface ReferralCodeContext {
  referralCodeData: ShareCode;
  errorCode: string;
  getReferralCodeData: () => Promise<void>;
}

const ReferralCodeDataContext = createContext<ReferralCodeContext>({
  referralCodeData: null,
  errorCode: null,
  getReferralCodeData: null,
});

export const useReferralCode = (): ReferralCodeContext => useContext(ReferralCodeDataContext);

const useProvideReferralCode = (referralCode: string): ReferralCodeContext => {
  const [referralCodeData, setReferralCodeData] = useState<ShareCode>(null);
  const [errorCode, setErrorCode] = useState<string>(null);
  const config = useConfig();

  async function getReferralCodeData(): Promise<void> {
    if (!referralCode) {
      throw new Error('no referral code');
    }

    try {
      const data: ShareCode = await get(`${config.clientConfig.host}/sharecodes/code/${referralCode}`);
      setReferralCodeData(data);
    } catch (e) {
      const err = e as RequestError;
      setErrorCode(err?.code ?? 'UnknownError');
      throw e;
    }
  }

  useEffect(() => {
    if (referralCode) {
      getReferralCodeData();
    }
  }, [referralCode]);

  return {
    referralCodeData,
    errorCode,
    getReferralCodeData,
  };
};

export function ProvideReferralCode({
  children,
  referralCode = null,
}: {
  children: JSX.Element | React.ReactNode;
  referralCode?: string | null;
}): JSX.Element {
  const referralCodeDataContext = useProvideReferralCode(referralCode);

  return (
    <ReferralCodeDataContext.Provider value={referralCodeDataContext}>
      {children}
    </ReferralCodeDataContext.Provider>
  );
}
