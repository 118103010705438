import React from 'react';
import { motion } from 'framer-motion';

export function BackDrop(
  { onClick, children }:
  { onClick: React.MouseEventHandler, children: JSX.Element },
): JSX.Element {
  return (
    <motion.div
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}
