/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  RedeemingGameProvider,
  getLinkingProviderFromRedeemingProvider,
} from '../../../account/providers/ProviderEnum';
import { Modal } from '../../modal/Modal';
import { LinkFlow } from '../../account-link/LinkFlow';
import { PlatformSelection } from '../../platform-selection/PlatformSelection';
import { CrossProgressionStep } from './CrossProgression.model';
import { StepList } from './StepList';
import { VerifyGameStatus } from '../verify-game-status/VerifyGameStatus';
import { GameIds } from '../../../account/my-games/MyGames.model';
import { Tutorial } from '../tutorial/Tutorial';
import { TutorialItem, Props as TutorialItemProps } from '../tutorial/TutorialItem';
import { ConfirmSynchronization } from './ConfirmSynchronization';
import { SynchronizationSuccess } from './SynchronizationSuccess';
import { CrossProgressionModalStepper } from './CrossProgressionModalStepper';
import { SynchronizationFailed } from './SynchronizationFailed';

interface Props {
  isOpen: boolean;
  close: () => void;
  platformStatuses: {
    id: RedeemingGameProvider;
    synced: boolean;
  }[];
  initialPlatform: RedeemingGameProvider | null;
  tutorial: TutorialItemProps[];
  getMergedAccounts: () => Promise<void>;
}

export function CrossProgressionModal({
  isOpen, close, platformStatuses, initialPlatform, tutorial, getMergedAccounts,
}: Props): JSX.Element {
  const { gameId }: { gameId: GameIds } = useParams();
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState<CrossProgressionStep>('select-platform');
  const [selectedPlatform, setSelectedPlatform] = useState<RedeemingGameProvider | null>(initialPlatform);

  const initCurrentStep = (): void => {
    if (initialPlatform) {
      setCurrentStep('step-list');
    } else {
      setCurrentStep('select-platform');
    }
  };

  const selectPlatform = (newPlatform: RedeemingGameProvider): void => {
    setSelectedPlatform(newPlatform);
    setCurrentStep('step-list');
  };

  const synchronizationConfirm = async (): Promise<void> => {
    setCurrentStep('success');
    await getMergedAccounts();
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedPlatform(initialPlatform);
      initCurrentStep();
    }
  }, [isOpen]);

  return (
    <Modal show={isOpen} mobileFullScreen minWidth={450} maxWidth={450} minHeight={630}>
      <>
        <CrossProgressionModalStepper currentStep={currentStep} />
        {currentStep === 'select-platform' && (
          <PlatformSelection
            confirm={selectPlatform}
            cancel={close}
            availablePlatforms={platformStatuses.filter((x) => !x.synced).map((x) => x.id)}
          />
        )}
        {currentStep === 'step-list' && selectedPlatform
          && (
            <StepList
              next={() => setCurrentStep('link')}
              cancel={close}
              platformStatuses={platformStatuses}
              selectedPlatform={selectedPlatform}
            />
          )}
        {currentStep === 'link' && selectedPlatform
        && (
          <LinkFlow
            platform={getLinkingProviderFromRedeemingProvider(selectedPlatform)}
            cancelFlow={close}
            completeFlow={() => setCurrentStep('verify-status')}
            customWarningMessage={(
              <Trans
                i18nKey='cross-progression.link-warning-message'
                components={{ b: <strong /> }}
              />
            )}
          />
        )}
        {currentStep === 'verify-status' && selectedPlatform
        && (
          <VerifyGameStatus
            platform={selectedPlatform}
            gameId={gameId}
            next={() => (tutorial.length ? setCurrentStep('how-it-works') : setCurrentStep('confirm-sync'))}
            cancel={close}
          />
        )}
        {currentStep === 'how-it-works'
        && (
          <Tutorial
            name='cross-progression-tutorial-modal'
            title={t('cross-progression.tutorial.title')}
            subtitle={t('cross-progression.tutorial.subtitle')}
            next={() => setCurrentStep('confirm-sync')}
            cancel={close}
            items={tutorial.map((item, index) => (
              <TutorialItem
                key={`cross-progression-modal-tutorial-item-${index}`}
                image={item.image}
                title={item.title}
                description={item.description}
                caption={item.caption}
                background={item.background}
              />
            ))}
          />
        )}
        {currentStep === 'confirm-sync' && selectedPlatform
        && (
          <ConfirmSynchronization
            next={(success: boolean) => (success ? synchronizationConfirm() : setCurrentStep('failed'))}
            cancel={close}
            platform={selectedPlatform}
            gameId={gameId}
          />
        )}
        {currentStep === 'success' && selectedPlatform && (
          <SynchronizationSuccess
            close={close}
            platformStatuses={platformStatuses}
            selectedPlatform={selectedPlatform}
          />
        )}
        {currentStep === 'failed'
        && (<SynchronizationFailed close={close} />)}
      </>
    </Modal>
  );
}
