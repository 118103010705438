/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { ExitToApp } from '@mui/icons-material';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useConfig } from '../../contexts/ConfigContext';
import { LocalStorageKeyName } from '../../contexts/Config.model';

export function EditorVersion(): JSX.Element {
  const config = useConfig();
  const location = useLocation();
  const history = useHistory();

  const resetVersion = (): void => {
    const searchParams = new URLSearchParams(location.search);
    config.updateStorageVersion(null);
    searchParams.delete('version');
    localStorage.removeItem(LocalStorageKeyName.PAGES_CONTENT);
    history.replace({
      search: searchParams.toString(),
    });
    window.location.reload();
  };

  return (
    <div style={{
      position: 'fixed',
      right: '10px',
      top: '90px',
      width: '300px',
      zIndex: '9999',
    }}
    >
      <Alert
        severity='info'
        action={(
          <IconButton
            className='reset-version-btn'
            data-for='tooltip-reset-version'
            data-tip
            onClick={resetVersion}
          >
            <ExitToApp />
            <ReactTooltip id='tooltip-reset-version' place='bottom'>
              Return to current live version
            </ReactTooltip>
          </IconButton>
        )}
      >
        <AlertTitle>Info</AlertTitle>

        Version:
        {' '}
        <strong>{config.pagesContent.version}</strong>

      </Alert>
    </div>

  );
}
