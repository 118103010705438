/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LinkingGameProvider,
  RedeemingGameProvider,
  getLinkingProviderFromRedeemingProvider } from '../../../../../account/providers/ProviderEnum';
import { Popup } from '../../../../../utils/Popup';
import { RequestError } from '../../../../../Error.model';
import { useAuth } from '../../../../../authentication/AuthenticationContext';
import { patch } from '../../../../../utils/Request';
import { buildAuthUrl } from '../../../../../utils/Providers';
import { ProviderLinkWrapper } from '../../../../../account/providers/provider/button/ProviderLinkWrapper';
import { ProviderConfirmationModal } from '../../../../../account/providers/provider/ProviderConfirmationModal';
import { useSnackbar } from '../../../../../snackbar/SnackbarContext';
import { ProviderButton } from '../../../provider-button/ProviderButton';
import { useConfig } from '../../../../../contexts/ConfigContext';

interface Props {
  providers: RedeemingGameProvider[];
  setProviderError: (e: RequestError | null) => void;
  setSelectedProvider: (provider: RedeemingGameProvider) => void;
}

export function ProviderSelection({ providers, setProviderError, setSelectedProvider }: Props): JSX.Element {
  const auth = useAuth();
  const config = useConfig();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [potentialRedeemProvider, setPotentialProvider] = useState<RedeemingGameProvider | null>(null);
  const [providerConfirmModal, setProviderConfirmModal] = useState<LinkingGameProvider | null>(null);

  const userAccounts = auth.user?.accounts || [];

  const relatedLinkProvider = getLinkingProviderFromRedeemingProvider(potentialRedeemProvider);

  const getProviderAuthUrl = (provider: LinkingGameProvider): string => buildAuthUrl(
    config?.clientConfig?.providers[provider]?.authUrl || null,
    config.serverConfig?.providers[provider] || null,
    provider,
  );

  const selectPotentialProvider = (provider: RedeemingGameProvider): void => {
    setLoading(true);
    setPotentialProvider(provider);
  };

  const resetProviderSelection = (): void => {
    setLoading(false);
    setPotentialProvider(null);
  };

  const closeConfirmModal = (): void => {
    resetProviderSelection();
    setProviderConfirmModal(null);
  };

  const confirmLinkProvider = (): void => {
    setProviderConfirmModal(null);
    const providerAuthUrl = relatedLinkProvider ? getProviderAuthUrl(relatedLinkProvider) : null;
    if (providerAuthUrl) {
      Popup.open(providerAuthUrl, `${relatedLinkProvider}_auth`);
    } else {
      snackbar.addErrorMessage('UnknownError');
    }
  };

  const errorHandler = (error: RequestError | null): void => {
    if (error) {
      resetProviderSelection();
    }
    setProviderError(error);
  };

  const selectProviderHandler = async (
    redeemProvider: RedeemingGameProvider,
    linkProvider: LinkingGameProvider,
  ): Promise<void> => {
    try {
      await patch(`${config.clientConfig?.host}/players/me/accounts/providers/${linkProvider}/games`);
      setLoading(false);
      setSelectedProvider(redeemProvider);
    } catch (err) {
      const reqErr = err as RequestError;
      const providerAuthUrl = getProviderAuthUrl(linkProvider);
      if (reqErr?.code === 'InvalidToken' && providerAuthUrl) {
        Popup.open(providerAuthUrl, `${linkProvider}_auth`);
      } else {
        errorHandler(reqErr);
      }
    }
  };

  useEffect(() => {
    if (potentialRedeemProvider && relatedLinkProvider) {
      const isLinked = !!auth.user?.accounts.find((account) => account.type === relatedLinkProvider);
      if (isLinked) {
        selectProviderHandler(potentialRedeemProvider, relatedLinkProvider);
      } else if (!isLinked) {
        setProviderConfirmModal(relatedLinkProvider);
      }
    }
  }, [potentialRedeemProvider, auth.user?.accounts]);

  return (
    <div className='streaming-campaign-provider-selection' data-cy='streaming-campaign-provider-selection'>
      {providers.map((provider) => (
        <ProviderButton
          key={provider}
          provider={provider}
          cta={() => selectPotentialProvider(provider)}
          className='btn-dark provider-list--item'
        />
      ))}

      {potentialRedeemProvider && relatedLinkProvider && (
        <ProviderLinkWrapper
          provider={relatedLinkProvider}
          isAuthProvider={false}
          errorHandler={errorHandler}
          providerAccount={userAccounts.find((account) => account.type === relatedLinkProvider)}
        >
          <ProviderConfirmationModal
            close={() => closeConfirmModal()}
            confirm={() => confirmLinkProvider()}
            show={providerConfirmModal === relatedLinkProvider}
            message={t('translation:account.provider.confirm-provider', {
              provider: t(`translation:${relatedLinkProvider}`),
            })}
          />
        </ProviderLinkWrapper>
      )}
      {loading && <CircularProgress color='inherit' className='streaming-campaign-provider-selection--loader' />}
    </div>
  );
}
