/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ReactNode, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { WarningAmberOutlined } from '@mui/icons-material';
import { LinkingGameProvider } from '../../account/providers/ProviderEnum';
import { ModalFooter } from '../modal/ModalFooter';
import { LinkStep } from './LinkFlow.model';
import { Popup } from '../../utils/Popup';
import { GameAccount } from '../../authentication/Authentication.model';
import { RequestError } from '../../Error.model';

interface Props {
  platform: LinkingGameProvider;
  nextStep: (newStep: LinkStep) => void;
  cancel: () => void;
  platformAuthUrl: string;
  userAccount: GameAccount | null;
  error: RequestError | null;
  customWarningMessage?: ReactNode;
}

export function LinkFlowWarning(
  { platform, nextStep, cancel, platformAuthUrl, userAccount, error, customWarningMessage = null }: Props,
): JSX.Element {
  const { t } = useTranslation();

  const next = (): void => {
    if (platformAuthUrl) {
      Popup.open(platformAuthUrl, `${platform}_auth`);
    } else {
      nextStep('failed');
    }
  };

  useEffect(() => {
    if (userAccount) {
      nextStep('success');
    }
  }, [userAccount]);

  useEffect(() => {
    if (error) {
      nextStep('failed');
    }
  }, [error]);

  return (
    <>
      <div className='link-flow'>
        <div className='link-warning'>
          <WarningAmberOutlined className='icon warning' />
          <p>
            {customWarningMessage ?? (
              <Trans
                i18nKey='account.provider.link-flow.link-warning'
                components={{ b: <strong /> }}
              />
            )}
          </p>
        </div>
      </div>
      <ModalFooter
        name='link-flow'
        nextLabel={t('button.continue')}
        cancelLabel={t('common.cancel')}
        nextHandler={next}
        cancelHandler={cancel}
      />
    </>
  );
}
