/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';

import { Mail, Lock } from '@mui/icons-material';
import { Providers } from '../providers/Providers';
import { RouteParams } from '../Authentication.model';
import { SubmitButton } from '../../_shared/form/SubmitButton';
import { LocalizedLink } from '../../_shared/LocalizedLink';
import { useAuth } from '../AuthenticationContext';
import { useConfig } from '../../contexts/ConfigContext';
import { useGameTheme } from '../../contexts/GameThemeContext';

interface FormValues {
  email: string;
  password: string;
}

interface Props {
  isModal?: boolean;
}

export function Signin({ isModal = false }: Props): JSX.Element {
  const { t } = useTranslation();
  const config = useConfig();
  const params = useParams<RouteParams>();
  const theme = useGameTheme();
  const auth = useAuth();

  const initialValuesFormik: FormValues = {
    email: '',
    password: '',
  };
  const loginSchema = Yup.object().shape({
    password: Yup.string()
      .required('form.field-required'),
    email: Yup.string()
      .email('form.mail-invalid')
      .required('form.field-required'),
  });

  const handleSubmitForm = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>): Promise<void> => {
    if (config.clientConfig.recaptchaEnabled) {
      const token = await window.grecaptcha.execute(
        config.serverConfig.website.recaptcha.clientId,
        { action: 'login' },
      );
      auth.signin({
        email: values.email,
        password: values.password,
      }, token);
    } else {
      auth.signin({
        email: values.email,
        password: values.password,
      });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    if (params.gameId) {
      theme.setGameId(params.gameId);
    }
  }, [params]);

  return (
    <div className='card-bhvra'>
      <h2 className='mt-3' data-cy='login-title-text'>{t('auth.login.title')}</h2>
      <p className='mb-3'>{t('auth.login.description')}</p>

      <Providers />

      <div className='mt-4'>
        <Formik
          initialValues={initialValuesFormik}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={loginSchema}
          enableReinitialize
          onSubmit={handleSubmitForm}
        >
          {({ errors, touched, handleSubmit }) => (
            <Form className='form-light'>
              <Field name='email'>
                {({ field }: FieldProps) => (
                  <div className='form-group'>
                    <label htmlFor='email'>{t('form.label.email')}</label>
                    <div className='input-group'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <Mail className='icon' />
                        </span>
                      </div>
                      <input
                        name='email'
                        id='email'
                        placeholder={t('form.label.email')}
                        className={`form-control
                            ${(errors.email && touched.email && handleSubmit) ? 'is-invalid' : ''}`}
                        type='text'
                        {...field}
                        data-cy='email-input'
                      />
                    </div>

                    {errors.email && touched.email && handleSubmit ? (
                      <div className='form-error' data-cy='email-error-text'>{t(`${errors.email}`)}</div>
                    ) : null}
                  </div>
                )}
              </Field>
              <Field name='password'>
                {({ field }: FieldProps) => (
                  <div className='form-group'>
                    <label htmlFor='password'>{t('form.label.password')}</label>
                    <div className='input-group'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <Lock className='icon' />
                        </span>
                      </div>
                      <input
                        name='password'
                        id='password'
                        placeholder={t('form.label.password')}
                        className={`form-control
                            ${(errors.password && touched.password && handleSubmit) ? 'is-invalid' : ''}`}
                        type='password'
                        {...field}
                        data-cy='password-input'
                      />
                    </div>
                  </div>
                )}
              </Field>
              <div className='form-row'>
                <div className='col'>
                  <span
                    className='pt-2 font-weight-bold underline-dotted pointer'
                    onClick={() => auth.updateVisibleModal('forgotPassword')}
                  >
                    <u data-cy='forgot-password-link'>
                      {t('auth.login.forgot-password')}
                    </u>
                  </span>

                </div>
              </div>
              <SubmitButton name='sign-in' btnClassName='auth-modal-btn' />
            </Form>
          )}
        </Formik>
      </div>
      <p className='text-center mt-3'>
        {t('auth.login.no-account')}
        {isModal && (
          <button
            type='button'
            className='btn-link font-weight-bold'
            onClick={() => auth.updateVisibleModal('registerOptions')}
          >
            <u>{t('auth.login.sign-up-page')}</u>
          </button>
        )}
        {!isModal && (
          <LocalizedLink
            className='font-weight-bold'
            to={params?.gameId ? `/auth/signup/${params.gameId}` : '/auth/signup'}
          >
            <u data-cy='signup-link'>{t('auth.login.sign-up-page')}</u>
          </LocalizedLink>
        )}
      </p>
    </div>
  );
}
