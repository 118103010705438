import { Promotion, PromotionStatus } from '../account/promotion/Promotion.model';
import { post } from './Request';

export async function claimPromotion(
  host: string,
  promoId: string,
  currentPromotions: Promotion[],
  platformName?: string,
): Promise<Promotion[]> {
  const url = `${host}/players/me/promotions/${promoId}/claim`;
  const { promocode } = await post<{ promocode: string, platform?: string }>(url, { data: { platformName } });

  const promos = [...currentPromotions];
  const promoIndex = promos.findIndex((promotion) => promotion.promotionData.promotionId === promoId);
  const updatedPromo: Promotion = {
    ...currentPromotions[promoIndex],
    userData: {
      status: PromotionStatus.Claimed,
      promocode: { code: promocode },
    },
  };
  promos[promoIndex] = updatedPromo;

  return promos;
}
