/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, Fragment, useEffect, useRef } from 'react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';

import WarningIcon from '@mui/icons-material/Warning';
import {
  Answers,
  MultipleQuestion,
  SimpleQuestion,
  StringQuestion,
  MatrixQuestion,
  Answer,
} from './QuestionTypes/QuestionBase';
import { CheckboxQuestion } from './QuestionTypes/CheckboxQuestion';
import { RadioQuestion } from './QuestionTypes/RadioQuestion';
import { MatrixRadioQuestion } from './QuestionTypes/MatrixRadioQuestion';
import { SemDiffMatrixQuestion } from './QuestionTypes/SemDiffMatrixQuestion';

import { TextQuestion } from './QuestionTypes/TextQuestion';
import { TermsOfService } from '../../preregistration/TermsOfService';
import { UserAdsTracking } from '../../preregistration/Preregistration.model';

export type Questions = SimpleQuestion | MultipleQuestion | MatrixQuestion | StringQuestion;
interface SurveyProps {
  questions: Questions[];
  title: string;
  submitSurvey: (answers: Answers, tosAccepted: boolean, utmParams? : UserAdsTracking) => Promise<void>;
  isCompleted?: boolean;
  onError?: (hasError: boolean) => void;
}

interface QuestionCircleProps {
  index: number,
  maxIndex: number,
  isCurrent: boolean,
  isMobile: boolean,
  isAnswered: boolean,
  hasRequirement?: boolean,
  changeQuestion: (index: number) => void,
}

function QuestionCircle({
  index,
  maxIndex,
  isCurrent,
  isMobile,
  isAnswered,
  hasRequirement,
  changeQuestion,
}: QuestionCircleProps) : JSX.Element {
  const checkControls = useAnimation();

  const checkVariants = {
    current: {
      scale: 1,
      width: 6,
      height: 6,
      rotate: 0,
      x: 0,
    },
    checked: {
      scale: 1,
      height: 4,
      transition: {
        type: 'tween',
        duration: 0.2,
        delay: 0.5,
        rotate: {
          delay: 0,
        },
      },
    },
  };

  const check1Variants = {
    current: {
      ...checkVariants.current,
      transition: {
        type: 'tween',
        duration: 0.2,
        rotate: {
          delay: 0.5,
        },
      },
    },
    active: {
      ...checkVariants.current,
      rotate: 45,
    },
    checked: {
      ...checkVariants.checked,
      width: 6,
      rotate: 45,
      x: -8,
    },
  };

  const check2Variants = {
    current: {
      ...checkVariants.current,
      display: 'none',
      transition: {
        type: 'tween',
        duration: 0.2,
        display: {
          delay: 0.2,
        },
        rotate: {
          delay: 0.2,
        },
      },
    },
    checked: {
      ...checkVariants.checked,
      width: 18,
      rotate: -45,
      x: 2,
      display: 'block',
    },
  };

  useEffect(() => {
    if (isCurrent) {
      checkControls.start('current');
    } else if (isAnswered) {
      checkControls.start('checked');
    }
  }, [isCurrent]);

  return (
    <div className='question-circle-node'>
      <div
        className={`question-circle
        ${maxIndex < index ? 'disabled' : ''}
        ${hasRequirement && !isCurrent && !isAnswered && !isMobile ? 'optional' : ''}
        `}
        onClick={() => index <= maxIndex && !isMobile && changeQuestion(index)}
        data-cy={`question-circle-${index}`}
      >
        <AnimatePresence>
          {(isAnswered || isCurrent) && (
            <>
              <motion.span
                variants={check1Variants}
                initial={{ scale: 0 }}
                animate={checkControls}
                exit={{ scale: 0 }}
                className='check'
              />
              <motion.span
                variants={check2Variants}
                initial={{ scale: 0 }}
                animate={checkControls}
                className='check'
              />
            </>
          )}
        </AnimatePresence>
      </div>
      <div className={`question-line
        ${maxIndex < index ? 'disabled' : ''}
        ${maxIndex === index ? 'in-progress' : ''}
        `}
      />
    </div>
  );
}

function QuestionType({ currentQuestion, answers, isMobile, addAnswer }
: {
  currentQuestion: Questions,
  answers: Answers,
  isMobile: boolean,
  addAnswer: (answer: Answer,
    questionId: string) => void
}): JSX.Element {
  const currentAnswer = answers[currentQuestion.id];

  if ('rows' in currentQuestion) {
    if (Array.isArray(currentQuestion.rows[0].value)) {
      return (
        <SemDiffMatrixQuestion
          question={currentQuestion}
          currentAnswered={currentAnswer as number[] || []}
          isMobile={isMobile}
          addAnswer={addAnswer}
        />
      );
    }
    return (
      <MatrixRadioQuestion
        question={currentQuestion}
        currentAnswered={currentAnswer as number[] || []}
        isMobile={isMobile}
        addAnswer={addAnswer}
      />
    );
  }

  if ('multiple' in currentQuestion) {
    return (
      <CheckboxQuestion
        question={currentQuestion}
        currentAnswered={currentAnswer as number[] || []}
        addAnswer={addAnswer}
      />
    );
  }

  if ('textFormat' in currentQuestion) {
    return (
      <TextQuestion
        question={currentQuestion}
        currentAnswered={currentAnswer as string}
        addAnswer={addAnswer}
      />
    );
  }

  return (
    <RadioQuestion
      question={currentQuestion}
      currentAnswered={currentAnswer as number}
      addAnswer={addAnswer}
    />
  );
}

export function Survey({ questions,
  title,
  submitSurvey,
  isCompleted,
  onError }: SurveyProps): JSX.Element {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [maxIndex, setMaxIndex] = useState<number>(0);
  const [answers, setAnswers] = useState<Answers>({});
  const [answered, setAnswered] = useState<boolean[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const [completed, setCompleted] = useState<boolean>(false);
  const [tosAccepted, setTosAccepted] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const circleRefs = useRef([]);
  const validateAnswer = (currentAnswer: Answer, questionId: string): void => {
    const questionIndex = questions.findIndex((question) => question.id === questionId);
    if (questionIndex >= 0) {
      const currentQuestion = questions[questionIndex];

      const validText = (): boolean => {
        if (currentAnswer === '') return false;
        switch ((currentQuestion as StringQuestion).textFormat) {
          case 'url':
            return /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/gi.test(currentAnswer as string);
          case 'email':
            return /^[\w-][\w-.]*@([\w-]+.)+[\w-]{2,4}$/.test(currentAnswer as string);
          default:
            return currentAnswer && true;
        }
      };

      let isAnswered = true;
      if (
        typeof currentAnswer === 'undefined'
        || (Array.isArray(currentAnswer) && ((currentAnswer as number[]).includes(undefined) || !currentAnswer.length))
        || ('rows' in currentQuestion && (currentAnswer as number[]).length !== currentQuestion.rows.length)
        || ('textFormat' in currentQuestion && !validText())
      ) {
        isAnswered = false;
      }

      const newAnswered = [...answered];
      newAnswered[questionIndex] = isAnswered;
      setAnswered(newAnswered);

      if (questionIndex >= 0 && !isAnswered) {
        setMaxIndex(questionIndex);
        if (currentIndex > questionIndex) {
          setCurrentIndex(questionIndex);
        }
      }
    }
  };

  const meetsRequirement = (index: number, newAnswers = answers): boolean => {
    const requirement = questions[index].requires;
    if (requirement) {
      return (Array.isArray(newAnswers[requirement.questionId])
        ? (newAnswers[requirement.questionId] as number[]).includes(requirement.choice)
        : newAnswers[requirement.questionId] === requirement.choice);
    }
    return true;
  };

  const resetRequirement = (ans: Answers, questionId: string): Answers => {
    const questionIndex = questions.findIndex((q) => q.id === questionId);
    let newMax = questionIndex;
    const newAnswers = ans;
    const newAnswered = [...answered];

    for (let i = maxIndex; i > questionIndex; i -= 1) {
      const currentQuestion = questions[i];
      if (currentQuestion.requires && currentQuestion.requires.questionId === questions[questionIndex].id) {
        if (!meetsRequirement(i, newAnswers)) {
          delete newAnswers[currentQuestion.id];
          newAnswered[i] = false;
        } else if (!answered[i]) {
          newMax = i;
        }
      } else {
        newMax = Math.max(i, newMax);
      }
    }

    setAnswered(newAnswered);
    setMaxIndex(newMax);
    if (currentIndex > newMax) {
      setCurrentIndex(newMax);
    }

    return newAnswers;
  };

  const addAnswer = (answer: Answer, questionId: string): void => {
    let newAnswers = { ...answers };
    newAnswers[questionId] = answer;
    if (typeof answer !== 'string') {
      newAnswers = resetRequirement(newAnswers, questionId);
    }
    setAnswers(newAnswers);

    validateAnswer(answer, questionId);
  };

  const handleChangeQuestion = (nextIndex: number): void => {
    if (nextIndex < currentIndex || answered[currentIndex]) {
      if (nextIndex < questions.length) {
        if (meetsRequirement(nextIndex)) {
          setCurrentIndex(nextIndex);
          setMaxIndex(Math.max(maxIndex, nextIndex));
        } else {
          handleChangeQuestion(nextIndex > currentIndex ? nextIndex + 1 : nextIndex - 1);
        }
      } else {
        setCompleted(true);
      }
    }
  };

  const handleWindowSizeChange = (): void => {
    setIsMobile(window.innerWidth < 768);
  };

  const handleScroll = (): void => {
    const windowY = window.scrollY;
    for (const node of circleRefs.current) {
      const currentTop = windowY - node.offsetTop;
      const height = node.clientHeight;
      const clampedProgress = Math.min(Math.max(0, currentTop + 40), height - 40);
      const circle = node.children[0].children[0];
      circle.style.top = `${clampedProgress}px`;
    }
  };

  // Move this message to a new UTM handler and make it generic
  const getPlayerAdsTrackingInfo = (): UserAdsTracking => {
    const trackingData = sessionStorage.getItem('userAdsTrackingData');
    let payload:UserAdsTracking;
    if (trackingData?.length) {
      try {
        payload = JSON.parse(trackingData);
      } catch (e) {
        return undefined;
      }
    }
    return payload;
  };

  const handleTermsSelection = async (isTosAccepted: boolean): Promise<void> => {
    try {
      setSubmitting(true);
      setTosAccepted(isTosAccepted);
      await submitSurvey(answers, isTosAccepted, getPlayerAdsTrackingInfo());
      setSubmitting(false);
      setCompleted(true);
      if (onError) {
        onError(false);
      }
      // Do this in UTM handler
      sessionStorage.removeItem('userAdsTrackingData');
    } catch (e) {
      setTosAccepted(false);
      setCompleted(false);
      setSubmitting(false);
      if (onError) {
        onError(true);
      }
    }
  };

  useEffect(() => {
    let debounce: ReturnType<typeof setTimeout>;
    window.addEventListener('resize', () => {
      clearTimeout(debounce);
      debounce = setTimeout(handleWindowSizeChange, 100);
    });

    if (isMobile) {
      window.addEventListener('scroll', handleScroll);
      handleChangeQuestion(maxIndex);
    } else {
      for (const node of circleRefs.current) {
        const circle = node.children[0].children[0];
        circle.style.top = '0px';
      }
    }

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  useEffect(() => {
    if (isCompleted) {
      setCompleted(isCompleted);
    }
  }, [isCompleted]);

  return (
    <motion.div
      className={`survey-card ${completed ? 'completed' : ''}`}
      layout
      initial={{ opacity: 0, y: 80, scale: 0 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: 80 }}
      transition={{
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.3,
      }}
    >
      <motion.div layout='position' className='w-100'>
        {!completed ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              type: 'tween',
              delay: 0.3,
              duration: 0.2,
              ease: 'easeInOut',
            }}
          >
            <h3 data-cy='survey-title'>{title}</h3>
            <div className='question-circle-container'>
              { questions.map((question, i) => (
                <Fragment key={i}>
                  {meetsRequirement(i) && (!isMobile || i <= currentIndex) && (
                    <div
                      key={question.id}
                      ref={(el) => {
                        if (el) {
                          circleRefs.current = [...circleRefs.current, el];
                        }
                      }}
                      className='question-mobile-container'
                    >
                      <QuestionCircle
                        index={i}
                        maxIndex={maxIndex}
                        isCurrent={currentIndex === i}
                        isMobile={isMobile}
                        hasRequirement={questions[i].requires != null}
                        changeQuestion={handleChangeQuestion}
                        isAnswered={answered[i]}
                      />
                      { isMobile && (
                        <QuestionType
                          currentQuestion={questions[i]}
                          answers={answers}
                          isMobile={isMobile}
                          addAnswer={addAnswer}
                        />
                      )}
                    </div>
                  )}
                </Fragment>
              ))}
            </div>

            { !isMobile && (
              <QuestionType
                currentQuestion={questions[currentIndex]}
                answers={answers}
                isMobile={isMobile}
                addAnswer={addAnswer}
              />
            )}

            <motion.div layout className='nav-btn-container'>
              {!isMobile && (
                <button
                  type='button'
                  onClick={() => handleChangeQuestion(currentIndex - 1)}
                  className='btn btn-primary-game btn-block'
                  disabled={currentIndex === 0}
                  data-cy='survey-back-btn'
                >
                  previous
                </button>
              )}

              <button
                type='button'
                onClick={() => handleChangeQuestion(currentIndex + 1)}
                className='btn btn-primary-game'
                disabled={!answered[currentIndex]}
                data-cy='survey-next-btn'
              >
                next
              </button>
            </motion.div>
          </motion.div>
        ) : (
          <>
            <TermsOfService onSelect={handleTermsSelection} submitting={submitting} />

            <AnimatePresence>
              { tosAccepted && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ type: 'tween', ease: 'easeInOut' }}
                  className='newsletter-warning-container'
                >
                  <WarningIcon className='icon warning' />
                  <span className='text'>
                    By agreeing to the terms of use,
                    You will be automatically subscribed to Meet Your Maker Playtest newsletter.
                  </span>
                </motion.div>
              )}
            </AnimatePresence>
          </>
        )}
      </motion.div>
    </motion.div>
  );
}
