/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { ErrorOutline, WarningAmber, CheckCircleOutline } from '@mui/icons-material';
import { Chip } from '../chip/Chip';

export interface MediaCardStatus {
  type: 'error' | 'warning' | 'success',
  text: string,
  showIcon?: boolean
}

export interface MediaCardCtaOptions {
  cta: () => Promise<void>;
  disabled: boolean;
  label: string
}

interface Props {
  imgUrl: string | null;
  imgAlt: string;
  title: string;
  text?: string;
  status?: MediaCardStatus | null;
  badges?: string[];
  details?: string;
  ctaOptions?: MediaCardCtaOptions | null;
  className?: string;
}

export function MediaCard({
  imgUrl, imgAlt, title, text, status, badges = [], details, ctaOptions, className,
}: Props): JSX.Element {
  const statusIcon = ((): JSX.Element | null => {
    if (status && status.showIcon) {
      const iconClass = 'icon';
      switch (status.type) {
        case 'error':
          return <ErrorOutline fontSize='small' className={iconClass} />;
        case 'warning':
          return <WarningAmber fontSize='small' className={iconClass} />;
        case 'success':
          return <CheckCircleOutline fontSize='small' className={iconClass} />;
        default:
          return null;
      }
    } else {
      return null;
    }
  })();

  return (
    <div className={`media-card ${className}`}>
      <div className='media-card--img-container'>
        {status && (
          <div className={`media-card--img-status media-card--img-status-${status.type}`}>
            {statusIcon}
            <span data-cy={`${title?.replaceAll(' ', '-')}-status`}>{status.text}</span>
          </div>
        )}
        <img className='media-card--img' src={imgUrl ?? ''} alt={imgAlt} />
      </div>
      <span className='media-card--details'>{details}</span>
      {badges.length > 0 && (
        <div className='media-card--badges'>
          {badges.map((badge) => <Chip key={badge} label={badge} />)}
        </div>
      )}
      <h3 className='media-card--title'>{title}</h3>
      {text && <p className='media-card--text'>{text}</p>}
      {ctaOptions && (
        <button
          type='button'
          className='btn btn-primary-game media-card--button'
          disabled={ctaOptions.disabled}
          onClick={ctaOptions.cta}
          data-cy={`${title?.replaceAll(' ', '-')}-btn`}
        >
          {ctaOptions.label}
        </button>
      ) }
    </div>
  );
}
