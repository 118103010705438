import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { Portal } from '@mui/base/Portal';
import { motion } from 'framer-motion';
import { BackDrop } from './BackDrop';

interface Props {
  textClose?: string;
  classClose?: string;
  textConfirm?: string;
  classConfirm?: string;
  className?: string;
  refresh?: boolean;
  disabled?: boolean;
  show: boolean;
  init?: boolean;
  isInfoModal?: boolean;
  children?: JSX.Element | JSX.Element[];
  close?: () => void;
  autoClose?: boolean;
  confirm?: () => void;
}

export function Modal(props: Props): JSX.Element {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [refresh, setRefresh] = useState(props.refresh);

  const close = (): void => {
    setIsSubmitted(true);
    props.close();
  };

  const handleEscape = (evt: KeyboardEvent): void => {
    let isEscape = false;
    if ('key' in evt) {
      isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
      if (props.show && isEscape) {
        close();
      }
    }
  };

  const confirm = (): void => {
    setIsSubmitted(true);
    props.confirm();
  };

  useEffect(() => {
    if (isSubmitted && props.init) {
      setIsSubmitted(false);
    }
  }, [props.init]);

  useEffect(() => {
    if (props.show) {
      setIsSubmitted(false);
      if (props.autoClose) {
        document.addEventListener('keydown', handleEscape);
      }
    }

    return () => {
      if (props.autoClose) {
        document.removeEventListener('keydown', handleEscape);
      }
    };
  }, [props.show]);

  useEffect(() => {
    setRefresh(props.refresh);
  }, [props.refresh]);

  return (
    <Portal container={document.getElementById('modal-root-cpn-manager')}>
      {props.show && (
        <BackDrop onClick={props.autoClose ? props.close : () => {}}>
          <div className={`modal-cpn-manager ${props.className || ''}`}>
            <motion.div
              onClick={(e) => e.stopPropagation()}
              key='modal'
              initial={{ opacity: 0, scale: 0.95, y: 10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, type: 'tween', ease: 'easeOut' }}
            >
              <div className='modal-cpn-manager--dialog'>
                {props.isInfoModal && (
                  <IconButton aria-label='close' className='info-close-btn' onClick={close}>
                    <CloseIcon />
                  </IconButton>
                )}
                <div className='modal-cpn-manager--body' data-cy='modal-body-div'>
                  {props.children}
                </div>
                {!props.isInfoModal && (
                  <div className='modal-cpn-manager--footer'>
                    { props.textConfirm && (
                      <button
                        type='button'
                        style={{ color: 'black' }}
                        disabled={(isSubmitted || props.disabled)}
                        className={props.classConfirm || 'btn btn-primary-game'}
                        onClick={confirm}
                        data-cy='modal-confirm-button'
                      >
                        {isSubmitted && refresh && (
                          <>
                            <span className='dbd-loader' />
                            &nbsp;&nbsp;
                          </>
                        )}
                        {props.textConfirm}
                      </button>
                    ) }

                    { props.textClose && (
                      <button
                        type='button'
                        disabled={isSubmitted && props.disabled}
                        className={props.classClose || 'btn btn-link'}
                        onClick={close}
                      >
                        {props.textClose}
                      </button>
                    ) }
                  </div>
                )}
              </div>
            </motion.div>
          </div>
        </BackDrop>
      )}
    </Portal>
  );
}
