/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import { InsertLink } from '@mui/icons-material';
import { ReactComponent as OpenGiftIcon } from '../../../_assets/content/gift-open-outline.svg';
import { ReactComponent as GiftIcon } from '../../../_assets/content/gift-outline.svg';
import { ReactComponent as TwitchLogo } from '../../../_assets/logos/twitch-color.svg';

export const definition: Definition = {
  title: {
    type: DefinitionPropertyType.TranslatedString,

  },
  description: {
    type: DefinitionPropertyType.TranslatedString,
  },
  steps: {
    type: DefinitionPropertyType.Array,
    elements: {
      title: {
        type: DefinitionPropertyType.TranslatedString,
      },
      description: {
        type: DefinitionPropertyType.TranslatedString,
      },
    },
  },
};

// TODO: make icon a property when issue with images inside array is fixed BA-2342
const stepIcons = [
  <InsertLink key='link' />,
  <TwitchLogo key='twitch' />,
  <GiftIcon key='gift' />,
  <OpenGiftIcon key='open-gift' />,
];

export interface Props {
  title: string;
  description: string;
  steps: Array<{ title: string, description: string }>;
}

export function Instructions({ title, description, steps }: Props): JSX.Element {
  return (
    <div className='instructions'>
      <h2 className='instructions--title'>{title}</h2>
      <p className='instructions--description'>{description}</p>
      <div>
        {steps.map((step, index) => (
          <div key={step.title} className='instructions--step'>
            <div className='instructions--step--icon'>
              {stepIcons[index]}
            </div>
            <div className='instructions--step--text'>
              <span className='instructions--step--text--title'>{step.title}</span>
              <span>
                {step.description}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Instructions;
