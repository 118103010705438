/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  name: string,
  label: ReactNode;
  isChecked: boolean;
  onChangeHandler: (newValue: boolean) => void;
}

export function SingleCheckbox({ name, label, isChecked, onChangeHandler }: Props): JSX.Element {
  return (
    <FormControlLabel
      className={`single-checkbox ${name}-single-checkbox`}
      aria-label={name}
      control={(
        <Checkbox
          checked={isChecked}
          onChange={(event) => onChangeHandler(event.target.checked)}
          className='single-checkbox--box'
          data-cy={`${name}-checkbox`}
        />
      )}
      classes={{
        label: 'single-checkbox--label',
      }}
      label={label}
    />
  );
}
