/* eslint-disable react/no-children-prop */
/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Redirect } from 'react-router-dom';
import { LegalContentModel } from './LegalContent.model';
import { get } from '../utils/Request';
import { RequestError } from '../Error.model';
import { useSnackbar } from '../snackbar/SnackbarContext';
import { Loading } from '../_shared/loading/Loading';
import { WithTranslationNamespace } from '../utils/WithTranslationNamespace';
import { useConfig } from '../contexts/ConfigContext';

interface Props {
  id: 'privacy-policy' | 'terms-of-use';
}

export function LegalContent({
  id,
}: Props): JSX.Element {
  const { i18n } = useTranslation();

  const config = useConfig();
  const snackbar = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [legalContentModel, setLegalContentModel] = useState<LegalContentModel>(null);

  const retrieveLegalContentDetails = async (): Promise<void> => {
    try {
      const response = await get<LegalContentModel>(`${config.clientConfig.host}/legal-contents/${id}`);
      setLegalContentModel(response);
    } catch (e) {
      const err = e as RequestError;
      snackbar.addErrorMessage(err.code);
      setLegalContentModel(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    retrieveLegalContentDetails();
  }, []);

  return (
    <div
      key={`${id}-${i18n.language}`}
      className='legal-content-wrapper bg-white'
      data-cy='legal-content'
    >
      {(() => {
        if (isLoading) {
          return (
            <Loading />
          );
        }

        if (!legalContentModel) {
          return (
            <Redirect to='/' />
          );
        }

        return (
          <WithTranslationNamespace namespace={`legalContents/v${legalContentModel.version}`}>
            {({ t }) => (
              <div
                className='legal-content'
                data-cy={id}
              >
                <h1
                  className='legal-content-title'
                  data-cy={`${id}-title`}
                >
                  {t(`${legalContentModel.id}.title`)}
                </h1>
                <div
                  className='legal-content-body'
                  data-cy={`${id}-body`}
                >
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {t(`${legalContentModel.id}.body`)}
                  </ReactMarkdown>
                </div>
              </div>
            )}
          </WithTranslationNamespace>
        );
      })()}
    </div>
  );
}
