/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RedeemingGameProvider } from '../../../account/providers/ProviderEnum';
import { ModalFlowContainer } from '../../modal/modal-flow-container/ModalFlowContainer';
import { ModalFlowContent } from '../../modal/modal-flow-container/ModalFlowContent';
import { ModalFooter } from '../../modal/ModalFooter';
import { GameIds } from '../../../account/my-games/MyGames.model';
import { getGameThumbnail } from '../../../utils/Game';
import { GameStatusRequirement } from './GameStatusRequirement';
import { get } from '../../../utils/Request';
import { RequestError } from '../../../Error.model';
import { useSnackbar } from '../../../snackbar/SnackbarContext';
import { Loading } from '../../loading/Loading';
import { useConfig } from '../../../contexts/ConfigContext';

interface Props {
  next: () => void;
  cancel: () => void;
  platform: RedeemingGameProvider;
  gameId: GameIds;
}

export function VerifyGameStatus({ next, cancel, platform, gameId }: Props): JSX.Element {
  const { t } = useTranslation();
  const config = useConfig();
  const snackbar = useSnackbar();

  const [playedOnce, setPlayedOnce] = useState(false);
  const [quitGame, setQuitGame] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const platformName = t(platform);
  const gameName = t(`games.${gameId}`);

  const requirementsFulfilled = playedOnce;

  const checkStatus = async (): Promise<void> => {
    if (requirementsFulfilled) {
      next();
    } else {
      setIsLoading(true);
      try {
        const data: { online: boolean } = await get(
          `${config.clientConfig?.host}/players/me/accounts/${platform}/${gameId}/presence`,
        );
        setPlayedOnce(true);
        setQuitGame(!data.online);
      } catch (e) {
        const err = e as RequestError;
        if (err.code === 'NoAccountForThisProviderError') {
          setPlayedOnce(false);
        } else {
          snackbar.addErrorMessage(err?.code || '404');
        }
      } finally {
        setIsVerified(true);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <ModalFlowContainer title={t('verify-game-status.title', { platform: platformName })}>
      <ModalFlowContent name='verify-game-status'>
        <img src={getGameThumbnail(gameId)} alt={`${gameName} Thumbnail`} className='thumbnail' />
        <p><strong>{t('verify-game-status.subtitle')}</strong></p>
        {!isVerified && <Loading fullscreen={false} />}
        {isVerified && (
          <>
            <GameStatusRequirement
              initialLabel={t(
                'verify-game-status.requirements.play-once.initial',
                { game: gameName, platform: platformName },
              )}
              labelToDo={t(
                'verify-game-status.requirements.play-once.to-do',
                { game: gameName, platform: platformName },
              )}
              labelDone={t(
                'verify-game-status.requirements.play-once.done',
                { game: gameName, platform: platformName },
              )}
              verified={isVerified}
              completed={playedOnce}
            />
            <GameStatusRequirement
              initialLabel={t('verify-game-status.requirements.quit-game.initial', { game: gameName })}
              labelToDo={t('verify-game-status.requirements.quit-game.to-do', { game: gameName })}
              labelDone={t('verify-game-status.requirements.quit-game.done', { game: gameName })}
              verified={isVerified && playedOnce}
              completed={quitGame}
              warningOnly
            />
          </>
        )}
      </ModalFlowContent>
      <ModalFooter
        name='cross-progression'
        nextLabel={requirementsFulfilled ? t('button.continue') : t('verify-game-status.check-status')}
        cancelLabel={t('common.cancel')}
        nextHandler={checkStatus}
        nextIsLoading={isLoading && isVerified}
        nextDisabled={!isVerified}
        cancelHandler={cancel}
      />
    </ModalFlowContainer>
  );
}
