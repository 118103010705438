/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

type EnumVals<T> = T[keyof T];

export const AUTHENTICATION_METHODS = {
  TWITCH: 'twitch',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  DISCORD: 'discord',
  PASSWORD: 'password',
} as const;

export const LINKING_GAME_PROVIDERS = {
  STEAM: 'steam',
  EGS: 'egs',
  XBL: 'xbl',
  PSN: 'psn',
  NINTENDO: 'nintendo',
} as const;

export const REDEEMING_GAME_PROVIDERS = {
  STEAM: 'steam',
  EGS: 'egs',
  XBOX: 'xbox',
  MS: 'grdk',
  PSN: 'psn',
  NINTENDO: 'nintendo',
} as const;

export const PROVIDER_NAMES = {
  ...AUTHENTICATION_METHODS,
  ...LINKING_GAME_PROVIDERS,
  ...REDEEMING_GAME_PROVIDERS,
} as const;

export type AuthenticationMethod = EnumVals<typeof AUTHENTICATION_METHODS>;
export type LinkingGameProvider = EnumVals<typeof LINKING_GAME_PROVIDERS>;
export type RedeemingGameProvider = EnumVals<typeof REDEEMING_GAME_PROVIDERS>;

export type ProviderName = AuthenticationMethod | LinkingGameProvider | RedeemingGameProvider;
export type GameProvider = LinkingGameProvider | RedeemingGameProvider;

export const isLinkingGameProvider = (x: string): x is LinkingGameProvider => Object
  .values(LINKING_GAME_PROVIDERS).indexOf(x as LinkingGameProvider) >= 0;

export const isRedeemingGameProvider = (x: string): x is RedeemingGameProvider => Object
  .values(REDEEMING_GAME_PROVIDERS).indexOf(x as RedeemingGameProvider) >= 0;

export const getLinkingProviderFromRedeemingProvider = (provider: RedeemingGameProvider): LinkingGameProvider => {
  if (provider === 'xbox' || provider === 'grdk') {
    return 'xbl';
  }
  return provider;
};
