/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, ContentCopy } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import thumbnailWTF from '../../../../../_assets/thumbnail/wtf.jpg';
import { StepKey } from '../../models';
import { useAuth } from '../../../../../authentication/AuthenticationContext';
import { GameIds } from '../../../../../account/my-games/MyGames.model';
import { ClaimInstructions } from '../../claim-instuctions/ClaimInstructions';

interface Props {
  preRegId: string,
  isKeyClaimed?: boolean,
  onComplete: (stepKey: StepKey) => void
  onClose: (stepKey?: StepKey) => void
}
export function ClaimFreeGameStep({ preRegId, isKeyClaimed, onComplete, onClose } : Props): JSX.Element {
  const [steamKey, setSteamKey] = useState<string>();
  const [isKeyCopied, setIsKeyCopied] = useState<boolean>(false);
  const { gameId }: { gameId: GameIds } = useParams();
  const auth = useAuth();

  const { t } = useTranslation();

  useEffect(() => {
    const gamePreRegs = auth?.user?.preRegistrations?.[gameId];
    setSteamKey(gamePreRegs?.[preRegId]?.providerKey?.key);
    onComplete(StepKey.CLAIM_KEY);
  }, [auth?.user, gameId]);

  const handleCopyKey = (): void => {
    try {
      navigator.clipboard.writeText(steamKey);
      setIsKeyCopied(true);
    } catch (e) {
      setIsKeyCopied(false);
    }
  };
  return (
    <>
      <Typography variant='h6' sx={{ fontWeight: '500', marginBottom: '20px' }}>
        {t('claimFreeGame.claimGame.title')}
      </Typography>
      <img src={thumbnailWTF} alt='what the fog' height={213} className='game-img' />

      <Box flexGrow={1}>
        { isKeyClaimed
          ? (
            <>
              { steamKey
                ? (
                  <ClaimInstructions steamKey={steamKey} isInFlow />
                ) : <div className='loader'><CircularProgress /></div>}
            </>
          ) : (
            <Box my={4}>
              <Alert severity='error' variant='outlined' sx={{ color: '#d53f3f' }}>
                {t('claimFreeGame.errors.noKeyDuringTheFlow')}
              </Alert>
            </Box>
          )}

        {isKeyCopied ? (
          <Alert icon={<CheckCircleOutlined />} sx={{ marginTop: '1rem', color: '#28a745' }} variant='outlined'>
            {t('claimFreeGame.claimGame.steamKeyCopied')}
          </Alert>
        ) : null }

      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 5, justifyContent: 'space-between' }}>
        <button
          type='button'
          className='btn btn-link'
          data-cy='claim-step-close-button'
          onClick={() => { onClose(StepKey.CLAIM_KEY); }}
        >
          {t('button.close')}
        </button>
        <button
          type='button'
          disabled={!steamKey || !isKeyClaimed}
          className='btn btn-primary-game'
          data-cy='wtf-claim-modal-button'
          onClick={handleCopyKey}
        >
          <ContentCopy />
          {t('claimFreeGame.claimGame.copyKey')}
        </button>
      </Box>
    </>
  );
}
