/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import { RedeemingGameProvider } from '../../../account/providers/ProviderEnum';

export const CROSS_PROGRESSION_STEPS = [
  'select-platform',
  'step-list',
  'link',
  'verify-status',
  'how-it-works',
  'confirm-sync',
  'success',
  'failed'] as const;

export type CrossProgressionStep = typeof CROSS_PROGRESSION_STEPS[number];

export const NumberedSteps: { [key in CrossProgressionStep]: number } = {
  'select-platform': 0,
  'step-list': 0,
  link: 1,
  'verify-status': 2,
  'how-it-works': 3,
  'confirm-sync': 3,
  success: 3,
  failed: 0,
};

export const COMPENSATION_STATUSES = [
  'approved',
  'declined',
  'inReview',
  'upcoming',
] as const;
export type CompensationStatus = typeof COMPENSATION_STATUSES[number];

export const CompensationStatusType: {
  [key in CompensationStatus]: 'light' | 'dark' | 'success' | 'error' | 'warning' | 'info'
} = {
  approved: 'success',
  declined: 'error',
  inReview: 'warning',
  upcoming: 'info',
};

export interface CompensationDlc {
  name: string;
  platforms: RedeemingGameProvider[];
}

export interface Compensation {
  name: string,
  offers: { [key: string]: number },
  status: CompensationStatus,
  syncedOn: string,
  availableOn: string,
  platforms?: RedeemingGameProvider[]
  dlcs?: CompensationDlc[]
}

export interface PlayerCompensations {
  compensations: Compensation[],
  totals: {
    [currencyId: string]: number;
  }
}
