/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';

type Variant = 'primary' | 'secondary' | 'dark';

const variantsSelectOptions: { [key in Variant]: string } = {
  primary: 'Primary',
  secondary: 'Secondary',
  dark: 'Dark',
} as const;

const variantsClassMap: { [key in Variant]: string } = {
  primary: 'primary-game',
  secondary: 'secondary',
  dark: 'dark',
};

export const definition: Definition = {
  title: {
    type: DefinitionPropertyType.TranslatedString,
  },
  label: {
    type: DefinitionPropertyType.TranslatedString,
  },
  link: {
    type: DefinitionPropertyType.String,
  },
  variant: {
    type: DefinitionPropertyType.Select,
    options: variantsSelectOptions,
  },
};

interface Props {
  title?: string;
  label: string;
  link: string;
  variant?: Variant
}

export function LinkButton({ title, label, link, variant = 'primary' }: Props) : JSX.Element {
  return (
    <div className='shared-link-button-container'>
      {title ? (
        <div className='subtitle'>
          <h3>{title}</h3>
        </div>
      ) : <></>}
      <a
        href={link}
        target='_blank'
        rel='noreferrer'
        className={`btn btn-${variantsClassMap[variant]} shared-link-button`}
      >
        {label || 'Label'}
      </a>
    </div>
  );
}

export default LinkButton;
