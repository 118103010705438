/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../_shared/modal/Modal';

interface Props {
  close: () => void;
  confirm: () => void;
  show: boolean;
  message: string | JSX.Element;
}

export function ProviderConfirmationModal({ close, confirm, show, message }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      confirm={confirm}
      close={close}
      textConfirm={t('translation:button.confirm')}
      textClose={t('translation:common.cancel')}
    >
      <div>{message}</div>
    </Modal>
  );
}
