/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { Fragment } from 'react';
import Slider from '@mui/material/Slider';
import { MatrixRadioQuestionProps } from './MatrixRadioQuestion';
import { QuestionBase } from './QuestionBase';

import 'rc-slider/assets/index.css';

export function SemDiffMatrixQuestion({
  question,
  currentAnswered,
  isMobile,
  addAnswer,
}: MatrixRadioQuestionProps): JSX.Element {
  const midPoint = question.choices.length / 2;

  const handleChange = (rowIndex: number, choiceIndex: number): void => {
    const newAnswer = [...currentAnswered];
    newAnswer[rowIndex] = choiceIndex > midPoint ? choiceIndex - 1 : choiceIndex;
    if (choiceIndex !== midPoint) {
      addAnswer(newAnswer, question.id);
    } else {
      newAnswer[rowIndex] = undefined;
      addAnswer(newAnswer, question.id);
    }
  };

  const answerToPoint = (ans: number): number => {
    if (ans >= midPoint) {
      return ans + 1;
    }
    return ans;
  };

  const handleTooltip = (value: number): string => {
    if (value === midPoint) {
      return 'Please Select an Option';
    }
    if (value > midPoint) {
      return question.choices[value - 1];
    }

    return question.choices[value];
  };

  return (
    <QuestionBase question={question}>
      <div className='slider-grid'>
        {question.rows.map((row, index) => (
          <Fragment key={row.id}>
            <div className='title'>{row.value[0]}</div>
            <Slider
              className={`slider ${currentAnswered[row.id] == null ? 'disabled' : ''}`}
              sx={{
                '.MuiSlider-thumb': {
                  transform: `translate(-50%, ${isMobile ? '' : '-'}50%) scale(${
                    1 + (currentAnswered[row.id] >= midPoint
                      ? (currentAnswered[row.id] % 3) / 15
                      : (3 - currentAnswered[row.id]) / 15)
                  })`,
                },
              }}
              min={0}
              max={question.choices.length}
              value={currentAnswered[row.id] == null ? midPoint : answerToPoint(currentAnswered[row.id])}
              marks
              orientation={isMobile ? 'vertical' : 'horizontal'}
              valueLabelDisplay='auto'
              valueLabelFormat={handleTooltip}
              track={false}
              onChange={(event, value) => handleChange(row.id, value as number)}
              data-cy={`${question.id}-slider`}
            />
            <div className='title'>{row.value[1]}</div>
            {isMobile && index < question.rows.length - 1 && <span className='slider-divider' />}
          </Fragment>
        ))}
      </div>
    </QuestionBase>
  );
}
