/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { GuardRoute } from '../_shared/GuardRoute';

import { PersonalInformation } from './personal-information/PersonalInformation';
import { CommunicationPreferences } from './communication-preferences/CommunicationPreferences';
import { ChangePassword } from './change-password/ChangePassword';
import { MultiFactorAuthentication } from './multi-factor-authentication/MultiFactorAuthentication';
import { GamePage } from './my-games/GamePage';
import { Preregistration } from '../preregistration/Preregistration';
import { Providers } from './providers/Providers';
import { GameLibrary } from './my-games/GameLibrary';

export function Account(): JSX.Element {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path={`${match.path}/my-account/providers`} exact component={Providers} />
          <Route path={`${match.path}/my-account/games`} exact component={GameLibrary} />

          <Route path={`${match.path}/my-account/games/:gameId`} exact component={GamePage} />
          <GuardRoute path={`${match.path}/my-account/games/:gameId/preregistration`} authRoute>
            <Preregistration />
          </GuardRoute>

          <Route path={`${match.path}/personal-information`} exact component={PersonalInformation} />
          <Route
            path={`${match.path}/communication-preferences`}
            exact
            component={CommunicationPreferences}
          />
          <Route path={`${match.path}/change-password`} exact component={ChangePassword} />
          <Route path={`${match.path}/mfa`} exact component={MultiFactorAuthentication} />

          <Redirect to={`${match.path}/my-account/games`} />
        </Switch>
      </AnimatePresence>
    </>
  );
}
