/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { PagesContent } from '@bhvr/components-manager';
import localConfig from '../config.json';
import {
  ConfigContext, ClientConfig, ServerConfig, LocalStorageKeyName, PagesContentConfig,
} from './Config.model';
import { get } from '../utils/Request';
import { getButlerJWT } from '../utils/Iframe';

const ConfigurationContext = createContext<ConfigContext>({
  clientConfig: null,
  serverConfig: null,
  isClientConfigLoad: false,
  isServerConfigLoad: false,
  pagesContent: null,
  getVersion: null,
  updateStorageVersion: null,
  loadPagesContent: null,
});

export const useConfig = (): ConfigContext => useContext(ConfigurationContext);

const useProvideConfig = (): ConfigContext => {
  const [isClientConfigLoaded, setIsClientConfigLoaded] = useState<boolean>(false);
  const [isServerConfigLoaded, setIsServerConfigLoaded] = useState<boolean>(false);
  const [isConfigVersionLoaded, setIsConfigVersionLoaded] = useState<boolean>(false);
  const [clientConfig, setClientConfig] = useState<ClientConfig | null>(null);
  const [serverConfig, setServerConfig] = useState<ServerConfig | null>(null);

  // initial pagesContent state depending on localStorage
  let storagePagesContent: string = localStorage.getItem(LocalStorageKeyName.PAGES_CONTENT);
  if (storagePagesContent) {
    const { expiry } = JSON.parse(storagePagesContent);
    const now = new Date();
    if (now.getTime() > expiry) {
      localStorage.removeItem(LocalStorageKeyName.PAGES_CONTENT);
      storagePagesContent = null;
    }
  }

  const {
    version: storageVersion,
  } = storagePagesContent ? JSON.parse(storagePagesContent) : { version: undefined };

  const [pagesContent, setPagesContent] = useState<PagesContentConfig>(
    {
      version: undefined || storageVersion,
      pages: null,
    },
  );
  const updateStorageVersion = (currentGamesVersion: string): void => {
    setPagesContent({
      ...pagesContent,
      version: currentGamesVersion,
    });
    localStorage.setItem(LocalStorageKeyName.PAGES_CONTENT, JSON.stringify({
      version: currentGamesVersion,
    }));
  };

  const getVersion = async (): Promise<string> => {
    if (!pagesContent.version) {
      const searchParams = new URLSearchParams(location.search);
      const version = searchParams.get('version');

      if (version) {
        updateStorageVersion(version);
      }
      return version;
    }

    return pagesContent.version;
  };

  const loadPagesContent = async (gameId: string): Promise<PagesContentConfig> => {
    try {
      const version = await getVersion();
      if (!version) {
        throw new Error('Something went wrong: version needed');
      }
      const pageConf = await get<PagesContent>(
        `${clientConfig?.host}/management/editor/${version}/${gameId}`,
        {
          headers: {
            Authorization: `Bearer ${await getButlerJWT()}`,
          },
        },
      );
      const temp = { ...pagesContent };
      if (temp.pages) {
        temp.pages[gameId] = pageConf;
      } else {
        temp.pages = {
          [gameId]: pageConf,
        };
      }

      setPagesContent({
        version,
        pages: temp.pages,
        expiry: new Date().getTime() + 86400000, // set the expiry date to now day + 1
      });

      return { version, pages: temp.pages };
    } catch (error) {
      console.error(error);
      throw new Error('Something went wrong');
    }
  };

  // Load clientConfig
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let localPagesContent;
      try {
        localPagesContent = require('../pagesContent.json');
      } catch (err) {
        localPagesContent = require('../pagesContent.dev.json');
      }
      setPagesContent({
        pages: localPagesContent,
        ...pagesContent.version ? { version: pagesContent.version } : {},
      });
      setClientConfig(localConfig as ClientConfig);
      setIsClientConfigLoaded(true);
    } else if (process.env.REACT_APP_ENVIRONMENT === 'test') {
      const localPagesContent = require('../pagesContentTest.json');
      setPagesContent({
        pages: localPagesContent,
        ...pagesContent.version ? { version: pagesContent.version } : {},
      });
      setClientConfig(localConfig as ClientConfig);
      setIsClientConfigLoaded(true);
    } else {
      axios.get<{ [id: string]: PagesContent }>('/pagesContent.json').then((response) => {
        setPagesContent({
          pages: response.data,
          ...pagesContent.version ? { version: pagesContent.version } : {},
        });
      });
      axios.get<ClientConfig>('/config.json').then((response) => {
        setClientConfig(response.data);
        setIsClientConfigLoaded(true);
      });
    }

    setIsConfigVersionLoaded(true);
    getVersion();
  }, []);

  // Load serverConfig
  useEffect(() => {
    if (isClientConfigLoaded && isConfigVersionLoaded && !isServerConfigLoaded) {
      get<ServerConfig>(`${clientConfig?.host}/application/config`).then((config) => {
        setServerConfig(config);
        setIsServerConfigLoaded(true);
      });
    }
  }, [isClientConfigLoaded]);

  return {
    isClientConfigLoad: isClientConfigLoaded,
    isServerConfigLoad: isServerConfigLoaded,
    clientConfig,
    serverConfig,
    pagesContent,
    getVersion,
    updateStorageVersion,
    loadPagesContent,
  };
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function ProvideConfiguration({ children }: Props): JSX.Element {
  const config = useProvideConfig();

  return (
    <ConfigurationContext.Provider value={config}>
      {children}
    </ConfigurationContext.Provider>
  );
}
