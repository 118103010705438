/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React from 'react';
import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { TMedia, getImageUrl } from '../../../utils/ComponentsManager';

export interface Props {
  image: TMedia;
  title: string;
  description: string;
  caption?: string;
  background?: TMedia;
}

export function TutorialItem({ image, title, description, caption, background }: Props): JSX.Element {
  const backgroundImage = getImageUrl(background);

  return (
    <div className='tutorial-item'>
      <div
        className='tutorial-item--image'
        style={{ backgroundImage: `url('${backgroundImage}')` }}
        data-cy='tutorial-img'
      >
        <img alt={title} src={getImageUrl(image)} />
      </div>
      {caption && (
        <div className='tutorial-item--caption' data-cy='tutorial-caption'>
          <Typography variant='caption'>
            {caption}
          </Typography>
        </div>
      )}
      <p><strong>{title}</strong></p>
      <p>
        <Trans
          i18nKey={description}
          components={{ b: <strong /> }}
        />
      </p>
    </div>
  );
}
