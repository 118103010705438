/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

export enum RegistrationStatus {
  UNAVAILABLE = 'unavailable',
  UNREGISTERED = 'unregistered',
  REGISTERED = 'registered',
  SELECTED = 'selected',
  GRANTED = 'granted',
  SELECTED_NO_CLAIM = 'selected_no_claim',
}
export interface Survey {
  surveyId: string;
  response: object;
  createdAt: number;
  gameId: string;
}

export interface GameSurveys {
  dbd?: Survey[],
  mym?: Survey[],
}

export enum ReferralStatus {
  SENT = 'sent',
  ACCEPTED = 'accepted',
}
