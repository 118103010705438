/*
* (C) Behaviour Interactive Inc. - All Rights Reserved
* Unauthorized copying of this file, via any medium, is strictly prohibited
* This file is proprietary and confidential
*/

import React, { ReactNode } from 'react';
import { Definition, DefinitionPropertyType } from '@bhvr/components-manager';
import { components } from '../index';
import { TMedia, getImageUrl } from '../../../utils/ComponentsManager';

export const possibleChildren = [
  components.HEADER,
  components.ACTIVATION,
  components.INSTRUCTIONS,
];

export const definition: Definition = {
  background: {
    type: DefinitionPropertyType.Image,
  },
};

interface Props {
  background: TMedia,
  children: ReactNode
}

export function Section({ background, children }: Props): JSX.Element {
  const backgroundImage = getImageUrl(background);

  return (
    <div className='section--container'>
      <div
        className='section-bg'
        style={{ backgroundImage:
          backgroundImage ? `url('${backgroundImage.replace(/(\r\n|\n|\r)/gm, '')}')` : '' }}
      />
      <div className='section'>
        {children}
      </div>
    </div>
  );
}

export default Section;
